import classNames from "classnames";
import { useIntl } from "react-intl";

import { defaultIconClassNames } from "./Icon";
import { ProductIconProps, ProductIconVariant } from "./ProductIcon";

const MAGNIFIER_COLOR_MAP: Record<ProductIconVariant, string> = {
  default: "",
  active: "text-primaryBlue500",
  inactive: "",
};

const DOCUMENT_COLOR_MAP: Record<ProductIconVariant, string> = {
  default: "",
  active: "text-greenFonoa",
  inactive: "",
};

export default function DocumentSearchIcon({
  size = 32,
  className = "",
  variant = "default",
}: ProductIconProps) {
  const intl = useIntl();
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 18 18"
      fill="none"
      className={classNames(defaultIconClassNames, className)}
      xmlns="http://www.w3.org/2000/svg"
      aria-label={intl.formatMessage({ defaultMessage: "Search", id: "xmcVZ0" })}
    >
      <g clipPath="url(#clip0_9403_291273)">
        <rect
          width="3"
          height="3"
          transform="matrix(-1 0 0 1 8 10)"
          fill="currentColor"
          className={classNames(DOCUMENT_COLOR_MAP[variant])}
        />
        <rect
          width="4"
          height="1"
          transform="matrix(-1 0 0 1 13 10)"
          fill="currentColor"
          className={classNames(DOCUMENT_COLOR_MAP[variant])}
        />
        <rect
          width="2"
          height="1"
          transform="matrix(-1 0 0 1 11 12)"
          fill="currentColor"
          className={classNames(DOCUMENT_COLOR_MAP[variant])}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M1.25 7.5C1.25 6.5335 2.0335 5.75 3 5.75L6 5.75V7.25L3 7.25C2.86193 7.25 2.75 7.36193 2.75 7.5L2.75 15C2.75 15.1381 2.86193 15.25 3 15.25L14.5 15.25C14.6381 15.25 14.75 15.1381 14.75 15L14.75 11L16.25 11V15C16.25 15.9665 15.4665 16.75 14.5 16.75H3C2.0335 16.75 1.25 15.9665 1.25 15L1.25 7.5Z"
          fill="currentColor"
          className={classNames(DOCUMENT_COLOR_MAP[variant])}
        />
        <path
          d="M13.225 6.6675C13.555 6.1425 13.75 5.535 13.75 4.875C13.75 3.0075 12.2425 1.5 10.375 1.5C8.5075 1.5 7 3.0075 7 4.875C7 6.7425 8.5075 8.25 10.3675 8.25C11.0275 8.25 11.6425 8.055 12.16 7.725L14.5 10.065L15.565 9L13.225 6.6675ZM10.375 6.75C9.34 6.75 8.5 5.91 8.5 4.875C8.5 3.84 9.34 3 10.375 3C11.41 3 12.25 3.84 12.25 4.875C12.25 5.91 11.41 6.75 10.375 6.75Z"
          fill="currentColor"
          className={classNames(MAGNIFIER_COLOR_MAP[variant])}
        />
      </g>
      <defs>
        <clipPath id="clip0_9403_291273">
          <rect width="18" height="18" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
