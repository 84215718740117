import classNames from "classnames";
import { useIntl } from "react-intl";

import { defaultIconClassNames, IconProps } from "./Icon";

export default function HelpIcon({ size = 32, className = "", secondColor = "white" }: IconProps) {
  const intl = useIntl();
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 16 16"
      fill="none"
      className={classNames(defaultIconClassNames, className)}
      xmlns="http://www.w3.org/2000/svg"
      aria-label={intl.formatMessage({ defaultMessage: "Help", id: "SENRqu" })}
    >
      <path d="M8.5 1C4.63599 1 1.5 4.13599 1.5 8C1.5 11.864 4.63599 15 8.5 15C12.364 15 15.5 11.864 15.5 8C15.5 4.13599 12.364 1 8.5 1Z" />
      <path d="M9.19933 10.8H7.79932V12.2001H9.19933V10.8Z" fill={secondColor} />
      <path
        d="M8.50068 3.80005C6.95366 3.80005 5.70068 5.05306 5.70068 6.60004H7.1007C7.1007 5.83003 7.7307 5.20003 8.50071 5.20003C9.27072 5.20003 9.90069 5.83006 9.90069 6.60007C9.90069 8.00008 7.80069 7.82506 7.80069 10.1001H9.2007C9.2007 8.52508 11.3007 8.3501 11.3007 6.60007C11.3007 5.05306 10.0477 3.80005 8.50068 3.80005Z"
        fill={secondColor}
      />
    </svg>
  );
}
