import { colors } from "@fonoa/design/tokens";
import { useRouter } from "next/router";
import Script from "next/script";
import { useEffect } from "react";

import { useAuth } from "@/hooks/auth";
import { routes } from "@/lib/routes";
import { isBrowser } from "@/lib/utils";
import config from "@/server/config";

import styles from "./zendesk.module.scss";

function useZendeskSearchSuggestion() {
  const { route } = useRouter();

  if (route.includes(routes.lookup)) return "validation";
  if (route.includes(routes.invoicing)) return "invoicing";
  if (route.includes(routes.tax)) return "tax";
  if (route.includes(routes.reporting)) return "reporting";
  if (route === routes.dashboard) return "dashboard";
  return "";
}

export function ZendeskScript() {
  const searchSuggestion = useZendeskSearchSuggestion();
  const auth = useAuth();

  // The widget is wrapped by a div which is affecting the layout
  // this sets it's height to 0
  useEffect(() => {
    const interval = setInterval(() => {
      const element = document.querySelector("#launcher")?.parentElement;
      if (element) {
        element.classList.add(styles["zendeskContainer"]);
        clearInterval(interval);
      }
    }, 1000);
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    if (isBrowser) {
      window.zESettings = {
        webWidget: {
          color: { launcher: colors.blueGray50, launcherText: colors.blueGray600 },
        },
      };
    }
  }, []);

  useEffect(() => {
    if (isBrowser && searchSuggestion) {
      window.zE?.("webWidget", "helpCenter:setSuggestions", { search: searchSuggestion });
    }
  }, [searchSuggestion]);

  useEffect(() => {
    if (!auth.user) {
      window.zE?.("webWidget", "hide");
    } else {
      window.zE?.("webWidget", "show");
    }
  }, [auth.user]);

  if (!auth.user) {
    return null;
  }

  return (
    <Script
      id="ze-snippet"
      src={`https://static.zdassets.com/ekr/snippet.js?key=${config.ZENDESK_KEY()}`}
      strategy="lazyOnload"
    />
  );
}
