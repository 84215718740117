import classNames from "classnames";
import { useIntl } from "react-intl";

import { defaultIconClassNames, IconProps } from "./Icon";

export default function CommentIcon({ size = 12, className = "" }: IconProps) {
  const intl = useIntl();
  return (
    <svg
      width={size}
      height={size}
      className={classNames(defaultIconClassNames, className)}
      viewBox="0 0 19 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      aria-label={intl.formatMessage({ defaultMessage: "Comments", id: "wCgTu5" })}
    >
      <path d="M3.375 3H15.375V12H4.2525L3.375 12.8775V3ZM3.375 1.5C2.55 1.5 1.8825 2.175 1.8825 3L1.875 16.5L4.875 13.5H15.375C16.2 13.5 16.875 12.825 16.875 12V3C16.875 2.175 16.2 1.5 15.375 1.5H3.375ZM4.875 9H10.875V10.5H4.875V9ZM4.875 6.75H13.875V8.25H4.875V6.75ZM4.875 4.5H13.875V6H4.875V4.5Z" />
    </svg>
  );
}
