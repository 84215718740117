import classNames from "classnames";
import { useIntl } from "react-intl";

import { defaultIconClassNames, IconProps } from "./Icon";

export default function RefreshIcon({ size = 16, className = "" }: IconProps) {
  const intl = useIntl();
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 16 16"
      fill="none"
      className={classNames(defaultIconClassNames, className)}
      xmlns="http://www.w3.org/2000/svg"
      aria-label={intl.formatMessage({ defaultMessage: "Refresh", id: "rELDbB" })}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.94794 1.00022C10.484 0.973357 13.3245 3.36114 13.8982 6.83931C14.1473 8.35015 13.9555 9.64193 13.6625 10.6138L15.3532 9.88156L16 11.3033L12.575 12.7867C12.0863 12.9984 11.5175 12.7899 11.2813 12.3125L9.82791 9.37506L11.2636 8.69878L12.0788 10.3464C12.3457 9.54108 12.5444 8.4218 12.3242 7.08645C11.8716 4.3425 9.684 2.53504 6.96035 2.55573C4.01597 2.57811 1.59423 4.97298 1.59423 8.0001C1.59423 11.0066 4.31553 13.4443 6.9542 13.4444C7.39443 13.4445 7.75129 13.7927 7.75126 14.2223C7.75123 14.6518 7.39433 15 6.95409 15C3.43573 14.9998 0 11.8664 0 8.0001C0 4.15446 3.09165 1.02953 6.94794 1.00022Z"
      />
    </svg>
  );
}
