import classNames from "classnames";
import { useIntl } from "react-intl";

import { defaultIconClassNames, IconProps } from "./Icon";

export default function CheckIconSmall({ size = 10, className = "" }: IconProps) {
  const intl = useIntl();
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 10 10"
      fill="none"
      className={classNames(defaultIconClassNames, className)}
      xmlns="http://www.w3.org/2000/svg"
      aria-label={intl.formatMessage({ defaultMessage: "Check", id: "RDZVQL" })}
    >
      <polygon
        id="Path"
        points="3.62072781 6.67438276 8.44239836 1.25 9.56351265 2.24654541 3.7185232 8.82216265 0.49 5.79542593 1.51591101 4.70111948"
      />
    </svg>
  );
}
