import classNames from "classnames";
import { useIntl } from "react-intl";

import { defaultIconClassNames, IconProps } from "./Icon";

export default function EditIcon({ size = 32, className = "", onClick }: IconProps) {
  const intl = useIntl();
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 14 14"
      fill="none"
      className={classNames(defaultIconClassNames, className)}
      xmlns="http://www.w3.org/2000/svg"
      aria-label={intl.formatMessage({ defaultMessage: "Edit", id: "wEQDC6" })}
      onClick={onClick}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.5982 0.227806C10.9019 -0.0759353 11.3944 -0.0759353 11.6981 0.227806L13.7722 2.30188C14.0759 2.60562 14.0759 3.09808 13.7722 3.40182L3.40182 13.7722C3.25596 13.9181 3.05813 14 2.85185 14H0.777778C0.348223 14 0 13.6518 0 13.2222V11.1481C0 10.9419 0.0819442 10.744 0.227806 10.5982L10.5982 0.227806ZM9.6555 3.37037L10.6296 4.3445L12.1223 2.85185L11.1481 1.87772L9.6555 3.37037ZM9.52969 5.44444L8.55556 4.47031L1.55556 11.4703V12.4444H2.52969L9.52969 5.44444Z"
      />
    </svg>
  );
}
