import classNames from "classnames";
import { useIntl } from "react-intl";

import { defaultIconClassNames, IconProps } from "./Icon";

export default function ThumbsUpActiveIcon({ size = 18, className = "" }: IconProps) {
  const intl = useIntl();
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 18 18"
      fill="none"
      className={classNames(defaultIconClassNames, className)}
      xmlns="http://www.w3.org/2000/svg"
      aria-label={intl.formatMessage({ defaultMessage: "Thumbs up", id: "0HnZ1c" })}
    >
      <g clipPath="url(#clip0_13281_3667)">
        <path
          d="M1.5 15H3C3.4125 15 3.75 14.6625 3.75 14.25V7.5C3.75 7.0875 3.4125 6.75 3 6.75H1.5V15ZM16.3725 9.66C16.455 9.4725 16.5 9.27 16.5 9.06V8.25C16.5 7.425 15.825 6.75 15 6.75H10.875L11.565 3.2625C11.6025 3.0975 11.58 2.9175 11.505 2.7675C11.3325 2.43 11.115 2.1225 10.845 1.8525L10.5 1.5L5.6925 6.3075C5.4075 6.5925 5.25 6.975 5.25 7.3725V13.2525C5.25 14.2125 6.0375 15 7.005 15H13.0875C13.6125 15 14.1075 14.7225 14.3775 14.2725L16.3725 9.66Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_13281_3667">
          <rect width="18" height="18" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
