import classNames from "classnames";
import { useIntl } from "react-intl";

import { defaultIconClassNames, IconProps } from "./Icon";

const ratio = 10 / 14.0;
export default function DragIcon({ size = 32, className = "", onClick }: IconProps) {
  const intl = useIntl();
  return (
    <svg
      width={Math.round(size * ratio)}
      height={size}
      viewBox="0 0 10 14"
      fill="none"
      className={classNames(defaultIconClassNames, className)}
      onClick={onClick}
      xmlns="http://www.w3.org/2000/svg"
      aria-label={intl.formatMessage({ defaultMessage: "Drag", id: "r5pj/5" })}
    >
      <path d="M1.66667 3.66666C2.58333 3.66666 3.33333 2.91666 3.33333 1.99999C3.33333 1.08333 2.58333 0.333328 1.66667 0.333328C0.75 0.333328 0 1.08333 0 1.99999C0 2.91666 0.75 3.66666 1.66667 3.66666ZM1.66667 5.33333C0.75 5.33333 0 6.08333 0 6.99999C0 7.91666 0.75 8.66666 1.66667 8.66666C2.58333 8.66666 3.33333 7.91666 3.33333 6.99999C3.33333 6.08333 2.58333 5.33333 1.66667 5.33333ZM1.66667 10.3333C0.75 10.3333 0 11.0833 0 12C0 12.9167 0.75 13.6667 1.66667 13.6667C2.58333 13.6667 3.33333 12.9167 3.33333 12C3.33333 11.0833 2.58333 10.3333 1.66667 10.3333Z" />
      <path d="M8.33366 3.66666C9.25033 3.66666 10.0003 2.91666 10.0003 1.99999C10.0003 1.08333 9.25033 0.333328 8.33366 0.333328C7.41699 0.333328 6.66699 1.08333 6.66699 1.99999C6.66699 2.91666 7.41699 3.66666 8.33366 3.66666ZM8.33366 5.33333C7.41699 5.33333 6.66699 6.08333 6.66699 6.99999C6.66699 7.91666 7.41699 8.66666 8.33366 8.66666C9.25033 8.66666 10.0003 7.91666 10.0003 6.99999C10.0003 6.08333 9.25033 5.33333 8.33366 5.33333ZM8.33366 10.3333C7.41699 10.3333 6.66699 11.0833 6.66699 12C6.66699 12.9167 7.41699 13.6667 8.33366 13.6667C9.25033 13.6667 10.0003 12.9167 10.0003 12C10.0003 11.0833 9.25033 10.3333 8.33366 10.3333Z" />
    </svg>
  );
}
