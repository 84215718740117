import { AbilityBuilder, subject } from "@casl/ability";
// this import is marked as circuler dependency because it's mixed with types imports, which shoulnd't be an issue
// eslint-disable-next-line @nx/enforce-module-boundaries
import { stubCountries } from "@fonoa/data-access/countries";
// importing `type` only, no "bad" circular dependency when doing that
// eslint-disable-next-line @nx/enforce-module-boundaries
import type { ConditionalRole, ProductType } from "@fonoa/data-access/user";

import { Abilities, Actions, AppAbility, Subjects } from "./ability/ability";
import { Permission } from "./permissions";
import { PermissionProductType, Role, RoleWithAvailableCountries } from "./types";

export const defineRulesForConditionalRoles = (
  conditionalRoles: ConditionalRole[],
  roles: RoleWithAvailableCountries[]
) => {
  const { can, rules } = new AbilityBuilder(AppAbility);

  conditionalRoles.forEach((conditionalRole) => {
    const role = roles.find((r) => r.id === conditionalRole.role_id);

    role?.permissions.forEach((permission) => {
      const [product, action, subject] = permission.split(":") as [
        ProductType,
        Actions,
        Extract<Subjects, string>
      ];

      let allowedCountries = conditionalRole.allowed_countries || [];

      // No allowed countries assigned means full country access given to the tenant
      if (!allowedCountries.length) {
        if (role.availableCountries.length) {
          allowedCountries = role.availableCountries.map(({ code }) => code);
        } else {
          // no `availableCountries` defined in the role doc means access to all countries supported by the product (full access)
          allowedCountries = stubCountries
            .filter((country) =>
              country.supportedByProducts?.find((supportedProduct) => supportedProduct === product)
            )
            .map((country) => country.id);
        }
      }

      if (allowedCountries.length) {
        can(action, subject, { product, country: { $in: allowedCountries } });
      }

      can(action, subject, { product, country: { $eq: undefined } }); // this duplicated rule is required for authorizing without the need of sending the country condition
    });
  });

  return rules;
};

export const defineAbilityForConditionalRoles = (
  conditionalRoles: ConditionalRole[],
  roles: RoleWithAvailableCountries[]
) => {
  const rules = defineRulesForConditionalRoles(conditionalRoles, roles);

  return new AppAbility(rules);
};

export const getAllowedCountriesForPermission = (
  conditionalRoles: ConditionalRole[],
  roles: RoleWithAvailableCountries[],
  permission: Permission
) => {
  // all roles that have the specified permision
  const rolesWithPermission = roles.filter((r) => r.permissions?.some((p) => p === permission));

  // all roles assigned to the user that have the specified permission
  const assignedRoles = conditionalRoles.filter((cr) =>
    rolesWithPermission.some((rp) => rp.id === cr.role_id)
  );

  // if no roles or assigned roles have this permission
  if (!rolesWithPermission.length || !assignedRoles.length) return null;

  // any assigned role without geographic limitation
  const anyRoleAllowAllCountries = assignedRoles.some(
    (cr) => !cr.allowed_countries || !cr.allowed_countries.length
  );

  // allowed countries spread in all assigned roles
  let allowedCountries = assignedRoles
    .filter((cr) => cr.allowed_countries)
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    .map((cr) => cr.allowed_countries!)
    .flat(2);

  // No allowed countries assigned means access to all countries of that Tenant
  if (!allowedCountries?.length || anyRoleAllowAllCountries) {
    allowedCountries = rolesWithPermission
      .map((rp) => rp.availableCountries.map(({ code }) => code))
      .flat(2);
  }

  return [...new Set(allowedCountries)];
};

// Given a list of `allRoles` and a specific `roleToCheck`, this function returns a list of
//   countries for which the user has all the permissions of the `roleToCheck`.
// This is used to prevent a user from inviting another user with more allowed countries than the user themself.
export const getAllowedCountriesForRole = (
  userRoles: ConditionalRole[],
  allRoles: RoleWithAvailableCountries[],
  roleToCheck: RoleWithAvailableCountries
) => {
  const allowedCountriesForEachPermissionOfRole = roleToCheck.permissions.map(
    (permission) => getAllowedCountriesForPermission(userRoles, allRoles, permission) || []
  );

  type CountryCount = { [key: string]: number };
  const countryCount = allowedCountriesForEachPermissionOfRole.reduce((acc, cur) => {
    cur.forEach((country) => {
      acc[country] = (acc[country] || 0) + 1;
    });

    return acc;
  }, {} as CountryCount);

  const countriesWhichAreAllowedForEveryPermission = Object.entries(countryCount).reduce(
    (acc, cur) => {
      const [country, count] = cur;
      if (count === roleToCheck.permissions.length) {
        // country is allowed for all permissions of the role
        acc.add(country);
      }
      return acc;
    },
    new Set<string>()
  );

  return countriesWhichAreAllowedForEveryPermission;
};

// This returns `allRoles` filtered by the roles that the user has all required permissions for,
//  i.e. is the user's role equal to (or a superset of) the given role?
export const getRolesThatUserHasAllPermissionsFor = (
  allRoles: RoleWithAvailableCountries[],
  userRoles: ConditionalRole[]
) => {
  const currentUserAbility = defineAbilityForConditionalRoles(userRoles, allRoles);

  return allRoles.filter((role) => userHasAllPermissionsForRole(role, currentUserAbility));
};

const userHasAllPermissionsForRole = (role: Role, userAbility: AppAbility) => {
  return role.permissions.every((permission) => {
    const [product, action, subj] = permission.split(":") as [PermissionProductType, ...Abilities];

    return userAbility.can(
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore because `permission` will always contain a valid combination of product, action, subject
      action,
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      subject(subj, { product })
    );
  });
};
