import classNames from "classnames";
import { useIntl } from "react-intl";

import { defaultIconClassNames, IconProps } from "./Icon";

export default function InvalidFileIcon({ size = 24, className = "" }: IconProps) {
  const intl = useIntl();
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 47 46"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={classNames(defaultIconClassNames, className)}
      aria-label={intl.formatMessage({ defaultMessage: "Invalid file", id: "L0xyX3" })}
    >
      <g clipPath="url(#clip0_9818_55207)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M9.7 3.59375C9.06759 3.59375 8.40625 4.15353 8.40625 5.03125V40.9687C8.40625 41.8465 9.0676 42.4062 9.7 42.4062H37.3C37.9324 42.4062 38.5938 41.8465 38.5938 40.9687V23V12.9375H30.5078C29.8131 12.9375 29.25 12.3744 29.25 11.6797V3.59375H23.5H9.7ZM9.7 1.4375C7.79462 1.4375 6.25 3.04648 6.25 5.03125V40.9687C6.25 42.9535 7.79462 44.5625 9.7 44.5625H37.3C39.2054 44.5625 40.75 42.9535 40.75 40.9687V23V12.3479C40.75 11.9768 40.6065 11.6201 40.3495 11.3524L31.2557 1.87949C30.9846 1.59712 30.6101 1.4375 30.2187 1.4375H23.5H9.7Z"
          fill="#627C91"
        />
        <path d="M6.16064 0.288124L42.3197 44.6286" stroke="#627C91" strokeWidth="3" />
        <path d="M7.41943 -0.909126L43.7277 43.4668" stroke="white" strokeWidth="2" />
      </g>
      <defs>
        <clipPath id="clip0_9818_55207">
          <rect width="46" height="46" fill="white" transform="translate(0.5)" />
        </clipPath>
      </defs>
    </svg>
  );
}
