import classNames from "classnames";
import { useIntl } from "react-intl";

import { defaultIconClassNames, IconProps } from "./Icon";

export default function MailIcon({ size = 32, className = "" }: IconProps) {
  const intl = useIntl();
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 16 12"
      fill="none"
      className={classNames(defaultIconClassNames, className)}
      xmlns="http://www.w3.org/2000/svg"
      aria-label={intl.formatMessage({ defaultMessage: "Mail", id: "aEJJQd" })}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.85 5.97342L1.75272 1.57088C1.77005 1.56717 1.78803 1.56522 1.80645 1.56522H14.1935C14.212 1.56522 14.2299 1.56717 14.2473 1.57088L8.15 5.97342C8.06027 6.03821 7.93973 6.03821 7.85 5.97342ZM10.6086 6.12172L14.4516 3.34684V8.71159L10.6086 6.12172ZM9.04998 7.24709L9.26099 7.09473L14.2158 10.4338C14.2085 10.4345 14.201 10.4348 14.1935 10.4348H1.80645C1.79896 10.4348 1.79154 10.4345 1.78421 10.4338L6.739 7.09473L6.95002 7.24709C7.57812 7.70061 8.42188 7.70061 9.04998 7.24709ZM5.39143 6.12172L1.54839 3.34684V8.71159L5.39143 6.12172ZM1.80645 0C0.808777 0 0 0.817567 0 1.82609V10.1739C0 11.1824 0.808776 12 1.80645 12H14.1935C15.1912 12 16 11.1824 16 10.1739V1.82609C16 0.817567 15.1912 0 14.1935 0H1.80645Z"
      />
    </svg>
  );
}
