export const Products = {
  LOOKUP: "lookup",
  TAX: "tax",
  INVOICING: "invoicing",
  REPORTING: "reporting",
  EINVOICING: "einvoicing",
  RETURNS: "returns",
} as const;

export const Subproducts = {
  REVERSE_LOOKUP: "reverse_lookup",
} as const;

export type Product = typeof Products[keyof typeof Products];
export type Subproduct = typeof Subproducts[keyof typeof Subproducts];

export interface IProduct {
  product: Product;
  isActive?: boolean;
}

export interface ISubproduct {
  subproduct: Subproduct;
  isActive?: boolean;
}

export function productFromPath(path: string): Product | undefined {
  const components = path.split("/").filter((path) => path);
  const firstComponent = components?.[0] === "api" ? components?.[1] : components?.[0];
  return Products[firstComponent?.toUpperCase() as Uppercase<Product>];
}
