type BrowserRule = {
  name: string;
  fullName: string;
  url: string;
  versions?: string[];
  belowVersion?: number;
  allVersions?: boolean;
};

export const NOT_SUPPORTED_BROWSERS: BrowserRule[] = [
  {
    name: "chrome",
    fullName: "Google Chrome",
    url: "https://www.google.com/chrome/",
    versions: ["95.0.4637"],
    belowVersion: 64,
  },
  {
    name: "edge",
    fullName: "Microsoft Edge",
    url: "https://www.microsoft.com/en-us/edge/download",
    belowVersion: 15,
  },
  {
    name: "ie",
    fullName: "Internet Explorer",
    url: "",
    allVersions: true,
  },
  {
    name: "firefox",
    fullName: "Mozilla Firefox",
    url: "https://www.mozilla.org/en-US/firefox/new/",
    belowVersion: 67,
  },
  {
    name: "safari",
    fullName: "Apple Safari",
    url: "https://www.apple.com/safari/",
    belowVersion: 12,
  },
  {
    name: "opera",
    fullName: "Opera",
    url: "https://www.opera.com/download",
    belowVersion: 51,
  },
];
