import classNames from "classnames";
import { useIntl } from "react-intl";

import { defaultIconClassNames, IconProps } from "./Icon";

export default function EmoticonIcon({ size = 16, className = "" }: IconProps) {
  const intl = useIntl();
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 19 19"
      className={classNames(defaultIconClassNames, className)}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      aria-label={intl.formatMessage({ defaultMessage: "Emoticon", id: "UvoEfa" })}
    >
      <g id="insert_emoticon" clipPath="url(#clip0_12943_1490)">
        <path
          id="Vector"
          d="M8.3265 1.33334C4.6465 1.33334 1.6665 4.32001 1.6665 8.00001C1.6665 11.68 4.6465 14.6667 8.3265 14.6667C12.0132 14.6667 14.9998 11.68 14.9998 8.00001C14.9998 4.32001 12.0132 1.33334 8.3265 1.33334ZM8.33317 13.3333C5.3865 13.3333 2.99984 10.9467 2.99984 8.00001C2.99984 5.05334 5.3865 2.66668 8.33317 2.66668C11.2798 2.66668 13.6665 5.05334 13.6665 8.00001C13.6665 10.9467 11.2798 13.3333 8.33317 13.3333ZM10.6665 7.33334C11.2198 7.33334 11.6665 6.88668 11.6665 6.33334C11.6665 5.78001 11.2198 5.33334 10.6665 5.33334C10.1132 5.33334 9.6665 5.78001 9.6665 6.33334C9.6665 6.88668 10.1132 7.33334 10.6665 7.33334ZM5.99984 7.33334C6.55317 7.33334 6.99984 6.88668 6.99984 6.33334C6.99984 5.78001 6.55317 5.33334 5.99984 5.33334C5.4465 5.33334 4.99984 5.78001 4.99984 6.33334C4.99984 6.88668 5.4465 7.33334 5.99984 7.33334ZM8.33317 11.6667C9.8865 11.6667 11.2065 10.6933 11.7398 9.33334H4.9265C5.45984 10.6933 6.77984 11.6667 8.33317 11.6667Z"
          fill="#50697D"
        />
      </g>
      <defs>
        <clipPath id="clip0_12943_1490">
          <rect width="16" height="16" fill="white" transform="translate(0.333252)" />
        </clipPath>
      </defs>
    </svg>
  );
}
