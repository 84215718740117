import classNames from "classnames";
import { useIntl } from "react-intl";

import { defaultIconClassNames, IconProps } from "./Icon";

export default function ArrowLeftIcon({ size = 32, className = "", onClick }: IconProps) {
  const intl = useIntl();
  return (
    <svg
      width={size / 2}
      height={size}
      viewBox="0 0 7 12"
      fill="none"
      className={classNames(defaultIconClassNames, className)}
      onClick={onClick}
      xmlns="http://www.w3.org/2000/svg"
      aria-label={intl.formatMessage({ defaultMessage: "Arrow left", id: "L0NDSe" })}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.87352 -4.924e-08L7 1.15074L2.25296 6L7 10.8493L5.87352 12L0.684788 6.69953C0.304253 6.3108 0.304253 5.68919 0.684788 5.30046L5.87352 -4.924e-08Z"
      />
    </svg>
  );
}
