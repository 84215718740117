import classNames from "classnames";
import { useIntl } from "react-intl";

import { defaultIconClassNames, IconProps } from "./Icon";

export default function DeveloperIcon({
  size = 32,
  className = "",
  secondColor = "white",
}: IconProps) {
  const intl = useIntl();
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 16 16"
      fill="none"
      className={classNames(defaultIconClassNames, className)}
      xmlns="http://www.w3.org/2000/svg"
      aria-label={intl.formatMessage({ defaultMessage: "Developer", id: "yZJxVS" })}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3 1C1.89543 1 1 1.89543 1 3V13C1 14.1046 1.89543 15 3 15H13C14.1046 15 15 14.1046 15 13V3C15 1.89543 14.1046 1 13 1H3Z"
      />
      <path
        d="M8.41967 6L7.16066 10H7.58033L8.83934 6H8.41967ZM5.5082 6.25568L4.0918 7.89205L4 8L4.0918 8.10795L5.5082 9.74432L5.84918 9.52841L4.52459 8L5.84918 6.47159L5.5082 6.25568ZM10.4918 6.25568L10.1508 6.47159L11.4754 8L10.1508 9.52841L10.4918 9.74432L11.9082 8.10795L12 8L11.9082 7.89205L10.4918 6.25568Z"
        fill={secondColor}
        stroke={secondColor}
        strokeWidth="0.5"
      />
    </svg>
  );
}
