import classNames from "classnames";
import { useIntl } from "react-intl";

import { defaultIconClassNames, IconProps } from "./Icon";

export default function HistoryIcon({ size = 12, className = "" }: IconProps) {
  const intl = useIntl();
  return (
    <svg
      width={size}
      height={size}
      className={classNames(defaultIconClassNames, className)}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      aria-label={intl.formatMessage({ defaultMessage: "History", id: "djJp6c" })}
    >
      <path d="M9.75 2.25C6.0225 2.25 3 5.2725 3 9H0.75L3.6675 11.9175L3.72 12.0225L6.75 9H4.5C4.5 6.0975 6.8475 3.75 9.75 3.75C12.6525 3.75 15 6.0975 15 9C15 11.9025 12.6525 14.25 9.75 14.25C8.3025 14.25 6.99 13.6575 6.045 12.705L4.98 13.77C6.2025 14.9925 7.8825 15.75 9.75 15.75C13.4775 15.75 16.5 12.7275 16.5 9C16.5 5.2725 13.4775 2.25 9.75 2.25ZM9 6V9.75L12.21 11.655L12.75 10.7475L10.125 9.1875V6H9Z" />
    </svg>
  );
}
