import classNames from "classnames";
import { useIntl } from "react-intl";

import { defaultIconClassNames, IconProps } from "./Icon";

export default function CalendarIcon({ size = 32, className = "" }: IconProps) {
  const intl = useIntl();
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 16 16"
      fill="none"
      className={classNames(defaultIconClassNames, className)}
      xmlns="http://www.w3.org/2000/svg"
      aria-label={intl.formatMessage({ defaultMessage: "Calendar", id: "Coy6SH" })}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.75 0V1.25H10.25V0H11.75V1.25H14C14.9665 1.25 15.75 2.0335 15.75 3V13C15.75 13.9665 14.9665 14.75 14 14.75H2C1.0335 14.75 0.25 13.9665 0.25 13V3C0.25 2.0335 1.0335 1.25 2 1.25H4.25V0H5.75ZM10.25 2.75H5.75L5.75 5.25H10.25L10.25 2.75ZM11.75 5.25L11.75 2.75H14C14.1381 2.75 14.25 2.86193 14.25 3V5.25H11.75ZM2 2.75H4.25L4.25 5.25H1.75V3C1.75 2.86193 1.86193 2.75 2 2.75ZM1.75 6.75V13C1.75 13.1381 1.86193 13.25 2 13.25H14C14.1381 13.25 14.25 13.1381 14.25 13V6.75H1.75Z"
      />
    </svg>
  );
}
