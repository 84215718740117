import { CloseIcon, CollapseIcon, DragIcon, ExpandIcon } from "@fonoa/ui-components/icons";
import { Transition } from "@headlessui/react";
import classNames from "classnames";
import { DragEventHandler, useEffect, useRef, useState } from "react";
import { useIntl } from "react-intl";

const embedEvent = "video-modal.embed";

type Point = {
  x: number;
  y: number;
};

export type VideoModalProps = {
  startMinimized?: boolean;
  initialUrl?: string;
  onClose?: () => void;
};

function Component({ startMinimized = false, onClose, initialUrl }: VideoModalProps) {
  const intl = useIntl();
  const [minimized, setMinimized] = useState(startMinimized);
  const [start, setStart] = useState<Point | null>(null);
  const [isDragging, setIsDragging] = useState<boolean>(false);
  const [embedUrl, setEmbedUrl] = useState<string | undefined>(initialUrl);
  const resizeDebounceTimer = useRef<NodeJS.Timeout | null>(null);
  const containerName = `video-modal`;

  const handleDragStart: DragEventHandler<HTMLDivElement> = (event) => {
    setIsDragging(true);
    event.preventDefault();

    if (!start) {
      setStart({ x: event.clientX, y: event.clientY });
    }
  };

  const handleDragMovement = (event: MouseEvent) => {
    if (!isDragging || !start || (event.clientX === 0 && event.clientY === 0)) return;

    const container = document.getElementById(containerName);

    if (container) {
      container.style.transform = `translate(
          ${event.clientX - start.x}px,
          ${event.clientY - start.y}px
          )`;

      event.preventDefault();
      event.stopPropagation();
    }
  };

  const handleDragEnd = () => {
    setIsDragging(false);
  };

  const handleEmbedUrl = (event: CustomEvent) => {
    setEmbedUrl(event.detail.url);
  };

  const handleResize = () => {
    if (resizeDebounceTimer.current) {
      clearTimeout(resizeDebounceTimer.current);
    }
    resizeDebounceTimer.current = setTimeout(resetPosition, 300);
  };

  const handleClose = () => {
    setEmbedUrl(undefined);
    onClose?.();
  };

  useEffect(() => {
    document.addEventListener("mousemove", handleDragMovement);
    document.addEventListener("mouseup", handleDragEnd);
    document.addEventListener(embedEvent, handleEmbedUrl as EventListener);
    window.addEventListener("resize", handleResize);
    return () => {
      document.removeEventListener("mousemove", handleDragMovement);
      document.removeEventListener("mouseup", handleDragEnd);
      document.removeEventListener(embedEvent, handleEmbedUrl as EventListener);
      window.removeEventListener("resize", handleResize);
    };
  });

  const toggleMinimized = () => {
    resetPosition();
    setMinimized(!minimized);
  };

  const resetPosition = () => {
    const container = document.getElementById(containerName);

    if (container) {
      setStart(null);
      container.style.transform = "translate(0px, 0px)";
    }
  };

  const containerTransitions = `opacity 300ms,
        width 500ms ease-in-out,
        height 500ms ease-in-out,
        margin 500ms ease-in-out,
        background-color 500ms 500ms
        ${!isDragging ? ", 500ms ease-in-out" : ""}`;

  const dragIcon = (
    <Transition
      as="div"
      show={minimized}
      enter="transition ease-out duration-300"
      enterFrom="opacity-0"
      enterTo="opacity-100"
      leave="transition ease-in duration-300"
      leaveFrom="opacity-100"
      leaveTo="opacity-0"
      className="m-2 flex-none cursor-pointer"
      draggable={true}
      onMouseDown={handleDragStart}
    >
      <DragIcon size={14} />
    </Transition>
  );

  return (
    <Transition
      id={containerName}
      show={Boolean(embedUrl)}
      as="div"
      style={{ transition: containerTransitions }}
      className={classNames(
        "fixed right-0 bottom-0 z-50 flex items-center justify-center shadow-lg",
        {
          "h-full w-full bg-black/60": !minimized,
          "h-53 w-72": minimized,
          "mr-2 mb-2": minimized,
        }
      )}
      enterFrom="opacity-0"
      enterTo="opacity-100"
      leaveFrom="opacity-100"
      leaveTo="opacity-0"
    >
      <div
        className={classNames("relative overflow-hidden transition-size duration-500 ease-in-out", {
          "h-96 w-180": !minimized,
          "h-53 w-72": minimized,
          "bg-white": !isDragging,
        })}
      >
        <div className="relative flex h-full w-full flex-col">
          <div className="relative grow">
            <object
              className={classNames("h-full w-full", {
                hidden: isDragging,
              })}
              aria-label={intl.formatMessage({ defaultMessage: "Video player", id: "fL3yWF" })}
              data={embedUrl}
            />
            <div
              className={classNames("absolute top-0 left-0 h-full w-full bg-black/60", {
                hidden: !isDragging,
              })}
            />
          </div>
          <div className="flex w-full flex-none flex-row items-center bg-white p-2">
            {dragIcon}
            <div className="grow" />
            {minimized ? (
              <ExpandIcon
                className="m-2 flex-none cursor-pointer"
                size={12}
                onClick={toggleMinimized}
              />
            ) : (
              <CollapseIcon
                className="m-2 flex-none cursor-pointer"
                size={12}
                onClick={toggleMinimized}
              />
            )}
            <CloseIcon className="m-2 cursor-pointer" size={18} onClick={handleClose} />
          </div>
        </div>
      </div>
    </Transition>
  );
}

type EmbedUrlEvent = {
  url: string;
};

const videoModal = {
  Component,
  open: (url: string) => {
    document.dispatchEvent(
      new CustomEvent<EmbedUrlEvent>(embedEvent, {
        detail: { url },
      })
    );
  },
};

export default videoModal;
