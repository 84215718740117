import classNames from "classnames";
import { FormattedMessage } from "react-intl";

interface LoaderProps {
  color?: string;
  size?: number;
  className?: string;
}

export function Loader({ color, className, size = 32 }: LoaderProps) {
  return (
    <>
      <div
        className={classNames(
          `inline-block align-text-bottom rounded-full animate-spin not-sr-only`,
          color,
          className
        )}
        style={{
          width: `${size}px`,
          height: `${size}px`,
          borderWidth: `${Math.floor(size / 6)}px`,
          borderLeftColor: `transparent`,
        }}
      />
      <span className="sr-only">
        <FormattedMessage defaultMessage="Loading" id="iFsDVR" />
      </span>
    </>
  );
}
