import { createPopper, Modifier, OptionsGeneric } from "@popperjs/core";
import classNames from "classnames";
import { ComponentType, createRef, MouseEvent, ReactNode, useState } from "react";
import { FormattedMessage } from "react-intl";

import useClickOutside from "../../lib/utils/useOnClickOutside";
import { IconProps, InfoIcon } from "../icons";
import { DataAttributes } from "../types";
import { convertDataAttributes, KEYS, useOnKeyPress } from "../utils";

export type Position =
  | "left"
  | "left-start"
  | "left-end"
  | "right"
  | "right-start"
  | "right-end"
  | "bottom"
  | "bottom-start"
  | "bottom-end"
  | "top"
  | "top-start"
  | "top-end";

export interface TooltipProps {
  body?: ReactNode;
  title?: ReactNode;
  icon?: ComponentType<React.PropsWithChildren<IconProps>>;
  disabled?: boolean;
  trigger?: "onClick" | "onHover";
  position?: Position;
  mode?: "light" | "dark";
  children?: ReactNode;
  offset?: [number, number];
  widthClassName?: string;
  maxWidthClassName?: string;
  textAlign?: "left" | "center" | "right";
  size?: "small" | "regular";
  iconSize?: number;
  panelPadding?: string;
  panelClassName?: string;
  onClose?: () => void;
  closeOnInnerClick?: boolean;
  dataAttributes?: DataAttributes;
  onTooltipOpen?: (event?: MouseEvent<HTMLDivElement>) => void;
  onTooltipClose?: () => void;
  createPopperOptions?: Partial<OptionsGeneric<Partial<Modifier<any, any>>>>;
  iconClassName?: string;
}

export default function Tooltip({
  mode = "light",
  position = "bottom",
  disabled,
  title,
  icon: TooltipIcon = InfoIcon,
  iconSize = 12,
  body,
  trigger = "onHover",
  offset,
  onClose,
  children,
  widthClassName = "w-40",
  maxWidthClassName,
  size = "regular",
  textAlign = "left",
  panelPadding = "2.5",
  panelClassName,
  closeOnInnerClick,
  dataAttributes,
  onTooltipOpen,
  onTooltipClose,
  createPopperOptions,
  iconClassName,
}: TooltipProps) {
  const [tooltipShow, setTooltipShow] = useState(false);

  const theme = mode === "dark" ? "bg-primaryBlue900 text-white" : "bg-white text-primaryBlue900";
  const tooltipVisible = tooltipShow ? "" : "hidden";

  const iconRef = createRef<HTMLDivElement>();
  const tooltipRef = createRef<HTMLDivElement>();

  const openTooltip = (event?: MouseEvent<HTMLDivElement>) => {
    setTooltipShow(true);

    iconRef.current &&
      tooltipRef.current &&
      createPopper(iconRef.current, tooltipRef.current, {
        placement: position,
        modifiers: [
          {
            name: "offset",
            options: {
              offset,
            },
          },
        ],
        ...createPopperOptions,
      });
    onTooltipOpen && onTooltipOpen(event);
  };

  const closeTooltip = () => {
    setTooltipShow(false);
    typeof onClose === "function" && onClose();
    onTooltipClose?.();
  };

  useClickOutside(tooltipRef, () => {
    if (tooltipShow && trigger === "onClick") {
      closeTooltip();
    }
  });

  useOnKeyPress(KEYS.ESCAPE, () => trigger === "onClick" && closeTooltip(), tooltipShow);

  return (
    <div className="flex flex-wrap">
      <div
        className="w-auto text-center"
        onMouseLeave={trigger === "onHover" ? closeTooltip : undefined}
      >
        <div
          className={children ? "w-auto" : "w-min"}
          onMouseEnter={trigger === "onHover" ? openTooltip : undefined}
          onClick={trigger === "onClick" ? openTooltip : undefined}
          {...convertDataAttributes(dataAttributes)}
          ref={iconRef}
          role={trigger === "onClick" ? "button" : undefined}
        >
          {children ? (
            children
          ) : (
            <>
              <span className="sr-only">
                <FormattedMessage defaultMessage="More info" id="HOsSgX" />
              </span>
              {TooltipIcon && (
                <TooltipIcon
                  size={iconSize}
                  className={classNames("text-blueGray500 fill-current", iconClassName)}
                />
              )}
            </>
          )}
        </div>
        <div
          className={classNames(
            `text- z-50 block text-xs font-normal leading-normal ${textAlign} whitespace-normal break-normal no-underline`,
            {
              "h-0 w-0 border-none opacity-0": !tooltipShow,
              [`${widthClassName}`]: !maxWidthClassName && tooltipShow,
              [`${maxWidthClassName}`]: Boolean(maxWidthClassName),
              "opacity-0": disabled,
              "pointer-events-none": disabled,
            }
          )}
          onClick={closeOnInnerClick ? closeTooltip : undefined}
          ref={tooltipRef}
        >
          <div
            className={classNames(`relative shadow-lg`, theme, tooltipVisible, panelClassName, {
              [`p-${panelPadding} rounded-lg`]: size === "regular",
              [`${panelPadding ? `p-${panelPadding}` : `py-1 px-2`} rounded`]: size === "small",
              "opacity-0": !(title || body),
            })}
          >
            {title && (
              <span className="m-0 block p-0 pb-1 text-sm font-medium leading-4">
                {tooltipShow && title}
              </span>
            )}
            {tooltipShow && body}
          </div>
        </div>
      </div>
    </div>
  );
}

Tooltip.position = "bottom" as Position;
