import classNames from "classnames";
import { useIntl } from "react-intl";

import { defaultIconClassNames, IconProps } from "./Icon";

export default function FileCodeIcon({ size = 32, className = "" }: IconProps) {
  const intl = useIntl();
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 17 16"
      className={classNames(defaultIconClassNames, className)}
      xmlns="http://www.w3.org/2000/svg"
      aria-label={intl.formatMessage({ defaultMessage: "File code", id: "3vFFNf" })}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.7 1.25C3.48003 1.25 3.25 1.44471 3.25 1.75V14.25C3.25 14.5553 3.48003 14.75 3.7 14.75H13.3C13.52 14.75 13.75 14.5553 13.75 14.25V8V4.5H10.9375C10.6959 4.5 10.5 4.30412 10.5 4.0625V1.25H8.5H3.7ZM3.7 0.5C3.03726 0.5 2.5 1.05964 2.5 1.75V14.25C2.5 14.9404 3.03726 15.5 3.7 15.5H13.3C13.9627 15.5 14.5 14.9404 14.5 14.25V8V4.29492C14.5 4.16584 14.4501 4.04177 14.3607 3.94866L11.1976 0.653737C11.1033 0.55552 10.9731 0.5 10.8369 0.5H8.5H3.7ZM7.49405 10.6597C7.3368 10.7945 7.10006 10.7763 6.96528 10.6191L5.46528 8.86905C5.34491 8.72862 5.34491 8.52139 5.46528 8.38096L6.96528 6.63096C7.10006 6.47371 7.3368 6.4555 7.49405 6.59029C7.65129 6.72507 7.6695 6.96181 7.53472 7.11905L6.2439 8.62501L7.53472 10.131C7.6695 10.2882 7.65129 10.5249 7.49405 10.6597ZM9.50595 10.6597C9.6632 10.7945 9.89994 10.7763 10.0347 10.6191L11.5347 8.86905C11.6551 8.72862 11.6551 8.52139 11.5347 8.38096L10.0347 6.63096C9.89994 6.47371 9.6632 6.4555 9.50595 6.59029C9.34871 6.72507 9.3305 6.96181 9.46528 7.11905L10.7561 8.62501L9.46528 10.131C9.3305 10.2882 9.34871 10.5249 9.50595 10.6597Z"
      />
    </svg>
  );
}
