import classNames from "classnames";
import { useIntl } from "react-intl";

import { defaultIconClassNames, IconProps } from "./Icon";

export default function StatusWarningIcon({
  size = 32,
  className = "",
  hideShadow = false,
  transparentBackground = false,
  height,
  width,
  secondColor = "#FFEA8A",
}: IconProps & { hideShadow?: boolean; transparentBackground?: boolean }) {
  const intl = useIntl();
  return (
    <svg
      width={width || size}
      height={height || size}
      viewBox="0 0 28 28"
      fill="none"
      className={classNames(defaultIconClassNames, className)}
      xmlns="http://www.w3.org/2000/svg"
      aria-label={intl.formatMessage({ defaultMessage: "Status warning", id: "qEoa1M" })}
    >
      {!hideShadow && (
        <path
          d="M14 28C21.732 28 28 21.732 28 14C28 6.26801 21.732 0 14 0C6.26801 0 0 6.26801 0 14C0 21.732 6.26801 28 14 28Z"
          fill={secondColor}
        />
      )}
      {!transparentBackground && (
        <path
          d="M14 23C18.9706 23 23 18.9706 23 14C23 9.02944 18.9706 5 14 5C9.02944 5 5 9.02944 5 14C5 18.9706 9.02944 23 14 23Z"
          fill="white"
        />
      )}
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14 4C8.486 4 4 8.486 4 14C4 19.514 8.486 24 14 24C19.514 24 24 19.514 24 14C24 8.486 19.514 4 14 4ZM14 22C9.589 22 6 18.411 6 14C6 9.589 9.589 6 14 6C18.411 6 22 9.589 22 14C22 18.411 18.411 22 14 22ZM14 15C14.553 15 15 14.553 15 14V10C15 9.447 14.553 9 14 9C13.447 9 13 9.447 13 10V14C13 14.553 13.447 15 14 15ZM14 16.9004C13.393 16.9004 12.9 17.3924 12.9 18.0004C12.9 18.6074 13.393 19.0994 14 19.0994C14.607 19.0994 15.1 18.6074 15.1 18.0004C15.1 17.3924 14.607 16.9004 14 16.9004Z"
        fill="currentColor"
      />
    </svg>
  );
}
