import classNames from "classnames";
import { useIntl } from "react-intl";

import { defaultIconClassNames, IconProps } from "./Icon";

export default function ListIcon({ size = 20, className = "" }: IconProps) {
  const intl = useIntl();
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 18 18"
      className={classNames(defaultIconClassNames, className)}
      aria-label={intl.formatMessage({ defaultMessage: "List", id: "nOk9mh" })}
    >
      <path d="M8.25 5.25h4.5v1.5h-4.5v-1.5zm0 3h4.5v1.5h-4.5v-1.5zm0 3h4.5v1.5h-4.5v-1.5zm-3-6h1.5v1.5h-1.5v-1.5zm0 3h1.5v1.5h-1.5v-1.5zm0 3h1.5v1.5h-1.5v-1.5zm9.825-9H2.925c-.375 0-.675.3-.675.675v12.15c0 .3.3.675.675.675h12.15c.3 0 .675-.375.675-.675V2.925c0-.375-.375-.675-.675-.675zm-.825 12H3.75V3.75h10.5v10.5z" />
    </svg>
  );
}
