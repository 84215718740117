import classNames from "classnames";
import { ComponentType, ReactNode } from "react";

import { IconProps } from "../icons";
import { Loader } from "../loaders";
import { ButtonLoaderVariant, ButtonVariant, LOADER_VARIANT_MAPS } from "./Button";

const ICON_VARIANT_MAPS: Record<ButtonVariant, string> = {
  FILLED: "text-white",
  FILLED_SUCCESS: "text-white",
  FILLED_WARNING: "text-white",
  FILLED_ALERT: "text-white",
  FILLED_LIGHT: "text-primaryBlue900 group-disabled:text-blueGray200",
  OUTLINED: "text-primaryBlue900 group-disabled:text-blueGray200",
  OUTLINED_SUCCESS: "text-green500 group-active:text-green700",
  OUTLINED_WARNING: "text-yellow500 group-active:text-yellow700",
  OUTLINED_ALERT: "text-red500 group-active:text-red700",
  TEXT: "text-blueGray600 group-active:text-primaryBlue900 group-disabled:text-blueGray200",
  TEXT_WHITE: "text-white",
  TEXT_LIGHT: "text-blueGray500 group-active:text-primaryBlue800 group-disabled:text-blueGray200",
  TEXT_DARK: "text-primaryBlue900 group-active:text-primaryBlue900 group-disabled:primaryBlue200",
  TEXT_SUCCESS: "text-green500 group-hover:text-green600 group-active:text-green700",
  TEXT_WARNING: "text-yellow500 group-hover:text-yellow600 group-active:text-yellow700",
  TEXT_ALERT: "text-red500 group-hover:text-red600 group-active:text-red700",
  LINK: "text-primaryBlue500 group-hover:text-primaryBlue600 group-active:text-primaryBlue700 group-disabled:text-blueGray200 group-focus:text-primaryBlue700",
  DARK_GRAY: "text-white",
};

interface IconComponentProps extends IconProps {
  icon?: ComponentType<IconProps>;
  loading: boolean | undefined;
  children: ReactNode;
  variant: ButtonVariant;
}

export function LeftButtonIcon({
  loading,
  children,
  variant,
  className,
  icon: Icon,
  ...props
}: IconComponentProps) {
  if (!Icon) {
    return null;
  }

  if (loading && Icon) {
    return (
      <Loader
        className={classNames("align-middle", LOADER_VARIANT_MAPS[variant as ButtonLoaderVariant], {
          "mr-2": Boolean(children),
        })}
        size={16}
      />
    );
  }

  return (
    <Icon
      {...props}
      className={classNames("align-middle", ICON_VARIANT_MAPS[variant], className, {
        "mr-2": Boolean(children),
      })}
    />
  );
}

export function RightButtonIcon({
  loading,
  children,
  variant,
  className,
  icon: Icon,
  ...props
}: IconComponentProps) {
  if (!Icon) {
    return null;
  }

  if (loading && Icon) {
    return (
      <Loader
        className={classNames(
          "ml-2 align-middle",
          LOADER_VARIANT_MAPS[variant as ButtonLoaderVariant]
        )}
        size={16}
      />
    );
  }

  return (
    <Icon
      {...props}
      className={classNames("align-middle", ICON_VARIANT_MAPS[variant], className, {
        "ml-2": Boolean(children),
      })}
    />
  );
}

// Only `ButtonLoaderVariant` variants have loading states
export function getLoadingState({
  variant,
  loading,
}: {
  variant: ButtonVariant;
  loading: boolean;
}): string {
  if (!loading) return "";

  switch (variant) {
    case "FILLED":
      return "!bg-primaryBlue200 !border-primaryBlue200 !hover:bg-primaryBlue200 !hover:border-primaryBlue200 active:bg-primaryBlue200 active:border-primaryBlue200 focus:bg-primaryBlue200 focus:border-primaryBlue200 cursor-not-allowed";
    case "OUTLINED":
      return "!text-blueGray500 !bg-white border !border-blueGray300 !hover:border-blueGray300 hover:border-1 hover:text-blueGray500 focus:border-blueGray300 focus:border-1 focus:text-blueGray500 active:bg-white hover:bg-white focus:bg-white active:border-blueGray300 active:text-blueGray500 active:border-1 cursor-not-allowed";
    case "TEXT":
      return "cursor-not-allowed hover:no-underline active:text-blueGray600 focus:text-blueGray600";
    case "LINK":
      return "cursor-not-allowed hover:no-underline active:text-info focus:text-info";
    default:
      return "";
  }
}

export function isTextVariant(variant: ButtonVariant): boolean {
  return [
    "TEXT",
    "TEXT_LIGHT",
    "TEXT_WHITE",
    "TEXT_DARK",
    "TEXT_SUCCESS",
    "TEXT_ALERT",
    "TEXT_WARNING",
    "LINK",
  ].includes(variant);
}

export function isFilledVariant(variant: ButtonVariant) {
  return ["FILLED", "FILLED_ALERT", "FILLED_WARNING", "FILLED_SUCCESS"].includes(variant);
}
