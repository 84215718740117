import classNames from "classnames";
import { useIntl } from "react-intl";

import { defaultIconClassNames, IconProps } from "./Icon";

interface ExtendedIconProps extends IconProps {
  height?: number;
  width?: number;
}
export default function InviteUser({
  width = 191,
  height = 165,
  className = "",
}: ExtendedIconProps) {
  const intl = useIntl();
  return (
    <svg
      className={classNames(defaultIconClassNames, className)}
      width={width}
      height={height}
      viewBox="0 0 191 165"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      aria-label={intl.formatMessage({ defaultMessage: "Invite user", id: "/4GN+O" })}
    >
      <g clipPath="url(#clip0)">
        <ellipse
          cx="134.738"
          cy="120.5"
          rx="108.541"
          ry="108.541"
          transform="rotate(-45 134.738 120.5)"
          fill="url(#paint0_linear_invite_user)"
        />
        <ellipse
          cx="134.738"
          cy="121"
          rx="81.9928"
          ry="81.9927"
          transform="rotate(-45 134.738 121)"
          fill="url(#paint1_linear_invite_user)"
        />
        <path
          opacity="0.9"
          d="M135.238 70.0002C149.842 70.0002 160.59 80.7978 160.59 95.5002C160.59 110.203 149.842 121 135.238 121C120.644 121 109.887 110.203 109.887 95.5002C109.887 80.7979 120.644 70.0002 135.238 70.0002ZM135.238 173C118.164 173 102.785 165.732 91.8772 154.142C99.5447 143.012 112.18 135.624 126.621 135.624H143.856C158.305 135.624 170.932 143.012 178.6 154.142C167.692 165.732 152.313 173 135.238 173Z"
          fill="url(#paint2_linear)"
          stroke="url(#paint3_linear)"
        />
        <path
          d="M45.2385 43.0002C56.008 43.0002 64.7385 51.7307 64.7385 62.5002C64.7385 73.2698 56.008 82.0002 45.2385 82.0002C34.4689 82.0002 25.7385 73.2698 25.7385 62.5002C25.7385 51.7307 34.4689 43.0002 45.2385 43.0002Z"
          fill="url(#paint4_linear)"
          stroke="url(#paint5_linear)"
        />
        <g filter="url(#filter0_d_invite_user)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M46.4885 53.7502C46.4885 53.0599 45.9288 52.5002 45.2385 52.5002C44.5481 52.5002 43.9885 53.0599 43.9885 53.7502V61.2502H36.4885C35.7981 61.2502 35.2385 61.8099 35.2385 62.5002C35.2385 63.1906 35.7981 63.7502 36.4885 63.7502H43.9885V71.2502C43.9885 71.9406 44.5481 72.5002 45.2385 72.5002C45.9288 72.5002 46.4885 71.9406 46.4885 71.2502V63.7502H53.9885C54.6788 63.7502 55.2385 63.1906 55.2385 62.5002C55.2385 61.8099 54.6788 61.2502 53.9885 61.2502H46.4885V53.7502Z"
            fill="url(#paint6_linear)"
          />
        </g>
      </g>
      <defs>
        <filter
          id="filter0_d_invite_user"
          x="31.2385"
          y="50.5002"
          width="28"
          height="28"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="2" />
          <feGaussianBlur stdDeviation="2" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.253993 0 0 0 0 0.436537 0 0 0 0 0.791667 0 0 0 0.5 0"
          />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
        </filter>
        <linearGradient
          id="paint0_linear_invite_user"
          x1="87.0073"
          y1="204.291"
          x2="209.69"
          y2="20.0894"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#D2E3FF" />
          <stop offset="1" stopColor="#D2E3FF" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_invite_user"
          x1="175.042"
          y1="46.7524"
          x2="134.738"
          y2="202.993"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.0386362" stopColor="#DAE8FF" />
          <stop offset="1" stopColor="#ABC9FF" />
        </linearGradient>
        <linearGradient
          id="paint2_linear"
          x1="179.562"
          y1="121.509"
          x2="83.8042"
          y2="128.174"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" />
          <stop offset="1" stopColor="white" stopOpacity="0.8" />
        </linearGradient>
        <linearGradient
          id="paint3_linear"
          x1="193.443"
          y1="108.733"
          x2="92.3082"
          y2="116.747"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#AED4F7" />
          <stop offset="1" stopColor="white" />
          <stop offset="1" stopColor="white" />
        </linearGradient>
        <linearGradient
          id="paint4_linear"
          x1="45.2385"
          y1="82.5002"
          x2="45.2385"
          y2="42.5002"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#4C85F2" />
          <stop offset="1" stopColor="#4C85F2" stopOpacity="0.7" />
        </linearGradient>
        <linearGradient
          id="paint5_linear"
          x1="50.1489"
          y1="88.9571"
          x2="45.8544"
          y2="43.1"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#DDEFFF" />
          <stop offset="1" stopColor="white" />
          <stop offset="1" stopColor="white" />
        </linearGradient>
        <linearGradient
          id="paint6_linear"
          x1="54.2385"
          y1="62.0002"
          x2="40.2678"
          y2="61.563"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#AED4F7" />
          <stop offset="1" stopColor="white" />
          <stop offset="1" stopColor="white" />
        </linearGradient>
        <clipPath id="clip0">
          <rect width="191" height="165" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
