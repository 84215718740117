import classNames from "classnames";
import { useIntl } from "react-intl";

import { defaultIconClassNames, IconProps } from "./Icon";

export default function CircleStopIcon({ size = 16, className = "" }: IconProps) {
  const intl = useIntl();
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 14 15"
      fill="none"
      className={classNames(defaultIconClassNames, className)}
      xmlns="http://www.w3.org/2000/svg"
      aria-label={intl.formatMessage({ defaultMessage: "Circle Stop", id: "cbVptq" })}
    >
      <path d="M9.33334 5.30615H4.66667V9.97282H9.33334V5.30615Z" />
      <path d="M7 1.80615C3.78001 1.80615 1.16667 4.41949 1.16667 7.63949C1.16667 10.8595 3.78001 13.4728 7 13.4728C10.22 13.4728 12.8333 10.8595 12.8333 7.63949C12.8333 4.41949 10.22 1.80615 7 1.80615ZM7 12.3062C4.42751 12.3062 2.33334 10.212 2.33334 7.63949C2.33334 5.06699 4.42751 2.97282 7 2.97282C9.5725 2.97282 11.6667 5.06699 11.6667 7.63949C11.6667 10.212 9.5725 12.3062 7 12.3062Z" />
    </svg>
  );
}
