import classNames from "classnames";
import { useIntl } from "react-intl";

import { defaultIconClassNames, IconProps } from "./Icon";

interface Props extends IconProps {
  height?: number;
  width?: number;
}

export default function GenericFileIcon({ className = "", height = 30, width = 24 }: Props) {
  const intl = useIntl();
  return (
    <svg
      width={width}
      height={height}
      className={classNames(className, defaultIconClassNames)}
      viewBox="0 0 24 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      aria-label={intl.formatMessage({ defaultMessage: "Generic file", id: "mP/RD0" })}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.4 1.5c-.44 0-.9.39-.9 1v25c0 .61.46 1 .9 1h19.2c.44 0 .9-.39.9-1V8h-5.625A.875.875 0 0 1 16 7.125V1.5H2.4Zm0-1.5C1.075 0 0 1.12 0 2.5v25C0 28.88 1.075 30 2.4 30h19.2c1.325 0 2.4-1.12 2.4-2.5V7.59a1 1 0 0 0-.279-.693L17.395.307A1 1 0 0 0 16.674 0H2.4Zm1.514 19.944A.185.185 0 0 0 4.05 20h.704a.213.213 0 0 0 .144-.048.195.195 0 0 0 .056-.144v-1.984h2.408a.213.213 0 0 0 .144-.048.195.195 0 0 0 .056-.144v-.56a.195.195 0 0 0-.056-.144.195.195 0 0 0-.144-.056H4.954v-1.52h2.568a.213.213 0 0 0 .144-.048.195.195 0 0 0 .056-.144v-.56a.195.195 0 0 0-.056-.144.195.195 0 0 0-.144-.056H4.05a.195.195 0 0 0-.144.056.213.213 0 0 0-.048.144v5.208c0 .054.018.1.056.136Zm5.044 0a.185.185 0 0 0 .136.056h.752a.17.17 0 0 0 .136-.056.185.185 0 0 0 .056-.136v-5.216a.17.17 0 0 0-.056-.136.17.17 0 0 0-.136-.056h-.752a.185.185 0 0 0-.136.056.17.17 0 0 0-.056.136v5.216c0 .054.019.1.056.136Zm2.767.056a.185.185 0 0 1-.136-.056.185.185 0 0 1-.056-.136v-5.216a.17.17 0 0 1 .056-.136.185.185 0 0 1 .136-.056h.744a.17.17 0 0 1 .136.056.17.17 0 0 1 .056.136v4.456h2.536c.059 0 .107.02.144.056a.195.195 0 0 1 .056.144v.56a.195.195 0 0 1-.056.144.214.214 0 0 1-.144.048h-3.472Zm4.745-.056a.185.185 0 0 0 .136.056h3.584a.213.213 0 0 0 .144-.048.195.195 0 0 0 .056-.144v-.528a.195.195 0 0 0-.056-.144.195.195 0 0 0-.144-.056h-2.696v-1.464h2.456a.213.213 0 0 0 .144-.048.195.195 0 0 0 .056-.144v-.496a.17.17 0 0 0-.056-.136.195.195 0 0 0-.144-.056h-2.456V15.32h2.632a.213.213 0 0 0 .144-.048.195.195 0 0 0 .056-.144V14.6a.195.195 0 0 0-.056-.144.195.195 0 0 0-.144-.056h-3.52a.195.195 0 0 0-.144.056.213.213 0 0 0-.048.144v5.208c0 .054.018.1.056.136Z"
      />
    </svg>
  );
}
