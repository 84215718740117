import classNames from "classnames";
import { useIntl } from "react-intl";

import { defaultIconClassNames, IconProps } from "./Icon";

export default function BoltIcon({ size = 12, className = "" }: IconProps) {
  const intl = useIntl();
  return (
    <svg
      width={size}
      height={size}
      className={classNames(defaultIconClassNames, className)}
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      aria-label={intl.formatMessage({ defaultMessage: "Bolt", id: "df2jLa" })}
    >
      <path d="M5.50006 10.5H5.00006L5.50006 7H3.75006C3.46006 7 3.46506 6.84 3.56006 6.67C3.65506 6.5 3.58506 6.63 3.59506 6.61C4.24006 5.47 5.21006 3.77 6.50006 1.5H7.00006L6.50006 5H8.25006C8.49506 5 8.53006 5.165 8.48506 5.255L8.45006 5.33C6.48006 8.775 5.50006 10.5 5.50006 10.5Z" />
    </svg>
  );
}
