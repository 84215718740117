import classNames from "classnames";
import { useIntl } from "react-intl";

import { defaultIconClassNames, IconProps } from "./Icon";

export default function PictureIcon({ size = 24, className = "" }: IconProps) {
  const intl = useIntl();
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 25 24"
      fill="none"
      className={classNames(defaultIconClassNames, className)}
      xmlns="http://www.w3.org/2000/svg"
      aria-label={intl.formatMessage({ defaultMessage: "Picture", id: "wvoA3H" })}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.5 2.25H3.5C3.08579 2.25 2.75 2.58579 2.75 3V14.5142L5.84037 12.1597C6.62249 11.5638 7.67206 11.4549 8.55981 11.8777L12.0376 13.5338C12.1797 13.6014 12.3489 13.5732 12.4613 13.4631L16.8886 9.12615C17.8367 8.19737 19.3289 8.12244 20.3653 8.95156L22.25 10.4593V3C22.25 2.58579 21.9142 2.25 21.5 2.25ZM22.25 13.3407L18.9598 10.7085C18.8117 10.5901 18.5985 10.6008 18.4631 10.7335L14.0358 15.0704C13.249 15.8411 12.0647 16.0388 11.0703 15.5652L7.59246 13.9091C7.46564 13.8487 7.3157 13.8643 7.20397 13.9494L2.75 17.3429V18C2.75 18.4142 3.08579 18.75 3.5 18.75H21.5C21.9142 18.75 22.25 18.4142 22.25 18V13.3407ZM3.5 0C1.84315 0 0.5 1.34315 0.5 3V18C0.5 19.6569 1.84315 21 3.5 21H21.5C23.1569 21 24.5 19.6569 24.5 18V3C24.5 1.34315 23.1569 0 21.5 0H3.5ZM8 6.75C8 5.50736 9.00736 4.5 10.25 4.5C11.4926 4.5 12.5 5.50736 12.5 6.75C12.5 7.99264 11.4926 9 10.25 9C9.00736 9 8 7.99264 8 6.75Z"
      />
    </svg>
  );
}
