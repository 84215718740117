import { A, Typography } from "@fonoa/ui-components/typography";
import { useRouter } from "next/router";
import { FormattedMessage } from "react-intl";

import SidebarLayout from "@/components/Layouts/SidebarLayout";

import ErrorImage from "./ErrorImage";
import ErrorTitle from "./ErrorTitle";

function DashboardError({ errorCode }: { errorCode: string | number }) {
  const router = useRouter();

  const reloadThePage = (
    <A
      className="text-primaryBlue500"
      href="javascript:location.reload();"
      onClick={router.reload}
      underline
    >
      <FormattedMessage defaultMessage="try reloading the web app" id="6NUk41" />
    </A>
  );

  const returnToDashboard = (
    <A className="text-primaryBlue500" href="/" underline>
      <FormattedMessage defaultMessage="return to the Dashboard" id="mL1u/d" />
    </A>
  );

  const contactUsLink = (
    <A className="text-primaryBlue500" href="https://www.fonoa.com/contact-us" underline>
      <FormattedMessage defaultMessage="contact us" id="cZ6IMP" />
    </A>
  );

  return (
    <SidebarLayout windowTitle="Fonoa Dashboard" withHeaderBar={false}>
      <div className="mt-40 flex flex-col items-center text-center">
        <ErrorImage />
        <ErrorTitle errorCode={errorCode} />
        <div className="max-w-80">
          <Typography
            color="text-blueGray600"
            spacing={{ mx: "auto", mt: 4 }}
            fontSize="text-sm"
            fontWeight="font-light"
          >
            <FormattedMessage
              defaultMessage="Our API is reliable but mishaps do happen. You can {reloadThePage}, or {returnToDashboard}"
              id="tI1OLu"
              values={{ reloadThePage, returnToDashboard }}
            />
          </Typography>
        </div>
        <div className="max-w-80">
          <Typography
            spacing={{ mx: "auto", mt: 4 }}
            fontSize="text-sm"
            color="text-blueGray600"
            fontWeight="font-light"
          >
            <FormattedMessage
              defaultMessage="If the problem persists, please {contactUsLink} for support."
              id="GPJ3Qr"
              values={{ contactUsLink }}
            />
          </Typography>
        </div>
      </div>
    </SidebarLayout>
  );
}

export default DashboardError;
