import classNames from "classnames";
import { useIntl } from "react-intl";

import { defaultIconClassNames, IconProps } from "./Icon";

export default function LifebuoyIcon({ size = 16, className = "" }: IconProps) {
  const intl = useIntl();
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 16 16"
      fill="none"
      className={classNames(defaultIconClassNames, className)}
      xmlns="http://www.w3.org/2000/svg"
      aria-label={intl.formatMessage({ defaultMessage: "Lifebuoy", id: "G2EMtf" })}
    >
      <path d="M13.453 10.387C14.118 8.868 14.118 7.125 13.453 5.613L11.535 6.481C11.955 7.447 11.955 8.546 11.542 9.519L13.453 10.387ZM10.394 2.547C9.63997 2.21803 8.82616 2.04823 8.0035 2.04823C7.18084 2.04823 6.36703 2.21803 5.613 2.547L6.481 4.458C7.454 4.045 8.553 4.045 9.526 4.465L10.394 2.547ZM2.547 5.606C2.21802 6.36125 2.04823 7.17621 2.04823 8C2.04823 8.82379 2.21802 9.63875 2.547 10.394L4.465 9.519C4.045 8.553 4.045 7.447 4.465 6.474L2.547 5.606ZM5.613 13.453C6.36707 13.7825 7.18131 13.952 8.00423 13.9508C8.82716 13.9496 9.64089 13.7777 10.394 13.446L9.526 11.535C9.04657 11.7422 8.53 11.8496 8.00772 11.8508C7.48544 11.852 6.96838 11.747 6.488 11.542L5.613 13.453ZM8 1C8.91925 1 9.8295 1.18106 10.6788 1.53284C11.5281 1.88463 12.2997 2.40024 12.9497 3.05025C13.5998 3.70026 14.1154 4.47194 14.4672 5.32122C14.8189 6.17049 15 7.08075 15 8C15 9.85651 14.2625 11.637 12.9497 12.9497C11.637 14.2625 9.85651 15 8 15C7.08075 15 6.17049 14.8189 5.32122 14.4672C4.47194 14.1154 3.70026 13.5998 3.05025 12.9497C1.7375 11.637 1 9.85651 1 8C1 6.14348 1.7375 4.36301 3.05025 3.05025C4.36301 1.7375 6.14348 1 8 1ZM8 5.2C7.25739 5.2 6.5452 5.495 6.0201 6.0201C5.495 6.5452 5.2 7.25739 5.2 8C5.2 8.74261 5.495 9.4548 6.0201 9.9799C6.5452 10.505 7.25739 10.8 8 10.8C8.74261 10.8 9.4548 10.505 9.9799 9.9799C10.505 9.4548 10.8 8.74261 10.8 8C10.8 7.25739 10.505 6.5452 9.9799 6.0201C9.4548 5.495 8.74261 5.2 8 5.2Z" />
    </svg>
  );
}
