import classNames from "classnames";
import { useIntl } from "react-intl";

import { defaultIconClassNames, IconProps } from "./Icon";

export default function CommentLightIcon({ size = 12, className = "" }: IconProps) {
  const intl = useIntl();
  return (
    <svg
      width={size}
      height={size}
      className={classNames(defaultIconClassNames, className)}
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      aria-label={intl.formatMessage({ defaultMessage: "Comments", id: "wCgTu5" })}
    >
      <path d="M11.6666 1.1665H2.33329C1.69163 1.1665 1.16663 1.6915 1.16663 2.33317V12.8332L3.49996 10.4998H11.6666C12.3083 10.4998 12.8333 9.97484 12.8333 9.33317V2.33317C12.8333 1.6915 12.3083 1.1665 11.6666 1.1665ZM11.6666 9.33317H3.49996L2.33329 10.4998V2.33317H11.6666V9.33317Z" />
    </svg>
  );
}
