import classNames from "classnames";
import { useIntl } from "react-intl";

import { defaultIconClassNames, IconProps } from "./Icon";

export default function FlagIcon({ size = 16, className = "" }: IconProps) {
  const intl = useIntl();
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 16 16"
      fill="none"
      className={classNames(defaultIconClassNames, className)}
      xmlns="http://www.w3.org/2000/svg"
      aria-label={intl.formatMessage({ defaultMessage: "Flag", id: "bSCV9J" })}
    >
      <path d="M8.23967 4.00033L8.50634 5.33366H11.9997V9.33366H9.75968L9.49301 8.00033H4.66634V4.00033H8.23967ZM9.33301 2.66699H3.33301V14.0003H4.66634V9.33366H8.39967L8.66634 10.667H13.333V4.00033H9.59967L9.33301 2.66699Z" />
    </svg>
  );
}
