const getCountryFlagPath = (country: string, format: "1x1" | "3x4" = "1x1") => {
  // This is unique to the flag-icon-css library which we use the assets of.
  // Technically the official flag of Northern Ireland (XI) is the Union Jack
  let flagCode = country.toLowerCase();
  if (flagCode == "xi") {
    flagCode = "gb-nir";
  }
  return `https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.3/flags/${format}/${flagCode}.svg`;
};

export default getCountryFlagPath;
