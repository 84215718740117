import classNames from "classnames";
import { useIntl } from "react-intl";

import { defaultIconClassNames, IconProps } from "./Icon";

export default function TableChartIcon({ size = 16, className = "" }: IconProps) {
  const intl = useIntl();
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 25 24"
      fill="none"
      className={classNames(defaultIconClassNames, className)}
      xmlns="http://www.w3.org/2000/svg"
      aria-label={intl.formatMessage({ defaultMessage: "Table chart", id: "VizRif" })}
    >
      <g clip-path="url(#clip0_668_4910)">
        <path
          d="M20.5 3H5.5C4.4 3 3.5 3.9 3.5 5V19C3.5 20.1 4.4 21 5.5 21H20.5C21.6 21 22.5 20.1 22.5 19V5C22.5 3.9 21.6 3 20.5 3ZM20.5 5V8H5.5V5H20.5ZM15.5 19H10.5V10H15.5V19ZM5.5 10H8.5V19H5.5V10ZM17.5 19V10H20.5V19H17.5Z"
          fill="#03243D"
        />
      </g>
      <defs>
        <clipPath id="clip0_668_4910">
          <rect width="24" height="24" fill="white" transform="translate(0.5)" />
        </clipPath>
      </defs>
    </svg>
  );
}
