import classNames from "classnames";
import { useIntl } from "react-intl";

import { defaultIconClassNames, IconProps } from "./Icon";

export default function ArrowDownIcon({ size = 32, className = "", onClick }: IconProps) {
  const intl = useIntl();
  return (
    <svg
      width={size}
      height={size / 2}
      viewBox="0 0 12 7"
      fill="none"
      className={classNames(defaultIconClassNames, className)}
      onClick={onClick}
      xmlns="http://www.w3.org/2000/svg"
      aria-label={intl.formatMessage({ defaultMessage: "Arrow down", id: "qvRojs" })}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 1.12648L1.15074 0L6 4.74704L10.8493 0L12 1.12648L6.69953 6.31521C6.3108 6.69575 5.68919 6.69575 5.30046 6.31521L0 1.12648Z"
      />
    </svg>
  );
}
