import { useTenant } from "@/hooks/auth";
import { IProduct, ISubproduct, Product, Products, Subproduct, Subproducts } from "@/lib/product";

export const useIsProductActive = (product: Product) => {
  const { data: tenant } = useTenant();

  return tenant?.products?.some(({ name, active }) => name === product && active);
};

export const useIsSubproductActive = (subproduct: Subproduct) => {
  const { data: tenant } = useTenant();

  return tenant?.products?.some(({ name, active }) => name === subproduct && active);
};

export const useIsProductActiveCallback = () => {
  const { data: tenant } = useTenant();

  return (product?: Product) => {
    if (!product) {
      return false;
    }

    return tenant?.products?.some(({ name, active }) => name === product && active) ?? false;
  };
};

export const useIsSubproductActiveCallback = () => {
  const { data: tenant } = useTenant();

  return (subproduct?: Subproduct) => {
    if (!subproduct) {
      return false;
    }

    return tenant?.products?.some(({ name, active }) => name === subproduct && active) ?? false;
  };
};

export const useAreProductsActive = () => {
  const isProductActive = useIsProductActiveCallback();
  const { isLoading, isFetching } = useTenant();

  const productArray: IProduct[] = Object.values(Products).map((product) => ({
    product,
    isActive: false,
  }));

  const activeArray: IProduct[] = productArray.map((productStatus) => ({
    ...productStatus,
    isActive: isProductActive(productStatus.product),
  }));

  return {
    isLoading,
    isFetching,
    products: activeArray,
  };
};

export const useAreSubproductsActive = () => {
  const isSubproductActive = useIsSubproductActiveCallback();
  const { isLoading, isFetching } = useTenant();

  const subproductArray: ISubproduct[] = Object.values(Subproducts).map((subproduct) => ({
    subproduct,
    isActive: false,
  }));

  const activeArray: ISubproduct[] = subproductArray.map((productStatus) => ({
    ...productStatus,
    isActive: isSubproductActive(productStatus.subproduct),
  }));

  // Sort by active first
  const subproducts = activeArray.sort((subproductA, subproductB) =>
    subproductA.isActive === subproductB.isActive ? 0 : subproductA.isActive ? -1 : 1
  );

  return {
    isLoading,
    isFetching,
    subproducts,
  };
};
