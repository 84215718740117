import classNames from "classnames";
import { useIntl } from "react-intl";

import { defaultIconClassNames, IconProps } from "./Icon";

export default function UploadFileIcon({ size = 16, className = "" }: IconProps) {
  const intl = useIntl();
  return (
    <svg
      width={size}
      height={size}
      className={classNames(defaultIconClassNames, className)}
      viewBox="0 0 12 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      aria-label={intl.formatMessage({ defaultMessage: "Upload file", id: "6oOCCL" })}
    >
      <path d="M7.5 0.5L1.5 0.5C0.675 0.5 0.00750017 1.175 0.00750017 2L0 14C0 14.825 0.6675 15.5 1.4925 15.5L10.5 15.5C11.325 15.5 12 14.825 12 14L12 5L7.5 0.5ZM10.5 14L1.5 14L1.5 2L6.75 2L6.75 5.75L10.5 5.75L10.5 14ZM3 10.2575L4.0575 11.315L5.25 10.13V13.25L6.75 13.25V10.13L7.9425 11.3225L9 10.2575L6.0075 7.25L3 10.2575Z" />
    </svg>
  );
}
