import classNames from "classnames";
import { useIntl } from "react-intl";

import { defaultIconClassNames, IconProps } from "./Icon";

export default function FileCopyIcon({ size = 32, className = "" }: IconProps) {
  const intl = useIntl();
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 17 16"
      className={classNames(defaultIconClassNames, className)}
      xmlns="http://www.w3.org/2000/svg"
      aria-label={intl.formatMessage({ defaultMessage: "File copy", id: "QbRtC8" })}
    >
      <path d="M10.5007 0.666748H3.16732C2.43398 0.666748 1.83398 1.26675 1.83398 2.00008V10.6667C1.83398 11.0334 2.13398 11.3334 2.50065 11.3334C2.86732 11.3334 3.16732 11.0334 3.16732 10.6667V2.66675C3.16732 2.30008 3.46732 2.00008 3.83398 2.00008H10.5007C10.8673 2.00008 11.1673 1.70008 11.1673 1.33341C11.1673 0.966748 10.8673 0.666748 10.5007 0.666748ZM10.894 3.72675L14.114 6.94675C14.3607 7.19341 14.5007 7.53341 14.5007 7.88675V14.0001C14.5007 14.7334 13.9007 15.3334 13.1673 15.3334H5.82732C5.09398 15.3334 4.50065 14.7334 4.50065 14.0001L4.50732 4.66675C4.50732 3.93341 5.10065 3.33341 5.83398 3.33341H9.94732C10.3007 3.33341 10.6407 3.47341 10.894 3.72675ZM10.5007 8.00008H13.5007L9.83398 4.33341V7.33341C9.83398 7.70008 10.134 8.00008 10.5007 8.00008Z" />
    </svg>
  );
}
