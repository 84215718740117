import { useQuery } from "@tanstack/react-query";
import { useMemo } from "react";

import api from "@/lib/api";

export const useCountries = () => {
  const { data: countries, isLoading } = useQuery(
    ["countries"],
    async () => await api.getCountries(),
    {
      select: (data) =>
        Object.fromEntries(data?.data?.map((country) => [country.id, country]) || []),
    }
  );

  return useMemo(
    () => ({
      countries: countries ?? {},
      isLoading,
    }),
    [countries, isLoading]
  );
};
