import { Typography } from "@fonoa/ui-components/typography";
import { FormattedMessage } from "react-intl";

import { Product } from "@/lib/product";

interface Props {
  username?: string;
  product?: Product;
}

const UserGreeting = ({ username, product }: Props) => {
  const productName = `${product?.[0].toUpperCase()}${product?.substring(1).toLowerCase()}`;

  const welcomeToProduct = (
    <FormattedMessage
      defaultMessage="Welcome to {product}, {name}!"
      id="Uyf/yq"
      values={{
        name: username,
        product: productName,
      }}
    />
  );

  const welcome = (
    <FormattedMessage
      defaultMessage="Welcome {name}!"
      id="J0yoQ/"
      values={{
        name: username,
      }}
    />
  );

  return (
    <div className="item-center flex justify-between pr-4">
      {username && (
        <div className="item-center flex space-x-2">
          <Typography
            component="span"
            dataAttributes={{ cy: "welcome-message" }}
            color="text-blueGray700"
            fontSize="text-sm"
            fontWeight="font-medium"
          >
            {product ? welcomeToProduct : welcome}
          </Typography>
        </div>
      )}
    </div>
  );
};

export default UserGreeting;
