import { useMemo } from "react";

function loadLocaleData(locale: string) {
  switch (locale) {
    default:
      return {};
  }
}

const useLocaleTranslation = (locale: string) => useMemo(() => loadLocaleData(locale), []);

export { useLocaleTranslation };
