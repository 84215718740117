import classNames from "classnames";
import { useIntl } from "react-intl";

import { defaultIconClassNames, IconProps } from "./Icon";

export default function HomeIcon({ size = 32, className = "" }: IconProps) {
  const intl = useIntl();
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 16 16"
      fill="none"
      className={classNames(defaultIconClassNames, className)}
      xmlns="http://www.w3.org/2000/svg"
      aria-label={intl.formatMessage({ defaultMessage: "Store", id: "RTOlSm" })}
    >
      <path d="M15.985 5L15.08 1.757C14.969 1.312 14.569 1 14.11 1H1.891C1.432 1 1.032 1.312 0.92 1.757L0.015 5C0.005 5.039 0 5.08 0 5.121C0 6.499 1.065 7.621 2.375 7.621C3.136 7.621 3.815 7.242 4.25 6.654C4.685 7.242 5.364 7.621 6.125 7.621C6.886 7.621 7.565 7.242 8 6.654C8.435 7.242 9.113 7.621 9.875 7.621C10.637 7.621 11.315 7.242 11.75 6.654C12.185 7.242 12.863 7.621 13.625 7.621C14.935 7.621 16 6.499 16 5.121C16 5.08 15.995 5.039 15.985 5Z" />
      <path d="M13.625 9.00011C12.944 9.00011 12.297 8.79211 11.75 8.41211C10.656 9.17311 9.094 9.17311 8 8.41211C6.906 9.17311 5.344 9.17311 4.25 8.41211C3.703 8.79211 3.056 9.00011 2.375 9.00011C1.884 9.00011 1.421 8.88511 1 8.68911V14.0001C1 14.5521 1.448 15.0001 2 15.0001H6V11.0001H10V15.0001H14C14.552 15.0001 15 14.5521 15 14.0001V8.68911C14.579 8.88511 14.116 9.00011 13.625 9.00011Z" />
    </svg>
  );
}
