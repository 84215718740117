import classNames from "classnames";
import { useIntl } from "react-intl";

import { defaultIconClassNames, IconProps } from "./Icon";

export default function LongArrowUpIcon({ size = 12, className = "" }: IconProps) {
  const intl = useIntl();
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 12 12"
      fill="none"
      className={classNames(defaultIconClassNames, className)}
      xmlns="http://www.w3.org/2000/svg"
      aria-label={intl.formatMessage({ defaultMessage: "Long arrow up", id: "g8xnu9" })}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.70711 2.20711C6.31658 1.81658 5.68342 1.81658 5.29289 2.20711L2.25 5.25L2.98089 5.98089L5.48318 3.4786V10.5H6.51682V3.4786L9.01911 5.98089L9.75 5.25L6.70711 2.20711Z"
      />
    </svg>
  );
}
