import classNames from "classnames";
import { useIntl } from "react-intl";

import { defaultIconClassNames, IconProps } from "./Icon";

export default function CircleProcessingIcon({
  size = 14,
  className = "fill-white",
}: IconProps & { color?: string }) {
  const intl = useIntl();
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 14 14"
      fill="none"
      className={classNames(defaultIconClassNames, className)}
      xmlns="http://www.w3.org/2000/svg"
      aria-label={intl.formatMessage({ defaultMessage: "Processing", id: "1Ia8qN" })}
    >
      <circle cx="7" cy="7" r="6.2" stroke="#E7BD07" stroke-width="1.6" />
      <path
        d="M4.97852 3.48444L4.98206 5.60944L6.39518 7.02611L4.98206 8.44632L4.97852 10.5678H9.22852V8.44278L7.81185 7.02611L9.22852 5.61299V3.48444H4.97852ZM8.52018 8.61986V9.85944H5.68685V8.61986L7.10352 7.20319L8.52018 8.61986Z"
        fill="#E7BD07"
      />
    </svg>
  );
}
