import classNames from "classnames";
import { useIntl } from "react-intl";

import { defaultIconClassNames, IconProps } from "./Icon";

export default function TriangleRightIcon({ size = 16, className = "" }: IconProps) {
  const intl = useIntl();
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 16 18"
      fill="none"
      className={classNames(defaultIconClassNames, className)}
      xmlns="http://www.w3.org/2000/svg"
      aria-label={intl.formatMessage({ defaultMessage: "Triangle right", id: "kLOM3/" })}
    >
      <path d="M15.8999 8.70856L0.674163 17.2171L0.674164 0.200061L15.8999 8.70856Z" />
    </svg>
  );
}
