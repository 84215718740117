import classNames from "classnames";
import { useIntl } from "react-intl";

import { defaultIconClassNames, IconProps } from "./Icon";

export default function HourglassTopIcon({ size = 10, className = "" }: IconProps) {
  const intl = useIntl();
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 14 14"
      fill="none"
      className={classNames(defaultIconClassNames, className)}
      xmlns="http://www.w3.org/2000/svg"
      aria-label={intl.formatMessage({ defaultMessage: "Hourglass count down", id: "huh26/" })}
    >
      <path d="M3.5 1.16667L3.50583 4.66667L5.83333 7.00001L3.50583 9.33917L3.5 12.8333H10.5V9.33334L8.16667 7.00001L10.5 4.67251V1.16667H3.5ZM9.33333 9.62501V11.6667H4.66667V9.62501L7 7.29167L9.33333 9.62501Z" />
    </svg>
  );
}
