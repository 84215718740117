import { colors } from "@fonoa/design/tokens";
import classNames from "classnames";
import { ComponentType, ReactNode } from "react";

import { ProductIconProps, ProductIconVariant } from "../icons";
import { FontSize, ThemeColorBg, ThemeColorText, ThemeDarkColorText } from "../types";
import { A, Typography } from "../typography";

export type SizeVariant = "Regular" | "Small";
export type StatusVariant = "Default" | "Active" | "Hover" | "Disabled";

const TEXT_SIZE_VARIANT_MAPS: Record<SizeVariant, FontSize> = {
  Regular: "text-sm",
  Small: "text-xs",
};

const TEXT_DARK_COLOR_VARIANT_MAPS: Record<StatusVariant, ThemeDarkColorText> = {
  Default: "dark:text-white",
  Hover: "dark:text-white",
  Active: "dark:text-greenFonoa",
  Disabled: "dark:text-blueGray600",
};

const BG_DARK_COLOR_VARIANT_MAPS: Record<StatusVariant, `dark:${ThemeColorBg}` | ""> = {
  Default: "",
  Hover: "",
  Active: "dark:bg-transparent",
  Disabled: "",
};

const TEXT_LIGHT_COLOR_VARIANT_MAPS: Record<StatusVariant, ThemeColorText> = {
  Default: "text-blueGray700",
  Hover: "text-blueGray700",
  Active: "text-primaryBlue500",
  Disabled: "text-blueGray300",
};

export interface SidebarLinkProps {
  className?: string;
  href: string;
  external?: boolean;
  icon?: ComponentType<ProductIconProps>;
  title: ReactNode;
  label?: ReactNode;
  hasAccess?: boolean;
  size?: SizeVariant;
  status?: StatusVariant;
  handleClick?: () => void;
}

const getIconVariant = (status?: StatusVariant): ProductIconVariant => {
  switch (status) {
    case "Active":
      return "active";
    case "Disabled":
      return "inactive";
    default:
      return "default";
  }
};

const Title = ({
  title,
  size = "Regular",
  status = "Default",
}: Pick<SidebarLinkProps, "title" | "size" | "status">) => (
  <Typography
    fontWeight={status === "Hover" ? "font-medium" : "font-normal"}
    fontSize={TEXT_SIZE_VARIANT_MAPS[size]}
    lineHeight="leading-6"
    color={TEXT_LIGHT_COLOR_VARIANT_MAPS[status]}
    darkColor={TEXT_DARK_COLOR_VARIANT_MAPS[status]}
    bgColor={BG_DARK_COLOR_VARIANT_MAPS[status] || undefined}
    UNSAFE_className={classNames("flex-1", {
      "group-hover:font-medium": status !== "Disabled",
    })}
  >
    {title}
  </Typography>
);

export function SidebarLink({
  icon: Icon,
  title,
  label,
  className,
  href,
  hasAccess = true,
  size = "Regular",
  status = "Default",
  handleClick = () => ({}),
  ...props
}: SidebarLinkProps) {
  const iconVariant = getIconVariant(status);

  if (!hasAccess) {
    return null;
  }

  return (
    <A
      href={href}
      onClick={handleClick}
      className={classNames(
        "group flex items-center w-64",
        TEXT_SIZE_VARIANT_MAPS[size],
        TEXT_DARK_COLOR_VARIANT_MAPS[status],
        BG_DARK_COLOR_VARIANT_MAPS[status],
        TEXT_LIGHT_COLOR_VARIANT_MAPS[status],
        status === "Disabled" ? "cursor-not-allowed" : "cursor-pointer",
        status === "Active" ? "bg-primaryBlue25" : "",
        {
          "h-8": size === "Regular",
          "h-7": size === "Small",
        },
        className
      )}
      {...props}
    >
      <div
        className={classNames("mr-6 w-[3px] h-full", {
          "mr-12": !Icon,
          "bg-primaryBlue500 dark:bg-greenFonoa": status === "Hover",
          "group-hover:bg-primaryBlue500 dark:group-hover:bg-greenFonoa": status !== "Disabled",
        })}
      />
      {Icon && (
        <>
          <div className="dark:hidden">
            <Icon size={16} variant={iconVariant} secondColor={colors.white} className="mr-2" />
          </div>
          <div className="hidden dark:block">
            <Icon
              size={16}
              variant={iconVariant}
              secondColor={colors.blueGray700}
              className="mr-2"
            />
          </div>
        </>
      )}
      {label ? (
        <div className="flex items-center space-x-2">
          <Title title={title} size={size} status={status} />
          <span className="font-normal">{label}</span>
        </div>
      ) : (
        <Title title={title} size={size} status={status} />
      )}
    </A>
  );
}
