import classNames from "classnames";
import { useIntl } from "react-intl";

import { defaultIconClassNames, IconProps } from "./Icon";

export default function HourglassIcon({ size = 10, className = "" }: IconProps) {
  const intl = useIntl();
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 10 10"
      fill="none"
      className={classNames(defaultIconClassNames, className)}
      xmlns="http://www.w3.org/2000/svg"
      aria-label={intl.formatMessage({ defaultMessage: "Hourglass", id: "22J2i6" })}
    >
      <path d="M7.6 3V1.8H8C8.22 1.8 8.4 1.62 8.4 1.4C8.4 1.18 8.22 1 8 1H2.4C2.18 1 2 1.18 2 1.4C2 1.62 2.18 1.8 2.4 1.8H2.8V3C2.8 3.836 3.228 4.572 3.876 5C3.54504 5.21818 3.27346 5.51515 3.08565 5.86424C2.89784 6.21332 2.79968 6.6036 2.8 7V8.2H2.4C2.18 8.2 2 8.38 2 8.6C2 8.82 2.18 9 2.4 9H8C8.22 9 8.4 8.82 8.4 8.6C8.4 8.38 8.22 8.2 8 8.2H7.6V7C7.6 6.164 7.172 5.428 6.524 5C6.85496 4.78182 7.12654 4.48485 7.31435 4.13576C7.50216 3.78668 7.60031 3.3964 7.6 3ZM6.8 3C6.8 3.884 6.084 4.6 5.2 4.6C4.316 4.6 3.6 3.884 3.6 3V1.8H6.8V3Z" />
    </svg>
  );
}
