import classNames from "classnames";
import { useIntl } from "react-intl";

import { defaultIconClassNames, IconProps } from "./Icon";

export default function ViewIcon({ size = 32, className = "" }: IconProps) {
  const intl = useIntl();
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 32 32"
      fill="none"
      className={classNames(defaultIconClassNames, className)}
      xmlns="http://www.w3.org/2000/svg"
      aria-label={intl.formatMessage({ defaultMessage: "View", id: "FgydNe" })}
    >
      <path d="M16 8.54545C18.3402 8.53767 20.6351 9.19045 22.6209 10.4287C24.6066 11.667 26.2027 13.4406 27.2255 15.5455C25.1255 19.8345 20.8364 22.5455 16 22.5455C11.1636 22.5455 6.87455 19.8345 4.77455 15.5455C5.79733 13.4406 7.39335 11.667 9.37912 10.4287C11.3649 9.19045 13.6598 8.53767 16 8.54545ZM16 6C9.63636 6 4.20182 9.95818 2 15.5455C4.20182 21.1327 9.63636 25.0909 16 25.0909C22.3636 25.0909 27.7982 21.1327 30 15.5455C27.7982 9.95818 22.3636 6 16 6ZM16 12.3636C16.8439 12.3636 17.6532 12.6989 18.2499 13.2956C18.8466 13.8923 19.1818 14.7016 19.1818 15.5455C19.1818 16.3893 18.8466 17.1986 18.2499 17.7953C17.6532 18.392 16.8439 18.7273 16 18.7273C15.1561 18.7273 14.3468 18.392 13.7501 17.7953C13.1534 17.1986 12.8182 16.3893 12.8182 15.5455C12.8182 14.7016 13.1534 13.8923 13.7501 13.2956C14.3468 12.6989 15.1561 12.3636 16 12.3636ZM16 9.81818C12.8436 9.81818 10.2727 12.3891 10.2727 15.5455C10.2727 18.7018 12.8436 21.2727 16 21.2727C19.1564 21.2727 21.7273 18.7018 21.7273 15.5455C21.7273 12.3891 19.1564 9.81818 16 9.81818Z" />
    </svg>
  );
}
