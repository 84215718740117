import classNames from "classnames";
import { useIntl } from "react-intl";

import { defaultIconClassNames, IconProps } from "./Icon";

interface Props extends IconProps {
  height?: number;
  width?: number;
}

export default function ExcelFileIcon({ className = "", height = 30, width = 24 }: Props) {
  const intl = useIntl();
  return (
    <svg
      width={width}
      height={height}
      className={classNames(className, defaultIconClassNames)}
      viewBox="0 0 24 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      aria-label={intl.formatMessage({ defaultMessage: "Excel file", id: "1tSNny" })}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.4 1.5c-.44 0-.9.39-.9 1v25c0 .61.46 1 .9 1h19.2c.44 0 .9-.39.9-1V8h-5.625A.875.875 0 0 1 16 7.125V1.5H2.4Zm0-1.5C1.075 0 0 1.12 0 2.5v25C0 28.88 1.075 30 2.4 30h19.2c1.325 0 2.4-1.12 2.4-2.5V7.59a1 1 0 0 0-.279-.693L17.395.307A1 1 0 0 0 16.674 0H2.4Zm13.586 19.864c.342.144.75.216 1.224.216a3.24 3.24 0 0 0 1.184-.2c.347-.133.614-.325.8-.576.192-.256.288-.554.288-.896 0-.314-.066-.573-.2-.776-.133-.202-.34-.37-.624-.504-.277-.133-.65-.253-1.12-.36a7.147 7.147 0 0 1-.8-.248 1.08 1.08 0 0 1-.4-.256.533.533 0 0 1-.128-.368c0-.213.086-.376.256-.488.171-.112.406-.168.704-.168.267 0 .491.059.672.176.182.112.288.254.32.424.043.102.12.152.232.152h.744a.163.163 0 0 0 .168-.168c-.01-.229-.1-.458-.272-.688-.165-.234-.408-.429-.728-.584-.32-.154-.698-.232-1.136-.232-.42 0-.792.07-1.112.208a1.716 1.716 0 0 0-.728.568c-.165.24-.248.515-.248.824 0 .459.144.814.432 1.064.294.246.755.443 1.384.592.384.102.672.187.864.256.198.07.344.152.44.248a.485.485 0 0 1 .152.368c0 .224-.096.4-.288.528-.192.123-.477.184-.856.184-.33 0-.592-.061-.784-.184a.816.816 0 0 1-.368-.456.306.306 0 0 0-.104-.112c-.032-.026-.08-.04-.144-.04h-.712a.163.163 0 0 0-.168.168c.011.272.104.526.28.76.176.235.435.424.776.568ZM4.536 20a.163.163 0 0 1-.167-.168c0-.032.008-.064.024-.096l1.792-2.592-1.68-2.48a.183.183 0 0 1-.024-.096.163.163 0 0 1 .168-.168h.816c.096 0 .179.051.248.152l1.176 1.728 1.184-1.728c.07-.101.152-.152.248-.152h.776a.163.163 0 0 1 .168.168.15.15 0 0 1-.032.096l-1.672 2.48 1.792 2.592a.246.246 0 0 1 .032.096.152.152 0 0 1-.056.12.154.154 0 0 1-.112.048h-.848c-.101 0-.181-.048-.24-.144l-1.28-1.808-1.256 1.808c-.07.096-.152.144-.248.144h-.808Zm6.042 0a.185.185 0 0 1-.136-.056.185.185 0 0 1-.056-.136v-5.216a.17.17 0 0 1 .056-.136.185.185 0 0 1 .136-.056h.744a.17.17 0 0 1 .136.056.17.17 0 0 1 .056.136v4.456h2.536c.058 0 .106.019.144.056a.195.195 0 0 1 .056.144v.56a.195.195 0 0 1-.056.144.214.214 0 0 1-.144.048h-3.472Z"
      />
    </svg>
  );
}
