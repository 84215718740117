import classNames from "classnames";
import { useIntl } from "react-intl";

import { defaultIconClassNames, IconProps } from "./Icon";

export default function ReplayIcon({ size = 12, className = "" }: IconProps) {
  const intl = useIntl();
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 8 11"
      className={classNames(defaultIconClassNames, className)}
      xmlns="http://www.w3.org/2000/svg"
      aria-label={intl.formatMessage({ defaultMessage: "Replay", id: "5yUsUe" })}
    >
      <path
        d="M4 2.86035V0.860352L1.5 3.36035L4 5.86035V3.86035C5.655 3.86035 7 5.20535 7 6.86035C7 8.51535 5.655 9.86035 4 9.86035C2.345 9.86035 1 8.51535 1 6.86035H0C0 9.07035 1.79 10.8604 4 10.8604C6.21 10.8604 8 9.07035 8 6.86035C8 4.65035 6.21 2.86035 4 2.86035Z"
        fill="#96AAB9"
      />
    </svg>
  );
}
