import classNames from "classnames";
import { useIntl } from "react-intl";

import { defaultIconClassNames, IconProps } from "./Icon";

export default function InfoIcon({ size = 16, className = "" }: IconProps) {
  const intl = useIntl();
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 12 12"
      fill="none"
      className={classNames(defaultIconClassNames, className)}
      xmlns="http://www.w3.org/2000/svg"
      aria-label={intl.formatMessage({ defaultMessage: "Info", id: "we4Lby" })}
    >
      <path d="M9.18756 0.75H2.81244C2.26542 0.750038 1.74081 0.967375 1.35403 1.3542C0.967251 1.74103 0.749975 2.26565 0.75 2.81268V9.18732C0.749975 9.73434 0.967251 10.259 1.35403 10.6458C1.74081 11.0326 2.26542 11.25 2.81244 11.25H9.18756C9.73458 11.25 10.2592 11.0326 10.646 10.6458C11.0327 10.259 11.25 9.73434 11.25 9.18732V2.81268C11.25 2.26565 11.0327 1.74103 10.646 1.3542C10.2592 0.967375 9.73458 0.750038 9.18756 0.75ZM6 2.68892C6.14159 2.68892 6.28001 2.73091 6.39774 2.80957C6.51547 2.88824 6.60723 3.00005 6.66141 3.13086C6.7156 3.26168 6.72978 3.40562 6.70215 3.5445C6.67453 3.68337 6.60635 3.81093 6.50622 3.91105C6.4061 4.01118 6.27854 4.07936 6.13967 4.10698C6.00079 4.13461 5.85685 4.12043 5.72603 4.06624C5.59522 4.01206 5.48341 3.9203 5.40474 3.80257C5.32608 3.68484 5.28409 3.54642 5.28409 3.40483C5.28409 3.21496 5.35952 3.03286 5.49378 2.89861C5.62803 2.76435 5.81013 2.68892 6 2.68892ZM6.98437 9.34091H5.07528C4.9487 9.34091 4.82731 9.29063 4.7378 9.20112C4.6483 9.11161 4.59801 8.99022 4.59801 8.86364C4.59801 8.73706 4.6483 8.61566 4.7378 8.52615C4.82731 8.43665 4.9487 8.38636 5.07528 8.38636H5.52273V5.52273H5.07528C4.9487 5.52273 4.82731 5.47244 4.7378 5.38294C4.6483 5.29343 4.59801 5.17204 4.59801 5.04545C4.59801 4.91887 4.6483 4.79748 4.7378 4.70797C4.82731 4.61847 4.9487 4.56818 5.07528 4.56818H6.47727V8.38636H6.98437C7.11096 8.38636 7.23235 8.43665 7.32186 8.52615C7.41136 8.61566 7.46165 8.73706 7.46165 8.86364C7.46165 8.99022 7.41136 9.11161 7.32186 9.20112C7.23235 9.29063 7.11096 9.34091 6.98437 9.34091Z" />
    </svg>
  );
}
