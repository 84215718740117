import classNames from "classnames";
import { useIntl } from "react-intl";

import { defaultIconClassNames, IconProps } from "./Icon";

export default function FilledTriangleWarningIcon({ size = 22, className = "" }: IconProps) {
  const intl = useIntl();
  return (
    <svg
      width={size}
      height={size}
      className={classNames(defaultIconClassNames, className)}
      viewBox="0 0 18 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      aria-label={intl.formatMessage({ defaultMessage: "Filled triangle warning", id: "uaqdJG" })}
    >
      <path
        d="M0.832031 14.75H17.332L9.08203 0.5L0.832031 14.75ZM9.83203 12.5H8.33203V11H9.83203V12.5ZM9.83203 9.5H8.33203V6.5H9.83203V9.5Z"
        fill="currentColor"
      />
    </svg>
  );
}
