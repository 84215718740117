import classNames from "classnames";
import { useIntl } from "react-intl";

import { defaultIconClassNames, IconProps } from "./Icon";

export default function FileDownloadIcon({ size = 20, className = "", onClick }: IconProps) {
  const intl = useIntl();
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 20 20"
      className={classNames(defaultIconClassNames, className)}
      onClick={onClick}
      xmlns="http://www.w3.org/2000/svg"
      aria-label={intl.formatMessage({ defaultMessage: "File download", id: "Zx8Esf" })}
    >
      <path d="M15.8332 7.5H12.4998V2.5H7.49984V7.5H4.1665L9.99984 13.3333L15.8332 7.5ZM4.1665 15V16.6667H15.8332V15H4.1665Z" />
    </svg>
  );
}
