import { Typography } from "@fonoa/ui-components/typography";
import { FormattedMessage } from "react-intl";

export default function ErrorTitle({ errorCode }: { errorCode: number | string }) {
  return (
    <Typography
      component="h3"
      color="text-blueGray600"
      spacing={{ mx: "auto", mt: 10 }}
      fontSize="text-lg"
      fontWeight="font-medium"
    >
      <FormattedMessage defaultMessage="Error {errorCode}" id="2wdU2v" values={{ errorCode }} />
    </Typography>
  );
}
