import * as envVar from "env-var";

type ExtraAccessors = {
  asUrlStringNoTrailingSlash: (value: string) => string;
  asStringNoDoubleQuotes: (value: string) => string;
};

const env = envVar.from<Record<string, string | undefined>, ExtraAccessors>(
  {
    ...process.env,
    NEXT_PUBLIC_AUTH0_DOMAIN: process.env.NEXT_PUBLIC_AUTH0_DOMAIN,
    NEXT_PUBLIC_AUTH0_SCOPE: process.env.NEXT_PUBLIC_AUTH0_SCOPE,
    NEXT_PUBLIC_AUTH0_CONNECTION: process.env.NEXT_PUBLIC_AUTH0_CONNECTION,
    NEXT_PUBLIC_HEAP_ID: process.env.NEXT_PUBLIC_HEAP_ID,
    NEXT_PUBLIC_LAUNCH_DARKLY_CLIENT_SIDE_ID: process.env.NEXT_PUBLIC_LAUNCH_DARKLY_CLIENT_SIDE_ID,
    NEXT_PUBLIC_ZENDESK_KEY: process.env.NEXT_PUBLIC_ZENDESK_KEY,
  },
  {
    asUrlStringNoTrailingSlash: (value) => envVar.accessors.asUrlString(value).replace(/\/+$/, ""),
    asStringNoDoubleQuotes: (value) => envVar.accessors.asString(value).replaceAll('"', ""),
  }
);

const config = {
  // General server config
  HOST: () => env.get("HOST").required().asString(),
  PORT: () => env.get("PORT").default(3000).asPortNumber(),
  FIT_NAME: () => env.get("FIT_NAME").default("dashboard-app").required().asString(),
  API_TIMEOUT: () => env.get("API_TIMEOUT").default(10000).asIntPositive(),

  // MongoDB
  MONGO_CONN_STRING: () => env.get("MONGO").required().asUrlStringNoTrailingSlash(),
  MONGO_DB_NAME: () => env.get("MONGO_DB_NAME").default("dashboard_app").asString(),

  // Fonoa Signing Key
  FONOA_JWT_SECRET_KEY: (demoDataMode: boolean) =>
    demoDataMode
      ? env.get("DEMO_INTERNAL_JWT_SECRET_KEY").required().asString()
      : env.get("INTERNAL_JWT_SECRET_KEY").required().asString(),

  //
  // API URLs
  //
  FONOA_API_URL: (demoDataMode: boolean) =>
    demoDataMode
      ? env
          .get("FONOA_DEMO_API_URL")
          .default("https://api-demo.fonoa.com")
          .asUrlStringNoTrailingSlash()
      : env.get("FONOA_API_URL").required().asUrlStringNoTrailingSlash(),

  FONOA_INTERNAL_TAX_API_URL: (demoDataMode: boolean) =>
    demoDataMode
      ? env.get("FONOA_INTERNAL_TAX_API_DEMO_URL").required().asUrlStringNoTrailingSlash()
      : env.get("FONOA_INTERNAL_TAX_API_URL").required().asUrlStringNoTrailingSlash(),

  //
  // Auth0
  //
  AUTH0_DOMAIN: () => env.get("NEXT_PUBLIC_AUTH0_DOMAIN").required().asString(),
  AUTH0_AUDIENCE_DOMAIN: () => env.get("AUTH0_AUDIENCE_DOMAIN").required().asString(),
  AUTH0_SCOPE: () => env.get("NEXT_PUBLIC_AUTH0_SCOPE").required().asStringNoDoubleQuotes(),
  AUTH0_MANAGEMENT_CLIENT_ID: () => env.get("AUTH0_MANAGEMENT_CLIENT_ID").required().asString(),
  AUTH0_CLIENT_SECRET: () => env.get("AUTH0_CLIENT_SECRET").required().asString(),
  AUTH0_CONNECTION: () => env.get("NEXT_PUBLIC_AUTH0_CONNECTION").required().asString(),
  AUTH0_ENCRYPTION_SECRET: () => env.get("AUTH0_ENCRYPTION_SECRET").required().asString(),

  //
  // Sendgrid
  //
  SENDGRID_API_KEY: () => env.get("SENDGRID_API_KEY").required().asString(),
  SENDGRID_API_ENDPOINT: () => env.get("SENDGRID_API_ENDPOINT").required().asString(),
  SENDGRID_INVITATION_TEMPLATE: () => env.get("SENDGRID_INVITATION_TEMPLATE").required().asString(),
  SENDGRID_RETURNS_AUDIT_TRAIL_REQUEST_TEMPLATE: () =>
    env.get("SENDGRID_RETURNS_AUDIT_TRAIL_REQUEST_TEMPLATE").required().asString(),
  SENDGRID_FROM_EMAIL: () => env.get("SENDGRID_FROM_EMAIL").required().asString(),
  SENDGRID_FROM_NAME: () => env.get("SENDGRID_FROM_NAME").required().asStringNoDoubleQuotes(),

  //
  // Heap analytics
  //
  HEAP_ID: () => env.get("NEXT_PUBLIC_HEAP_ID").default("2621106660").asString(),

  //
  // Sentry monitoring
  //
  SENTRY_DSN: () =>
    env
      .get("NEXT_PUBLIC_SENTRY_DSN")
      .default("https://a479c7d6225044e99be80f978534ed5e@o586515.ingest.sentry.io/5738237")
      .asUrlStringNoTrailingSlash(),

  SENTRY_TRACE_RATE: () => env.get("NEXT_PUBLIC_SENTRY_TRACE_RATE").default("1").asFloat(),

  //
  // LaunchDarkly
  //
  LAUNCH_DARKLY_CLIENT_SIDE_ID: () =>
    env.get("NEXT_PUBLIC_LAUNCH_DARKLY_CLIENT_SIDE_ID").required().asString(),

  // Fonoa Email Address
  SUPPORT_EMAIL_ADDRESS: () => env.get("SUPPORT_EMAIL").default("support@fonoa.com").asString(),
  INFO_EMAIL_ADDRESS: () => env.get("INFO_EMAIL").default("info@fonoa.com").asString(),

  // Fonoauth
  FONOAUTH_GRPC_URL: () => env.get("FONOAUTH_GRPC_URL").required().asString(),
  FONOAUTH_TLS: () => env.get("FONOAUTH_TLS").default("false").asBool(),
  FONOAUTH_INGRESS_EXTERNAL_AUTH_BEARER_TOKEN: () =>
    env.get("FONOAUTH_INGRESS_EXTERNAL_AUTH_BEARER_TOKEN").default("").asString(),
  FONOAUTH_GRPC_URL_DEMO: () => env.get("FONOAUTH_GRPC_URL_DEMO").required().asString(),
  FONOAUTH_DEMO_TLS: () => env.get("FONOAUTH_DEMO_TLS").default("false").asBool(),
  FONOAUTH_DEMO_INGRESS_EXTERNAL_AUTH_BEARER_TOKEN: () =>
    env.get("FONOAUTH_DEMO_INGRESS_EXTERNAL_AUTH_BEARER_TOKEN").default("").asString(),

  // Message Bus
  MESSAGE_BUS_GRPC_URL: () => env.get("MESSAGE_BUS_GRPC_URL").required().asString(),

  // Zendesk
  ZENDESK_KEY: () =>
    env.get("NEXT_PUBLIC_ZENDESK_KEY").default("2af2f3e3-d479-4ed9-be39-6c49e8d4736a").asString(),

  // Reporting
  REPORTING_LEGACY_TENANT_SLUGS: () => env.get("REPORTING_LEGACY_TENANT_SLUGS").asString(),

  // Intercom
  INTERCOM_APP_ID: () => env.get("NEXT_PUBLIC_INTERCOM_APP_ID").default("zwoawp9k").asString(),
  INTERCOM_WEB_VERIFICATION_SECRET_KEY: () =>
    env.get("INTERCOM_WEB_VERIFICATION_SECRET_KEY").asString(),
  INTERCOM_ENABLED_TENANT_SLUGS: () => env.get("INTERCOM_ENABLED_TENANT_SLUGS").asString(),
};

export default config;
