import classNames from "classnames";
import { useIntl } from "react-intl";

import { defaultIconClassNames, IconProps } from "./Icon";

export default function PrintIcon({ size = 20, className = "" }: IconProps) {
  const intl = useIntl();
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 16 16"
      fill="none"
      className={classNames(defaultIconClassNames, className)}
      xmlns="http://www.w3.org/2000/svg"
      aria-label={intl.formatMessage({ defaultMessage: "Print", id: "CXRlIo" })}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.75 1.77778C4.75 1.63752 4.86193 1.52381 5 1.52381H10H11C11.1381 1.52381 11.25 1.63752 11.25 1.77778V2.79365V4.8254H4.75V1.77778ZM3.25 4.8254V1.77778C3.25 0.795938 4.0335 0 5 0H10H11C11.9665 0 12.75 0.795938 12.75 1.77778V2.79365V4.8254H14.5H15C15.5523 4.8254 16 5.28022 16 5.84127V6.34921V11.4286V11.9365C16 12.4976 15.5523 12.9524 15 12.9524H14.5H13.5H12V14.4762V14.9841C12 15.5452 11.5523 16 11 16H5C4.44772 16 4 15.5452 4 14.9841V14.4762V12.9524H2.5H1.5H1C0.447715 12.9524 0 12.4976 0 11.9365V11.4286V6.34921V5.84127C0 5.28022 0.447715 4.8254 1 4.8254H1.5H3.25ZM10.5 11.4286V12.9524V14.4762H5.5V12.9524V11.4286V10.4127H10.5V11.4286ZM10.5 8.88889H12C12.4142 8.88889 12.75 9.23001 12.75 9.65079C12.75 10.0716 12.4142 10.4127 12 10.4127V11.4286H13H13.5H14.5V9.90476V7.87302V6.34921H13H3H1.5V7.87302V9.90476V11.4286H2.5H3H4V10.4127C3.58579 10.4127 3.25 10.0716 3.25 9.65079C3.25 9.23001 3.58579 8.88889 4 8.88889H5.5H10.5Z"
      />
    </svg>
  );
}
