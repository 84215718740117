import classNames from "classnames";
import { useIntl } from "react-intl";

import { defaultIconClassNames, IconProps } from "./Icon";

export default function PendingActionsIcon({ size = 14, className = "" }: IconProps) {
  const intl = useIntl();
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 14 14"
      className={classNames(defaultIconClassNames, className)}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      aria-label={intl.formatMessage({ defaultMessage: "Pending actions", id: "SuF7il" })}
    >
      <path d="M9.91658 6.99998C8.30658 6.99998 6.99992 8.30665 6.99992 9.91665C6.99992 11.5266 8.30658 12.8333 9.91658 12.8333C11.5266 12.8333 12.8333 11.5266 12.8333 9.91665C12.8333 8.30665 11.5266 6.99998 9.91658 6.99998ZM10.8791 11.2875L9.62492 10.0333V8.16665H10.2083V9.79415L11.2874 10.8733L10.8791 11.2875ZM10.4999 1.74998H8.64492C8.39992 1.07331 7.75825 0.583313 6.99992 0.583313C6.24158 0.583313 5.59992 1.07331 5.35492 1.74998H3.49992C2.85825 1.74998 2.33325 2.27498 2.33325 2.91665V11.6666C2.33325 12.3083 2.85825 12.8333 3.49992 12.8333H7.06408C6.71992 12.5008 6.43992 12.1041 6.23575 11.6666H3.49992V2.91665H4.66658V4.66665H9.33325V2.91665H10.4999V5.87998C10.9141 5.93831 11.3049 6.06081 11.6666 6.22998V2.91665C11.6666 2.27498 11.1416 1.74998 10.4999 1.74998ZM6.99992 2.91665C6.67908 2.91665 6.41658 2.65415 6.41658 2.33331C6.41658 2.01248 6.67908 1.74998 6.99992 1.74998C7.32075 1.74998 7.58325 2.01248 7.58325 2.33331C7.58325 2.65415 7.32075 2.91665 6.99992 2.91665Z" />
    </svg>
  );
}
