import { CodeIcon, GlobeIcon, IconProps, QuestionAnswerIcon } from "@fonoa/ui-components/icons";
import { A, Typography } from "@fonoa/ui-components/typography";
import { ComponentType, ReactNode } from "react";
import { FormattedMessage } from "react-intl";

import { Product } from "@/lib/product";

interface ResourcesItem {
  title: ReactNode;
  href: string;
  icon: ComponentType<IconProps>;
}

type Resources = Record<Exclude<Product, "returns">, ResourcesItem[]>;

const helpCenter: ResourcesItem = {
  title: <FormattedMessage defaultMessage="Support Center" id="8Yfgk1" />,
  href: "https://www.fonoa.com/help-center",
  icon: QuestionAnswerIcon,
};
const supportedCountries: ResourcesItem = {
  title: <FormattedMessage defaultMessage="Supported countries" id="1PHWvQ" />,
  href: "https://www.fonoa.com/countries",
  icon: GlobeIcon,
};

const RESOURCES: Resources = {
  lookup: [
    { ...supportedCountries, href: "https://www.fonoa.com/countries?lookup=true" },
    {
      title: <FormattedMessage defaultMessage="Lookup API Doc" id="/SloyL" />,
      href: "https://docs.fonoa.com/reference/about-fonoa-lookup",
      icon: CodeIcon,
    },
    helpCenter,
  ],
  tax: [
    { ...supportedCountries, href: "https://www.fonoa.com/countries?tax=true" },
    {
      title: <FormattedMessage defaultMessage="Tax API Doc" id="Oh5LAa" />,
      href: "https://docs.fonoa.com/reference/about-fonoa-tax",
      icon: CodeIcon,
    },
    helpCenter,
  ],
  invoicing: [
    { ...supportedCountries, href: "https://www.fonoa.com/countries?invoicing=true" },
    {
      title: <FormattedMessage defaultMessage="Invoicing API Doc" id="A9xjMX" />,
      href: "https://docs.fonoa.com/reference/about-fonoa-e-invoicing",
      icon: CodeIcon,
    },
    helpCenter,
  ],
  reporting: [
    { ...supportedCountries, href: "https://www.fonoa.com/countries?reporting=true" },
    {
      title: <FormattedMessage defaultMessage="Reporting API Doc" id="njJupI" />,
      href: "https://docs.fonoa.com/reference/about-fonoa-e-invoicing",
      icon: CodeIcon,
    },
    helpCenter,
  ],
  einvoicing: [
    { ...supportedCountries, href: "https://www.fonoa.com/countries?reporting=true" },
    {
      title: <FormattedMessage defaultMessage="E-Invoicing API Doc" id="jhCjt8" />,
      href: "https://docs.fonoa.com/reference/about-fonoa-e-invoicing",
      icon: CodeIcon,
    },
    helpCenter,
  ],
};

function SidebarResourcesItem({ icon, title, href }: ResourcesItem) {
  return (
    <div className="mt-3">
      <A
        href={href}
        className="flex w-full items-center text-xs text-blueGray500 group-hover:text-white"
        leftIcon={icon}
        iconSize={10}
        leftIconProps={{ className: "text-blueGray600 group-hover:text-white" }}
        externalIconProps={{ className: "text-blueGray600 group-hover:text-white ml-auto" }}
        basic
        external
      >
        {title}
      </A>
    </div>
  );
}

interface SidebarResourcesProps {
  currentProduct?: Product;
  isProductActive: boolean;
}

export function SidebarResources({ currentProduct, isProductActive }: SidebarResourcesProps) {
  if (!isProductActive || !currentProduct) {
    return null;
  }

  const productResources = RESOURCES[currentProduct as keyof typeof RESOURCES];

  if (!productResources) {
    return null;
  }

  return (
    <div className="my-8 mx-6" data-cy="resources">
      <Typography color="text-blueGray500" fontSize="text-xs">
        <FormattedMessage defaultMessage="RESOURCES:" id="O061ok" />
      </Typography>
      {productResources?.map((r: ResourcesItem) => (
        <SidebarResourcesItem key={r.href} {...r} />
      ))}
    </div>
  );
}
