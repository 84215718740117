import { Typography } from "@fonoa/ui-components/typography";
import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { FormattedMessage } from "react-intl";
import { Placement, Step } from "react-joyride";

export type BasicTourStep = {
  id: string;
  description: string;
  selector: string;
  route: string;

  title?: string;
  placement?: Placement;
  shouldShowInFull?: boolean;
};

export type EnhancedTourStep = Step & {
  id: string;
  shouldShowInFull?: boolean;
  route?: string;
};

type PatchState<T> = Partial<T> | ((prevState: T) => Partial<T>);

const useSetState = <T extends object>(
  initialState: T = {} as T
): [T, (patch: PatchState<T>) => void] => {
  const [state, set] = useState<T>(initialState);

  const setState = useCallback((patch: PatchState<T>) => {
    set((prevState) =>
      Object.assign({}, prevState, patch instanceof Function ? patch(prevState) : patch)
    );
  }, []);

  return [state, setState];
};

export interface TourGuideState {
  run: boolean;
  stepIndex: number;
  tourActive: boolean;
  steps: EnhancedTourStep[];
}

type TourGuidStateModifier = (patch: PatchState<TourGuideState>) => void;

interface TourGuideContextData {
  tourGuideState: TourGuideState;
  setTourGuideState: TourGuidStateModifier;
}

const initialState: TourGuideState = {
  run: false,
  stepIndex: 0,
  tourActive: false,
  steps: [],
};

const initialValue: TourGuideContextData = {
  tourGuideState: initialState,
  setTourGuideState: () => undefined,
};

const TourGuideContext = createContext<TourGuideContextData>(initialValue);
TourGuideContext.displayName = "TourGuideContext";

interface GuidedTourProps {
  children: ReactNode;
}

export function GuidedTourProvider({ children }: GuidedTourProps) {
  const [tourGuideState, setTourGuideState] = useSetState(initialState);

  const value: TourGuideContextData = useMemo(() => {
    return { tourGuideState, setTourGuideState };
  }, [tourGuideState, setTourGuideState]);

  return <TourGuideContext.Provider value={value}>{children}</TourGuideContext.Provider>;
}

export function useTourGuideContext(): TourGuideContextData {
  const context = useContext(TourGuideContext);
  return context;
}

export type TourSetupFunction = (
  tourGuideState: TourGuideState,
  setTourGuideState: TourGuidStateModifier
) => boolean | Promise<boolean>;

export function useTourStepSetup(stepId: string, setup: TourSetupFunction) {
  const { tourGuideState, setTourGuideState } = useTourGuideContext();

  useEffect(() => {
    if (
      tourGuideState.tourActive &&
      tourGuideState.steps[tourGuideState.stepIndex].id === stepId &&
      !tourGuideState.run
    ) {
      const shouldResumeTour = setup(tourGuideState, setTourGuideState);

      Promise.resolve(shouldResumeTour).then((shouldResume) => {
        if (shouldResume) {
          setTourGuideState({ run: true });
        }
      });
    }
  }, [stepId, setup, tourGuideState, setTourGuideState]);
}

interface TourControls {
  isTourRunning: boolean;
  startTour: (steps: BasicTourStep[]) => void;
}

function enhanceBasicSteps(steps: BasicTourStep[]): EnhancedTourStep[] {
  return steps.map((step) => {
    return {
      id: step.id,
      target: step.selector,
      content: (
        <div className="pr-8">
          <Typography component="p" fontWeight="font-normal" align="text-left">
            <FormattedMessage
              defaultMessage="{description}"
              id="JPGSJT"
              values={{ description: step.description }}
            />
          </Typography>
        </div>
      ),
      floaterProps: {
        disableAnimation: true,
      },
      spotlightPadding: 5,
      placement: step.placement ? step.placement : "auto",
      disableBeacon: true,

      shouldShowInFull: step.shouldShowInFull,
      route: step.route,
    };
  });
}

export function useTourControls() {
  const { tourGuideState, setTourGuideState } = useTourGuideContext();

  const controls: TourControls = {
    isTourRunning: tourGuideState.run,
    startTour: (steps) =>
      setTourGuideState({
        tourActive: true,
        run: false,
        stepIndex: 0,
        steps: enhanceBasicSteps(steps),
      }),
  };

  return controls;
}
