import classNames from "classnames";
import { useIntl } from "react-intl";

import { defaultIconClassNames, IconProps } from "./Icon";

export default function SettingsIcon({ size = 32, className = "" }: IconProps) {
  const intl = useIntl();
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 16 16"
      fill="none"
      className={classNames(defaultIconClassNames, className)}
      xmlns="http://www.w3.org/2000/svg"
      aria-label={intl.formatMessage({ defaultMessage: "Settings", id: "D3idYv" })}
    >
      <path d="M15.8422 9.78406C15.8414 9.78333 15.8401 9.78271 15.8395 9.78137L14.8141 8.83936C14.8488 8.5553 14.8668 8.27405 14.8668 8C14.8668 7.72595 14.8488 7.4447 14.8141 7.16064L15.8422 6.21594C16.1814 5.89661 16.2588 5.39001 16.0288 4.97937L14.8975 3.02332C14.6708 2.62 14.1781 2.42798 13.7268 2.56873L12.3961 2.98865C11.9448 2.63733 11.4581 2.35461 10.946 2.14661L10.6388 0.78064C10.5381 0.328613 10.1294 0 9.66675 0H7.40015C6.93738 0 6.52881 0.328613 6.42811 0.780029L6.12073 2.14661C5.60877 2.35461 5.12208 2.63733 4.67078 2.98865L3.33875 2.56873C2.89014 2.42932 2.39612 2.61938 2.17212 3.01929L1.03614 4.98328C0.80811 5.39001 0.885381 5.89661 1.22815 6.21863L2.25342 7.16064C2.21814 7.4447 2.20008 7.72595 2.20008 8C2.20008 8.27405 2.21814 8.5553 2.25281 8.83936L1.22474 9.78406C0.885381 10.1034 0.80811 10.61 1.03809 11.02L2.16944 12.976C2.39612 13.38 2.88807 13.5713 3.34009 13.4313L4.67078 13.0114C5.12208 13.3627 5.60877 13.6454 6.12073 13.8534L6.42811 15.2186C6.52881 15.6714 6.93738 16 7.40015 16H9.66675C10.1294 16 10.5381 15.6714 10.6388 15.22L10.946 13.8534C11.4581 13.6454 11.9448 13.3633 12.3961 13.0114L13.7282 13.4313C14.1781 13.572 14.6708 13.38 14.8954 12.98L16.0315 11.016C16.2588 10.61 16.1814 10.1034 15.8422 9.78406ZM8.53345 11.3334C6.69544 11.3334 5.20008 9.83801 5.20008 8C5.20008 6.16199 6.69544 4.66663 8.53345 4.66663C10.3715 4.66663 11.8668 6.16199 11.8668 8C11.8668 9.83801 10.3715 11.3334 8.53345 11.3334Z" />
    </svg>
  );
}
