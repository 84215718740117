export class HeapAnalytics {
  static async identify(userProps: Record<string, string>) {
    const appVersion = window.__NEXT_DATA__?.buildId || "dev";

    // LGL-504 confirms what PII we can capture
    if (userProps.email) {
      window.heap.identify(userProps.email);

      window.heap.addUserProperties({
        auth_id: userProps.user_id, // renamed to avoid conflicting with Heap's "user_id" concept
        tenant: userProps.tenant,
        demo: userProps.demo_mode,
        version: appVersion,
        product_updates: userProps.product_updates,
      });
    }
  }
}

export const TrackCustomEvent = (
  eventName: keyof EventNames,
  properties: Record<string, unknown>
) => {
  window.heap.track(eventName, properties);
};

type EventNames = {
  ThisPageWasUseful: string;
};
