import classNames from "classnames";
import { useIntl } from "react-intl";

import { defaultIconClassNames, IconProps } from "./Icon";

export default function MergeIcon({ size = 32, className = "" }: IconProps) {
  const intl = useIntl();
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 22 22"
      fill="none"
      className={classNames(defaultIconClassNames, className)}
      xmlns="http://www.w3.org/2000/svg"
      aria-label={intl.formatMessage({ defaultMessage: "Merge", id: "NLSvjh" })}
    >
      <path d="m20 11-4 4-1.41-1.41L16.17 12h-5.23A8.974 8.974 0 0 1 6 19.05 3.005 3.005 0 0 1 3 22c-1.66 0-3-1.34-3-3s1.34-3 3-3c.95 0 1.78.45 2.33 1.14A6.969 6.969 0 0 0 8.91 12h-3.1C5.4 13.16 4.3 14 3 14c-1.66 0-3-1.34-3-3s1.34-3 3-3c1.3 0 2.4.84 2.82 2h3.1C8.6 7.77 7.23 5.9 5.33 4.86 4.78 5.55 3.95 6 3 6 1.34 6 0 4.66 0 3s1.34-3 3-3a2.99 2.99 0 0 1 2.99 2.95A8.974 8.974 0 0 1 10.93 10h5.23l-1.58-1.59L16 7l4 4Z" />
    </svg>
  );
}
