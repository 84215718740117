import * as Sentry from "@sentry/nextjs";
import { Event, EventHint, Request, SamplingContext } from "@sentry/types";
import axios from "axios";

import { productFromPath } from "./product";
import { Environment } from "./utils";

export const environmentAllowList: Environment[] = ["staging", "production"];

export function initSentry(ingestUrl: string, environment: Environment, samplingRate: number) {
  Sentry.init({
    dsn: ingestUrl,
    // Configures the sample rate for error events, in the range of 0.0 to 1.0.
    // The default is 1.0 which means that 100% of error events are sent.
    // If set to 0.1 only 10% of error events will be sent. Events are picked randomly.
    sampleRate: 1,
    beforeSend: (event, hint) => {
      // In dev environment, log to console and don't forward to Sentry
      if (!environmentAllowList.includes(environment)) {
        console.error(
          "LOG SENTRY ERROR",
          hint?.originalException?.toString() ??
            hint?.syntheticException?.message ??
            "Unknown error"
        );
        return null; // Don't send to Sentry
      }

      return applyFingerprint(removePII(obfuscateSensitiveData(event)), hint);
    },
    beforeSendTransaction: (event) => {
      return obfuscateSensitiveData(event);
    },
    replaysSessionSampleRate: 0, // We don't need session replays when errors didn't occur
    replaysOnErrorSampleRate: 1.0,
    tracesSampler: (samplingContext) => {
      return sampleTrace(samplingContext, samplingRate, environment);
    },
    environment: environment,
  });
}

function obfuscateSensitiveData(event: Event): Event {
  if (event.request?.headers?.referer?.includes("/acceptInvitation?")) {
    const newUrl = "/acceptInvitation?token=REDACTED";
    const fullUrl = event.request?.headers?.referer.split("/acceptInvitation?")[0] + newUrl;

    event.request.headers.referer = fullUrl;
    event.request.url = fullUrl;

    if (typeof event.request.query_string === "object") {
      event.request.query_string = {
        ...event.request.query_string,
        token: "REDACTED",
      };
    }

    if (event?.sdkProcessingMetadata?.request.url) {
      event.sdkProcessingMetadata.request.url = newUrl;
      event.sdkProcessingMetadata.request.originalUrl = newUrl;
    }
  }

  return event;
}

/**
 * Removes PII from Sentry events.
 *
 * Currently we do not identify a user in Sentry, so treat this as an example.
 * @param event the `Sentry.Event` from a `beforeSend` hook
 * @see https://docs.sentry.io/platforms/javascript/data-management/sensitive-data/
 * @returns the event with PII removed
 */
function removePII(event: Event): Event {
  if (event.user) {
    delete event.user.email;
  }

  return event;
}

/**
 * Applies a fingerprint to Sentry event, so we can group issues correctly.
 *
 * "{{ default }}" is always the first item so that we start with Sentry's default grouping
 * and then split it up further.
 * @param event the `Sentry.Event` from a `beforeSend` hook
 * @param hint the `Sentry.EventHint` from a `beforeSend` hook
 * @see https://docs.sentry.io/platforms/javascript/usage/sdk-fingerprinting
 * @returns the event with a fingerprint applied
 */
export function applyFingerprint(event: Event, hint: EventHint): Event {
  const exception = hint.originalException;
  const request = event.request;

  if (request?.url?.includes("/api/trpc/")) {
    const trpcEndpoints = getTrpcEndpointsFromRequest(request).join(",");

    event.fingerprint = ["{{ default }}", trpcEndpoints];
  } else if (axios.isAxiosError(exception)) {
    const statusCode = `${exception.response?.status || "Status code unknown"}`;
    const product = exception.request?.path ? productFromPath(exception.request.path) : undefined;

    event.fingerprint = [
      "{{ default }}",
      ...(product ? [product] : []),
      exception.request?.method,
      statusCode,
    ];
  }

  return event;
}

/**
 * This method extracts an array of trpc endpoints from the query string of a request.
 * @param request the `Sentry.Request` attached to the `Sentry.Event` from a `beforeSend` hook
 * @returns an array of trpc endpoints, (_array_ because trpc batches requests). e.g. `[ "returns.returnTaxTypes", "returns.returnCountries", "returns.forms" ]`
 */
function getTrpcEndpointsFromRequest(request: Request): string[] {
  if (Array.isArray(request.query_string)) {
    const [_, trpcParam] = request.query_string.find((queryPair) => queryPair[0] === "trpc") || [];
    return trpcParam?.split(",") || [];
  } else if (typeof request.query_string === "object") {
    return request.query_string.trpc?.split(",") || [];
  } else {
    return [];
  }
}

function sampleTrace(
  samplingContext: SamplingContext,
  samplingRate: number,
  environment: Environment
): number | boolean {
  // Sampler for transactions
  if (samplingContext.parentSampled !== undefined) {
    return samplingContext.parentSampled;
  }

  const isHealthcheckEndpoint = samplingContext.transactionContext.name.includes("/status");
  const isUnallowedEnvironment = !environmentAllowList.includes(environment);

  // Filter unwanted transactions
  if (isUnallowedEnvironment || isHealthcheckEndpoint) {
    return 0;
  }

  return samplingRate;
}
