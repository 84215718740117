import classNames from "classnames";
import { useIntl } from "react-intl";

import { defaultIconClassNames, IconProps } from "./Icon";

export default function TrashIconOutline({ size = 32, className = "" }: IconProps) {
  const intl = useIntl();
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 16 16"
      fill="none"
      className={classNames(defaultIconClassNames, className)}
      xmlns="http://www.w3.org/2000/svg"
      aria-label={intl.formatMessage({ defaultMessage: "Trash", id: "j/I5tV" })}
    >
      <g clipPath="url(#clip0_14124_147070)">
        <path
          d="M3.99998 13.1667C3.99998 13.9 4.59998 14.5 5.33331 14.5H10.6666C11.4 14.5 12 13.9 12 13.1667V5.16667H3.99998V13.1667ZM5.33331 6.5H10.6666V13.1667H5.33331V6.5ZM10.3333 3.16667L9.66665 2.5H6.33331L5.66665 3.16667H3.33331V4.5H12.6666V3.16667H10.3333Z"
          fill="#8FA2B0"
        />
      </g>
    </svg>
  );
}
