import classNames from "classnames";
import { useIntl } from "react-intl";

import { defaultIconClassNames, IconProps } from "./Icon";

export default function SentEmailIcon({ className = "" }: IconProps) {
  const intl = useIntl();
  return (
    <svg
      width="50"
      height="24"
      viewBox="0 0 50 24"
      className={classNames(defaultIconClassNames, className)}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      aria-label={intl.formatMessage({ defaultMessage: "Sent email", id: "TYxXiH" })}
    >
      <path
        d="M9.38299 17.2091L4.96095 19.3095C4.14636 19.6596 3.33179 19.0761 3.33179 18.1426L4.14637 9.27405"
        fill="#2D69DC"
      />
      <path
        d="M49.9958 4.02291L47.0865 5.65659L16.5978 22.927C15.2014 23.7438 13.4558 23.277 12.5249 21.9934L5.54271 11.6079L4.14628 9.50741L0.306092 3.67283C-0.508493 2.38923 0.422463 0.638852 1.93526 0.755543L44.5264 3.78953L49.9958 4.02291Z"
        fill="#739BE8"
      />
      <path
        d="M49.9957 4.02293L47.0865 5.65661L5.65904 11.4912L4.14624 9.39074L44.6428 3.67285L49.9957 4.02293Z"
        fill="#2D69DC"
      />
    </svg>
  );
}
