import classNames from "classnames";
import { useIntl } from "react-intl";

import { defaultIconClassNames, IconProps } from "./Icon";

export default function BusinessIcon({ size = 32, className = "" }: IconProps) {
  const intl = useIntl();
  return (
    <svg
      width={size}
      height={size}
      className={classNames(defaultIconClassNames, className)}
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      aria-label={intl.formatMessage({ defaultMessage: "Business", id: "w1Fanr" })}
    >
      <path
        d="M12.5 7V3H2.5V21H22.5V7H12.5ZM6.5 19H4.5V17H6.5V19ZM6.5 15H4.5V13H6.5V15ZM6.5 11H4.5V9H6.5V11ZM6.5 7H4.5V5H6.5V7ZM10.5 19H8.5V17H10.5V19ZM10.5 15H8.5V13H10.5V15ZM10.5 11H8.5V9H10.5V11ZM10.5 7H8.5V5H10.5V7ZM20.5 19H12.5V17H14.5V15H12.5V13H14.5V11H12.5V9H20.5V19ZM18.5 11H16.5V13H18.5V11ZM18.5 15H16.5V17H18.5V15Z"
        fill="currentColor"
      />
    </svg>
  );
}
