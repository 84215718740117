import { FontSize, SpacingProps } from "@fonoa/ui-components/types";
import { Typography } from "@fonoa/ui-components/typography";
import { getSpacingClass } from "@fonoa/ui-components/utils";
import classNames from "classnames";
import Image from "next/image";

import { RequireAtLeastOne } from "@/lib/types";

const containerSizes: Record<Size, string> = {
  "3xs": "w-4 h-4",
  "2xs": "w-6 h-6",
  xs: "w-8 h-8",
  s: "w-10 h-10",
  m: "w-12 h-12",
  l: "w-16 h-16",
  xl: "w-20 h-20",
};

const labelSizes: Record<Size, FontSize> = {
  "3xs": "text-2xs",
  "2xs": "text-xs",
  xs: "text-sm",
  s: "text-sm",
  m: "text-sm",
  l: "text-lg",
  xl: "text-xl",
};

const altSizes: Record<Size, string> = {
  "3xs": "p-0 text-2xs",
  "2xs": "p-0 text-xs",
  xs: "p-1 text-sm",
  s: "p-2 text-sm",
  m: "p-2 text-lg",
  l: "p-2 text-xl",
  xl: "p-2 text-2xl",
};

const indicatorSizes: Record<Size, string> = {
  "3xs": "bottom-0 right-0 border w-1.5 h-1.5",
  "2xs": "bottom-0 right-0 border w-2 h-2",
  xs: "bottom-0.5 right-0.5 border w-2.5 h-2.5",
  s: "bottom-0.5 right-0.5 border w-2.5 h-2.5",
  m: "bottom-0.5 right-0.5 border w-2.5 h-2.5",
  l: "bottom-0.5 right-0.5 border w-3 h-3",
  xl: "bottom-0.5 right-0.5 w-4 h-4 border-2",
};

// One of this props is required
interface OptionalProps {
  img?: string;
  alt?: string;
}

export type Size = "3xs" | "2xs" | "xs" | "s" | "m" | "l" | "xl";

interface Props extends SpacingProps {
  dark?: boolean;
  activeStatus?: "hidden" | boolean;
  label?: string;
  size?: Size;
  variant?: "contained" | "outlined";
}

const StatusIndicator = ({ active, size }: { active: boolean; size: Size }) => {
  return (
    <div
      data-testid="status-indicator"
      className={classNames(
        "absolute rounded-full",
        indicatorSizes[size],
        { "border-white bg-green600": active },
        { "border-blueGray300 bg-white": !active }
      )}
    />
  );
};

const Avatar = ({
  dark = false,
  activeStatus = "hidden",
  size = "m",
  variant = "contained",
  alt,
  img,
  label,
  spacing,
}: Props & RequireAtLeastOne<OptionalProps>) => {
  return (
    <div className={classNames("flex items-center", getSpacingClass(spacing))}>
      <div
        className={classNames(
          "relative flex items-center justify-items-center rounded-full",
          containerSizes[size],
          { "bg-blueGray700 text-white": dark && variant === "contained" },
          {
            "border border-blueGray600 text-blueGray600": variant === "outlined",
          },
          {
            "bg-blueGray100 text-blueGray600": !dark && variant === "contained",
          }
        )}
      >
        {img ? (
          <div className="h-full w-full overflow-hidden">
            <Image
              className="rounded-full object-cover"
              data-testid="image"
              src={img}
              alt={alt ?? label ?? ""}
              loader={({ src }) => src}
              fill
              sizes="100vw"
            />
          </div>
        ) : (
          <div
            data-testid="alt-text"
            className={classNames("w-full truncate text-center", altSizes[size])}
          >
            {alt}
          </div>
        )}
        {activeStatus !== "hidden" && <StatusIndicator size={size} active={activeStatus} />}
      </div>
      {label && (
        <Typography
          data-testid="label"
          lineHeight="leading-6"
          color="text-blueGray600"
          fontSize={labelSizes[size]}
          spacing={{ ml: 2 }}
        >
          {label}
        </Typography>
      )}
    </div>
  );
};

export default Avatar;
