import classNames from "classnames";
import { useIntl } from "react-intl";

import { defaultIconClassNames } from "./Icon";
import { ProductIconProps, ProductIconVariant } from "./ProductIcon";

export const LIGHT_FIRST_COLOR_MAPS: Record<ProductIconVariant, string> = {
  default: "text-blueGray700 text-opacity-70",
  active: "text-primaryBlue500",
  inactive: "text-blueGray100",
};

export const LIGHT_SECOND_COLOR_MAPS: Record<ProductIconVariant, string> = {
  default: "text-blueGray700",
  active: "text-greenFonoa",
  inactive: "text-blueGray400",
};

export const DARK_FIRST_COLOR_MAPS: Record<ProductIconVariant, string> = {
  default: "dark:text-blueGray700 text-opacity-70",
  active: "dark:text-primaryBlue500",
  inactive: "dark:text-blueGray100",
};

export const DARK_SECOND_COLOR_MAPS: Record<ProductIconVariant, string> = {
  default: "dark:text-white",
  active: "dark:text-greenFonoa",
  inactive: "dark:text-blueGray400",
};

export default function ProductLookupIcon({
  className,
  size = 16,
  variant = "default",
}: ProductIconProps) {
  const intl = useIntl();
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 16 16"
      fill="none"
      className={classNames(defaultIconClassNames, className)}
      xmlns="http://www.w3.org/2000/svg"
      aria-label={intl.formatMessage({ defaultMessage: "Product lookup", id: "FbC4nf" })}
    >
      <path
        fill="currentColor"
        d="M1.37793 8.08156C1.37793 7.65131 1.72672 7.30253 2.15696 7.30253L7.67704 7.30253L11.5387 3.44085L9.73823 1.6402C9.60784 1.50982 9.60784 1.29842 9.73823 1.16804C9.80084 1.10542 9.88576 1.07025 9.97431 1.07025L14.2876 1.07025C14.472 1.07025 14.6215 1.21973 14.6215 1.40412L14.6215 5.71745C14.6215 5.90185 14.472 6.05133 14.2876 6.05133C14.1991 6.05133 14.1142 6.01615 14.0516 5.95354L12.6404 4.54263L8.32241 8.8606L2.15696 8.8606C1.72672 8.8606 1.37793 8.51181 1.37793 8.08156Z"
        className={classNames(LIGHT_SECOND_COLOR_MAPS[variant], DARK_SECOND_COLOR_MAPS[variant])}
      />
      <path
        fill="currentColor"
        d="M9.29541 10.5323L10.4848 9.52592L12.3971 11.786L14.0516 10.1317C14.1037 10.0795 14.1714 10.0464 14.2438 10.0368L14.2876 10.0339C14.472 10.0339 14.6215 10.1834 14.6215 10.3677L14.6215 14.7364C14.6215 14.9208 14.472 15.0703 14.2876 15.0703L9.91895 15.0703C9.8304 15.0703 9.74548 15.0351 9.68287 14.9725C9.55248 14.8421 9.55248 14.6307 9.68287 14.5004L11.2913 12.8918L9.29541 10.5323Z"
        className={classNames(LIGHT_FIRST_COLOR_MAPS[variant], DARK_FIRST_COLOR_MAPS[variant])}
      />
    </svg>
  );
}
