import classNames from "classnames";
import { useIntl } from "react-intl";

import { defaultIconClassNames, IconProps } from "./Icon";

export default function ClockIcon({ size = 12, className = "" }: IconProps) {
  const intl = useIntl();
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 12 12"
      fill="none"
      className={classNames(defaultIconClassNames, className)}
      xmlns="http://www.w3.org/2000/svg"
      aria-label={intl.formatMessage({ defaultMessage: "Clock", id: "qCyPKf" })}
    >
      <path d="M6 10.2C8.31 10.2 10.2 8.31 10.2 6C10.2 3.69 8.31 1.8 6 1.8C3.69 1.8 1.8 3.69 1.8 6C1.8 8.31 3.69 10.2 6 10.2ZM6 0.75C8.8875 0.75 11.25 3.1125 11.25 6C11.25 8.8875 8.8875 11.25 6 11.25C3.1125 11.25 0.75 8.8875 0.75 6C0.75 3.1125 3.1125 0.75 6 0.75ZM8.625 6.9975L8.2575 7.68L5.475 6.1575V3.375H6.2625V5.685L8.625 6.9975Z" />
    </svg>
  );
}
