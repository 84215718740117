import { Button } from "@fonoa/ui-components/button";
import { Drawer } from "@fonoa/ui-components/drawer";
import { noop } from "@fonoa/ui-components/utils";
import { FormattedMessage } from "react-intl";

import { Notification } from "./Notification";
import {
  NotificationElement,
  useIsNotificationsCenterOpen,
  useNotificationsDispatcher,
  useNotificationsState,
} from "./NotificationsManager";

type NotificationsCenterProps = {
  open: boolean;
  onClose?: () => void;
  notifications: NotificationElement[];
  onNotificationClicked?: (notification: NotificationElement) => void;
  onMarkAllAsReadClicked?: () => void;
};

const DrawerTitle = <FormattedMessage defaultMessage="Notifications" id="NAidKb" />;
const MarkAllAsRead = <FormattedMessage defaultMessage="Mark all as read" id="/af+jQ" />;
const MarkAllAsReadButton = ({ onClick }: { onClick: () => void }) => (
  <Button variant="TEXT" onClick={onClick}>
    {MarkAllAsRead}
  </Button>
);

export const NotificationsCenter = ({
  open,
  onClose = noop,
  notifications,
  onNotificationClicked = noop,
  onMarkAllAsReadClicked = noop,
}: NotificationsCenterProps) => {
  return (
    <Drawer
      open={open}
      onClose={onClose}
      title={DrawerTitle}
      customDrawerWidthClassName="max-w-lg"
      customHeaderBackgroundColor="bg-blueGray25"
      topRightBlock={<MarkAllAsReadButton onClick={onMarkAllAsReadClicked} />}
    >
      <div className="flex flex-col border-t border-blueGray10 ">
        {notifications.length ? (
          notifications.map((notification) => (
            <Notification
              key={notification.id}
              isUnread={notification.unread}
              notification={{
                ...notification,
                action: notification.action
                  ? {
                      ...notification.action,
                      onClick: () => onNotificationClicked?.(notification),
                    }
                  : undefined,
              }}
              borderClassName="border-r border-l border-b border-blueGray10 "
            />
          ))
        ) : (
          <div className="mt-10 w-max self-center p-4">
            <FormattedMessage defaultMessage="Nothing to show" id="VleH11" />
          </div>
        )}
      </div>
    </Drawer>
  );
};

export const NotificationsCenterConnected = () => {
  const notificationDispatcher = useNotificationsDispatcher();
  const isNotificationCenterOpen = useIsNotificationsCenterOpen();
  const notificationState = useNotificationsState();

  return (
    <NotificationsCenter
      open={isNotificationCenterOpen}
      onClose={() => notificationDispatcher({ type: "CLOSE_DRAWER" })}
      notifications={notificationState.notifications.slice(0).reverse()}
      onMarkAllAsReadClicked={() => notificationDispatcher({ type: "READ_ALL" })}
      onNotificationClicked={(notification) => {
        notificationDispatcher({ type: "CLOSE_DRAWER" });
        notificationDispatcher({
          type: "READ",
          payload: { notificationId: notification.id },
        });
        notification.action?.onClick();
      }}
    />
  );
};
