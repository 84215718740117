import classNames from "classnames";
import { useIntl } from "react-intl";

import { defaultIconClassNames, IconProps } from "./Icon";

export default function FilterAltIcon({ size = 12, className = "" }: IconProps) {
  const intl = useIntl();
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 12 12"
      fill="none"
      className={classNames(defaultIconClassNames, className)}
      xmlns="http://www.w3.org/2000/svg"
      aria-label={intl.formatMessage({ defaultMessage: "Filter", id: "9Obw6C" })}
    >
      <path d="M2.25001 1.5H9.75001L5.99251 6.225L2.25001 1.5ZM0.187511 1.2075C1.70251 3.15 4.50001 6.75 4.50001 6.75V11.25C4.50001 11.6625 4.83751 12 5.25001 12H6.75001C7.16251 12 7.50001 11.6625 7.50001 11.25V6.75C7.50001 6.75 10.29 3.15 11.805 1.2075C12.1875 0.7125 11.835 0 11.2125 0H0.780011C0.157511 0 -0.194989 0.7125 0.187511 1.2075Z" />
    </svg>
  );
}
