import { useQuery, UseQueryOptions } from "@tanstack/react-query";
import axios from "axios";
import FormData from "form-data";

import { BatchTinSearchResponseDto } from "@/api/lookup/post-batch-tin-searches";
import { UploadCsvTinSearchKeys } from "@/features/Lookup/CsvUploadTinSearch/CsvUploadTinSearch.queries";
import { BatchTinSearchRequestListRequest } from "@/features/Lookup/CsvUploadTinSearch/CsvUploadTinSearch.types";
import { TinSearchBatchesResponse } from "@/server/trpc/routers/lookup/getTinSearchBatches";
import { ApiResponse } from "@/server/types";

export async function uploadTinSearchCsv(
  data: FormData
): Promise<ApiResponse<BatchTinSearchResponseDto>> {
  const res = await axios.post("/api/lookup/post-batch-tin-searches", data);
  return res.data;
}

interface BatchTinSearchRequestListRequestProps {
  variables?: BatchTinSearchRequestListRequest;
}

async function getBatchTinSearches({ variables }: BatchTinSearchRequestListRequestProps) {
  const res = await axios.get("/api/lookup/get-batch-tin-searches", {
    params: { variables },
  });
  return res.data;
}

export interface UseBatchTinSearchesProps {
  variables?: BatchTinSearchRequestListRequest;
  options?: UseQueryOptions<TinSearchBatchesResponse, unknown, TinSearchBatchesResponse>;
}

export const useBatchTinSearches = ({ variables, options }: UseBatchTinSearchesProps) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return useQuery<any, unknown, TinSearchBatchesResponse>(
    [UploadCsvTinSearchKeys.batchTinSearches, variables, options],
    () => getBatchTinSearches({ variables }),
    {
      select: (d) => d.data,
      ...options,
    }
  );
};
