import classNames from "classnames";
import { useIntl } from "react-intl";

import { defaultIconClassNames, IconProps } from "./Icon";

export default function ErrorIcon({ size = 32, className = "", onClick }: IconProps) {
  const intl = useIntl();
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 16 16"
      fill="none"
      className={classNames(defaultIconClassNames, className)}
      xmlns="http://www.w3.org/2000/svg"
      onClick={onClick}
      aria-label={intl.formatMessage({ defaultMessage: "Error", id: "KN7zKn" })}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 14.75C11.7279 14.75 14.75 11.7279 14.75 8C14.75 4.27208 11.7279 1.25 8 1.25C4.27208 1.25 1.25 4.27208 1.25 8C1.25 11.7279 4.27208 14.75 8 14.75ZM8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16ZM7.03997 3.9992C7.01816 3.45394 7.4543 3 8 3C8.5457 3 8.98184 3.45394 8.96003 3.9992L8.77998 8.5006C8.76323 8.91924 8.41898 9.25 8 9.25C7.58102 9.25 7.23677 8.91924 7.22002 8.5006L7.03997 3.9992ZM7.9751 10.5C7.35378 10.5 6.8501 11.0037 6.8501 11.625C6.8501 12.2463 7.35378 12.75 7.9751 12.75C8.59642 12.75 9.1001 12.2463 9.1001 11.625C9.1001 11.0037 8.59642 10.5 7.9751 10.5Z"
      />
    </svg>
  );
}
