import { useRouter } from "next/router";

import { SidebarLink, SidebarLinkProps } from "../sidebar/SidebarLink";
import { ExternalProps } from "../typography";

type SidebarLinkBase = {
  id: string;
  title: SidebarLinkProps["title"];
  href: SidebarLinkProps["href"];
  status: (pathname: string) => SidebarLinkProps["status"];
  className?: SidebarLinkProps["className"];
};

export type SidebarLinkRootType = {
  icon: SidebarLinkProps["icon"];
  label?: SidebarLinkProps["label"];
  showChildren?: (pathname: string) => boolean;
  children?: SidebarLinkChild[];
  handleClick?: (id: string) => void;
} & SidebarLinkBase &
  ExternalProps;

type SidebarLinkChild = {
  show?: boolean;
  handleClick?: (id: string) => void;
} & SidebarLinkBase;

const SidebarLinkChild = ({
  id,
  title,
  status,
  className,
  href,
  show,
  handleClick,
}: SidebarLinkChild) => {
  const router = useRouter();

  return (
    <SidebarLink
      key={id}
      title={title}
      status={status(router.pathname)}
      className={className}
      href={href}
      handleClick={() => handleClick?.(id)}
      hasAccess={typeof show === "boolean" ? show : true}
    />
  );
};

export const SidebarLinkRoot = ({
  id,
  title,
  icon,
  status,
  className,
  href,
  label,
  showChildren,
  children,
  handleClick,
  ...props
}: SidebarLinkRootType) => {
  const router = useRouter();

  return (
    <>
      <SidebarLink
        key={id}
        title={title}
        label={label}
        icon={icon}
        status={status(router.pathname)}
        className={className}
        href={href}
        handleClick={() => handleClick?.(id)}
        {...props}
      />
      {showChildren?.(router.pathname) && children
        ? children.map((link) => <SidebarLinkChild {...link} key={link.id} />)
        : null}
    </>
  );
};
