import classNames from "classnames";
import { useIntl } from "react-intl";

import { defaultIconClassNames, IconProps } from "./Icon";

function TransitionArrowsIcon({ className, onClick, size = 72 }: IconProps) {
  const intl = useIntl();
  return (
    <svg
      width={size}
      height={size / 2.6}
      viewBox="0 0 72 26"
      xmlns="http://www.w3.org/2000/svg"
      className={classNames(defaultIconClassNames, className)}
      onClick={onClick}
      aria-label={intl.formatMessage({ defaultMessage: "Transition arrows", id: "1fwH8x" })}
    >
      <path d="M71.185 12.487L60.591.63C60.414.282 59.948 0 59.551 0h-6.085c-.397 0-.575.282-.397.63l10.593 11.857c.178.348.178.913 0 1.261L53.07 25.37c-.178.349 0 .631.397.631h6.085c.744 0 1.202-.794 1.63-1.278.565-.64 8.418-9.233 10.004-10.974.177-.348.177-.913 0-1.261z" />
      <path
        opacity={0.7}
        d="M57.598 12.487L47.346.63C47.175.282 46.724 0 46.34 0H40.45c-.384 0-.556.282-.385.63l10.252 11.857c.172.348.172.913 0 1.261L40.066 25.37c-.171.349 0 .631.385.631h5.889c.72 0 1.163-.794 1.576-1.278.548-.64 8.147-9.233 9.683-10.974.171-.348.171-.913 0-1.261z"
      />
      <path
        opacity={0.3}
        d="M30.598 12.487L20.346.63C20.175.282 19.724 0 19.34 0H13.45c-.384 0-.556.282-.384.63l10.252 11.857c.171.348.171.913 0 1.261L13.067 25.37c-.172.349 0 .631.384.631h5.889c.72 0 1.163-.794 1.576-1.278.548-.64 8.147-9.233 9.683-10.974.171-.348.171-.913 0-1.261z"
      />
      <path
        opacity={0.4}
        d="M44.598 12.487L34.346.63C34.175.282 33.724 0 33.34 0H27.45c-.384 0-.556.282-.384.63l10.252 11.857c.171.348.171.913 0 1.261L27.066 25.37c-.171.349 0 .631.385.631h5.889c.72 0 1.163-.794 1.576-1.278.548-.64 8.147-9.233 9.683-10.974.171-.348.171-.913 0-1.261z"
      />
      <path
        opacity={0.15}
        d="M17.598 12.487L7.346.63C7.175.282 6.724 0 6.34 0H.45C.067 0-.105.282.067.63l10.252 11.857c.171.348.171.913 0 1.261L.067 25.37c-.172.349 0 .631.384.631H6.34c.72 0 1.163-.794 1.577-1.278.547-.64 8.146-9.233 9.682-10.974.171-.348.171-.913 0-1.261z"
      />
    </svg>
  );
}

export default TransitionArrowsIcon;
