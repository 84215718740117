import { NextRouter } from "next/router";
import { FormattedMessage, IntlShape } from "react-intl";

import { NotificationConfig } from "@/components/Notifications/NotificationsManager";
import { Batch } from "@/features/Lookup/CsvUploadValidation/CsvUploadValidation.types";
import { routes } from "@/lib/routes";

const completedTitle = <FormattedMessage defaultMessage="Validation completed" id="qZCez+" />;

const canceledTitle = <FormattedMessage defaultMessage="Validation canceled" id="Z2qo30" />;
const pendingTitle = <FormattedMessage defaultMessage="Validation scheduled" id="+b41at" />;
const runningTitle = <FormattedMessage defaultMessage="Validation in progress" id="Q+IBrs" />;

const getActualProgressLabel = (intl: IntlShape, completionPercentage: number): string =>
  intl.formatMessage(
    { defaultMessage: "Progress {progress}%", id: "m0f8YU" },
    { progress: completionPercentage }
  );

export function buildBatchValidationStartedNotification(
  intl: IntlShape,
  batchId: string
): NotificationConfig {
  return {
    id: batchId,
    type: "info",
    title: runningTitle,
    description: getActualProgressLabel(intl, 0),
    toast: {
      title: runningTitle,
    },
  };
}

export function buildValidationNotification(
  validation: Batch,
  intl: IntlShape,
  router: NextRouter
): NotificationConfig {
  const validatedTins = intl.formatMessage(
    { defaultMessage: "{tins} TINs validated", id: "pM7hsb" },
    { tins: validation.finished_count }
  );
  const actualProgress = getActualProgressLabel(intl, validation.completion_percentage);

  const view = intl.formatMessage({ defaultMessage: "View", id: "FgydNe" });
  const goToValidation = () =>
    router.push(`${routes.lookupValidationHistoryBatch}/${validation.batch_id}`);

  switch (validation.status) {
    case "canceled":
      return {
        id: validation.batch_id,
        type: "info",
        title: canceledTitle,
        description: validatedTins,
        action: {
          title: view,
          onClick: goToValidation,
        },
        toast: {
          title: canceledTitle,
        },
      };
    case "completed":
      return {
        id: validation.batch_id,
        type: "success",
        title: completedTitle,
        description: validatedTins,
        action: {
          title: view,
          onClick: goToValidation,
        },
        toast: {
          title: completedTitle,
        },
      };
    case "pending":
      return {
        id: validation.batch_id,
        type: "info",
        title: pendingTitle,
        description: actualProgress,
        toast: {
          title: pendingTitle,
        },
      };
    case "running":
      return {
        id: validation.batch_id,
        type: "info",
        title: runningTitle,
        description: actualProgress,
      };
  }
}
