import classNames from "classnames";
import { useIntl } from "react-intl";

import { defaultIconClassNames, IconProps } from "./Icon";

export default function CallCenterIcon({ size = 16, className = "" }: IconProps) {
  const intl = useIntl();
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 16 16"
      fill="none"
      className={classNames(defaultIconClassNames, className)}
      xmlns="http://www.w3.org/2000/svg"
      aria-label={intl.formatMessage({ defaultMessage: "Call center", id: "/uYLXV" })}
    >
      <path d="M8.00001 1.33325C4.32401 1.33325 1.33334 4.32392 1.33334 7.99992V10.7619C1.33334 11.4446 1.93134 11.9999 2.66668 11.9999H3.33334C3.51015 11.9999 3.67972 11.9297 3.80475 11.8047C3.92977 11.6796 4.00001 11.5101 4.00001 11.3333V7.90459C4.00001 7.72777 3.92977 7.55821 3.80475 7.43318C3.67972 7.30816 3.51015 7.23792 3.33334 7.23792H2.72801C3.09868 4.65792 5.31868 2.66659 8.00001 2.66659C10.6813 2.66659 12.9013 4.65792 13.272 7.23792H12.6667C12.4899 7.23792 12.3203 7.30816 12.1953 7.43318C12.0702 7.55821 12 7.72777 12 7.90459V11.9999C12 12.7353 11.402 13.3333 10.6667 13.3333H9.33334V12.6666H6.66668V14.6666H10.6667C12.1373 14.6666 13.3333 13.4706 13.3333 11.9999C14.0687 11.9999 14.6667 11.4446 14.6667 10.7619V7.99992C14.6667 4.32392 11.676 1.33325 8.00001 1.33325Z" />
    </svg>
  );
}
