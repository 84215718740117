import classNames from "classnames";
import { useIntl } from "react-intl";

import { defaultIconClassNames, IconProps } from "./Icon";

export default function OpenInNewTabIcon({ size = 19, className = "" }: IconProps) {
  const intl = useIntl();
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 19 19"
      fill="none"
      className={classNames(defaultIconClassNames, className)}
      xmlns="http://www.w3.org/2000/svg"
      aria-label={intl.formatMessage({ defaultMessage: "Open in new tab", id: "jeJllq" })}
    >
      <g id="open_in_new" clipPath="url(#clip0_12943_529)">
        <path
          id="Vector"
          d="M14.8984 15.0305H4.39844V4.53046H9.64844V3.03046H4.39844C3.56594 3.03046 2.89844 3.70546 2.89844 4.53046V15.0305C2.89844 15.8555 3.56594 16.5305 4.39844 16.5305H14.8984C15.7234 16.5305 16.3984 15.8555 16.3984 15.0305V9.78046H14.8984V15.0305ZM11.1484 3.03046V4.53046H13.8409L6.46844 11.903L7.52594 12.9605L14.8984 5.58796V8.28046H16.3984V3.03046H11.1484Z"
          fill="50697D"
        />
      </g>
      <defs>
        <clipPath id="clip0_12943_529">
          <rect width="18" height="18" fill="none" transform="translate(0.648438 0.780457)" />
        </clipPath>
      </defs>
    </svg>
  );
}
