import classNames from "classnames";
import { useIntl } from "react-intl";

import { defaultIconClassNames, IconProps } from "./Icon";

export default function PhoneIcon({ size = 14, className = "", onClick }: IconProps) {
  const intl = useIntl();
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 14 14"
      fill="none"
      className={classNames(defaultIconClassNames, className)}
      onClick={onClick}
      xmlns="http://www.w3.org/2000/svg"
      aria-label={intl.formatMessage({ defaultMessage: "Phone", id: "O95R3Z" })}
    >
      <path
        fill="currentColor"
        d="M1.73314 13.337C8.48862 13.3465 13.616 8.15812 13.6089 1.46124C13.6089 1.02334 13.2541 0.666992 12.817 0.666992H10.7265C10.3345 0.666992 10.0011 0.955234 9.94329 1.34325C9.80543 2.27375 9.53475 3.17965 9.13954 4.03325L9.05798 4.20904C9.00319 4.32734 8.9914 4.46105 9.02465 4.58711C9.0579 4.71317 9.1341 4.82367 9.24011 4.89956C9.88786 5.36201 10.1349 6.29247 9.6305 7.01782C8.9978 7.92848 8.20038 8.72669 7.28972 9.3594C6.56358 9.86303 5.63312 9.61596 5.17146 8.969C5.09565 8.86267 4.98506 8.78619 4.85882 8.75279C4.73258 8.71938 4.59863 8.73116 4.48015 8.78608L4.30515 8.86685C3.45151 9.26279 2.54561 9.53361 1.61515 9.67139C1.22714 9.7292 0.938893 10.0626 0.938893 10.4553V12.5451C0.938893 12.6492 0.959402 12.7522 0.999247 12.8483C1.03909 12.9445 1.09749 13.0318 1.17111 13.1053C1.24472 13.1789 1.33211 13.2372 1.42828 13.2769C1.52445 13.3167 1.6275 13.3371 1.73156 13.337H1.73314Z"
      />
    </svg>
  );
}
