import axios from "axios";

import { process401Response } from "@/utils/trpc";

export const API_URL = process.env.NEXT_PUBLIC_DASHBOARD_API_URL || "/";

const setupAxiosInterceptors = (): void => {
  axios.interceptors.request.use(function (config) {
    config.baseURL = API_URL;
    config.headers.common["X-Requested-With"] = "XMLHttpRequest";
    config.headers.common["Accept"] = "application/json";
    return config;
  });

  axios.interceptors.response.use(undefined, async (error) => {
    const is401Response =
      error.response?.status === 401 || error.response?.data?.message === "401 Unauthorized";

    if (is401Response) {
      // if a users session has expired, trigger the sign out flow
      await process401Response();
    }

    return Promise.reject(error);
  });
};

export default setupAxiosInterceptors;
