import { Tooltip } from "@fonoa/ui-components/tooltip";
import { ThemeColorText } from "@fonoa/ui-components/types";
import { Typography } from "@fonoa/ui-components/typography";
import { FormattedMessage } from "react-intl";

import {
  BusinessStatus,
  ErrorDetails,
  FormatResponse,
  FuzzyMatchingResult,
  IsPresent,
  ValidationStatus,
} from "@/api/lookup/interfaces";

export const StatusColors: Record<string, ThemeColorText> = {
  red: "text-red600",
  green: "text-green700",
  blue: "text-primaryBlue500",
  yellow: "text-yellow800",
  gray: "text-blueGray600",
};

export function parseValidationStatus(status: ValidationStatus) {
  switch (status) {
    case "pending":
      return <FormattedMessage defaultMessage="Pending" id="eKEL/g" />;
    case "running":
      return <FormattedMessage defaultMessage="Running" id="nDyaq/" />;
    case "completed":
      return <FormattedMessage defaultMessage="Completed" id="95stPq" />;
    case "canceled":
      return <FormattedMessage defaultMessage="Canceled" id="PFtMy9" />;
    default:
      return "";
  }
}

interface ValidationFormatColumnProps {
  format: FormatResponse;
}

interface TinValidationColumnProps {
  tin_status?: IsPresent;
  error_details?: ErrorDetails;
  validation_status: ValidationStatus;
  cy: string;
}

export const YesOrNoCell = ({ isPresent, cy }: { isPresent: IsPresent; cy: string }) => {
  switch (isPresent) {
    case "yes":
      return (
        <Typography dataAttributes={{ cy }} color={StatusColors.blue} fontSize="text-sm">
          <FormattedMessage defaultMessage="Yes" id="a5msuh" />
        </Typography>
      );
    case "no":
      return (
        <Typography dataAttributes={{ cy }} color={StatusColors.red} fontSize="text-sm">
          <FormattedMessage defaultMessage="No" id="oUWADl" />
        </Typography>
      );
    case "unknown":
      return (
        <Typography dataAttributes={{ cy }} color={StatusColors.gray} fontSize="text-sm">
          <FormattedMessage defaultMessage="Unknown" id="5jeq8P" />
        </Typography>
      );
    case "not_applicable":
      return (
        <Typography dataAttributes={{ cy }} color={StatusColors.gray} fontSize="text-sm">
          <FormattedMessage defaultMessage="Not applicable" id="61zy45" />
        </Typography>
      );
  }
};

export const TinStatusCellV2 = ({
  tin_status,
  error_details,
  validation_status,
  cy,
}: TinValidationColumnProps) => {
  if (error_details && error_details.code !== "NO_ERROR") {
    return (
      <Tooltip body={error_details.message} position="top" widthClassName="w-128 -top-2">
        <Typography dataAttributes={{ cy }} color={StatusColors.gray} fontSize="text-sm">
          <FormattedMessage defaultMessage="Unknown" id="5jeq8P" />
        </Typography>
      </Tooltip>
    );
  }

  if (validation_status === "pending" || validation_status === "running") {
    return (
      <Typography dataAttributes={{ cy }} color={StatusColors.yellow} fontSize="text-sm">
        <FormattedMessage defaultMessage="Pending" id="eKEL/g" />
      </Typography>
    );
  }

  return <YesOrNoCell isPresent={tin_status || "no"} cy={cy} />;
};

interface TinValidationColumnV1Props {
  tin_status?: IsPresent;
  error_details?: ErrorDetails;
  validation_status: ValidationStatus;
}

export const TinStatusCellV1 = ({
  tin_status,
  error_details,
  validation_status,
}: TinValidationColumnV1Props) => {
  if (error_details && error_details.code !== "NO_ERROR") {
    return (
      <Tooltip body={error_details.message} position="top" widthClassName="w-128 -top-2">
        <Typography
          dataAttributes={{ cy: "tin_exists_online" }}
          color={StatusColors.gray}
          fontSize="text-sm"
        >
          <FormattedMessage defaultMessage="Unknown" id="5jeq8P" />
        </Typography>
      </Tooltip>
    );
  }

  if (validation_status === "pending" || validation_status === "running") {
    return (
      <Typography
        color={StatusColors.yellow}
        dataAttributes={{ cy: "tin_exists_online" }}
        fontSize="text-sm"
      >
        <FormattedMessage defaultMessage="Pending" id="eKEL/g" />
      </Typography>
    );
  }

  if (tin_status === "yes") {
    return (
      <Typography
        color={StatusColors.blue}
        dataAttributes={{ cy: "tin_exists_online" }}
        fontSize="text-sm"
      >
        <FormattedMessage defaultMessage="Valid" id="BN5yXZ" />
      </Typography>
    );
  }

  return (
    <Typography
      color={StatusColors.red}
      dataAttributes={{ cy: "tin_exists_online" }}
      fontSize="text-sm"
    >
      <FormattedMessage defaultMessage="Invalid" id="89T/ze" />
    </Typography>
  );
};

export function getFormatCell({ format }: ValidationFormatColumnProps) {
  switch (format) {
    case "invalid":
      return (
        <Typography
          dataAttributes={{ cy: "format_valid" }}
          color={StatusColors.red}
          fontSize="text-sm"
        >
          <FormattedMessage defaultMessage="Invalid" id="89T/ze" />
        </Typography>
      );
    case "valid":
      return (
        <Typography
          dataAttributes={{ cy: "format_valid" }}
          color={StatusColors.blue}
          fontSize="text-sm"
        >
          <FormattedMessage defaultMessage="Valid" id="BN5yXZ" />
        </Typography>
      );
  }
}

export function getFuzzyMatchingColumn(fuzzyMatchingResult: FuzzyMatchingResult | undefined) {
  return (
    <>
      {fuzzyMatchingResult?.similarity_percentage !== undefined
        ? fuzzyMatchingResult.similarity_percentage
        : "-"}
    </>
  );
}

export const BusinessStatusCell = ({ business_status }: { business_status?: BusinessStatus }) => {
  if (!business_status || business_status === "unknown") {
    return (
      <Typography color={StatusColors.gray}>
        <FormattedMessage defaultMessage="Unknown" id="5jeq8P" />
      </Typography>
    );
  }

  if (business_status === "active") {
    return (
      <Typography color={StatusColors.green}>
        <FormattedMessage defaultMessage="Active" id="3a5wL8" />
      </Typography>
    );
  }

  return (
    <Typography color={StatusColors.red}>
      <FormattedMessage defaultMessage="Inactive" id="6Tps09" />
    </Typography>
  );
};
