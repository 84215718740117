import classNames from "classnames";
import { useIntl } from "react-intl";

import { defaultIconClassNames, IconProps } from "./Icon";

export default function CircleCrossIcon({ size = 20, className = "" }: IconProps) {
  const intl = useIntl();
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 20 20"
      fill="none"
      className={classNames(defaultIconClassNames, className)}
      xmlns="http://www.w3.org/2000/svg"
      aria-label={intl.formatMessage({ defaultMessage: "Circle cross", id: "eK06uK" })}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 14.5C11.5899 14.5 14.5 11.5899 14.5 8C14.5 4.41015 11.5899 1.5 8 1.5C4.41015 1.5 1.5 4.41015 1.5 8C1.5 11.5899 4.41015 14.5 8 14.5ZM8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16ZM8.00006 6.80306L5.66672 4.46973L4.46973 5.66672L6.80306 8.00006L4.46973 10.3334L5.66672 11.5304L8.00006 9.19705L10.3334 11.5304L11.5304 10.3334L9.19705 8.00006L11.5304 5.66672L10.3334 4.46973L8.00006 6.80306Z"
      />
    </svg>
  );
}
