import { shouldPolyfill as shouldPolyfillDisplayNames } from "@formatjs/intl-displaynames/should-polyfill";
import { shouldPolyfill as shouldPolyfillCanonical } from "@formatjs/intl-getcanonicallocales/should-polyfill";
import { shouldPolyfill as shouldPolyfillLocale } from "@formatjs/intl-locale/should-polyfill";

export const polyfillFormatJS = async (locale: string) => {
  if (shouldPolyfillCanonical()) {
    await import("@formatjs/intl-getcanonicallocales/polyfill");
  }

  if (shouldPolyfillLocale()) {
    await import("@formatjs/intl-locale/polyfill");
  }

  const unsupportedLocale = shouldPolyfillDisplayNames(locale);
  // This locale is supported
  if (!unsupportedLocale) {
    return;
  }
  // Load the polyfill 1st BEFORE loading data
  await import("@formatjs/intl-displaynames/polyfill-force");
  await import(`@formatjs/intl-displaynames/locale-data/${unsupportedLocale}`);
};
