import classNames from "classnames";
import { useIntl } from "react-intl";

import { defaultIconClassNames, IconProps } from "./Icon";

export default function ToggleOffIcon({ size = 18, className = "" }: IconProps) {
  const intl = useIntl();
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 18 18"
      fill="none"
      className={classNames(defaultIconClassNames, className)}
      xmlns="http://www.w3.org/2000/svg"
      aria-label={intl.formatMessage({ defaultMessage: "Toggle off", id: "Elf6mh" })}
    >
      <path d="M12.75 4.5H5.25C2.7675 4.5 0.75 6.5175 0.75 9C0.75 11.4825 2.7675 13.5 5.25 13.5H12.75C15.2325 13.5 17.25 11.4825 17.25 9C17.25 6.5175 15.2325 4.5 12.75 4.5ZM12.75 12H5.25C3.5925 12 2.25 10.6575 2.25 9C2.25 7.3425 3.5925 6 5.25 6H12.75C14.4075 6 15.75 7.3425 15.75 9C15.75 10.6575 14.4075 12 12.75 12ZM5.25 6.75C4.005 6.75 3 7.755 3 9C3 10.245 4.005 11.25 5.25 11.25C6.495 11.25 7.5 10.245 7.5 9C7.5 7.755 6.495 6.75 5.25 6.75Z" />
    </svg>
  );
}
