import { useRouter } from "next/router";

import { Product, productFromPath } from "@/lib/product";

export const useProduct: () => Product | undefined = () => {
  const router = useRouter();
  const path = router.pathname;

  return productFromPath(path);
};
