import classNames from "classnames";
import { useIntl } from "react-intl";

import { defaultIconClassNames, IconProps } from "./Icon";

export default function EyeIcon({ size = 16, className = "" }: IconProps) {
  const intl = useIntl();
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 16 16"
      className={classNames(defaultIconClassNames, className)}
      fill="#627C90"
      xmlns="http://www.w3.org/2000/svg"
      aria-label={intl.formatMessage({ defaultMessage: "Eye", id: "rydC2/" })}
    >
      <path
        d="M8 4.27273C9.1701 4.26884 10.3176 4.59523 11.3104 5.21437C12.3033 5.83352 13.1013 6.72028 13.6127 7.77273C12.5627 9.91727 10.4182 11.2727 8 11.2727C5.58182 11.2727 3.43727 9.91727 2.38727 7.77273C2.89867 6.72028 3.69668 5.83352 4.68956 5.21437C5.68244 4.59523 6.8299 4.26884 8 4.27273ZM8 3C4.81818 3 2.10091 4.97909 1 7.77273C2.10091 10.5664 4.81818 12.5455 8 12.5455C11.1818 12.5455 13.8991 10.5664 15 7.77273C13.8991 4.97909 11.1818 3 8 3ZM8 6.18182C8.42194 6.18182 8.82659 6.34943 9.12494 6.64778C9.4233 6.94614 9.59091 7.35079 9.59091 7.77273C9.59091 8.19466 9.4233 8.59932 9.12494 8.89767C8.82659 9.19602 8.42194 9.36364 8 9.36364C7.57806 9.36364 7.17341 9.19602 6.87506 8.89767C6.5767 8.59932 6.40909 8.19466 6.40909 7.77273C6.40909 7.35079 6.5767 6.94614 6.87506 6.64778C7.17341 6.34943 7.57806 6.18182 8 6.18182ZM8 4.90909C6.42182 4.90909 5.13636 6.19455 5.13636 7.77273C5.13636 9.35091 6.42182 10.6364 8 10.6364C9.57818 10.6364 10.8636 9.35091 10.8636 7.77273C10.8636 6.19455 9.57818 4.90909 8 4.90909Z"
        fill="#627C91"
      />
    </svg>
  );
}
