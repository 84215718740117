import classNames from "classnames";
import { useIntl } from "react-intl";

import { defaultIconClassNames, IconProps } from "./Icon";

export default function TriangleWarningFilledIcon({ size = 18, className = "" }: IconProps) {
  const intl = useIntl();
  return (
    <svg
      width={size}
      height={Math.round((15 / 18) * size)}
      viewBox="0 0 18 15"
      fill="none"
      className={classNames(defaultIconClassNames, className)}
      xmlns="http://www.w3.org/2000/svg"
      aria-label={intl.formatMessage({ defaultMessage: "Triangle warning", id: "ljhXf+" })}
    >
      <path d="M0.75 14.25H17.25L9 0L0.75 14.25ZM9.75 12H8.25V10.5H9.75V12ZM9.75 9H8.25V6H9.75V9Z" />
    </svg>
  );
}
