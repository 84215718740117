import classNames from "classnames";
import { useIntl } from "react-intl";

import { defaultIconClassNames } from "./Icon";
import { ProductIconProps, ProductIconVariant } from "./ProductIcon";

export const LIGHT_FIRST_COLOR_MAPS: Record<ProductIconVariant, string> = {
  default: "text-blueGray700",
  active: "text-greenFonoa",
  inactive: "text-blueGray400",
};

export const LIGHT_SECOND_COLOR_MAPS: Record<ProductIconVariant, string> = {
  default: "text-white",
  active: "text-white",
  inactive: "text-blueGray100",
};

export const LIGHT_THIRD_COLOR_MAPS: Record<ProductIconVariant, string> = {
  default: "text-blueGray600",
  active: "text-blueGray800",
  inactive: "text-blueGray100",
};

export const DARK_FIRST_COLOR_MAPS: Record<ProductIconVariant, string> = {
  default: "dark:text-white",
  active: "dark:text-greenFonoa",
  inactive: "dark:text-blueGray400",
};

export const DARK_SECOND_COLOR_MAPS: Record<ProductIconVariant, string> = {
  default: "dark:text-blueGray700",
  active: "dark:text-white",
  inactive: "dark:text-blueGray100",
};

export const DARK_THIRD_COLOR_MAPS: Record<ProductIconVariant, string> = {
  default: "dark:text-blueGray600",
  active: "dark:text-blueGray800",
  inactive: "dark:text-blueGray100",
};

export default function ProductTaxIcon({
  className,
  size = 16,
  variant = "default",
}: ProductIconProps) {
  const intl = useIntl();
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 16 16"
      fill="none"
      className={classNames(defaultIconClassNames, className)}
      xmlns="http://www.w3.org/2000/svg"
      aria-label={intl.formatMessage({ defaultMessage: "Product tax", id: "7GNDJB" })}
    >
      <path
        fill="currentColor"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 0L10.3427 2.34298L13.9916 2.34315L13.9914 5.9913L16 8L13.9914 10.0081L13.9916 13.6569L10.3427 13.6568L8 16L5.65674 13.6568L2.67788 13.6569L2.67754 10.6773L0 8L2.67754 5.32218L2.67788 2.34315L5.65674 2.34298L8 0Z"
        className={classNames(LIGHT_FIRST_COLOR_MAPS[variant], DARK_FIRST_COLOR_MAPS[variant])}
      />
      <path
        fill="currentColor"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.99732 5.19604L5.36816 10.1247L6.45742 11.2139L11.0866 6.2853L9.99732 5.19604Z"
        className={classNames(LIGHT_SECOND_COLOR_MAPS[variant], DARK_SECOND_COLOR_MAPS[variant])}
      />
      <path
        fill="currentColor"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.00956 5.65908C7.00956 6.22053 6.55513 6.67485 5.99379 6.67485C5.4335 6.67485 4.97803 6.22053 4.97803 5.65908C4.97803 5.09762 5.4335 4.64331 5.99379 4.64331C6.55513 4.64331 7.00956 5.09762 7.00956 5.65908Z"
        className={classNames(LIGHT_THIRD_COLOR_MAPS[variant], DARK_THIRD_COLOR_MAPS[variant])}
      />
      <path
        fill="currentColor"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.6227 11.3038C10.0624 11.3038 9.60693 10.8494 9.60693 10.288C9.60693 9.7255 10.0624 9.27222 10.6227 9.27222C11.184 9.27222 11.6385 9.7255 11.6385 10.288C11.6385 10.8494 11.184 11.3038 10.6227 11.3038Z"
        className={classNames(LIGHT_THIRD_COLOR_MAPS[variant], DARK_THIRD_COLOR_MAPS[variant])}
      />
    </svg>
  );
}
