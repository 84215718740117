import { useCallback, useEffect } from "react";

import { KEYS, Keys } from "../utils";

export function useOnKeyPress(
  key: Keys,
  handler: (e: KeyboardEvent) => void,
  overlayOpen: boolean
): void {
  const onKeyPress = useCallback(
    (e: KeyboardEvent) => {
      if (e.key === key && overlayOpen) {
        handler && handler(e);
      }
    },
    [handler, key, overlayOpen]
  );

  useEffect(() => {
    document.body.addEventListener("keydown", onKeyPress);
    return () => document.body.removeEventListener("keydown", onKeyPress);
  }, [onKeyPress]);
}

useOnKeyPress.keys = KEYS;
