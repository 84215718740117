import classNames from "classnames";
import { useIntl } from "react-intl";

import { defaultIconClassNames, IconProps } from "./Icon";

export default function MultipleTaxationIcon({ size = 16, className = "" }: IconProps) {
  const intl = useIntl();
  return (
    <svg
      width={size}
      height={Math.round((22 / 28) * size)}
      viewBox="0 0 28 22"
      fill="none"
      className={classNames(defaultIconClassNames, className)}
      xmlns="http://www.w3.org/2000/svg"
      aria-label={intl.formatMessage({ defaultMessage: "Multiple taxation", id: "IUJ01W" })}
    >
      <rect width="28" height="22" rx="11" fill="#F3F5F7" />
      <path d="M14.5 6H10C9.45 6 9 6.45 9 7V11.5C9 11.775 9.225 12 9.5 12C9.775 12 10 11.775 10 11.5V7H14.5C14.775 7 15 6.775 15 6.5C15 6.225 14.775 6 14.5 6ZM16.5 8H12C11.45 8 11 8.45 11 9V13.5C11 13.775 11.225 14 11.5 14C11.775 14 12 13.775 12 13.5V9H16.5C16.775 9 17 8.775 17 8.5C17 8.225 16.775 8 16.5 8ZM18 10H14C13.45 10 13 10.45 13 11V15C13 15.55 13.45 16 14 16H18C18.55 16 19 15.55 19 15V11C19 10.45 18.55 10 18 10Z" />
    </svg>
  );
}
