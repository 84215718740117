import classNames from "classnames";
import { useIntl } from "react-intl";

import { defaultIconClassNames, IconProps } from "./Icon";

export default function CircleFailedIcon({
  size = 14,
  className = "fill-white",
}: IconProps & { color?: string }) {
  const intl = useIntl();
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 14 14"
      fill="none"
      className={classNames(defaultIconClassNames, className)}
      xmlns="http://www.w3.org/2000/svg"
      aria-label={intl.formatMessage({ defaultMessage: "Failed", id: "vXCeIi" })}
    >
      <path
        d="M7 0C3.129 0 0 3.129 0 7C0 10.871 3.129 14 7 14C10.871 14 14 10.871 14 7C14 3.129 10.871 0 7 0ZM7 12.6C3.913 12.6 1.4 10.087 1.4 7C1.4 3.913 3.913 1.4 7 1.4C10.087 1.4 12.6 3.913 12.6 7C12.6 10.087 10.087 12.6 7 12.6ZM9.513 3.5L7 6.013L4.487 3.5L3.5 4.487L6.013 7L3.5 9.513L4.487 10.5L7 7.987L9.513 10.5L10.5 9.513L7.987 7L10.5 4.487L9.513 3.5Z"
        fill="#D22E11"
      />
    </svg>
  );
}
