import classNames from "classnames";
import { useIntl } from "react-intl";

import { defaultIconClassNames } from "./Icon";
import { ProductIconProps, ProductIconVariant } from "./ProductIcon";

export const LIGHT_COLOR_MAPS: Record<ProductIconVariant, string> = {
  default: "text-blueGray700",
  active: "text-greenFonoa",
  inactive: "text-blueGray400",
};

export const DARK_COLOR_MAPS: Record<ProductIconVariant, string> = {
  default: "dark:text-white",
  active: "dark:text-greenFonoa",
  inactive: "dark:text-blueGray400",
};

export default function ProductDashboardIcon({
  className,
  size = 16,
  variant = "default",
}: ProductIconProps) {
  const intl = useIntl();
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 16 16"
      fill="none"
      className={classNames(defaultIconClassNames, className)}
      xmlns="http://www.w3.org/2000/svg"
      aria-label={intl.formatMessage({ defaultMessage: "Product dashboard", id: "dwzxD7" })}
    >
      <path
        fill="currentColor"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2 1C1.44772 1 1 1.44772 1 2V8C1 8.55228 1.44772 9 2 9H6C6.55228 9 7 8.55228 7 8V2C7 1.44772 6.55228 1 6 1H2ZM10 1C9.44772 1 9 1.44772 9 2V3C9 3.55228 9.44772 4 10 4H14C14.5523 4 15 3.55228 15 3V2C15 1.44772 14.5523 1 14 1H10ZM1 12C1 11.4477 1.44772 11 2 11H6C6.55228 11 7 11.4477 7 12V14C7 14.5523 6.55228 15 6 15H2C1.44772 15 1 14.5523 1 14V12ZM10 6C9.44772 6 9 6.44772 9 7V14C9 14.5523 9.44772 15 10 15H14C14.5523 15 15 14.5523 15 14V7C15 6.44772 14.5523 6 14 6H10Z"
        className={classNames(LIGHT_COLOR_MAPS[variant], DARK_COLOR_MAPS[variant])}
      />
    </svg>
  );
}
