import classNames from "classnames";
import { useIntl } from "react-intl";

import { defaultIconClassNames, IconProps } from "./Icon";

export default function AutoAwesomeMotionIcon({ size = 32, className = "" }: IconProps) {
  const intl = useIntl();
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 17 16"
      className={classNames(defaultIconClassNames, className)}
      xmlns="http://www.w3.org/2000/svg"
      aria-label={intl.formatMessage({ defaultMessage: "Motion", id: "zpupd8" })}
    >
      <path
        d="M9.16732 1.33325H3.16732C2.43398 1.33325 1.83398 1.93325 1.83398 2.66659V8.66659C1.83398 9.03325 2.13398 9.33325 2.50065 9.33325C2.86732 9.33325 3.16732 9.03325 3.16732 8.66659V2.66659H9.16732C9.53398 2.66659 9.83398 2.36659 9.83398 1.99992C9.83398 1.63325 9.53398 1.33325 9.16732 1.33325ZM11.834 3.99992H5.83398C5.10065 3.99992 4.50065 4.59992 4.50065 5.33325V11.3333C4.50065 11.6999 4.80065 11.9999 5.16732 11.9999C5.53398 11.9999 5.83398 11.6999 5.83398 11.3333V5.33325H11.834C12.2007 5.33325 12.5007 5.03325 12.5007 4.66659C12.5007 4.29992 12.2007 3.99992 11.834 3.99992ZM13.834 6.66659H8.50065C7.76732 6.66659 7.16732 7.26659 7.16732 7.99992V13.3333C7.16732 14.0666 7.76732 14.6666 8.50065 14.6666H13.834C14.5673 14.6666 15.1673 14.0666 15.1673 13.3333V7.99992C15.1673 7.26659 14.5673 6.66659 13.834 6.66659Z"
        fill="#627C91"
      />
    </svg>
  );
}
