import classNames from "classnames";
import { useIntl } from "react-intl";

import { defaultIconClassNames, IconProps } from "./Icon";

export default function DocumentValidationIcon({ size = 32, className = "" }: IconProps) {
  const intl = useIntl();
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 18 18"
      fill="none"
      className={classNames(defaultIconClassNames, className)}
      xmlns="http://www.w3.org/2000/svg"
      aria-label={intl.formatMessage({ defaultMessage: "Validate", id: "EXb8Fu" })}
    >
      <g clipPath="url(#clip0_9514_56253)">
        <rect width="3" height="3" transform="matrix(-1 0 0 1 8 10)" fill="currentColor" />
        <rect width="4" height="1" transform="matrix(-1 0 0 1 13 10)" fill="currentColor" />
        <rect width="2" height="1" transform="matrix(-1 0 0 1 11 12)" fill="currentColor" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M16.5436 2.51431L10.3317 9.09164L7.45312 6.04372L8.54364 5.01379L10.3317 6.90704L15.4531 1.48438L16.5436 2.51431Z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M1.25 7.5C1.25 6.5335 2.0335 5.75 3 5.75L6 5.75V7.25L3 7.25C2.86193 7.25 2.75 7.36193 2.75 7.5L2.75 15C2.75 15.1381 2.86193 15.25 3 15.25L14.5 15.25C14.6381 15.25 14.75 15.1381 14.75 15L14.75 7L16.25 7V15C16.25 15.9665 15.4665 16.75 14.5 16.75H3C2.0335 16.75 1.25 15.9665 1.25 15L1.25 7.5Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_9514_56253">
          <rect width="18" height="18" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
