import classNames from "classnames";
import { useIntl } from "react-intl";

import { defaultIconClassNames, IconProps } from "./Icon";

interface Props extends IconProps {
  height?: number;
  width?: number;
}

export default function ImageFileIcon({ className = "", height = 30, width = 24 }: Props) {
  const intl = useIntl();
  return (
    <svg
      width={width}
      height={height}
      className={classNames(className, defaultIconClassNames)}
      viewBox="0 0 24 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      aria-label={intl.formatMessage({ defaultMessage: "Image file", id: "HXy4cK" })}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.4 1.5c-.44 0-.9.39-.9 1v25c0 .61.46 1 .9 1h19.2c.44 0 .9-.39.9-1V8h-5.625A.875.875 0 0 1 16 7.125V1.5H2.4Zm0-1.5C1.075 0 0 1.12 0 2.5v25C0 28.88 1.075 30 2.4 30h19.2c1.325 0 2.4-1.12 2.4-2.5V7.59a1 1 0 0 0-.279-.693L17.395.307A1 1 0 0 0 16.674 0H2.4Zm12.565 19.512c.41.379.981.568 1.712.568.485 0 .91-.088 1.272-.264.362-.176.64-.426.832-.752.197-.33.296-.717.296-1.16v-.84a.195.195 0 0 0-.056-.144.17.17 0 0 0-.136-.056h-1.912a.195.195 0 0 0-.144.056.213.213 0 0 0-.048.144v.424c0 .059.016.107.048.144a.195.195 0 0 0 .144.056h.976v.248c0 .406-.115.712-.344.92-.23.203-.534.304-.912.304-.79 0-1.2-.424-1.232-1.272-.006-.16-.008-.394-.008-.704 0-.314.002-.549.008-.704.016-.421.128-.733.336-.936.213-.202.506-.304.88-.304.33 0 .59.072.776.216.186.139.317.307.392.504a.272.272 0 0 0 .072.104.238.238 0 0 0 .12.024h.824a.18.18 0 0 0 .12-.04.153.153 0 0 0 .048-.112v-.016c-.01-.24-.107-.482-.288-.728-.182-.25-.448-.458-.8-.624-.352-.165-.774-.248-1.264-.248-.72 0-1.288.19-1.704.568-.41.374-.63.891-.656 1.552-.006.155-.008.403-.008.744 0 .342.002.59.008.744.026.678.242 1.206.648 1.584ZM5.338 20a.185.185 0 0 1-.136-.056.185.185 0 0 1-.056-.136v-5.216a.17.17 0 0 1 .056-.136.185.185 0 0 1 .136-.056h.752a.17.17 0 0 1 .136.056.17.17 0 0 1 .056.136v5.216a.185.185 0 0 1-.056.136.17.17 0 0 1-.136.056h-.752Zm2.638 0a.213.213 0 0 1-.144-.048.195.195 0 0 1-.056-.144V14.6c0-.058.019-.106.056-.144a.195.195 0 0 1 .144-.056h.632c.112 0 .192.051.24.152l1.544 2.88 1.56-2.88c.054-.101.134-.152.24-.152h.624c.059 0 .107.019.144.056a.195.195 0 0 1 .056.144v5.208a.195.195 0 0 1-.056.144.214.214 0 0 1-.144.048h-.672a.185.185 0 0 1-.136-.056.185.185 0 0 1-.056-.136v-3.464l-1.12 2.136c-.069.118-.16.176-.272.176h-.328c-.112 0-.202-.058-.272-.176l-1.12-2.136v3.464a.185.185 0 0 1-.056.136.17.17 0 0 1-.136.056h-.672Z"
      />
    </svg>
  );
}
