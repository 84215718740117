import classNames from "classnames";
import { LinkProps } from "next/dist/client/link";
import Link from "next/link";
import {
  ComponentType,
  FC,
  HTMLAttributes,
  MouseEventHandler,
  PropsWithChildren,
  ReactNode,
} from "react";

import { IconProps, OutsideIcon } from "../icons";
import { DataAttributes, SpacingProps, TextBase, ThemeWeight } from "../types";
import { convertDataAttributes, getSpacingClass } from "../utils";

export type ExternalProps =
  | {
      external: true;
      externalIconProps?: IconProps;
    }
  | {
      external?: false;
      externalIconProps?: never;
    };

interface CommonAProps extends SpacingProps, Partial<Pick<TextBase, "fontSize" | "lineHeight">> {
  children: ReactNode;
  href: LinkProps["href"];
  // For Analytics handlers ONLY please
  onClick?: MouseEventHandler<HTMLAnchorElement> | undefined;
  className?: string;
  basic?: boolean;
  leftIcon?: ComponentType<IconProps>;
  iconSize?: number;
  leftIconProps?: Omit<IconProps, "size">;
  containerProps?: HTMLAttributes<HTMLDivElement>;
  fontWeight?: ThemeWeight;
  dataAttributes?: DataAttributes;
  underline?: boolean;
  ariaHidden?: boolean;
}

export type AProps = CommonAProps & ExternalProps;

const LinkOrA: FC<
  PropsWithChildren<
    Pick<AProps, "external" | "href" | "onClick" | "dataAttributes" | "ariaHidden"> & {
      linkClass: string;
    }
  >
> = ({ href, onClick, external, linkClass, children, dataAttributes, ariaHidden }) => {
  if (external) {
    return (
      <a
        href={href as string}
        {...convertDataAttributes(dataAttributes)}
        className={linkClass}
        onClick={onClick}
        target="_blank"
        rel="noreferrer noopener"
        tabIndex={ariaHidden ? -1 : 0}
      >
        {children}
      </a>
    );
  }

  return (
    <Link
      href={href}
      {...convertDataAttributes(dataAttributes)}
      className={linkClass}
      onClick={onClick}
      tabIndex={ariaHidden ? -1 : 0}
    >
      {children}
    </Link>
  );
};

export function A({
  children,
  className,
  href,
  onClick,
  external,
  externalIconProps = {},
  basic,
  spacing,
  leftIcon: LeftIconComponent,
  iconSize,
  fontWeight = "light",
  leftIconProps = {},
  containerProps = {},
  dataAttributes = {},
  fontSize,
  lineHeight,
  underline,
  ariaHidden,
}: AProps) {
  const { className: containerClassName, ...otherContainerProps } = containerProps;
  const { className: externalIconClassName, ...otherExternalIconProps } = externalIconProps;

  const linkClass = classNames(
    {
      [`font-${fontWeight}`]: !basic,
      "text-primaryBlue600 group-hover:text-primaryBlue700 active:text-primaryBlue800": !basic,
      "underline hover:no-underline": underline,
    },
    fontSize,
    lineHeight,
    className,
    getSpacingClass(spacing)
  );

  if (LeftIconComponent) {
    return (
      <div
        className={classNames("group flex items-center cursor-pointer", containerClassName)}
        {...otherContainerProps}
      >
        <LinkOrA
          href={href}
          external={external}
          linkClass={linkClass}
          onClick={onClick}
          dataAttributes={dataAttributes}
        >
          <>
            {LeftIconComponent && (
              <LeftIconComponent
                {...leftIconProps}
                className={classNames("mr-2 align-middle", leftIconProps.className)}
                size={iconSize}
              />
            )}
            {children}
            {external && (
              <OutsideIcon
                className={classNames("ml-1", externalIconClassName)}
                size={8}
                {...otherExternalIconProps}
              />
            )}
          </>
        </LinkOrA>
      </div>
    );
  }

  return (
    <LinkOrA
      href={href}
      external={external}
      linkClass={linkClass}
      onClick={onClick}
      dataAttributes={dataAttributes}
      ariaHidden={ariaHidden}
    >
      <>
        {children}
        {external && (
          <OutsideIcon
            className={classNames("ml-1", externalIconClassName)}
            size={8}
            {...otherExternalIconProps}
          />
        )}
      </>
    </LinkOrA>
  );
}
