import classNames from "classnames";
import { useIntl } from "react-intl";

import { defaultIconClassNames, IconProps } from "./Icon";

export default function InvoiceErrorIcon({ size = 94, className }: IconProps) {
  const intl = useIntl();
  return (
    <svg
      width={size / 1.12}
      height={size}
      className={classNames(defaultIconClassNames, className)}
      viewBox="0 0 84 94"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      aria-label={intl.formatMessage({ defaultMessage: "Invoice error", id: "JtaUvW" })}
    >
      <mask id="mask0_6488:21064" maskUnits="userSpaceOnUse" x="0" y="49" width="70" height="44">
        <path
          d="M7.16799 49.3721L0.104492 53.9426V92.9995H69.4929V58.0976L55.366 53.9426L45.394 58.0976L27.943 53.9426L20.464 58.0976L7.16799 49.3721Z"
          fill="#C4C4C4"
        />
      </mask>
      <g mask="url(#mask0_6488:21064)">
        <path
          d="M67.1322 86.0643C67.1322 88.0572 65.5283 89.6704 63.547 89.6704H6.18291C4.20159 89.6704 2.59766 88.0572 2.59766 86.0643V13.0905C2.59766 11.0976 4.20159 9.48438 6.18291 9.48438H47.7907L66.4718 30.0772L66.8492 64.62L67.1322 86.0643Z"
          fill="url(#paint0_linear_6488:21064)"
        />
        <path
          d="M56.5653 66.1815H12.156C11.5899 66.1815 11.1182 65.707 11.1182 65.1376C11.1182 64.5682 11.5899 64.0938 12.156 64.0938H56.5653C57.1314 64.0938 57.6031 64.5682 57.6031 65.1376C57.5088 65.707 57.1314 66.1815 56.5653 66.1815Z"
          fill="#C4D2E1"
        />
        <path
          d="M56.0463 52.4735H12.4099C11.8438 52.4735 11.3721 51.999 11.3721 51.4296C11.3721 50.8602 11.8438 50.3857 12.4099 50.3857H56.0463C56.6124 50.3857 57.0841 50.8602 57.0841 51.4296C57.0841 51.999 56.6124 52.4735 56.0463 52.4735Z"
          fill="url(#paint1_linear_6488:21064)"
        />
        <path
          d="M57.0842 76.4305H12.4099C11.8438 76.4305 11.3721 75.956 11.3721 75.3866C11.3721 74.8173 11.8438 74.3428 12.4099 74.3428H56.9899C57.556 74.3428 58.0277 74.8173 58.0277 75.3866C58.1221 75.956 57.6503 76.4305 57.0842 76.4305Z"
          fill="#C4D2E1"
        />
      </g>
      <mask id="mask1_6488:21064" maskUnits="userSpaceOnUse" x="0" y="0" width="70" height="54">
        <path
          d="M7.16799 44.4585L0.104492 49.029V0H69.4929V53.184L55.366 49.029L45.394 53.184L27.943 49.029L20.464 53.184L7.16799 44.4585Z"
          fill="#C4C4C4"
        />
      </mask>
      <g mask="url(#mask1_6488:21064)">
        <path
          d="M67.1322 82.8121C67.1322 84.805 65.5283 86.4182 63.547 86.4182H6.18291C4.20159 86.4182 2.59766 84.805 2.59766 82.8121V6.51432C2.59766 4.52147 4.20159 2.9082 6.18291 2.9082H47.7907L66.4718 23.501L66.8492 58.0438L67.1322 82.8121Z"
          fill="url(#paint2_linear_6488:21064)"
        />
        <path
          d="M56.1877 55.1971H12.1267C11.5606 55.1971 11.0889 54.7226 11.0889 54.1532C11.0889 53.5839 11.5606 53.1094 12.1267 53.1094H56.1877C56.7538 53.1094 57.2255 53.5839 57.2255 54.1532C57.2255 54.8175 56.7538 55.1971 56.1877 55.1971Z"
          fill="#E3EAF2"
        />
        <path
          d="M56.0463 45.8973H12.4099C11.8438 45.8973 11.3721 45.4228 11.3721 44.8534C11.3721 44.2841 11.8438 43.8096 12.4099 43.8096H56.0463C56.6124 43.8096 57.0841 44.2841 57.0841 44.8534C57.0841 45.4228 56.6124 45.8973 56.0463 45.8973Z"
          fill="#C4D2E1"
        />
        <path
          d="M29.1098 36.5965H12.6931C12.127 36.5965 11.6553 36.1221 11.6553 35.5527C11.6553 34.9833 12.127 34.5088 12.6931 34.5088H29.0155C29.5815 34.5088 30.0533 34.9833 30.0533 35.5527C30.0533 36.1221 29.6759 36.5965 29.1098 36.5965Z"
          fill="#C4D2E1"
        />
        <path
          d="M40.6198 15.5292H13.0699C12.3151 15.5292 11.749 14.9598 11.749 14.2006C11.749 13.4415 12.3151 12.8721 13.0699 12.8721H40.6198C41.3745 12.8721 41.9406 13.4415 41.9406 14.2006C41.9406 14.8649 41.2802 15.5292 40.6198 15.5292Z"
          fill="#DAE2EB"
        />
        <path
          d="M23.5426 22.1728H13.0699C12.3151 22.1728 11.749 21.6034 11.749 20.8442C11.749 20.085 12.3151 19.5156 13.0699 19.5156H23.637C24.3918 19.5156 24.9579 20.085 24.9579 20.8442C24.8635 21.5085 24.2974 22.1728 23.5426 22.1728Z"
          fill="#DAE2EB"
        />
        <path
          d="M47.79 2.9082V19.2306C47.79 21.5082 49.7714 23.4061 52.0357 23.4061H66.4711"
          fill="#DAE2EB"
        />
      </g>
      <path
        d="M83.8962 76C83.8962 78.3226 83.5114 80.4516 82.6454 82.4839C80.7212 87.5161 76.5839 91.3871 71.4846 93.129C69.7527 93.7097 67.9246 94 66.0004 94C56.0903 94 48.1045 85.9677 48.1045 76C48.1045 66.0323 56.0903 58 66.0004 58C75.9104 58 83.8962 66.129 83.8962 76Z"
        fill="#96AAB9"
      />
      <path
        d="M64.9136 79.4462C64.754 79.4462 64.6183 79.3903 64.5066 79.2786C64.3948 79.1668 64.339 79.0312 64.339 78.8715V67.9057C64.339 67.7461 64.3948 67.6104 64.5066 67.4987C64.6183 67.3869 64.754 67.3311 64.9136 67.3311H66.853C67.0286 67.3311 67.1642 67.3869 67.26 67.4987C67.3717 67.5944 67.4276 67.7301 67.4276 67.9057V78.8715C67.4276 79.0312 67.3717 79.1668 67.26 79.2786C67.1642 79.3903 67.0286 79.4462 66.853 79.4462H64.9136ZM64.7699 84.9385C64.6103 84.9385 64.4746 84.8826 64.3629 84.7709C64.2512 84.6592 64.1953 84.5235 64.1953 84.3639V82.1372C64.1953 81.9616 64.2512 81.818 64.3629 81.7062C64.4746 81.5945 64.6103 81.5386 64.7699 81.5386H66.9966C67.1722 81.5386 67.3159 81.5945 67.4276 81.7062C67.5393 81.818 67.5952 81.9616 67.5952 82.1372V84.3639C67.5952 84.5235 67.5393 84.6592 67.4276 84.7709C67.3159 84.8826 67.1722 84.9385 66.9966 84.9385H64.7699Z"
        fill="white"
      />
      <defs>
        <linearGradient
          id="paint0_linear_6488:21064"
          x1="34.865"
          y1="9.48437"
          x2="34.865"
          y2="89.6704"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.0625" stopColor="#F6F7FA" />
          <stop offset="1" stopColor="#E3E9F1" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_6488:21064"
          x1="34.2281"
          y1="50.3857"
          x2="34.2281"
          y2="52.4735"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.104167" stopColor="white" />
          <stop offset="1" stopColor="#EEF2F6" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_6488:21064"
          x1="34.865"
          y1="2.9082"
          x2="34.865"
          y2="86.4182"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.0625" stopColor="#F6F7FA" />
          <stop offset="0.401042" stopColor="#F1F5F9" />
          <stop offset="0.494792" stopColor="#EDF2F8" />
        </linearGradient>
      </defs>
    </svg>
  );
}
