import classNames from "classnames";
import { useIntl } from "react-intl";

import { defaultIconClassNames, IconProps } from "./Icon";

export default function AssignmentIcon({ size = 16, className = "", onClick }: IconProps) {
  const intl = useIntl();
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 18 18"
      fill="none"
      className={classNames(defaultIconClassNames, className)}
      onClick={onClick}
      xmlns="http://www.w3.org/2000/svg"
      aria-label={intl.formatMessage({ defaultMessage: "Assignment", id: "XPX/Qj" })}
    >
      <path d="M5.25 11.25L10.5 11.25V12.75L5.25 12.75V11.25ZM5.25 8.25L12.75 8.25V9.75L5.25 9.75V8.25ZM5.25 5.25L12.75 5.25L12.75 6.75L5.25 6.75V5.25ZM14.25 2.25L11.115 2.25C10.8 1.38 9.975 0.75 9 0.75C8.025 0.75 7.2 1.38 6.885 2.25L3.75 2.25C3.645 2.25 3.5475 2.2575 3.45 2.28C3.1575 2.34 2.895 2.49 2.6925 2.6925C2.5575 2.8275 2.445 2.9925 2.37 3.1725C2.295 3.345 2.25 3.54 2.25 3.75L2.25 14.25C2.25 14.4525 2.295 14.655 2.37 14.835C2.445 15.015 2.5575 15.1725 2.6925 15.315C2.895 15.5175 3.1575 15.6675 3.45 15.7275C3.5475 15.7425 3.645 15.75 3.75 15.75L14.25 15.75C15.075 15.75 15.75 15.075 15.75 14.25L15.75 3.75C15.75 2.925 15.075 2.25 14.25 2.25ZM9 2.0625C9.3075 2.0625 9.5625 2.3175 9.5625 2.625C9.5625 2.9325 9.3075 3.1875 9 3.1875C8.6925 3.1875 8.4375 2.9325 8.4375 2.625C8.4375 2.3175 8.6925 2.0625 9 2.0625ZM14.25 14.25L3.75 14.25L3.75 3.75L14.25 3.75L14.25 14.25Z" />
    </svg>
  );
}
