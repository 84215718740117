import classNames from "classnames";
import { useIntl } from "react-intl";

import { defaultIconClassNames, IconProps } from "./Icon";

export default function DoneAllIcon({ size = 12, className = "" }: IconProps) {
  const intl = useIntl();
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 12 13"
      fill="none"
      className={classNames(defaultIconClassNames, className)}
      xmlns="http://www.w3.org/2000/svg"
      aria-label={intl.formatMessage({ defaultMessage: "Done all", id: "b195JU" })}
    >
      <path d="M9.00008 4.47355L8.29508 3.76855L5.12508 6.93856L5.83008 7.64356L9.00008 4.47355ZM11.1201 3.76855L5.83008 9.05856L3.74008 6.97356L3.03508 7.67856L5.83008 10.4736L11.8301 4.47355L11.1201 3.76855ZM0.205078 7.67856L3.00008 10.4736L3.70508 9.76856L0.915078 6.97356L0.205078 7.67856Z" />
    </svg>
  );
}
