import classNames from "classnames";
import type { PolymorphicPropsWithoutRef } from "react-polymorphic-types";

import { DataAttributes, FontSize, LineHeight, SpacingProps, TextBase } from "../types";
import { convertDataAttributes } from "../utils/convertDataAttributes";
import { getSpacingClass } from "../utils/getSpacingClass";

export type TypographyComponent = "h1" | "h2" | "h3" | "h4" | "h5" | "h6" | "p" | "span";
export type TypographyVariant = TypographyComponent | "mini" | "micro" | "small";

type VariantValueType = `${FontSize} ${LineHeight}${string}`;

const VARIANT_MAPS: Record<TypographyVariant, VariantValueType> = {
  h1: "text-4xl leading-10",
  h2: "text-2xl leading-8",
  h3: "text-xl leading-8",
  h4: "text-lg leading-6",
  h5: "text-base leading-6",
  h6: "text-xs leading-4",
  p: "text-sm leading-5",
  span: "text-sm leading-5",
  small: "text-xs leading-4",
  mini: "text-2xs leading-4",
  micro: "text-3xs leading-4",
};

function getVariantStyle(variant: VariantValueType, fontSize?: FontSize, lineHeight?: LineHeight) {
  const [defaultFontSize, defaultLineHeight] = variant.split(" ");
  return [fontSize || defaultFontSize, lineHeight || defaultLineHeight].join(" ");
}

export const TypographyDefaultElement = "p";

type TypographyOwnProps = {
  variant?: TypographyVariant;
  dataAttributes?: DataAttributes;
  UNSAFE_className?: string;
} & Partial<TextBase> &
  SpacingProps;

type PolymorphicProps<T extends TypographyComponent = typeof TypographyDefaultElement> =
  PolymorphicPropsWithoutRef<TypographyOwnProps, T>;

// Rename `as` prop that is coming from `PolymorphicPropsWithoutRef` helper to `component` prop
export type Props<T extends TypographyComponent = typeof TypographyDefaultElement> = Omit<
  PolymorphicProps<T>,
  "style" | "as"
> & { component?: PolymorphicProps<T>["as"] };

const defaultFontWeight = "font-normal" as const;

export function Typography<
  TypographyElement extends TypographyComponent = typeof TypographyDefaultElement
>({
  component,
  variant: propVariant,
  fontWeight,
  fontSize,
  color = "text-primaryBlue900",
  darkColor,
  bgColor,
  transform,
  spacing,
  children,
  dataAttributes,
  lineHeight,
  wordBreak,
  overflow,
  align,
  whitespace,
  truncate,
  UNSAFE_className,
  ...props
}: Props<TypographyElement>) {
  const Component: TypographyComponent = component ?? TypographyDefaultElement;
  const variant = propVariant ?? Component;

  return (
    <Component
      {...convertDataAttributes(dataAttributes)}
      className={classNames(
        fontWeight ?? defaultFontWeight,
        color,
        darkColor,
        bgColor,
        transform,
        wordBreak,
        overflow,
        align,
        whitespace,
        truncate,
        getVariantStyle(VARIANT_MAPS[variant], fontSize, lineHeight),
        getSpacingClass(spacing),
        UNSAFE_className
      )}
      {...props}
    >
      {children}
    </Component>
  );
}
