import classNames from "classnames";
import { useIntl } from "react-intl";

import { defaultIconClassNames, IconProps } from "./Icon";

export default function MinusIcon({ size = 20, className = "" }: IconProps) {
  const intl = useIntl();
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 16 16"
      fill="none"
      className={classNames(defaultIconClassNames, className)}
      xmlns="http://www.w3.org/2000/svg"
      aria-label={intl.formatMessage({ defaultMessage: "Minus", id: "3fYtUf" })}
    >
      <path d="M12.6667 8.66666H3.33337V7.33333H12.6667V8.66666Z" />
    </svg>
  );
}
