import classNames from "classnames";
import { useIntl } from "react-intl";

import { defaultIconClassNames, IconProps } from "./Icon";

export default function CollapseIcon({ size = 32, className = "", onClick }: IconProps) {
  const intl = useIntl();
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 12 12"
      fill="none"
      className={classNames(defaultIconClassNames, className)}
      onClick={onClick}
      xmlns="http://www.w3.org/2000/svg"
      aria-label={intl.formatMessage({ defaultMessage: "Collapse", id: "W/V6+Y" })}
    >
      <path d="M0.166992 9.33334H2.66699V11.8333H4.33366V7.66667H0.166992V9.33334ZM2.66699 2.66667H0.166992V4.33334H4.33366V0.166668H2.66699V2.66667ZM7.66699 11.8333H9.33366V9.33334H11.8337V7.66667H7.66699V11.8333ZM9.33366 2.66667V0.166668H7.66699V4.33334H11.8337V2.66667H9.33366Z" />
    </svg>
  );
}
