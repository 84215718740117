import { useRouter } from "next/router";

export const useReturnsAuditTrailDialog = () => {
  const router = useRouter();

  return {
    shouldShowAuditDialog: router.query?.audit === "true",
    setShouldShowAuditDialog: (newValue: boolean) => {
      if (newValue) {
        router.push({
          pathname: router.pathname,
          query: { ...router.query, audit: "true" },
        });
      } else {
        const newQueryParams = { ...router.query };
        delete newQueryParams?.audit;

        router.push({
          pathname: router.pathname,
          query: newQueryParams,
        });
      }
    },
    auditTrailSidebarUrl: `${router.pathname}?${new URLSearchParams({
      ...(router.query as Record<string, string>),
      audit: "true",
    })}`,
  };
};
