import classNames from "classnames";
import { useIntl } from "react-intl";

import { defaultIconClassNames } from "./Icon";
import {
  DARK_FIRST_COLOR_MAPS,
  DARK_SECOND_COLOR_MAPS,
  DARK_THIRD_COLOR_MAPS,
  LIGHT_FIRST_COLOR_MAPS,
  LIGHT_SECOND_COLOR_MAPS,
  LIGHT_THIRD_COLOR_MAPS,
  ProductIconProps,
} from "./ProductIcon";

export default function ProductReportingIcon({
  className = "",
  size = 16,
  variant = "default",
  overrideTextColor,
}: ProductIconProps) {
  const intl = useIntl();
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 16 16"
      fill="none"
      className={classNames(defaultIconClassNames, className)}
      xmlns="http://www.w3.org/2000/svg"
      aria-label={intl.formatMessage({ defaultMessage: "Product reporting", id: "n3j3HF" })}
    >
      <path
        fill="currentColor"
        d="M7.41083 0C7.63193 0 7.84588 0.0783488 8.01469 0.221137L11.5779 3.23501C11.7879 3.41268 11.9091 3.67383 11.9091 3.94894L11.909 13.2913C11.9062 14.4379 11.8471 14.5418 10.6313 14.5454L2.35065 14.5455C1.03961 14.5455 1.001 14.4571 1.00002 13.2226L1 1.39394C1 0.115842 1.01433 0.00381246 2.27779 0.000101511L7.41083 0ZM6.92208 10.9091H4.53247C4.18819 10.9091 3.90909 11.1882 3.90909 11.5325V11.7403C3.90909 12.0846 4.18819 12.3636 4.53247 12.3636H6.92208C7.26636 12.3636 7.54546 12.0846 7.54546 11.7403V11.5325C7.54546 11.1882 7.26636 10.9091 6.92208 10.9091ZM9.83117 8.00001H4.53247C4.18819 8.00001 3.90909 8.2791 3.90909 8.62338V8.83117C3.90909 9.17546 4.18819 9.45455 4.53247 9.45455H9.83117C10.1755 9.45455 10.4545 9.17546 10.4545 8.83117V8.62338C10.4545 8.2791 10.1755 8.00001 9.83117 8.00001Z"
        className={classNames(LIGHT_SECOND_COLOR_MAPS[variant], DARK_SECOND_COLOR_MAPS[variant])}
      />
      <rect
        fill="currentColor"
        x="3"
        y="7"
        width="9"
        height="7"
        className={classNames(
          overrideTextColor ? "text-white" : LIGHT_THIRD_COLOR_MAPS[variant],
          DARK_THIRD_COLOR_MAPS[variant]
        )}
      />
      <path
        fill="currentColor"
        d="M9.59305 1.45459C9.81415 1.45459 10.0281 1.53294 10.1969 1.67573L13.7601 4.6896C13.9702 4.86727 14.0913 5.12842 14.0913 5.40353V14.6061C14.0913 15.8842 14.077 15.9962 12.8135 16L3.8056 16.0001C2.51929 16.0001 2.45786 15.915 2.45508 14.7459V2.70876C2.45781 1.56213 2.51695 1.45826 3.73274 1.45469L9.59305 1.45459ZM6.92249 10.9091H4.53287C4.18859 10.9091 3.9095 11.1882 3.9095 11.5325V11.7403C3.9095 12.0846 4.18859 12.3637 4.53287 12.3637H6.92249C7.26677 12.3637 7.54586 12.0846 7.54586 11.7403V11.5325C7.54586 11.1882 7.26677 10.9091 6.92249 10.9091ZM9.83158 8.00005H4.53287C4.18859 8.00005 3.9095 8.27914 3.9095 8.62343V8.83122C3.9095 9.1755 4.18859 9.4546 4.53287 9.4546H9.83158C10.1759 9.4546 10.455 9.1755 10.455 8.83122V8.62343C10.455 8.27914 10.1759 8.00005 9.83158 8.00005Z"
        className={classNames(LIGHT_FIRST_COLOR_MAPS[variant], DARK_FIRST_COLOR_MAPS[variant])}
      />
    </svg>
  );
}
