import { BellIcon } from "@fonoa/ui-components/icons";

import { useHasUnreadNotifications, useNotificationsDispatcher } from "./NotificationsManager";

type NotificationsBellProps = {
  hasUnreadNotifications: boolean;
  onClick: () => void;
};

export const NotificationsBell = ({ onClick, hasUnreadNotifications }: NotificationsBellProps) => (
  <div className="relative text-right">
    <BellIcon
      size={18}
      onClick={onClick}
      className="h-10 w-10 cursor-pointer rounded-md bg-blueGray200 p-3 text-blueGray700 hover:bg-blueGray400"
    />
    {hasUnreadNotifications && (
      <div
        className="absolute box-border bg-red500"
        data-testid="notifications-bell-unread-marker"
        style={{
          width: "8px",
          height: "8px",
          border: "1px solid rgb(215 222 227)",
          borderRadius: "4px",
          top: "11px",
          right: "11px",
        }}
      />
    )}
  </div>
);

export const NotificationsBellContainer = () => {
  const hasUnreadNotifications = useHasUnreadNotifications();
  const notificationsDispatcher = useNotificationsDispatcher();

  return (
    <NotificationsBell
      hasUnreadNotifications={hasUnreadNotifications}
      onClick={() => notificationsDispatcher({ type: "OPEN_DRAWER" })}
    />
  );
};
