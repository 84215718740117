import { ReactNode } from "react";

type HeaderBarProps = {
  children: ReactNode;
  rightCorner?: ReactNode;
  banner?: ReactNode;
};

export const HeaderBar = ({ children, rightCorner, banner }: HeaderBarProps) => (
  <>
    {banner && (
      <div className="flex h-12 w-full items-center justify-center bg-primaryBlue50 py-2 text-xs font-medium">
        {banner}
      </div>
    )}
    <div className="flex items-center border-b border-blueGray200/50 py-5 pl-6 pr-10">
      <div className="grow">{children}</div>
      {rightCorner && <div>{rightCorner}</div>}
    </div>
  </>
);
