import { DataAttributes } from "../types";

export const convertDataAttributes = (attributes?: DataAttributes) => {
  if (attributes) {
    return Object.keys(attributes).reduce(
      (acc, key) => ({ ...acc, [`data-${key}`]: attributes[key] }),
      {}
    );
  }

  return {};
};
