import { Button } from "@fonoa/ui-components/button";
import {
  StatusErrorIcon,
  StatusInfoIcon,
  StatusSuccessIcon,
  StatusWarningIcon,
} from "@fonoa/ui-components/icons";
import { Typography } from "@fonoa/ui-components/typography";
import classNames from "classnames";
import { ReactNode } from "react";

import { NotificationConfig } from "./NotificationsManager";

type NotificationProps = {
  notification: NotificationConfig;
  isUnread?: boolean;
  borderClassName?: string;
  onActionClick?: () => void;
};

const iconFromType: Record<NonNullable<NotificationConfig["type"]>, ReactNode> = {
  success: <StatusSuccessIcon size={24} />,
  info: <StatusInfoIcon size={24} />,
  error: <StatusErrorIcon size={24} />,
  warning: <StatusWarningIcon size={24} className="text-tangerine700" />,
};

export const Notification = ({
  notification,
  borderClassName,
  onActionClick,
  isUnread = false,
}: NotificationProps) => (
  <div
    className={classNames(
      "flex items-center p-6",
      {
        "bg-primaryBlue10": isUnread,
      },
      borderClassName
    )}
  >
    {iconFromType[notification.type || "info"]}
    <div className="mx-4 h-min grow">
      <Typography
        dataAttributes={{ testid: "notification-title" }}
        component="span"
        fontWeight="font-bold"
        color="text-blueGray700"
      >
        {notification.title}
      </Typography>
      <Typography
        component="span"
        spacing={{ ml: 1 }}
        fontWeight="font-normal"
        color="text-blueGray700"
      >
        {notification.description}
      </Typography>
    </div>
    {notification.action ? (
      <Button
        variant="LINK"
        onClick={() => {
          notification.action?.onClick();
          onActionClick?.();
        }}
        spacing={{ ml: 2, mr: 8 }}
        bold
      >
        {notification.action?.title}
      </Button>
    ) : null}
  </div>
);
