import classNames from "classnames";
import { useIntl } from "react-intl";

import { defaultIconClassNames, IconProps } from "./Icon";

export default function FilterIcon({ size = 32, className = "" }: IconProps) {
  const intl = useIntl();
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 16 16"
      className={classNames(defaultIconClassNames, className)}
      xmlns="http://www.w3.org/2000/svg"
      aria-label={intl.formatMessage({ defaultMessage: "Filter", id: "9Obw6C" })}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 1C0 0.447715 0.447715 0 1 0H13C13.5523 0 14 0.447715 14 1C14 1.55228 13.5523 2 13 2H1C0.447715 2 0 1.55228 0 1ZM2 6C2 5.44772 2.44772 5 3 5H11C11.5523 5 12 5.44772 12 6C12 6.55228 11.5523 7 11 7H3C2.44772 7 2 6.55228 2 6ZM5 10C4.44772 10 4 10.4477 4 11C4 11.5523 4.44772 12 5 12H9C9.55229 12 10 11.5523 10 11C10 10.4477 9.55229 10 9 10H5Z"
      />
    </svg>
  );
}
