import classNames from "classnames";
import { MouseEvent } from "react";
import { FormattedMessage } from "react-intl";

import { CircleCloseIcon, CloseIcon } from "../icons";
import { DataAttributes, SpacingProps } from "../types";
import { convertDataAttributes, getSpacingClass } from "../utils";

export interface CloseButtonProps extends SpacingProps {
  size?: number;
  iconClassName?: string;
  onClose?: (event: MouseEvent<HTMLDivElement>) => void;
  dataAttributes?: DataAttributes;
  variant?: "filled" | "ghost";
}

export function CloseButton({
  size = 16,
  iconClassName = "text-blueGray500 hover:text-blueGray600",
  onClose,
  dataAttributes = {},
  variant = "filled",
  spacing,
}: CloseButtonProps) {
  const dataAttr = convertDataAttributes(dataAttributes);
  const iconProps = {
    size,
    className: classNames("fill-current", iconClassName),
  };

  return (
    <div
      onClick={onClose}
      className={classNames("flex cursor-pointer", getSpacingClass(spacing))}
      {...dataAttr}
    >
      <span className="sr-only">
        <FormattedMessage defaultMessage="Close" id="rbrahO" />
      </span>
      {variant === "filled" ? <CircleCloseIcon {...iconProps} /> : <CloseIcon {...iconProps} />}
    </div>
  );
}
