import { PropsWithChildren } from "react";

import { useIsNotBrowserSupported } from "@/components/SuggestBrowser/hooks";
import { SuggestBrowser } from "@/components/SuggestBrowser/SuggestBrowser";

export const SupportedBrowsers = ({ children }: PropsWithChildren) => {
  const isNotBrowserSupported = useIsNotBrowserSupported();

  if (isNotBrowserSupported) {
    return <SuggestBrowser />;
  }

  return <>{children}</>;
};
