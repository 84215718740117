import { GenericLayout } from "@fonoa/ui-components/GenericLayout";
import Head from "next/head";
import { ReactNode } from "react";

import { HeaderBar } from "@/components/HeaderBar/HeaderBar";
import { DemoDataLabelConnected } from "@/components/Layouts/DemoDataLabel";
import { NotificationsBellContainer } from "@/components/Notifications/NotificationsBell";
import { NotificationsCenterConnected } from "@/components/Notifications/NotificationsCenter";
import Sidebar from "@/components/Sidebar/Sidebar";
import UserGreeting from "@/components/UserGreeting";
import { useAuth, useTenant } from "@/hooks/auth";
import { useDemoDataMode } from "@/hooks/useDemoDataMode";
import { useProduct } from "@/hooks/useProduct";

interface SidebarLayoutProps {
  windowTitle?: string;
  children: ReactNode;
  withPadding?: boolean;
  withHeaderBar?: boolean;
  headerBanner?: ReactNode;
}

export default function SidebarLayout({
  windowTitle = "Fonoa Dashboard",
  children,
  withPadding = true,
  withHeaderBar = true,
  headerBanner,
}: SidebarLayoutProps) {
  const auth = useAuth();
  const { data: tenant } = useTenant();
  const product = useProduct();
  const { initialised } = useDemoDataMode();

  return (
    <>
      <Head>
        <title>{windowTitle}</title>
      </Head>
      <GenericLayout
        windowTitle={windowTitle}
        Sidebar={<Sidebar user={auth?.user || undefined} tenant={tenant} />}
        DemoDataLabel={!headerBanner ? <DemoDataLabelConnected /> : undefined}
        HeaderBar={
          withHeaderBar ? (
            <HeaderBar rightCorner={<NotificationsBellContainer />} banner={headerBanner}>
              <UserGreeting username={auth.user?.firstname} product={product} />
            </HeaderBar>
          ) : undefined
        }
        initialised={initialised}
        NotificationsCenter={<NotificationsCenterConnected />}
        withPadding={withPadding}
      >
        {children}
      </GenericLayout>
    </>
  );
}
