import { Typography } from "@fonoa/ui-components/typography";
import { Transition } from "@headlessui/react";
import { FormattedMessage } from "react-intl";

import { useDemoDataMode } from "@/hooks/useDemoDataMode";

export const DemoDataLabel = ({ show }: { show?: boolean }) => (
  <div className="absolute top-0 left-0 w-full">
    <Transition
      show={show}
      enter="transition ease-out duration-300 transform"
      enterFrom="opacity-0 -translate-y-4"
      enterTo="opacity-100 translate-y-0"
      leave="transition ease-in duration-200 transform"
      leaveFrom="opacity-100 translate-y-0"
      leaveTo="opacity-0 -translate-y-4"
    >
      <div className="flex h-1 items-center justify-center bg-tangerine700">
        <div className="mt-5 bg-tangerine700 px-4">
          <Typography color="text-white" lineHeight="leading-6">
            <FormattedMessage defaultMessage="DEMO DATA" id="1CdHjj" />
          </Typography>
        </div>
      </div>
    </Transition>
  </div>
);

export const DemoDataLabelConnected = () => {
  const { isDemoDataMode } = useDemoDataMode();

  return <DemoDataLabel show={isDemoDataMode} />;
};
