import classNames from "classnames";
import { useIntl } from "react-intl";

import { defaultIconClassNames, IconProps } from "./Icon";

export default function MagicIcon({ size = 32, className = "" }: IconProps) {
  const intl = useIntl();
  return (
    <svg
      width={size}
      height={(size / 22) * 20}
      viewBox="0 0 22 20"
      fill="none"
      className={classNames(defaultIconClassNames, className)}
      xmlns="http://www.w3.org/2000/svg"
      aria-label={intl.formatMessage({ defaultMessage: "Magic", id: "q2SwrY" })}
    >
      <path
        d="M9.61177 9.68217L19.6846 19.755C20.0009 20.0713 20.5022 20.0829 20.8042 19.7808C21.1062 19.4788 21.0947 18.9776 20.7784 18.6613L10.7055 8.58841L8.82614 6.79858L7.66412 7.94711L9.61177 9.68217Z"
        fill="#6E8294"
      />
      <path
        d="M3.7923 9.0006L5.33835 6.52407C5.36937 6.47435 5.3764 6.41327 5.35745 6.35781L4.43525 3.65879C4.38286 3.50553 4.52945 3.35894 4.68275 3.41134L7.38177 4.33354C7.43723 4.35248 7.49831 4.34545 7.54803 4.31443L9.63552 3.01123L9.84028 3.08798L9.61456 6.02262L10.3582 6.52722L12.111 7.7887L9.4552 8.55885L8.80453 10.5905L8.37963 10.9225L8.36715 11.0767L7.12506 9.15406C7.0862 9.09391 7.01753 9.0599 6.94612 9.06552L3.9729 9.29811C3.81334 9.31063 3.70755 9.13629 3.7923 9.0006Z"
        fill="#F3C00B"
      />
      <path
        d="M9.05728 8.42108C9.07614 8.35859 9.12506 8.30967 9.18755 8.29081L11.5329 7.58274L9.45266 6.23882C9.39251 6.19996 9.3585 6.13125 9.36411 6.05988L9.5967 3.08666C9.59808 3.06933 9.59683 3.05281 9.59408 3.03694L10.024 2.76852C10.1597 2.68377 10.3341 2.78956 10.3216 2.94908L10.089 5.92229C10.0834 5.99367 10.1174 6.06238 10.1775 6.10124L12.5372 7.62564C12.5737 7.64923 12.5982 7.68134 12.6121 7.71657L12.6124 7.71661C12.6126 7.71701 12.6126 7.71745 12.6128 7.7179C12.6154 7.72465 12.6168 7.73167 12.6186 7.73866C12.6202 7.74444 12.6223 7.75005 12.6233 7.75595C12.6233 7.75607 12.6234 7.75615 12.6234 7.75627H12.6233C12.6383 7.84672 12.5898 7.94516 12.4877 7.97598L9.90826 8.75474C9.84577 8.7736 9.79686 8.82252 9.77799 8.88501L8.99923 11.4645C8.95124 11.6235 8.73901 11.6534 8.64889 11.5139L8.29084 10.9597L9.05728 8.42108Z"
        fill="#CC9B1D"
      />
      <path
        d="M14.4087 4.12047C14.8294 4.47016 14.9496 4.97828 14.7693 5.64483C14.7424 5.7444 14.6371 5.80119 14.5393 5.76843L13.3455 5.36893C13.2214 5.32737 13.1824 5.17092 13.2726 5.07596L14.1653 4.1359C14.2298 4.06788 14.3366 4.06057 14.4087 4.12047Z"
        fill="#F3C00B"
      />
      <path
        d="M7.00774 0.210413C7.46153 -0.0952097 7.98294 -0.0679032 8.572 0.292373C8.66002 0.346218 8.68495 0.463119 8.62605 0.547826L7.90736 1.58139C7.83259 1.68892 7.67146 1.68238 7.60565 1.56915L6.95414 0.448375C6.90704 0.367345 6.92998 0.262804 7.00774 0.210413Z"
        fill="#F3C00B"
      />
      <path
        d="M11.1962 1.73783C11.2846 1.73783 11.3721 1.69941 11.432 1.62533L11.984 0.942627C12.0891 0.812517 12.0689 0.621776 11.9388 0.516589C11.8087 0.411403 11.6179 0.4316 11.5128 0.56171L10.9609 1.24441C10.8557 1.37452 10.8759 1.56526 11.006 1.67045C11.062 1.71577 11.1294 1.73783 11.1962 1.73783Z"
        fill="#F3C00B"
      />
      <path
        d="M5.11551 13.4134C5.4652 13.8342 5.97332 13.9544 6.63986 13.7741C6.73943 13.7471 6.79623 13.6419 6.76347 13.5441L6.36397 12.3503C6.3224 12.2261 6.16596 12.1871 6.07099 12.2773L5.13094 13.17C5.06291 13.2346 5.0556 13.3413 5.11551 13.4134Z"
        fill="#F3C00B"
      />
      <path
        d="M1.20492 6.01254C0.899293 6.46633 0.9266 6.98774 1.28688 7.57681C1.34072 7.66483 1.45762 7.68975 1.54233 7.63085L2.5759 6.91216C2.68343 6.83739 2.67688 6.67626 2.56366 6.61046L1.44288 5.95894C1.36181 5.91184 1.25727 5.93478 1.20492 6.01254Z"
        fill="#F3C00B"
      />
      <path
        d="M1.74535 11.0566C1.81221 11.0566 1.87954 11.0346 1.93561 10.9892L2.61831 10.4373C2.74846 10.3321 2.76866 10.1414 2.66343 10.0112C2.55829 9.88106 2.36759 9.86082 2.2374 9.96613L1.55469 10.5181C1.42454 10.6233 1.40435 10.814 1.50957 10.9441C1.5694 11.0182 1.65697 11.0566 1.74535 11.0566Z"
        fill="#F3C00B"
      />
      <path
        d="M3.2553 2.62945C3.33625 2.62945 3.41696 2.59722 3.47662 2.53348C3.59094 2.41132 3.58455 2.21957 3.46236 2.10526L2.70501 1.3967C2.58286 1.28231 2.39111 1.28877 2.27679 1.41096C2.16247 1.53311 2.16886 1.72487 2.29105 1.83918L3.0484 2.54774C3.10685 2.60239 3.18118 2.62945 3.2553 2.62945Z"
        fill="#F3C00B"
      />
    </svg>
  );
}
