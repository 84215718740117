import classNames from "classnames";
import { useIntl } from "react-intl";

import { defaultIconClassNames, IconProps } from "./Icon";

export default function CircleCloseIcon({ size = 32, className = "" }: IconProps) {
  const intl = useIntl();
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 16 16"
      fill="none"
      className={classNames(defaultIconClassNames, className)}
      xmlns="http://www.w3.org/2000/svg"
      aria-label={intl.formatMessage({ defaultMessage: "Circle close", id: "NmW8tK" })}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16ZM10.4042 11.2526L8.00008 8.84853L5.59588 11.2527L4.74735 10.4042L7.15155 8L4.74735 5.59579L5.59588 4.74726L8.00008 7.15147L10.4042 4.74735L11.2527 5.59588L8.84861 8L11.2527 10.4041L10.4042 11.2526Z"
      />
    </svg>
  );
}
