import classNames from "classnames";
import { useIntl } from "react-intl";

import { defaultIconClassNames, IconProps } from "./Icon";

export default function DemoIcon({ size = 72, className = "" }: IconProps) {
  const intl = useIntl();
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 72 72"
      fill="none"
      className={classNames(defaultIconClassNames, className)}
      xmlns="http://www.w3.org/2000/svg"
      aria-label={intl.formatMessage({ defaultMessage: "Demo", id: "TPv/Zv" })}
    >
      <path
        d="M29 28.1333C23.4863 28.1333 19 32.6196 19 38.1333V46.1333C19 46.8266 19.3593 47.4693 19.9492 47.8345C20.5265 48.1936 21.2616 48.2366 21.8945 47.9224L29.8945 43.9224L29 28.1333ZM41.7789 24.1333L47 20.1333V19.0766C47 12.7973 43.5117 7.01943 37.8945 4.2109C37.332 3.9297 36.668 3.9297 36.1055 4.2109C30.4883 7.01943 27 12.7973 27 19.0766V20.1333L31.7368 24.1333H41.7789ZM43 41.8912V28.1333H31V41.8912L29.0605 49.649C28.9101 50.2466 29.0449 50.8796 29.4239 51.3638C29.8028 51.8501 30.3848 52.1333 31 52.1333H34.1527H39.6473H43C43.6152 52.1333 44.1972 51.8501 44.5761 51.3638C44.9551 50.8794 45.0899 50.2466 44.9395 49.649L43 41.8912ZM45 28.1333L44.1055 43.9224L52.1055 47.9224C52.7369 48.236 53.4721 48.1944 54.0508 47.8345C54.6407 47.4693 55 46.8266 55 46.1333V38.1333C55 32.6196 50.5137 28.1333 45 28.1333Z"
        fill="#133550"
      />
      <path
        d="M55 56.1333C52.6035 56.1333 50.3535 57.1977 48.8261 59.0572C48.496 59.4596 46.6144 60.1333 45.7472 60.1333C43.5715 60.1333 41.6667 58.3618 41.6667 56.1861V52.1333H32.3333V56.1861C32.3333 58.3618 30.4285 60.1333 28.2528 60.1333C27.3856 60.1333 25.504 59.4594 25.172 59.0552C23.6465 57.1977 21.3965 56.1333 19 56.1333C14.5879 56.1333 11 59.7212 11 64.1333C11 68.5454 14.5879 72.2666 19 72.2666H55C59.4121 72.2666 63 68.5454 63 64.1333C63 59.7212 59.4121 56.1333 55 56.1333ZM29 44.1333H45C46.1055 44.1333 47 43.2388 47 42.1333V20.1333H27V42.1333C27 43.2388 27.8945 44.1333 29 44.1333ZM37 28.133C38.1045 28.133 39 29.0285 39 30.133C39 31.2378 38.1045 32.133 37 32.133C35.8952 32.133 35 31.2378 35 30.133C35 29.0285 35.8952 28.133 37 28.133Z"
        fill="#BFEEC9"
      />
    </svg>
  );
}
