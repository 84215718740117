import classNames from "classnames";
import { useIntl } from "react-intl";

import { defaultIconClassNames, IconProps } from "./Icon";

export default function StatusSuccessIcon({ size = 32, className = "" }: IconProps) {
  const intl = useIntl();
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 28 28"
      fill="none"
      className={classNames(defaultIconClassNames, className)}
      xmlns="http://www.w3.org/2000/svg"
      aria-label={intl.formatMessage({ defaultMessage: "Status success", id: "G/96sy" })}
    >
      <path
        d="M14 28C21.732 28 28 21.732 28 14C28 6.26801 21.732 0 14 0C6.26801 0 0 6.26801 0 14C0 21.732 6.26801 28 14 28Z"
        fill="#BBE5B3"
      />
      <path
        d="M14 23C18.9706 23 23 18.9706 23 14C23 9.02944 18.9706 5 14 5C9.02944 5 5 9.02944 5 14C5 18.9706 9.02944 23 14 23Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14 4C8.486 4 4 8.486 4 14C4 19.514 8.486 24 14 24C19.514 24 24 19.514 24 14C24 8.486 19.514 4 14 4ZM14 22C9.589 22 6 18.411 6 14C6 9.589 9.589 6 14 6C18.411 6 22 9.589 22 14C22 18.411 18.411 22 14 22ZM16.293 11.293L13 14.586L11.707 13.293C11.316 12.902 10.684 12.902 10.293 13.293C9.902 13.684 9.902 14.316 10.293 14.707L12.293 16.707C12.488 16.902 12.744 17 13 17C13.256 17 13.512 16.902 13.707 16.707L17.707 12.707C18.098 12.316 18.098 11.684 17.707 11.293C17.316 10.902 16.684 10.902 16.293 11.293Z"
        fill="#108043"
      />
    </svg>
  );
}
