import { useIntl } from "react-intl";

export type ImageType =
  | "productInvoicing"
  | "productLookup"
  | "productReporting"
  | "productEInvoicing"
  | "productTax"
  | "productReturns"
  | "iconInvoicing"
  | "iconReporting"
  | "iconTaxEngine"
  | "iconTaxStatus"
  | "invoicingTemplates"
  | "lookupHero"
  | "lookupTinSearchHero"
  | "reportingHero"
  | "taxHero"
  | "returnsHero"
  | "emptyTableResults"
  | "emptyTableUploadCsv"
  | "errorTableResults"
  | "iconEntreprise"
  | "notFoundPage"
  | "errorPage"
  | "emptyBarChart"
  | "emptyPieChart"
  | "calendar";

export type ImageAttributes = {
  src: string;
  alt: string;
  width: number;
  height: number;
};

export const useImages = (): Record<ImageType, ImageAttributes> => {
  const intl = useIntl();

  return {
    productInvoicing: {
      src: "/img/product-invoicing.png",
      alt: intl.formatMessage({ defaultMessage: "Fonoa Invoicing", id: "GkPny8" }),
      width: 444,
      height: 260,
    },
    productLookup: {
      src: "/img/product-lookup.png",
      alt: intl.formatMessage({ defaultMessage: "Fonoa Lookup", id: "lu0TpH" }),
      width: 444,
      height: 260,
    },
    productReporting: {
      src: "/img/product-reporting.png",
      alt: intl.formatMessage({ defaultMessage: "Fonoa Reporting", id: "AtHDbv" }),
      width: 444,
      height: 260,
    },
    productEInvoicing: {
      src: "/img/product-einvoicing.png",
      alt: intl.formatMessage({ defaultMessage: "Fonoa E-Invoicing", id: "ksizQj" }),
      width: 444,
      height: 260,
    },
    productTax: {
      src: "/img/product-tax.png",
      alt: intl.formatMessage({ defaultMessage: "Fonoa Tax", id: "pyVvtN" }),
      width: 444,
      height: 260,
    },
    productReturns: {
      src: "/img/product-returns.png",
      alt: intl.formatMessage({ defaultMessage: "Fonoa Returns", id: "n3OTK3" }),
      width: 444,
      height: 260,
    },
    iconInvoicing: {
      src: "/img/icons/Invoicing.svg",
      alt: intl.formatMessage({ defaultMessage: "Invoicing Icon", id: "tbchiq" }),
      width: 54,
      height: 54,
    },
    iconReporting: {
      src: "/img/icons/Reporting.svg",
      alt: intl.formatMessage({ defaultMessage: "Reporting Icon", id: "1mBeYm" }),
      width: 54,
      height: 54,
    },
    iconTaxEngine: {
      src: "/img/icons/TaxEngine.svg",
      alt: intl.formatMessage({ defaultMessage: "Tax Engine Icon", id: "eaIdaD" }),
      width: 54,
      height: 54,
    },
    iconTaxStatus: {
      src: "/img/icons/TaxStatus.svg",
      alt: intl.formatMessage({ defaultMessage: "Tax Status Icon", id: "AA7GSR" }),
      width: 54,
      height: 54,
    },
    invoicingTemplates: {
      src: "/img/invoicing/InvoiceTemplates.png",
      alt: intl.formatMessage({ defaultMessage: "Invoicing Templates", id: "xOr4gy" }),
      width: 744,
      height: 576,
    },
    lookupHero: {
      src: "/img/lookup/LookupHero.png",
      alt: intl.formatMessage({ defaultMessage: "Lookup product", id: "Ep1Db7" }),
      width: 976,
      height: 496,
    },
    lookupTinSearchHero: {
      src: "/img/lookup/TinSearchHero.png",
      alt: intl.formatMessage({ defaultMessage: "TIN Search", id: "06lo7q" }),
      width: 1118,
      height: 497,
    },
    reportingHero: {
      src: "/img/reporting/ReportingHero.png",
      alt: intl.formatMessage({ defaultMessage: "Reporting product", id: "hhb86N" }),
      width: 1130,
      height: 642,
    },
    taxHero: {
      src: "/img/tax/TaxHero.png",
      alt: intl.formatMessage({ defaultMessage: "Tax product", id: "UezcTE" }),
      width: 929,
      height: 613,
    },
    returnsHero: {
      src: "/img/returns/ReturnsHero.png",
      alt: intl.formatMessage({ defaultMessage: "Returns product", id: "POir8g" }),
      width: 922,
      height: 586,
    },
    emptyTableResults: {
      src: "/img/empty-table-results.svg",
      alt: intl.formatMessage({ defaultMessage: "Empty Table Results", id: "v4OFy/" }),
      width: 118,
      height: 90,
    },
    emptyTableUploadCsv: {
      src: "/img/empty-table-upload-csv.svg",
      alt: intl.formatMessage({ defaultMessage: "Empty Table - Upload CSV", id: "RSPeus" }),
      width: 122,
      height: 113,
    },
    errorTableResults: {
      src: "/img/error-table-results.svg",
      alt: intl.formatMessage({ defaultMessage: "Table Results Error", id: "Ze5N5L" }),
      width: 84,
      height: 94,
    },
    iconEntreprise: {
      src: "/img/icons/entreprise.svg",
      alt: intl.formatMessage({ defaultMessage: "Entreprise", id: "lXB072" }),
      width: 28,
      height: 28,
    },
    notFoundPage: {
      src: "/img/404.svg",
      alt: intl.formatMessage({ defaultMessage: "Not Found Page", id: "CXNujs" }),
      width: 452,
      height: 146,
    },
    errorPage: {
      src: "/img/error.svg",
      alt: intl.formatMessage({ defaultMessage: "Error Page", id: "3qxoWD" }),
      width: 355,
      height: 123,
    },
    emptyBarChart: {
      src: "/img/empty-bar-chart.svg",
      alt: intl.formatMessage({ defaultMessage: "Empty bar chart", id: "vX75o0" }),
      width: 89,
      height: 90,
    },
    emptyPieChart: {
      src: "/img/empty-pie-chart.svg",
      alt: intl.formatMessage({ defaultMessage: "Empty pie chart", id: "UO+xX9" }),
      width: 90,
      height: 91,
    },
    calendar: {
      src: "/img/calendar.svg",
      alt: intl.formatMessage({ defaultMessage: "Calendar", id: "Coy6SH" }),
      width: 82,
      height: 91,
    },
  };
};
