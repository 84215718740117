import classNames from "classnames";
import { useIntl } from "react-intl";

import { IconProps } from "./Icon";

export default function UsFlagIcon({ size = 16, className = "", onClick }: IconProps) {
  const intl = useIntl();
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 23 23"
      fill="none"
      className={classNames("inline-block", className)}
      onClick={onClick}
      xmlns="http://www.w3.org/2000/svg"
      aria-label={intl.formatMessage({ defaultMessage: "USA flag", id: "7vTn7b" })}
    >
      <g clipPath="url(#clip0_1569_3911)">
        <path d="M17.25 1.725C15.4875 0.6375 13.4625 0 11.25 0V1.725H17.25Z" fill="#ED4C5C" />
        <path
          d="M11.25 3.44998H19.35C18.7125 2.81248 18 2.21248 17.25 1.72498H11.25V3.44998Z"
          fill="white"
        />
        <path
          d="M11.25 5.17507H20.7375C20.325 4.53757 19.875 3.97507 19.3875 3.45007H11.25V5.17507Z"
          fill="#ED4C5C"
        />
        <path
          d="M11.25 6.89993H21.6375C21.375 6.29993 21.075 5.69993 20.7375 5.17493H11.25V6.89993Z"
          fill="white"
        />
        <path
          d="M11.25 8.62503H22.2C22.05 8.02503 21.8625 7.46252 21.6375 6.90002H11.25V8.62503Z"
          fill="#ED4C5C"
        />
        <path
          d="M11.25 10.3875H22.4625C22.425 9.78748 22.3125 9.22498 22.2 8.66248H11.25V10.3875Z"
          fill="white"
        />
        <path
          d="M22.4625 10.3876H11.25V11.2501H0C0 11.5501 -3.63216e-08 11.8126 0.0375 12.1126H22.4625C22.5 11.8126 22.5 11.5501 22.5 11.2501C22.5 10.9501 22.5 10.6501 22.4625 10.3876Z"
          fill="#ED4C5C"
        />
        <path
          d="M0.300098 13.8374H22.2001C22.3501 13.2749 22.4251 12.7124 22.4626 12.1124H0.0375977C0.0750977 12.6749 0.150098 13.2749 0.300098 13.8374Z"
          fill="white"
        />
        <path
          d="M0.862793 15.5625H21.6378C21.8628 15 22.0503 14.4375 22.2003 13.8375H0.300293C0.450293 14.4375 0.637793 15 0.862793 15.5625Z"
          fill="#ED4C5C"
        />
        <path
          d="M1.76279 17.2875H20.7378C21.0753 16.725 21.3753 16.1625 21.6378 15.5625H0.862793C1.12529 16.1625 1.42529 16.725 1.76279 17.2875Z"
          fill="white"
        />
        <path
          d="M3.11221 19.0125H19.3872C19.8747 18.4875 20.3622 17.8875 20.7372 17.2875H1.76221C2.13721 17.925 2.62471 18.4875 3.11221 19.0125Z"
          fill="#ED4C5C"
        />
        <path
          d="M5.21279 20.7376H17.2878C18.0753 20.2501 18.7503 19.6501 19.3878 19.0126H3.11279C3.75029 19.6876 4.46279 20.2501 5.21279 20.7376Z"
          fill="white"
        />
        <path
          d="M11.2499 22.4999C13.4624 22.4999 15.5249 21.8624 17.2874 20.7374H5.2124C6.9749 21.8624 9.0374 22.4999 11.2499 22.4999Z"
          fill="#ED4C5C"
        />
        <path
          d="M5.25 1.725C4.4625 2.2125 3.75 2.8125 3.1125 3.45C2.5875 3.975 2.1375 4.575 1.7625 5.175C1.425 5.7375 1.0875 6.3 0.8625 6.9C0.6375 7.4625 0.45 8.025 0.3 8.625C0.15 9.1875 0.075 9.75 0.0375 10.35C-3.63216e-08 10.65 0 10.95 0 11.25H11.25V0C9.0375 0 7.0125 0.6375 5.25 1.725Z"
          fill="#428BC1"
        />
        <path
          d="M8.625 0.375L8.8125 0.9375H9.375L8.925 1.3125L9.075 1.875L8.625 1.5375L8.175 1.875L8.325 1.3125L7.875 0.9375H8.4375L8.625 0.375Z"
          fill="white"
        />
        <path
          d="M10.125 2.625L10.3125 3.1875H10.875L10.425 3.5625L10.575 4.125L10.125 3.7875L9.675 4.125L9.825 3.5625L9.375 3.1875H9.9375L10.125 2.625Z"
          fill="white"
        />
        <path
          d="M7.125 2.625L7.3125 3.1875H7.875L7.425 3.5625L7.575 4.125L7.125 3.7875L6.675 4.125L6.825 3.5625L6.375 3.1875H6.9375L7.125 2.625Z"
          fill="white"
        />
        <path
          d="M8.625 4.875L8.8125 5.4375H9.375L8.925 5.8125L9.075 6.375L8.625 6.0375L8.175 6.375L8.325 5.8125L7.875 5.4375H8.4375L8.625 4.875Z"
          fill="white"
        />
        <path
          d="M5.625 4.875L5.8125 5.4375H6.375L5.925 5.8125L6.075 6.375L5.625 6.0375L5.175 6.375L5.325 5.8125L4.875 5.4375H5.4375L5.625 4.875Z"
          fill="white"
        />
        <path
          d="M2.625 4.875L2.8125 5.4375H3.375L2.925 5.8125L3.075 6.375L2.625 6.0375L2.175 6.375L2.325 5.8125L1.875 5.4375H2.4375L2.625 4.875Z"
          fill="white"
        />
        <path
          d="M10.125 7.125L10.3125 7.6875H10.875L10.425 8.0625L10.575 8.625L10.125 8.2875L9.675 8.625L9.825 8.0625L9.375 7.6875H9.9375L10.125 7.125Z"
          fill="white"
        />
        <path
          d="M7.125 7.125L7.3125 7.6875H7.875L7.425 8.0625L7.575 8.625L7.125 8.2875L6.675 8.625L6.825 8.0625L6.375 7.6875H6.9375L7.125 7.125Z"
          fill="white"
        />
        <path
          d="M4.125 7.125L4.3125 7.6875H4.875L4.425 8.0625L4.575 8.625L4.125 8.2875L3.675 8.625L3.825 8.0625L3.375 7.6875H3.9375L4.125 7.125Z"
          fill="white"
        />
        <path
          d="M8.625 9.375L8.8125 9.9375H9.375L8.925 10.3125L9.075 10.875L8.625 10.5375L8.175 10.875L8.325 10.3125L7.875 9.9375H8.4375L8.625 9.375Z"
          fill="white"
        />
        <path
          d="M5.625 9.375L5.8125 9.9375H6.375L5.925 10.3125L6.075 10.875L5.625 10.5375L5.175 10.875L5.325 10.3125L4.875 9.9375H5.4375L5.625 9.375Z"
          fill="white"
        />
        <path
          d="M2.625 9.375L2.8125 9.9375H3.375L2.925 10.3125L3.075 10.875L2.625 10.5375L2.175 10.875L2.325 10.3125L1.875 9.9375H2.4375L2.625 9.375Z"
          fill="white"
        />
        <path
          d="M3.67461 4.125L4.12461 3.7875L4.57461 4.125L4.38711 3.5625L4.83711 3.1875H4.27461L4.12461 2.625L3.93711 3.1875H3.41211L3.86211 3.525L3.67461 4.125Z"
          fill="white"
        />
        <path
          d="M0.674902 8.625L1.1249 8.2875L1.5749 8.625L1.3874 8.0625L1.8374 7.6875H1.3124L1.1249 7.125L0.937402 7.6875H0.562402C0.562402 7.725 0.524902 7.7625 0.524902 7.8L0.824902 8.025L0.674902 8.625Z"
          fill="white"
        />
      </g>
      <rect x="0.5" y="0.5" width="21.5" height="21.4999" rx="10.75" stroke="#E3E8EC" />
      <defs>
        <clipPath id="clip0_1569_3911">
          <rect width="22.5" height="22.4999" rx="11.25" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
