import classNames from "classnames";
import { useIntl } from "react-intl";

import { defaultIconClassNames, IconProps } from "./Icon";

export default function BellIcon({ size = 16, className = "", onClick }: IconProps) {
  const intl = useIntl();
  return (
    <svg
      width={(size * 12.0) / 16}
      height={size}
      className={classNames(defaultIconClassNames, className)}
      onClick={onClick}
      viewBox="0 0 12 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      aria-label={intl.formatMessage({ defaultMessage: "Bell", id: "yDaFUX" })}
    >
      <path
        d="M6 15.1406C6.825 15.1406 7.5 14.4656 7.5 13.6406H4.5C4.5 14.4656 5.175 15.1406 6 15.1406ZM10.5 10.6406V6.89062C10.5 4.58812 9.2775 2.66062 7.125 2.15062V1.64062C7.125 1.01812 6.6225 0.515625 6 0.515625C5.3775 0.515625 4.875 1.01812 4.875 1.64062V2.15062C2.73 2.66062 1.5 4.58062 1.5 6.89062V10.6406L0 12.1406V12.8906H12V12.1406L10.5 10.6406ZM9 11.3906H3V6.89062C3 5.03062 4.1325 3.51562 6 3.51562C7.8675 3.51562 9 5.03062 9 6.89062V11.3906Z"
        fill="currentColor"
      />
    </svg>
  );
}
