import classNames from "classnames";
import { useIntl } from "react-intl";

import { defaultIconClassNames, IconProps } from "./Icon";

export default function ClosedEyeIcon({ size = 16, className = "" }: IconProps) {
  const intl = useIntl();
  return (
    <svg
      width={size}
      height={size}
      className={classNames(defaultIconClassNames, className)}
      viewBox="0 0 14 13"
      fill="#627C90"
      xmlns="http://www.w3.org/2000/svg"
      aria-label={intl.formatMessage({ defaultMessage: "Closed eye", id: "MyO8dE" })}
    >
      <path
        d="M7 2.25909C9.41182 2.25909 11.5627 3.61455 12.6127 5.75909C12.2373 6.53546 11.7091 7.20364 11.0791 7.74455L11.9764 8.64182C12.8609 7.85909 13.5609 6.87909 14 5.75909C12.8991 2.96546 10.1818 0.986364 7 0.986364C6.19182 0.986364 5.41545 1.11364 4.68364 1.34909L5.73364 2.39909C6.14727 2.31636 6.56727 2.25909 7 2.25909ZM6.31909 2.98455L7.63636 4.30182C7.99909 4.46091 8.29182 4.75364 8.45091 5.11636L9.76818 6.43364C9.81909 6.21727 9.85727 5.98818 9.85727 5.75273C9.86364 4.17455 8.57818 2.89545 7 2.89545C6.76455 2.89545 6.54182 2.92727 6.31909 2.98455ZM0.642727 0.903636L2.34818 2.60909C1.31091 3.42364 0.49 4.50546 0 5.75909C1.10091 8.55273 3.81818 10.5318 7 10.5318C7.96727 10.5318 8.89636 10.3473 9.74909 10.01L11.9255 12.1864L12.8227 11.2891L1.54 0L0.642727 0.903636ZM5.41545 5.67636L7.07636 7.33727C7.05091 7.34364 7.02545 7.35 7 7.35C6.12182 7.35 5.40909 6.63727 5.40909 5.75909C5.40909 5.72727 5.41545 5.70818 5.41545 5.67636ZM3.25182 3.51273L4.36545 4.62636C4.21909 4.97636 4.13636 5.35818 4.13636 5.75909C4.13636 7.33727 5.42182 8.62273 7 8.62273C7.40091 8.62273 7.78273 8.54 8.12636 8.39364L8.75 9.01727C8.19 9.17 7.60455 9.25909 7 9.25909C4.58818 9.25909 2.43727 7.90364 1.38727 5.75909C1.83273 4.84909 2.48182 4.09818 3.25182 3.51273Z"
        fill="#627C91"
      />
    </svg>
  );
}
