import classNames from "classnames";
import { useIntl } from "react-intl";

import { defaultIconClassNames, IconProps } from "./Icon";

export default function ExpandIcon({ size = 32, className = "", onClick }: IconProps) {
  const intl = useIntl();
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 12 12"
      fill="none"
      className={classNames(defaultIconClassNames, className)}
      onClick={onClick}
      xmlns="http://www.w3.org/2000/svg"
      aria-label={intl.formatMessage({ defaultMessage: "Expand", id: "0oLj/t" })}
    >
      <path d="M1.83366 7.66667H0.166992V11.8333H4.33366V10.1667H1.83366V7.66667ZM0.166992 4.33334H1.83366V1.83334H4.33366V0.166672H0.166992V4.33334ZM10.167 10.1667H7.66699V11.8333H11.8337V7.66667H10.167V10.1667ZM7.66699 0.166672V1.83334H10.167V4.33334H11.8337V0.166672H7.66699Z" />
    </svg>
  );
}
