import classNames from "classnames";
import { useIntl } from "react-intl";

import { defaultIconClassNames, IconProps } from "./Icon";

export default function InsightsIcon({ size = 16, className = "" }: IconProps) {
  const intl = useIntl();
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 16 16"
      fill="none"
      className={classNames(defaultIconClassNames, className)}
      xmlns="http://www.w3.org/2000/svg"
      aria-label={intl.formatMessage({ defaultMessage: "Insights", id: "xK7rmd" })}
    >
      <path d="M14 5.33325C13.0333 5.33325 12.4933 6.29325 12.7133 7.00659L10.3467 9.37992C10.1467 9.31992 9.85332 9.31992 9.65332 9.37992L7.95332 7.67992C8.17999 6.96659 7.63999 5.99992 6.66666 5.99992C5.69999 5.99992 5.15332 6.95992 5.37999 7.67992L2.33999 10.7133C1.62666 10.4933 0.666656 11.0333 0.666656 11.9999C0.666656 12.7333 1.26666 13.3333 1.99999 13.3333C2.96666 13.3333 3.50666 12.3733 3.28666 11.6599L6.31999 8.61992C6.51999 8.67992 6.81332 8.67992 7.01332 8.61992L8.71332 10.3199C8.48666 11.0333 9.02666 11.9999 9.99999 11.9999C10.9667 11.9999 11.5133 11.0399 11.2867 10.3199L13.66 7.95325C14.3733 8.17325 15.3333 7.63325 15.3333 6.66659C15.3333 5.93325 14.7333 5.33325 14 5.33325Z" />
      <path d="M10 6L10.6267 4.62L12 4L10.6267 3.38L10 2L9.38667 3.38L8 4L9.38667 4.62L10 6Z" />
      <path d="M2.33332 7.33333L2.66666 6L3.99999 5.66667L2.66666 5.33333L2.33332 4L1.99999 5.33333L0.666656 5.66667L1.99999 6L2.33332 7.33333Z" />
    </svg>
  );
}
