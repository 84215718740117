import { Button } from "@fonoa/ui-components/button";
import { ContactSupportIcon } from "@fonoa/ui-components/icons";
import { A } from "@fonoa/ui-components/typography";
import { useRouter } from "next/router";
import { FormattedMessage } from "react-intl";

import ErrorImage from "./ErrorImage";
import ErrorTitle from "./ErrorTitle";

function DefaultError({ errorCode }: { errorCode: number | string }) {
  const router = useRouter();

  return (
    <div className="mt-40 flex flex-col items-center text-center">
      <ErrorImage />
      <ErrorTitle errorCode={errorCode} />
      <Button spacing={{ mx: "auto", mt: 8 }} onClick={() => router.back()}>
        <FormattedMessage defaultMessage="Take me back" id="L9M2rY" />
      </Button>
      <A
        fontWeight="normal"
        className="mt-5 flex items-center justify-center text-sm text-primaryBlue500"
        href="https://www.fonoa.com/contact-us"
      >
        <ContactSupportIcon className="mr-2" />
        <FormattedMessage defaultMessage="Contact Us" id="hZOGLS" />
      </A>
    </div>
  );
}

export default DefaultError;
