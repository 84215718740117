import { noop } from "@fonoa/ui-components/utils";
import {
  createContext,
  Dispatch,
  FC,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useReducer,
  useState,
} from "react";

type Handler<T> = (state: T | undefined) => Promise<T | undefined> | T | undefined;
type TaskStatus = "idle" | "running" | "success" | "error";
type TaskState<T> = {
  status?: TaskStatus;
  data?: T;
};

type TaskStateOrError<T> = { state?: TaskState<T> | undefined; error?: Error | undefined };

type Listener<T> = (
  taskId: string,
  taskState: TaskState<T> | undefined,
  error: Error | undefined
) => void;

type GlobalSuccessListenersFn = (data: unknown) => void;

type BackgroundTasksState = {
  tasks: Record<string, Task<unknown> | undefined>;
  listeners: Record<string, Listener<unknown>[] | undefined>;
  states: Record<string, TaskStateOrError<unknown> | undefined>;
  globalSuccessListeners: Record<string, GlobalSuccessListenersFn | undefined>;
  cacheTimers: Record<string, NodeJS.Timeout>;
  options: {
    cacheInMinutes: number;
  };
};

type BackgroundTasksOptions = {
  cacheInMinutes?: number;
};

type NewTask<T> = {
  taskId: string;
  handler: Handler<TaskState<T>>;
  options?: {
    enabled?: boolean;
    cacheInMinutes?: number;
    onGlobalSuccess?: (data: T) => void;
    onTaskStarted?: () => void;
    initialValue?: unknown;
  };
};

type Task<T> = {
  taskId: string;
  handler: Handler<TaskState<T>>;
  canceled?: boolean;
};

export type BackgroundTasksDispatcherAction =
  | { type: "TASK"; payload: NewTask<any> }
  | { type: "ADD_LISTENER"; payload: { taskId: string; listener: Listener<any> } }
  | { type: "REMOVE_LISTENER"; payload: { taskId: string; listener: Listener<any> } }
  | {
      type: "ADD_GLOBAL_SUCCESS_LISTENER";
      payload: { taskId: string; listener: (data: any) => void };
    }
  | { type: "CANCEL"; payload: { taskId: string } };

export type BackgroundTasksDispatcher = Dispatch<BackgroundTasksDispatcherAction>;

const INITIAL_OPTIONS: BackgroundTasksState["options"] = {
  cacheInMinutes: 1,
};

const initialStateWithOptions = (options?: BackgroundTasksOptions): BackgroundTasksState => ({
  tasks: {},
  listeners: {},
  states: {},
  cacheTimers: {},
  globalSuccessListeners: {},
  options: Object.assign({}, INITIAL_OPTIONS, options),
});

const notifySingle = (
  taskId: string,
  state: TaskStateOrError<unknown> | undefined,
  listener: Listener<unknown>
) => {
  listener?.(taskId, state?.state, state?.error);
};

const notify = (
  taskId: string,
  taskState: TaskStateOrError<unknown> | undefined,
  state: BackgroundTasksState
) => {
  state.listeners[taskId]?.forEach((listener) =>
    listener(taskId, taskState?.state, taskState?.error)
  );

  if (taskState?.state?.status === "success") {
    state.globalSuccessListeners[taskId]?.(taskState?.state.data);
  }
};

const clearState = (taskId: string, state: BackgroundTasksState) => {
  delete state.states[taskId];
};

const clearTask = (taskId: string, state: BackgroundTasksState) => {
  delete state.tasks[taskId];
  delete state.states[taskId];
  delete state.globalSuccessListeners[taskId];
};

const processTask = (
  taskId: string,
  taskState: TaskState<unknown> | undefined,
  state: BackgroundTasksState,
  cacheInMinutes: number
) => {
  if (state.tasks[taskId]?.canceled) {
    clearTask(taskId, state);
    notify(taskId, undefined, state);
  }

  clearTimeout(state.cacheTimers[taskId]);

  if (!state.tasks[taskId]) return;

  Promise.resolve(state.tasks[taskId]?.handler(taskState))
    .then((newTaskState) => {
      if (!state.tasks[taskId]?.canceled) {
        state.states[taskId] = {
          state: newTaskState,
          error: undefined,
        };
        notify(taskId, { state: newTaskState }, state);
      }

      if (!["success", "error"].includes(newTaskState?.status || "idle")) {
        post(() => processTask(taskId, newTaskState, state, cacheInMinutes));
      }
    })
    .catch((error) => {
      state.states[taskId] = {
        state: undefined,
        error: error,
      };
      notify(taskId, { error }, state);
    })
    .finally(() => {
      const status = state.states[taskId]?.state?.status;
      if (status && ["success", "error"].includes(status)) {
        if (cacheInMinutes) {
          state.cacheTimers[taskId] = setTimeout(() => {
            clearTask(taskId, state);
          }, cacheInMinutes * 1000 * 60);
        }
      }
    });
};

const addListener = (taskId: string, state: BackgroundTasksState, listener: Listener<unknown>) => {
  if (!state.listeners[taskId]) {
    state.listeners[taskId] = [];
  }
  listener && state.listeners[taskId]?.push(listener);
};

function updateTask(
  taskId: string,
  state: BackgroundTasksState,
  handler: Handler<TaskState<unknown>>
) {
  state.tasks[taskId] = {
    taskId,
    handler,
  };
}

function post(action: () => void): void {
  setTimeout(action, 0);
}

const stateReducer = (
  state: BackgroundTasksState,
  action: BackgroundTasksDispatcherAction
): BackgroundTasksState => {
  const taskId = action.payload.taskId;
  const newTask = !state.tasks[action.payload.taskId];
  const cancelledTask = state.tasks[action.payload.taskId]?.canceled;
  const taskState = state.states[action.payload.taskId];
  const completedTask = ["success", "error"].includes(
    state.states[action.payload.taskId]?.state?.status || "idle"
  );

  switch (action.type) {
    case "TASK":
      updateTask(taskId, state, action.payload.handler);
      if (completedTask) {
        post(() => {
          action.payload.options?.onTaskStarted?.();
          notify(taskId, taskState, state);
        });
      } else if (newTask || cancelledTask) {
        post(() => {
          state.states[taskId] = {
            state: {
              status: "running",
              data: action.payload.options?.initialValue || undefined,
            },
          };

          notify(taskId, state.states[taskId], state);

          processTask(
            taskId,
            state.states[taskId]?.state,
            state,
            action.payload.options?.cacheInMinutes === undefined
              ? state.options.cacheInMinutes
              : action.payload.options?.cacheInMinutes
          );
          action.payload.options?.onTaskStarted?.();
        });
      }
      return state;

    case "ADD_LISTENER":
      addListener(taskId, state, action.payload.listener);
      post(() => notifySingle(taskId, state.states[taskId], action.payload.listener));
      return state;

    case "REMOVE_LISTENER":
      state.listeners[taskId] = state.listeners[taskId]?.filter(
        (x) => x !== action.payload.listener
      );
      return state;

    case "ADD_GLOBAL_SUCCESS_LISTENER":
      state.globalSuccessListeners[taskId] = action.payload.listener;
      return state;

    case "CANCEL":
      state.tasks[taskId] = Object.assign({}, state.tasks[taskId], { canceled: true });
      clearState(taskId, state);
      post(() => notify(taskId, undefined, state));
      return state;
  }
};

const BackgroundTasksStateContext = createContext<BackgroundTasksState>(initialStateWithOptions());
const BackgroundTasksDispatcherContext = createContext<BackgroundTasksDispatcher>(noop);

export const BackgroundTasksProvider: FC<{
  children: ReactNode;
  options?: BackgroundTasksOptions;
}> = ({ children, options }) => {
  const [state, dispatch] = useReducer(stateReducer, { ...initialStateWithOptions(options) });

  return (
    <BackgroundTasksStateContext.Provider value={state}>
      <BackgroundTasksDispatcherContext.Provider value={dispatch}>
        {children}
      </BackgroundTasksDispatcherContext.Provider>
    </BackgroundTasksStateContext.Provider>
  );
};

const useBackgroundTaskDispatcher = () => {
  return useContext<BackgroundTasksDispatcher>(BackgroundTasksDispatcherContext);
};

const useBackgroundTaskState = () => {
  return useContext<BackgroundTasksState>(BackgroundTasksStateContext);
};

export const useBackgroundTaskCancel = () => {
  const dispatch = useContext<BackgroundTasksDispatcher>(BackgroundTasksDispatcherContext);
  return (dependencies: any[]) =>
    dispatch({
      type: "CANCEL",
      payload: { taskId: JSON.stringify(dependencies) },
    });
};

export function useBackgroundTask<T>(
  dependencies: any[],
  handler?: Handler<TaskState<T>>,
  options?: NewTask<T>["options"]
) {
  const hash = useMemo(() => JSON.stringify(dependencies), dependencies);
  const dispatcher = useBackgroundTaskDispatcher();
  const state = useBackgroundTaskState();
  const enabled = options?.enabled === undefined || options.enabled;
  const [taskError, setTaskError] = useState<Error | undefined>(state.states[hash]?.error);
  const [taskState, setTaskState] = useState<TaskState<T> | undefined>(
    state.states[hash]?.state as TaskState<T>
  );

  const updateState = useCallback<Listener<T>>(
    (taskId, state, error) => {
      if (taskId === hash) {
        error ? setTaskError(error) : setTaskState(state);
      }
    },
    [hash]
  );

  useEffect(() => {
    dispatcher({
      type: "ADD_LISTENER",
      payload: {
        taskId: hash,
        listener: updateState,
      },
    });

    if (options?.onGlobalSuccess) {
      dispatcher({
        type: "ADD_GLOBAL_SUCCESS_LISTENER",
        payload: {
          taskId: hash,
          listener: options?.onGlobalSuccess,
        },
      });
    }

    if (handler && enabled) {
      dispatcher({
        type: "TASK",
        payload: {
          taskId: hash,
          handler,
          options,
        },
      });
    }

    return () => {
      dispatcher({
        type: "REMOVE_LISTENER",
        payload: {
          taskId: hash,
          listener: updateState,
        },
      });
    };
  }, [hash, enabled]);

  return {
    data: taskState?.data,
    error: taskError,
    isRunning: taskState?.status === "running",
    isSuccess: taskState?.status === "success",
    isError: taskState?.status === "error",
    isCompleted: ["success", "error"].includes(taskState?.status || "idle"),
  };
}

export function useBackgroundTaskValue<T>(dependencies: any[]) {
  return useBackgroundTask<T>(dependencies);
}

export function taskError<T>(data: T): TaskState<T> {
  return {
    status: "error",
    data,
  };
}

export function taskSuccess<T>(data: T): TaskState<T> {
  return {
    status: "success",
    data,
  };
}

export function taskNext<T>(data: T): TaskState<T> {
  return {
    status: "running",
    data,
  };
}
