import classNames from "classnames";
import { useIntl } from "react-intl";

import { defaultIconClassNames, IconProps } from "./Icon";

export default function ArchiveIcon({ size = 16, className = "" }: IconProps) {
  const intl = useIntl();
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 16 16"
      fill="none"
      className={classNames(defaultIconClassNames, className)}
      xmlns="http://www.w3.org/2000/svg"
      aria-label={intl.formatMessage({ defaultMessage: "Archive", id: "hrgo+E" })}
    >
      <path d="M13.6933 4.23667L12.7667 3.11667C12.5867 2.89 12.3133 2.75 12 2.75H4C3.68667 2.75 3.41333 2.89 3.22667 3.11667L2.30667 4.23667C2.11333 4.46333 2 4.76333 2 5.08333V13.4167C2 14.15 2.6 14.75 3.33333 14.75H12.6667C13.4 14.75 14 14.15 14 13.4167V5.08333C14 4.76333 13.8867 4.46333 13.6933 4.23667ZM4.16 4.08333H11.84L12.38 4.73H3.62667L4.16 4.08333ZM3.33333 13.4167V6.08333H12.6667V13.4167H3.33333ZM8.96667 7.41667H7.03333V9.41667H5.33333L8 12.0833L10.6667 9.41667H8.96667V7.41667Z" />
    </svg>
  );
}
