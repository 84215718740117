import { z } from "zod";

import { DashboardActions, DashboardProductType, DashboardSubjects } from "./ability/dashboard";
import { InvoicingActions, InvoicingProductType, InvoicingSubjects } from "./ability/invoicing";
import { LookupActions, LookupProductType, LookupSubjects } from "./ability/lookup";
import { ReportingActions, ReportingProductType, ReportingSubjects } from "./ability/reporting";
import { ReturnsActions, ReturnsProductType, ReturnsSubjects } from "./ability/returns";
import { TaxActions, TaxProductType, TaxSubjects } from "./ability/tax";

export const dashboardPermissions: `${DashboardProductType}:${DashboardActions}:${Extract<
  DashboardSubjects,
  string
>}`[] = [
  "dashboard:invite:users",
  "dashboard:edit:users",
  "dashboard:view:homepage",
  "dashboard:view:users",
  "dashboard:edit:profile",
  "dashboard:view:profile",
];

export const lookupPermissions: `${LookupProductType}:${LookupActions}:${Extract<
  LookupSubjects,
  string
>}`[] = ["lookup:view:product", "lookup:execute:single", "lookup:execute:batch"];

export const taxPermissions: `${TaxProductType}:${TaxActions}:${Extract<TaxSubjects, string>}`[] = [
  "tax:view:product",
];

export const invoicingPermissions: `${InvoicingProductType}:${InvoicingActions}:${Extract<
  InvoicingSubjects,
  string
>}`[] = ["invoicing:view:product"];

export const reportingPermissions: `${ReportingProductType}:${ReportingActions}:${Extract<
  ReportingSubjects,
  string
>}`[] = ["reporting:view:product"];

export const returnsPermissions: `${ReturnsProductType}:${ReturnsActions}:${Extract<
  ReturnsSubjects,
  string
>}`[] = [
  "returns:view:product",
  "returns:view:returns",
  "returns:activate:csv",
  "returns:activate:transactions",
  "returns:deactivate:transactions",
  "returns:download:transactions",
  "returns:deactivate:csv",
  "returns:process:returns",
  "returns:approve:data",
  "returns:approve:returns",
  "returns:unapprove:data",
  "returns:unapprove:returns",
  "returns:file:returns",
  "returns:download:returns",
  "returns:upload:csv",
  "returns:upload:filedConfirmation",
  "returns:update:paymentDeadline",
  "returns:upload:paymentConfirmation",
  "returns:request:auditTrail",
  "returns:update:workflowTemplate",
  "returns:update:returnAssignees",
  "returns:create:transactions",
  "returns:update:transactions",
];

export const permissions = [
  ...dashboardPermissions,
  ...lookupPermissions,
  ...taxPermissions,
  ...invoicingPermissions,
  ...reportingPermissions,
  ...returnsPermissions,
];

export const permissionValidation = z
  .string()
  .refine((str): str is Permission => permissions.includes(str as Permission), {
    message: "Not a valid permission",
  });

export type Permission = typeof permissions[number];
