import classNames from "classnames";
import { useIntl } from "react-intl";

import { defaultIconClassNames } from "./Icon";
import {
  DARK_FIRST_COLOR_MAPS,
  DARK_SECOND_COLOR_MAPS,
  LIGHT_FIRST_COLOR_MAPS,
  LIGHT_SECOND_COLOR_MAPS,
  ProductIconProps,
  ProductIconVariant,
} from "./ProductIcon";

export const LIGHT_THIRD_COLOR_MAPS: Record<ProductIconVariant, string> = {
  default: "text-white",
  active: "text-primaryBlue500",
  inactive: "text-blueGray400",
};

export const DARK_THIRD_COLOR_MAPS: Record<ProductIconVariant, string> = {
  default: "dark:text-blueGray800",
  active: "dark:text-primaryBlue500",
  inactive: "dark:text-blueGray400",
};

export default function ProductReturnsIcon({
  className,
  size = 16,
  variant = "default",
}: ProductIconProps) {
  const intl = useIntl();
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 16 16"
      className={classNames(defaultIconClassNames, className)}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      aria-label={intl.formatMessage({ defaultMessage: "Product returns", id: "bHT0Zf" })}
    >
      <path
        d="M7.41083 0C7.63193 0 7.84588 0.0783488 8.01469 0.221137L11.5779 3.23501C11.7879 3.41268 11.9091 3.67383 11.9091 3.94894L11.909 13.2913C11.9062 14.4379 11.8471 14.5418 10.6313 14.5454L2.35065 14.5455C1.03961 14.5455 1.001 14.4571 1.00002 13.2226L1 1.39394C1 0.115842 1.01433 0.00381246 2.27779 0.000101511L7.41083 0ZM6.92208 10.9091H4.53247C4.18819 10.9091 3.90909 11.1882 3.90909 11.5325V11.7403C3.90909 12.0845 4.18819 12.3636 4.53247 12.3636H6.92208C7.26636 12.3636 7.54546 12.0845 7.54546 11.7403V11.5325C7.54546 11.1882 7.26636 10.9091 6.92208 10.9091ZM9.83117 8H4.53247C4.18819 8 3.90909 8.2791 3.90909 8.62338V8.83117C3.90909 9.17546 4.18819 9.45455 4.53247 9.45455H9.83117C10.1755 9.45455 10.4545 9.17546 10.4545 8.83117V8.62338C10.4545 8.2791 10.1755 8 9.83117 8Z"
        fill="currentColor"
        className={classNames(LIGHT_SECOND_COLOR_MAPS[variant], DARK_SECOND_COLOR_MAPS[variant])}
      />
      <rect
        x="3"
        y="4"
        width="10"
        height="10"
        fill="currentColor"
        className={classNames(LIGHT_THIRD_COLOR_MAPS[variant], DARK_THIRD_COLOR_MAPS[variant])}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.1969 1.67573C10.0281 1.53294 9.81415 1.45459 9.59305 1.45459L3.73274 1.45469C2.51695 1.45826 2.45781 1.56213 2.45508 2.70876V14.7459C2.45786 15.915 2.51929 16.0001 3.8056 16.0001L12.8135 16C14.077 15.9962 14.0913 15.8842 14.0913 14.6061V5.40353C14.0913 5.12842 13.9702 4.86727 13.7601 4.6896L10.1969 1.67573ZM5.33843 8.71173L5.33843 10.0902L7.91906 10.1L7.91914 12.1194C7.91914 12.2831 8.05184 12.4158 8.21554 12.4158C8.29415 12.4158 8.36953 12.3846 8.42512 12.329L11.1675 9.58659C11.2832 9.47084 11.2832 9.28318 11.1675 9.16743L8.42512 6.42505C8.30937 6.30931 8.12171 6.30931 8.00596 6.42505L7.98023 6.45442C7.94083 6.50586 7.91914 6.56913 7.91914 6.63464L7.91906 8.7117L5.33843 8.71173Z"
        className={classNames(LIGHT_FIRST_COLOR_MAPS[variant], DARK_FIRST_COLOR_MAPS[variant])}
      />
    </svg>
  );
}
