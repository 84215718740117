import classNames from "classnames";
import { useIntl } from "react-intl";

import { defaultIconClassNames, IconProps } from "./Icon";

export default function AttachMoneyIcon({ size = 16, className = "" }: IconProps) {
  const intl = useIntl();
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 18 18"
      fill="none"
      className={classNames(defaultIconClassNames, className)}
      xmlns="http://www.w3.org/2000/svg"
      aria-label={intl.formatMessage({ defaultMessage: "Money", id: "rMmNYa" })}
    >
      <g clipPath="url(#clip0_14825_54009)">
        <path d="M9.34828 8.175C7.64578 7.7325 7.09828 7.275 7.09828 6.5625C7.09828 5.745 7.85578 5.175 9.12328 5.175C10.4583 5.175 10.9533 5.8125 10.9983 6.75H12.6558C12.6033 5.46 11.8158 4.275 10.2483 3.8925V2.25L7.99828 2.25V3.87C6.54328 4.185 5.37328 5.13 5.37328 6.5775C5.37328 8.31 6.80578 9.1725 8.89828 9.675C10.7733 10.125 11.1483 10.785 11.1483 11.4825C11.1483 12 10.7808 12.825 9.12328 12.825C7.57828 12.825 6.97078 12.135 6.88828 11.25H5.23828C5.32828 12.8925 6.55828 13.815 7.99828 14.1225V15.75H10.2483V14.1375C11.7108 13.86 12.8733 13.0125 12.8733 11.475C12.8733 9.345 11.0508 8.6175 9.34828 8.175Z" />
      </g>
      <defs>
        <clipPath id="clip0_14825_54009">
          <rect width="18" height="18" transform="translate(0.5)" />
        </clipPath>
      </defs>
    </svg>
  );
}
