import classNames from "classnames";
import { useIntl } from "react-intl";

import { defaultIconClassNames, IconProps } from "./Icon";

export default function TrashIcon({ size = 32, className = "", onClick }: IconProps) {
  const intl = useIntl();
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 14 16"
      fill="none"
      className={classNames(defaultIconClassNames, className)}
      onClick={onClick}
      xmlns="http://www.w3.org/2000/svg"
      aria-label={intl.formatMessage({ defaultMessage: "Trash", id: "j/I5tV" })}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.25 2C3.25 1.0335 4.0335 0.25 5 0.25H9C9.9665 0.25 10.75 1.0335 10.75 2V3.5H13.25C13.6642 3.5 14 3.83579 14 4.25C14 4.66421 13.6642 5 13.25 5H12.75V14C12.75 14.9665 11.9665 15.75 11 15.75H3C2.0335 15.75 1.25 14.9665 1.25 14V5H0.75C0.335786 5 0 4.66421 0 4.25C0 3.83579 0.335786 3.5 0.75 3.5H3.25V2ZM5 1.75C4.86193 1.75 4.75 1.86193 4.75 2V3.25H9.25V2C9.25 1.86193 9.13807 1.75 9 1.75H5ZM2.75 5.25V14C2.75 14.1381 2.86193 14.25 3 14.25H4.5L4.5 5.25H2.75ZM8 14.25H6L6 5.25H8L8 14.25ZM9.5 14.25H11C11.1381 14.25 11.25 14.1381 11.25 14V5.25H9.5V14.25Z"
      />
    </svg>
  );
}
