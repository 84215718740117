import { useQuery } from "@tanstack/react-query";
import { useMemo } from "react";

import api from "@/lib/api";

export const useCurrencies = () => {
  const { data: currencies, isLoading } = useQuery(
    ["currencies"],
    async () => await api.getCurrencies(),
    {
      select: (data) =>
        Object.fromEntries(data?.data?.map((currency) => [currency.code, currency]) || []),
    }
  );

  return useMemo(
    () => ({
      currencies: currencies ?? {},
      isLoading,
    }),
    [currencies, isLoading]
  );
};
