import classNames from "classnames";
import { useIntl } from "react-intl";

import { defaultIconClassNames, IconProps } from "./Icon";

export default function AutoRenewIcon({ size = 32, className = "" }: IconProps) {
  const intl = useIntl();
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 17 16"
      className={classNames(defaultIconClassNames, className)}
      xmlns="http://www.w3.org/2000/svg"
      aria-label={intl.formatMessage({ defaultMessage: "Auto renew", id: "pFogv3" })}
    >
      <path d="M8.49935 4.00004V5.19337C8.49935 5.49337 8.85935 5.64004 9.06602 5.42671L10.926 3.56671C11.0593 3.43337 11.0593 3.22671 10.926 3.09337L9.06602 1.23338C8.85935 1.02671 8.49935 1.17338 8.49935 1.47338V2.66671C5.55268 2.66671 3.16602 5.05338 3.16602 8.00004C3.16602 8.69337 3.29935 9.36004 3.54602 9.96671C3.72602 10.4134 4.29935 10.5334 4.63935 10.1934C4.81935 10.0134 4.89268 9.74004 4.79268 9.50004C4.59935 9.04004 4.49935 8.52671 4.49935 8.00004C4.49935 5.79338 6.29268 4.00004 8.49935 4.00004ZM12.3593 5.80671C12.1793 5.98671 12.106 6.26671 12.206 6.50004C12.3927 6.96671 12.4993 7.47337 12.4993 8.00004C12.4993 10.2067 10.706 12 8.49935 12V10.8067C8.49935 10.5067 8.13935 10.36 7.93268 10.5734L6.07268 12.4334C5.93935 12.5667 5.93935 12.7734 6.07268 12.9067L7.93268 14.7667C8.13935 14.9734 8.49935 14.8267 8.49935 14.5334V13.3334C11.446 13.3334 13.8327 10.9467 13.8327 8.00004C13.8327 7.30671 13.6993 6.64004 13.4527 6.03337C13.2727 5.58671 12.6993 5.46671 12.3593 5.80671Z" />
    </svg>
  );
}
