import classNames from "classnames";
import { useIntl } from "react-intl";

import { defaultIconClassNames, IconProps } from "./Icon";

export default function LampIcon({ size = 32, className = "" }: IconProps) {
  const intl = useIntl();
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 20 20"
      fill="none"
      className={classNames(defaultIconClassNames, className)}
      xmlns="http://www.w3.org/2000/svg"
      aria-label={intl.formatMessage({ defaultMessage: "Lamp", id: "8akef4" })}
    >
      <path d="M10 2.73667C9.67225 2.73667 9.40625 2.47067 9.40625 2.14292V0.593628C9.40625 0.265878 9.67225 -0.00012207 10 -0.00012207C10.3277 -0.00012207 10.5937 0.265878 10.5937 0.593628V2.14292C10.5937 2.47067 10.3277 2.73667 10 2.73667Z" />
      <path d="M15.1995 4.89154C15.0475 4.89154 14.8955 4.83375 14.7799 4.71738C14.548 4.48542 14.548 4.10938 14.7799 3.87742L15.8756 2.78176C16.1075 2.5498 16.4836 2.5498 16.7155 2.78176C16.9475 3.01371 16.9475 3.38975 16.7155 3.62171L15.6199 4.71738C15.5035 4.83296 15.3515 4.89154 15.1995 4.89154Z" />
      <path d="M18.9057 10.0936H17.3564C17.0287 10.0936 16.7627 9.82763 16.7627 9.49988C16.7627 9.17213 17.0287 8.90613 17.3564 8.90613H18.9057C19.2335 8.90613 19.4995 9.17213 19.4995 9.49988C19.4995 9.82763 19.2335 10.0936 18.9057 10.0936Z" />
      <path d="M16.2952 16.3913C16.1432 16.3913 15.9912 16.3335 15.8756 16.2171L14.7799 15.1215C14.548 14.8895 14.548 14.5135 14.7799 14.2815C15.0119 14.0496 15.3879 14.0496 15.6199 14.2815L16.7155 15.3772C16.9475 15.6091 16.9475 15.9852 16.7155 16.2171C16.5992 16.3335 16.4472 16.3913 16.2952 16.3913Z" />
      <path d="M3.70439 16.3913C3.55239 16.3913 3.40039 16.3335 3.28481 16.2171C3.05285 15.9852 3.05285 15.6091 3.28481 15.3772L4.38047 14.2815C4.61243 14.0496 4.98847 14.0496 5.22042 14.2815C5.45238 14.5135 5.45238 14.8895 5.22042 15.1215L4.12476 16.2171C4.00839 16.3335 3.85639 16.3913 3.70439 16.3913Z" />
      <path d="M2.64304 10.0936H1.09375C0.766 10.0936 0.5 9.82763 0.5 9.49988C0.5 9.17213 0.766 8.90613 1.09375 8.90613H2.64304C2.97079 8.90613 3.23679 9.17213 3.23679 9.49988C3.23679 9.82763 2.97079 10.0936 2.64304 10.0936Z" />
      <path d="M4.80005 4.89154C4.64805 4.89154 4.49605 4.83375 4.38047 4.71738L3.28481 3.62171C3.05285 3.38975 3.05285 3.01371 3.28481 2.78176C3.51677 2.5498 3.89281 2.5498 4.12476 2.78176L5.22042 3.87742C5.45238 4.10938 5.45238 4.48542 5.22042 4.71738C5.10326 4.83296 4.95205 4.89154 4.80005 4.89154Z" />
      <path d="M12.375 16.6249V17.6145C12.375 18.3745 11.7496 18.9999 10.9896 18.9999H9.01042C8.34542 18.9999 7.625 18.4932 7.625 17.3849V16.6249H12.375Z" />
      <path d="M13.4893 5.19326C12.1909 4.14035 10.4809 3.72868 8.8105 4.08493C6.71259 4.52035 5.01051 6.23035 4.57509 8.32826C4.13176 10.4816 4.93926 12.6191 6.66509 13.9253C7.13217 14.2737 7.45675 14.812 7.56759 15.4374V15.4453C7.58342 15.4374 7.60717 15.4374 7.623 15.4374H12.373C12.3888 15.4374 12.3968 15.4374 12.4126 15.4453V15.4374C12.5234 14.8358 12.8797 14.2816 13.4259 13.8541C14.7638 12.7933 15.5397 11.2099 15.5397 9.49992C15.5397 7.82159 14.7955 6.2541 13.4893 5.19326ZM12.9668 9.89576C12.6422 9.89576 12.373 9.62659 12.373 9.30201C12.373 8.09868 11.3993 7.12493 10.1959 7.12493C9.87134 7.12493 9.60217 6.85576 9.60217 6.53118C9.60217 6.2066 9.87134 5.93743 10.1959 5.93743C12.0484 5.93743 13.5605 7.44951 13.5605 9.30201C13.5605 9.62659 13.2913 9.89576 12.9668 9.89576Z" />
      <path d="M7.56836 15.4374H7.62377C7.60794 15.4374 7.58419 15.4374 7.56836 15.4453V15.4374Z" />
      <path d="M12.4146 15.4374V15.4453C12.3987 15.4374 12.3908 15.4374 12.375 15.4374H12.4146Z" />
    </svg>
  );
}
