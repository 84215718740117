import classNames from "classnames";
import { useIntl } from "react-intl";

import { defaultIconClassNames, IconProps } from "./Icon";

export default function StatusInfoIcon({ size = 32, className = "", secondColor }: IconProps) {
  const intl = useIntl();
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 28 28"
      fill="none"
      className={classNames(defaultIconClassNames, className)}
      xmlns="http://www.w3.org/2000/svg"
      aria-label={intl.formatMessage({ defaultMessage: "Status info", id: "18tHJ4" })}
    >
      <path
        d="M14 28C21.732 28 28 21.732 28 14C28 6.26801 21.732 0 14 0C6.26801 0 0 6.26801 0 14C0 21.732 6.26801 28 14 28Z"
        fill={secondColor || "#2568E9"}
        fillOpacity={secondColor ? "1" : "0.12"}
      />
      <path
        d="M14 23C18.9706 23 23 18.9706 23 14C23 9.02944 18.9706 5 14 5C9.02944 5 5 9.02944 5 14C5 18.9706 9.02944 23 14 23Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14 4C8.486 4 4 8.486 4 14C4 19.514 8.486 24 14 24C19.514 24 24 19.514 24 14C24 8.486 19.514 4 14 4ZM14 22C9.589 22 6 18.411 6 14C6 9.589 9.589 6 14 6C18.411 6 22 9.589 22 14C22 18.411 18.411 22 14 22ZM15 17V14C15 13.447 14.552 13 14 13H13C12.448 13 12 13.447 12 14C12 14.553 12.448 15 13 15V18C13 18.553 13.448 19 14 19H15C15.552 19 16 18.553 16 18C16 17.447 15.552 17 15 17ZM14 11.0996C14.607 11.0996 15.1 10.6076 15.1 9.9996C15.1 9.3926 14.607 8.9006 14 8.9006C13.393 8.9006 12.9 9.3926 12.9 9.9996C12.9 10.6076 13.393 11.0996 14 11.0996Z"
        fill="#2658B8"
      />
    </svg>
  );
}
