import classNames from "classnames";
import { useIntl } from "react-intl";

import { defaultIconClassNames, IconProps } from "./Icon";

export default function CodeIcon({ size = 12, className = "" }: IconProps) {
  const intl = useIntl();
  return (
    <svg
      width={size}
      height={size}
      className={classNames(defaultIconClassNames, className)}
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      aria-label={intl.formatMessage({ defaultMessage: "Code", id: "h2vipu" })}
    >
      <path d="M4.7 8.3L2.4 6L4.7 3.7L4 3L1 6L4 9L4.7 8.3ZM7.3 8.3L9.6 6L7.3 3.7L8 3L11 6L8 9L7.3 8.3Z" />
    </svg>
  );
}
