import classNames from "classnames";
import { useIntl } from "react-intl";

import { defaultIconClassNames, IconProps } from "./Icon";

export default function DownloadIcon({ size = 32, className = "" }: IconProps) {
  const intl = useIntl();
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 12 12"
      fill="none"
      className={classNames(defaultIconClassNames, className)}
      xmlns="http://www.w3.org/2000/svg"
      aria-label={intl.formatMessage({ defaultMessage: "Download", id: "5q3qC0" })}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.580645 8.37273C0.259964 8.37273 -2.9438e-07 8.63269 -2.66345e-07 8.95337L-1.16829e-07 10.6636C-5.23059e-08 11.4017 0.606583 12 1.35484 12L10.6452 12C11.3934 12 12 11.4017 12 10.6636L12 8.95337C12 8.63269 11.74 8.37273 11.4194 8.37273C11.0987 8.37273 10.8387 8.63269 10.8387 8.95337L10.8387 10.6636C10.8387 10.7691 10.7521 10.8545 10.6452 10.8545L1.35484 10.8545C1.24794 10.8545 1.16129 10.7691 1.16129 10.6636L1.16129 8.95337C1.16129 8.63269 0.901327 8.37273 0.580645 8.37273ZM8.70834 4.44793L6.58065 6.33675L6.58064 4.73775e-07L5.41935 5.75298e-07L5.41935 6.33675L3.29166 4.44793L2.51479 5.29934L5.61157 8.04843C5.83316 8.24515 6.16684 8.24515 6.38843 8.04843L9.4852 5.29934L8.70834 4.44793Z"
      />
    </svg>
  );
}
