import classNames from "classnames";
import { useIntl } from "react-intl";

import { defaultIconClassNames, IconProps } from "./Icon";

export default function AlignRightIcon({ size = 16, className = "" }: IconProps) {
  const intl = useIntl();
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 16 16"
      fill="none"
      className={classNames(defaultIconClassNames, className)}
      xmlns="http://www.w3.org/2000/svg"
      aria-label={intl.formatMessage({ defaultMessage: "Align right", id: "Bz/qc/" })}
    >
      <path d="M12.2 8.7V12.9H1V8.7H12.2ZM5.2 3.1V7.3H12.2V3.1H5.2ZM13.6 1V15H15V1H13.6Z" />
    </svg>
  );
}
