import { AppAbility, defineAbilityForConditionalRoles } from "@fonoa/permissions";
import { createContext, PropsWithChildren, useContext, useMemo } from "react";

import { useAuth } from "@/hooks/auth";
import { useRoles } from "@/lib/api";

export const AbilityContext = createContext<AppAbility>({} as AppAbility);

export const AbilityProvider = ({ children }: PropsWithChildren) => {
  const auth = useAuth();
  const { data: roles } = useRoles({});

  const ability = useMemo(
    () => defineAbilityForConditionalRoles(auth.user?.conditionalRoles || [], roles || []),
    [auth.user?.conditionalRoles, roles]
  );

  return <AbilityContext.Provider value={ability}>{children}</AbilityContext.Provider>;
};

export const useAbility = () => useContext(AbilityContext);
