import moment, { Moment } from "moment";
import { FormattedDate } from "react-intl";

import { useDateRangeFilterOptions } from "./SelectOptions";

type DateLike = Moment | Date | string | null | undefined;

export const startOfDay = (date: DateLike) => moment(date).startOf("d");

export const endOfDay = (date: DateLike) => moment(date).endOf("d");

export type RangeTag =
  | "today"
  | "last_24_hours"
  | "last_7_days"
  | "last_14_days"
  | "last_30_days"
  | "last_3_months"
  | "last_6_months"
  | "last_12_months"
  | "custom";

export type DatesRange = {
  from: string | undefined;
  to: string | undefined;
};

type Tag = RangeTag | undefined | null;

type ObjectType<T> = T extends RangeTag
  ? DatesRange
  : T extends undefined | null
  ? undefined
  : never;

export function mapTagToRange<T extends Tag>(tag: T): ObjectType<T> {
  switch (tag) {
    case "today":
      return {
        from: startOfDay(moment()).format(),
        to: endOfDay(moment()).format(),
      } as ObjectType<T>;
    case "last_24_hours":
      return {
        from: moment().subtract("1", "d").format(),
        to: moment().format(),
      } as ObjectType<T>;
    case "last_7_days":
      return {
        from: startOfDay(moment()).subtract("7", "d").format(),
        to: endOfDay(moment()).format(),
      } as ObjectType<T>;
    case "last_14_days":
      return {
        from: startOfDay(moment()).subtract("14", "d").format(),
        to: endOfDay(moment()).format(),
      } as ObjectType<T>;
    case "last_30_days":
      return {
        from: startOfDay(moment()).subtract("30", "d").format(),
        to: endOfDay(moment()).format(),
      } as ObjectType<T>;
    case "last_3_months":
      return {
        from: startOfDay(moment()).subtract("3", "M").format(),
        to: endOfDay(moment()).format(),
      } as ObjectType<T>;
    case "last_6_months":
      return {
        from: startOfDay(moment()).subtract("6", "M").format(),
        to: endOfDay(moment()).format(),
      } as ObjectType<T>;
    case "last_12_months":
      return {
        from: startOfDay(moment()).subtract("12", "M").format(),
        to: endOfDay(moment()).format(),
      } as ObjectType<T>;
    default:
      return undefined as ObjectType<T>;
  }
}

export const mapRangeToTag = (range: DatesRange | "custom" | null | undefined) => {
  if (range === "custom") return "custom";
  if (!range || !range?.from || !range?.to) return;

  if (endOfDay(range?.to).format() !== endOfDay(moment()).format()) return "custom";

  if (startOfDay(range.from).format() === startOfDay(moment()).format()) {
    return "today";
  } else if (startOfDay(range.from).format() === startOfDay(moment().subtract("7", "d")).format()) {
    return "last_7_days";
  } else if (
    startOfDay(range.from).format() === startOfDay(moment().subtract("14", "d")).format()
  ) {
    return "last_14_days";
  } else if (
    startOfDay(range.from).format() === startOfDay(moment().subtract("30", "d")).format()
  ) {
    return "last_30_days";
  } else if (startOfDay(range.from).format() === startOfDay(moment().subtract("3", "M")).format()) {
    return "last_3_months";
  } else if (startOfDay(range.from).format() === startOfDay(moment().subtract("6", "M")).format()) {
    return "last_6_months";
  } else if (
    startOfDay(range.from).format() === startOfDay(moment().subtract("12", "M")).format()
  ) {
    return "last_12_months";
  }

  return "custom";
};

export const PrettyDateRange = ({ range }: { range: DatesRange | null | undefined }) => {
  const dateRangeFilterOptions = useDateRangeFilterOptions();

  if (!range) {
    return <></>;
  }

  const currentDateRangeValue = mapRangeToTag(range);
  const currentSelectedDateRangeOption = dateRangeFilterOptions.find(
    ({ value }) => value === currentDateRangeValue
  );
  const currentSelectedDateRangeLabel =
    currentSelectedDateRangeOption?.value === "custom" && range.from && range.to ? (
      <>
        <FormattedDate value={new Date(range.from)} month="short" day="2-digit" /> -{" "}
        <FormattedDate value={new Date(range.to)} month="short" day="2-digit" />
      </>
    ) : (
      currentSelectedDateRangeOption?.label
    );

  return <>{currentSelectedDateRangeLabel}</>;
};

export const FormatAsUTCDate = (dateText: string, format: string) => {
  const localDate = new Date(dateText);
  const utcDate = new Date(
    localDate.getUTCFullYear(),
    localDate.getUTCMonth(),
    localDate.getUTCDate(),
    localDate.getUTCHours(),
    localDate.getUTCMinutes(),
    localDate.getUTCSeconds()
  );

  return moment(utcDate).format(format);
};
