import { NextRouter } from "next/router";
import { FormattedMessage, IntlShape } from "react-intl";

import { NotificationConfig } from "@/components/Notifications/NotificationsManager";
import { routes } from "@/lib/routes";
import { TinSearchBatchesItem } from "@/server/trpc/routers/lookup/getTinSearchBatches";

const completedTitle = <FormattedMessage defaultMessage="TIN search completed" id="Dd2QHq" />;
const runningTitle = <FormattedMessage defaultMessage="TIN search in progress" id="uQ+sLS" />;

const getActualProgressLabel = (intl: IntlShape, completionPercentage: number): string =>
  intl.formatMessage(
    { defaultMessage: "Progress {progress}%", id: "m0f8YU" },
    { progress: completionPercentage }
  );

export function buildBatchTinSearchStartedNotification(
  intl: IntlShape,
  batchId: string
): NotificationConfig {
  return {
    id: batchId,
    type: "info",
    title: runningTitle,
    description: getActualProgressLabel(intl, 0),
    toast: {
      title: runningTitle,
    },
  };
}

export function buildBatchTinSearchNotification(
  batch: TinSearchBatchesItem,
  intl: IntlShape,
  router: NextRouter
): NotificationConfig {
  const searchedTins = intl.formatMessage(
    { defaultMessage: "{tins} TINs searched", id: "k9Dr2W" },
    { tins: batch.tin_search_request_count }
  );
  const actualProgress = getActualProgressLabel(intl, batch.completion_percentage);

  const view = intl.formatMessage({ defaultMessage: "View", id: "FgydNe" });
  const goToBatchTinSearch = () =>
    router.push(`${routes.lookupTinSearchHistoryBatch}/${batch.tin_search_batch_id}`);

  switch (batch.status) {
    case "completed":
      return {
        id: batch.tin_search_batch_id,
        type: "success",
        title: completedTitle,
        description: searchedTins,
        action: {
          title: view,
          onClick: goToBatchTinSearch,
        },
        toast: {
          title: completedTitle,
        },
      };
    case "running":
      return {
        id: batch.tin_search_batch_id,
        type: "info",
        title: runningTitle,
        description: actualProgress,
      };
  }
}
