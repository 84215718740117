import { IconProps } from "./Icon";

export type ProductIconVariant = "default" | "active" | "inactive";

export interface ProductIconProps extends IconProps {
  variant?: ProductIconVariant;
  overrideTextColor?: boolean;
}

export const LIGHT_FIRST_COLOR_MAPS: Record<ProductIconVariant, string> = {
  default: "text-blueGray600",
  active: "text-greenFonoa",
  inactive: "text-blueGray100",
};

export const LIGHT_SECOND_COLOR_MAPS: Record<ProductIconVariant, string> = {
  default: "text-blueGray700",
  active: "text-green700",
  inactive: "text-blueGray400",
};

export const LIGHT_THIRD_COLOR_MAPS: Record<ProductIconVariant, string> = {
  default: "text-blueGray700",
  active: "text-primaryBlue500",
  inactive: "text-blueGray400",
};

export const DARK_FIRST_COLOR_MAPS: Record<ProductIconVariant, string> = {
  default: "dark:text-white",
  active: "dark:text-greenFonoa",
  inactive: "dark:text-blueGray100",
};

export const DARK_SECOND_COLOR_MAPS: Record<ProductIconVariant, string> = {
  default: "dark:text-blueGray700",
  active: "dark:text-green700",
  inactive: "dark:text-blueGray400",
};

export const DARK_THIRD_COLOR_MAPS: Record<ProductIconVariant, string> = {
  default: "dark:text-primaryBlue900",
  active: "dark:text-primaryBlue500",
  inactive: "dark:text-blueGray400",
};
