import { NextApiResponse } from "next";

export type Environment = "production" | "staging" | "dev" | "local";

export const getEnvironment: () => Environment = () => {
  const env = typeof window === "undefined" ? "localhost" : window?.location?.hostname;

  switch (env) {
    case "app.fonoa.com":
      return "production";
    case "app-demo.fonoa.com":
      return "staging";
    case "app-jazz.fonoa.dev":
      return "dev";
    default:
      return "local";
  }
};

export const isBrowser = typeof window !== "undefined";

// No operation fn
export const noop = () => ({});

export const localStorageKeys = {
  demo_data_mode: "demo_data_mode",
  device_id: "device_id",
  doc_banner_closed: "doc_banner_closed", // used to indicate that the user has closed the documentation banner
};

// headers type of axios response is typed any
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const copyDownloadHeadersToResponse = (headers: any, res: NextApiResponse) => {
  copyHeaderIfPresent(headers, res, "content-type");
  copyHeaderIfPresent(headers, res, "transfer-encoding");
  copyHeaderIfPresent(headers, res, "connection");
  copyHeaderIfPresent(headers, res, "content-disposition");
  copyHeaderIfPresent(headers, res, "strict-transport-security");
  copyHeaderIfPresent(headers, res, "x-request-id");
};

const copyHeaderIfPresent = (headers: any, res: NextApiResponse, key: string) => {
  if (headers[key]) {
    res.setHeader(key, headers[key]);
  }
};
