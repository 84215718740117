import classNames from "classnames";
import { useIntl } from "react-intl";

import { defaultIconClassNames, IconProps } from "./Icon";

export default function ChevronUpIcon({ size = 10, className = "" }: IconProps) {
  const intl = useIntl();

  return (
    <svg
      width={size}
      height={size}
      fill="none"
      className={classNames(defaultIconClassNames, className)}
      xmlns="http://www.w3.org/2000/svg"
      aria-label={intl.formatMessage({ defaultMessage: "Chevron up", id: "yDqlf+" })}
    >
      <path d="M1 7.5L9 7.5L5 3.5L1 7.5Z" />
    </svg>
  );
}
