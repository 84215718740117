import classNames from "classnames";
import { useIntl } from "react-intl";

import { defaultIconClassNames, IconProps } from "./Icon";

export default function FileColorIcon({ size = 16, className = "" }: IconProps) {
  const intl = useIntl();
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 16 16"
      fill="none"
      className={classNames(defaultIconClassNames, className)}
      xmlns="http://www.w3.org/2000/svg"
      aria-label={intl.formatMessage({ defaultMessage: "File color", id: "4YDwMu" })}
    >
      <path d="M10 0.666748H2.66667C1.93334 0.666748 1.33334 1.26675 1.33334 2.00008V10.6667C1.33334 11.0334 1.63334 11.3334 2 11.3334C2.36667 11.3334 2.66667 11.0334 2.66667 10.6667V2.66675C2.66667 2.30008 2.96667 2.00008 3.33334 2.00008H10C10.3667 2.00008 10.6667 1.70008 10.6667 1.33341C10.6667 0.966748 10.3667 0.666748 10 0.666748ZM10.3933 3.72675L13.6133 6.94675C13.86 7.19341 14 7.53341 14 7.88675V14.0001C14 14.7334 13.4 15.3334 12.6667 15.3334H5.32667C4.59334 15.3334 4 14.7334 4 14.0001L4.00667 4.66675C4.00667 3.93341 4.6 3.33341 5.33334 3.33341H9.44667C9.8 3.33341 10.14 3.47341 10.3933 3.72675ZM10 8.00008H13L9.33334 4.33341V7.33341C9.33334 7.70008 9.63334 8.00008 10 8.00008Z" />
    </svg>
  );
}
