import "@design-tokens/css/color-variables.scss";
import "@/styles/autosuggest.css";
import "@/styles/forms.css";
import "@/styles/globals.css";
import "@/styles/react-colorful.scss";
import "@/styles/toastify/main.scss";
import "@/styles/world-map.css";
import "intersection-observer";

import { UserProvider } from "@auth0/nextjs-auth0";
import { initForBrowser } from "@fonoa/location-code-to-name";
import * as Sentry from "@sentry/react";
import { AppProps } from "next/app";
import Head from "next/head";
import { FC, Fragment, PropsWithChildren, useEffect } from "react";
import { IntlProvider } from "react-intl";
import { ToastContainer } from "react-toastify";
import ReactHeap from "reactjs-heap";

import { NotificationsManagerProvider } from "@/components/Notifications/NotificationsManager";
import EnhancedJoyride from "@/components/Tours/EnhancedJoyride";
import { mapTagToRange } from "@/components/Utils/Date";
import VideoModal from "@/components/VideoModal/VideoModal";
import ErrorProxy from "@/features/Error/ErrorProxy";
import { TinSearchContextProviderConnected } from "@/features/Lookup/components/CsvValidation/TinSearchBatchesContextProvider";
import { ValidationContextProviderConnected } from "@/features/Lookup/components/CsvValidation/ValidationsContextProvider";
import { ProvideProductAuth } from "@/hooks/auth";
import { FeatureFlagsProviderConnected } from "@/hooks/featureFlags/FeatureFlags";
import { AbilityProvider } from "@/hooks/useAbility";
import { BackgroundTasksProvider } from "@/hooks/useBackgroundTask";
import { DemoDataModeProvider } from "@/hooks/useDemoDataMode";
import { GuidedTourProvider } from "@/hooks/useGuidedTour";
import { useLocaleTranslation } from "@/hooks/useLocaleTranslation";
import { SupportedBrowsers } from "@/hooks/useSupportedBrowsers";
import { polyfillFormatJS } from "@/lib/polyfills/formatjs";
import { WithSentryCustomTags } from "@/lib/sentry";
import setupAxiosInterceptors from "@/lib/setupAxiosInterceptors";
import { Environment, getEnvironment } from "@/lib/utils";
import config from "@/server/config";
import { getUserLanguage } from "@/utils/getUserLanguage";
import { trpc } from "@/utils/trpc";
import { ZendeskScript } from "@/utils/zendesk";

setupAxiosInterceptors();
polyfillFormatJS("en");

const SafeHydrate: FC<PropsWithChildren<unknown>> = ({ children }) => (
  <div suppressHydrationWarning>{typeof window === "undefined" ? null : children}</div>
);

const validationRange = mapTagToRange("last_24_hours");

function MyApp({ Component, pageProps }: AppProps) {
  const environment = getEnvironment();
  const isNotLocalOrDevEnvironment = !(["local", "dev"] as Environment[]).includes(environment);
  const supportedLanguages = ["en"];
  const userLocale = getUserLanguage(supportedLanguages) || "en";
  try {
    initForBrowser();
  } catch (cause) {
    Sentry.captureException(
      new Error(
        `Unable to initiate @fonoa/location-code-to-name for browser environment. Translations of US Regional FIPS codes might not work.`,
        { cause }
      )
    );
  }
  const translation = useLocaleTranslation(userLocale);

  const translations = process.env.NEXT_PUBLIC_INTL
    ? JSON.parse(process.env.NEXT_PUBLIC_INTL)
    : translation;

  useEffect(() => {
    ReactHeap.initialize(config.HEAP_ID());
  }, []);

  return (
    <>
      <Head>
        {/* eslint-disable-next-line @nx/workspace/no-raw-value */}
        <title>Fonoa Dashboard</title>
      </Head>
      <IntlProvider
        locale={userLocale}
        defaultLocale="en"
        textComponent={Fragment}
        messages={translations}
      >
        <SupportedBrowsers>
          <SafeHydrate>
            <UserProvider>
              <FeatureFlagsProviderConnected>
                <NotificationsManagerProvider>
                  <AbilityProvider>
                    <DemoDataModeProvider>
                      <GuidedTourProvider>
                        <ProvideProductAuth>
                          <BackgroundTasksProvider>
                            <ValidationContextProviderConnected
                              validationRange={validationRange}
                              startValidating={isNotLocalOrDevEnvironment}
                            >
                              <TinSearchContextProviderConnected
                                tinSearchesRange={validationRange}
                                startFetching={isNotLocalOrDevEnvironment}
                              >
                                <Sentry.ErrorBoundary
                                  fallback={({ resetError }) => (
                                    <ErrorProxy resetError={resetError} />
                                  )}
                                  showDialog
                                >
                                  <WithSentryCustomTags>
                                    <Component {...pageProps} />
                                    <VideoModal.Component />
                                    <EnhancedJoyride />
                                    <ToastContainer />
                                    <ZendeskScript />
                                  </WithSentryCustomTags>
                                </Sentry.ErrorBoundary>
                              </TinSearchContextProviderConnected>
                            </ValidationContextProviderConnected>
                          </BackgroundTasksProvider>
                        </ProvideProductAuth>
                      </GuidedTourProvider>
                    </DemoDataModeProvider>
                  </AbilityProvider>
                </NotificationsManagerProvider>
              </FeatureFlagsProviderConnected>
            </UserProvider>
          </SafeHydrate>
        </SupportedBrowsers>
      </IntlProvider>
    </>
  );
}
export default trpc.withTRPC(MyApp);
