import classNames from "classnames";
import { useIntl } from "react-intl";

import { defaultIconClassNames, IconProps } from "./Icon";

export default function CopyIcon({ size = 14, className = "", onClick }: IconProps) {
  const intl = useIntl();
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 14 14"
      fill="none"
      className={classNames(defaultIconClassNames, className)}
      onClick={onClick}
      xmlns="http://www.w3.org/2000/svg"
      aria-label={intl.formatMessage({ defaultMessage: "Copy", id: "4l6vz1" })}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.58064 0C0.707679 0 0 0.707679 0 1.58065V6.26613V7.90323C0 8.77619 0.707679 9.48387 1.58065 9.48387H2.54C2.91413 9.48387 3.21 9.18058 3.21 8.80645C3.21 8.43232 2.91413 8.12903 2.54 8.12903H1.58065C1.45594 8.12903 1.35484 8.02794 1.35484 7.90323V6.26613V1.58065C1.35484 1.45594 1.45594 1.35484 1.58064 1.35484H6.26613H7.90323C8.02794 1.35484 8.12903 1.45594 8.12903 1.58065V2.25806V2.54C8.12903 2.91413 8.43232 3.21 8.80645 3.21C9.18058 3.21 9.48387 2.91413 9.48387 2.54V2.25806V1.58065C9.48387 0.707679 8.77619 0 7.90323 0H6.26613H1.58064ZM6.09677 4.51613C5.22381 4.51613 4.51613 5.22381 4.51613 6.09677V12.4194C4.51613 13.2923 5.22381 14 6.09677 14H12.4194C13.2923 14 14 13.2923 14 12.4194V6.09677C14 5.22381 13.2923 4.51613 12.4194 4.51613H6.09677ZM5.87097 6.09677C5.87097 5.97206 5.97206 5.87097 6.09677 5.87097H12.4194C12.5441 5.87097 12.6452 5.97206 12.6452 6.09677V12.4194C12.6452 12.5441 12.5441 12.6452 12.4194 12.6452H6.09677C5.97206 12.6452 5.87097 12.5441 5.87097 12.4194V6.09677Z"
      />
    </svg>
  );
}
