import { noop } from "@fonoa/ui-components/utils";
import { useRouter } from "next/router";
import { createContext, ReactNode, useContext, useEffect, useState } from "react";
import { useIntl } from "react-intl";

import { useNotificationsDispatcher } from "@/components/Notifications/NotificationsManager";
import { DatesRange, mapTagToRange } from "@/components/Utils/Date";
import { buildBatchTinSearchNotification } from "@/features/Lookup/components/CsvValidation/TinSearchNotifications";
import { useBatchTinSearches } from "@/features/Lookup/CsvUploadTinSearch/CsvUploadTinSearch.api";
import { useAuth, useTenant } from "@/hooks/auth";
import { useDemoDataMode } from "@/hooks/useDemoDataMode";
import { Subproducts } from "@/lib/product";
import { TinSearchBatchesResponse } from "@/server/trpc/routers/lookup/getTinSearchBatches";

export type TinSearchStatus = "idle" | "fetching";

export interface TinSearchState {
  status: TinSearchStatus;
  isLoading: boolean;
  tinSearchBatches: TinSearchBatchesResponse | undefined;
  error?: Error;
  fetchTinSearches: () => void;
}

const INITIAL_STATE: TinSearchState = {
  status: "idle",
  isLoading: true,
  tinSearchBatches: undefined,
  fetchTinSearches: noop,
};

const TinSearchContext = createContext<TinSearchState>(INITIAL_STATE);

const requestIntervalInMs = 2500;

export interface TinSearchContextProviderProps {
  tinSearchesRange?: DatesRange;
  children: ReactNode;
  enabled: boolean;
  startFetching?: boolean;
  retry?: number;
}

const defaultTinSearchesRange = mapTagToRange("last_24_hours");
export const TinSearchContextProvider = ({
  children,
  tinSearchesRange = defaultTinSearchesRange,
  enabled,
  startFetching = true,
  retry = 3,
}: TinSearchContextProviderProps) => {
  const intl = useIntl();
  const router = useRouter();
  const notificationsDispatcher = useNotificationsDispatcher();
  const { initialised: demoDataModeInitialised } = useDemoDataMode();
  const { from } = tinSearchesRange;
  const [shouldFetchTinSearches, setShouldFetchTinSearches] = useState(startFetching);
  const [itWasInProgress, setItWasInProgress] = useState<Set<string>>(new Set());
  const [state, setState] = useState<TinSearchState>({
    status: "idle",
    isLoading: false,
    tinSearchBatches: undefined,
    fetchTinSearches: () => setShouldFetchTinSearches(true),
  });
  const allBatchTinSearches = state.tinSearchBatches?.data || [];
  const tinSearchesInProgress = allBatchTinSearches.filter((batch) => batch.status === "running");

  const { data, error, failureCount, dataUpdatedAt } = useBatchTinSearches({
    options: {
      enabled:
        enabled &&
        (shouldFetchTinSearches || state.status === "fetching") &&
        demoDataModeInitialised,
      refetchInterval: requestIntervalInMs,
      retry,
    },
    variables: { date_from: from, sort: "search_request_date.desc" },
  });

  useEffect(() => {
    allBatchTinSearches
      .filter(
        (batch) => batch.status === "running" || itWasInProgress.has(batch.tin_search_batch_id)
      )
      .forEach((batch) => {
        notificationsDispatcher({
          type: "PUSH",
          payload: buildBatchTinSearchNotification(batch, intl, router),
        });
      }); // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allBatchTinSearches, itWasInProgress]);

  useEffect(() => {
    setState({
      ...state,
      status: failureCount >= retry ? "idle" : state.status,
      error: (error as Error) || undefined,
    }); // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error, failureCount]);

  useEffect(() => {
    if (!data) return;
    const hasRunningTinSearches = data.data.some((batch) => batch.status === "running");

    setItWasInProgress(
      new Set([
        ...itWasInProgress,
        ...tinSearchesInProgress.map((batch) => batch.tin_search_batch_id),
      ])
    );

    setState({
      ...state,
      tinSearchBatches: data,
      status: hasRunningTinSearches ? "fetching" : "idle",
      isLoading: false,
    }); // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, dataUpdatedAt]); // `dataUpdatedAt` is required so the fn gets called even after a batch is updated from the UI with the exact same name

  useEffect(() => {
    if (shouldFetchTinSearches) {
      setState({ ...state, status: "fetching", isLoading: true });
      setShouldFetchTinSearches(false);
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldFetchTinSearches]);

  return <TinSearchContext.Provider value={state}>{children}</TinSearchContext.Provider>;
};

function useIsTinSearchContextEnabled() {
  const auth = useAuth();
  const { data: tenant } = useTenant();

  return (
    Boolean(auth.user) &&
    Boolean(tenant?.products?.find(({ name }) => name === Subproducts.REVERSE_LOOKUP)?.name)
  );
}

export const TinSearchContextProviderConnected = (
  props: Omit<TinSearchContextProviderProps, "enabled">
) => {
  const enabled = useIsTinSearchContextEnabled();

  return <TinSearchContextProvider {...props} enabled={enabled} />;
};

export const TinSearchContextConsumer = ({
  children,
}: {
  children: (state: TinSearchState) => ReactNode;
}) => <TinSearchContext.Consumer>{children}</TinSearchContext.Consumer>;

export const useTinSearchContext = () => useContext<TinSearchState>(TinSearchContext);
