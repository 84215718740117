import classNames from "classnames";
import { useIntl } from "react-intl";

import { defaultIconClassNames, IconProps } from "./Icon";

export interface CircleCheckIconProps extends IconProps {
  withBackground?: boolean;
}

export default function CircleCheckIcon({
  withBackground = true,
  size = 20,
  className = "",
}: CircleCheckIconProps) {
  const intl = useIntl();
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 20 20"
      fill="none"
      className={classNames(defaultIconClassNames, className)}
      xmlns="http://www.w3.org/2000/svg"
      aria-label={intl.formatMessage({ defaultMessage: "Circle check", id: "P9v72N" })}
    >
      {withBackground && (
        <path
          d="M9.99986 16.4284C13.5503 16.4284 16.4284 13.5503 16.4284 9.99986C16.4284 6.44946 13.5503 3.57129 9.99986 3.57129C6.44946 3.57129 3.57129 6.44946 3.57129 9.99986C3.57129 13.5503 6.44946 16.4284 9.99986 16.4284Z"
          fill="white"
        />
      )}
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.99979 2.85693C6.06122 2.85693 2.85693 6.06122 2.85693 9.99979C2.85693 13.9384 6.06122 17.1426 9.99979 17.1426C13.9384 17.1426 17.1426 13.9384 17.1426 9.99979C17.1426 6.06122 13.9384 2.85693 9.99979 2.85693ZM9.99979 15.7141C6.84908 15.7141 4.2855 13.1505 4.2855 9.99979C4.2855 6.84908 6.84908 4.28551 9.99979 4.28551C13.1505 4.28551 15.7141 6.84908 15.7141 9.99979C15.7141 13.1505 13.1505 15.7141 9.99979 15.7141ZM11.6376 8.06622L9.2855 10.4184L8.36193 9.49479C8.08265 9.21551 7.63122 9.21551 7.35193 9.49479C7.07265 9.77408 7.07265 10.2255 7.35193 10.5048L8.7805 11.9334C8.91979 12.0726 9.10265 12.1426 9.2855 12.1426C9.46836 12.1426 9.65122 12.0726 9.7905 11.9334L12.6476 9.07622C12.9269 8.79693 12.9269 8.34551 12.6476 8.06622C12.3684 7.78693 11.9169 7.78693 11.6376 8.06622Z"
      />
    </svg>
  );
}
