import { useUser, withPageAuthRequired } from "@auth0/nextjs-auth0";
import { subject } from "@casl/ability";
import type { DashboardUser } from "@fonoa/data-access/auth0";
import { Actions, AppAbility, Subjects } from "@fonoa/permissions";
import { NextRouter } from "next/dist/shared/lib/router/router";
import Router, { useRouter } from "next/router";
import { ComponentType, FC, PropsWithChildren, useEffect, useMemo, useState } from "react";

import { useAbility } from "@/hooks/useAbility";
import { useHasMounted } from "@/hooks/useHasMounted";
import { useAreProductsActive, useAreSubproductsActive } from "@/hooks/useIsProductActive";
import { useRoles } from "@/lib/api";
import { Product, Products, Subproducts } from "@/lib/product";
import { routes, RouteValueType } from "@/lib/routes";
import { isBrowser, localStorageKeys } from "@/lib/utils";
import { HeapAnalytics } from "@/utils/heap";
import { isUrlAbsolute } from "@/utils/helpers";
import { isTRPCClientError, trpc } from "@/utils/trpc";

export interface AuthContextProps {
  user: DashboardUser | null;
  isLoading: boolean;
  getInitialUserData: () => void;
  refreshUserData: () => void;
}

export const ProvideProductAuth: FC<PropsWithChildren<unknown>> = ({ children }) => {
  const hasMounted = useHasMounted();
  const router = useRouter();

  const {
    products,
    isLoading: activeProductsAreLoading,
    isFetching: activeProductsAreFetching,
  } = useAreProductsActive();

  const {
    subproducts,
    isLoading: activeSubproductsAreLoading,
    isFetching: activeSubproductsAreFetching,
  } = useAreSubproductsActive();

  useEffect(() => {
    if (activeProductsAreLoading || activeSubproductsAreLoading) {
      return;
    }

    const isRouteUnauthorized = products.find(
      (product) =>
        !product.isActive &&
        router.pathname.startsWith(`/${product.product}/`) &&
        product.product !== Products.LOOKUP
    );

    const isLookupValidationUnauthorized = products.find(
      (product) => product.product === Products.LOOKUP && !product.isActive
    );

    const isLookupTinSearchUnauthorized = subproducts.find(
      (subproduct) => subproduct.subproduct === Subproducts.REVERSE_LOOKUP && !subproduct.isActive
    );

    const isLookupUnauthorized =
      isLookupValidationUnauthorized &&
      isLookupTinSearchUnauthorized &&
      router.pathname.startsWith(`/lookup/`);

    // Check if e-invoicing is unauthorized (user has neither reporting nor invoicing permissions)
    const reportingUnauthorized = products.find(
      (product) => product.product === Products.REPORTING && !product.isActive
    );
    const invoicingUnauthorized = products.find(
      (product) => product.product === Products.INVOICING && !product.isActive
    );
    const isEInvoicingUnauthorized =
      reportingUnauthorized && invoicingUnauthorized && router.pathname.startsWith(`/e-invoicing/`);

    if (isRouteUnauthorized || isEInvoicingUnauthorized || isLookupUnauthorized) {
      redirectAfterLogin(router, routes.dashboard);
    }
  }, [products, router, activeProductsAreLoading]);

  if (!hasMounted) {
    return null;
  }

  // Return null until router is ready and active products are fetched to prevent flashing of unauthorized product pages
  if (
    !router.isReady ||
    (activeProductsAreLoading &&
      activeProductsAreFetching &&
      activeSubproductsAreLoading &&
      activeSubproductsAreFetching)
  ) {
    return null;
  }

  return <>{children}</>;
};

export const useAuth = (): AuthContextProps => {
  const { user: triggerUser } = useUser();
  const [currentUser, setCurrentUser] = useState<DashboardUser | null>();

  const {
    data: user,
    refetch: refetchUser,
    isInitialLoading,
  } = trpc.user.whoami.useQuery(undefined, {
    enabled: Boolean(triggerUser),
    onError: (error) => {
      if (isTRPCClientError(error) && error.data?.code === "UNAUTHORIZED" && isBrowser) {
        document.dispatchEvent(new Event("auth.signout"));
      }
    },
    onSuccess: (data) => {
      setCurrentUser(data);
    },
  });

  useEffect(() => {
    if (currentUser) {
      HeapAnalytics.identify({
        email: currentUser.email,
        tenant: currentUser.tenantSlug,
        demo_mode: currentUser.demoMode?.toString() || "false",
        user_id: currentUser.id,
        product_updates: currentUser.settings.productUpdateEmails.toString() || "false",
      });
    }
  }, [currentUser?.id]);

  return useMemo(
    () => ({
      user: user ?? null,
      isLoading: isInitialLoading,
      getInitialUserData: refetchUser,
      refreshUserData: refetchUser,
    }),
    [isInitialLoading, refetchUser, user]
  );
};

export const useTenant = () => {
  const auth = useAuth();

  return trpc.user.tenant.useQuery(undefined, { enabled: Boolean(auth.user) });
};

export const withoutAuthentication = <TProps extends Record<string, unknown>>(
  WrappedComponent: ComponentType<TProps>
) => {
  const RequiresNonAuthentication = (props: TProps) => {
    const auth = useAuth();
    const router = useRouter();

    if (auth.isLoading) {
      return null;
    }

    if (auth.user) {
      redirectAfterLogin(router, routes.dashboard);
      return null;
    }

    return <WrappedComponent {...props} />;
  };

  return RequiresNonAuthentication;
};

export const withAuthentication = <TProps extends Record<string, unknown>>(
  WrappedComponent: ComponentType<TProps>
) => {
  return (props: TProps) => (
    <WithAuthentication>
      <WrappedComponent {...props} />
    </WithAuthentication>
  );
};

const canViewProductPage = (path: string, ability: AppAbility) => {
  if (path === routes.dashboard) {
    return ability.can("view", subject("homepage", { product: "dashboard" }));
  }

  // Allow access to e-invoicing page for reporting or invoicing permissions
  if (path.startsWith(routes.einvoicing)) {
    return ability.can("view", subject("product", { product: "reporting" || "invoicing" }));
  }

  // Allow any role access to the settings pages
  if (path.startsWith(routes.settings)) {
    switch (path) {
      case routes.settingsTeam:
      case routes.settingsRoles:
        return ability.can("view", subject("users", { product: "dashboard" }));
      default:
        return true;
    }
  }

  const productParam = path.split("/")[1]?.split("?")[0];

  return ability.can("view", subject("product", { product: productParam }));
};

const findFirstAuthorizedProduct = (ability: AppAbility): Product | undefined => {
  for (const key in Products) {
    const productName = Products[key as Uppercase<Product>];
    if (ability.can("view", subject("product", { product: productName }))) {
      return productName;
    }
  }
};

const logout = () => {
  localStorage.removeItem(localStorageKeys.demo_data_mode);
  return Router.push("/api/auth/logout");
};

export const WithAuthenticationComponentConnected = ({ children }: PropsWithChildren) => {
  const auth = useAuth();
  const { isInitialLoading: areRolesLoading } = useRoles({});

  return (
    <WithAuthenticationComponent auth={auth} areRolesLoading={areRolesLoading}>
      {children}
    </WithAuthenticationComponent>
  );
};

export const WithAuthenticationComponent = ({
  auth,
  areRolesLoading,
  children,
}: PropsWithChildren<{ auth: AuthContextProps; areRolesLoading: boolean }>) => {
  const router = useRouter();
  const ability = useAbility();

  useEffect(() => {
    document.addEventListener("auth.signout", logout);
    return () => {
      document.removeEventListener("auth.signout", logout);
    };
  }, []);

  useEffect(() => {
    // take no effect is `auth` is still "processing"
    // OR if roles are not loaded yet
    if (auth.isLoading || areRolesLoading) {
      return;
    }

    // There is a race condition, when `user` is already fetched, and has `conditionalRoles`,
    // but `ability` is not yet built with those roles, wait until it is built
    const userHasConditionalRolesButAbilityNotBuilt =
      (auth.user?.conditionalRoles ?? []).length > 0 && ability.rules.length === 0;

    if (userHasConditionalRolesButAbilityNotBuilt) {
      return;
    }

    let firstAuthorizedPath: RouteValueType = routes.dashboard;

    if (!ability.can("view", subject("homepage", { product: "dashboard" }))) {
      const firstAuthorizedProduct = findFirstAuthorizedProduct(ability);

      if (firstAuthorizedProduct) {
        firstAuthorizedPath = routes[firstAuthorizedProduct];
      } else {
        // Fall back to redirecting to the settings page in case an account has no product page view permission
        firstAuthorizedPath = routes.settingsDetails;
      }
    }

    // if `abilitySubject` was provided, and we cannot view it, pop off to the first accessible page if not already there
    if (!canViewProductPage(router.pathname, ability)) {
      if (router.pathname !== firstAuthorizedPath) {
        redirectAfterLogin(router, firstAuthorizedPath);
      }
    }
  }, [ability, areRolesLoading, auth.user, auth.isLoading, router]);

  useEffect(() => {
    document.addEventListener("auth.refresh", auth.refreshUserData);
    return () => {
      document.removeEventListener("auth.refresh", auth.refreshUserData);
    };
  }, [auth.refreshUserData]);

  if (!auth.user) {
    return null;
  }

  if (canViewProductPage(router.pathname, ability)) {
    return <>{children}</>;
  }

  return null;
};

export const WithAuthentication = withPageAuthRequired(WithAuthenticationComponentConnected);

export function redirectAfterLogin(router: NextRouter, fallbackRoute: string) {
  if (router.query?.redirect && !isUrlAbsolute(router.query?.redirect as string)) {
    router.push(router.query.redirect as string);
    return;
  }

  router.push(fallbackRoute);
}

export const useRedirectIfNoPermission = ({
  requiredPermission,
  redirectTo = routes.settingsDetails,
}: {
  requiredPermission: { action: Actions; subject: Subjects };
  redirectTo?: string;
}) => {
  const router = useRouter();

  const ability = useAbility();
  const { isInitialLoading: areRolesLoading } = useRoles({});

  // TODO: Figure out how we can help TS here
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const hasPermission = ability.can(requiredPermission.action, requiredPermission.subject);

  useEffect(() => {
    if (areRolesLoading) {
      return;
    }

    if (!hasPermission) {
      router.push(redirectTo);
    }
  }, [areRolesLoading, hasPermission, redirectTo]);

  return null;
};
