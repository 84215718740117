import classNames from "classnames";

import { defaultIconClassNames, IconProps } from "./Icon";

export default function PlaceIcon({ size = 16, className = "" }: IconProps) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 14 21"
      fill="none"
      className={classNames(defaultIconClassNames, className)}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7 0.859375C3.13 0.859375 0 3.98938 0 7.85938C0 13.1094 7 20.8594 7 20.8594C7 20.8594 14 13.1094 14 7.85938C14 3.98938 10.87 0.859375 7 0.859375ZM7 10.3594C5.62 10.3594 4.5 9.23938 4.5 7.85938C4.5 6.47937 5.62 5.35938 7 5.35938C8.38 5.35938 9.5 6.47937 9.5 7.85938C9.5 9.23938 8.38 10.3594 7 10.3594Z"
        fill="#627C91"
      />
    </svg>
  );
}
