import classNames from "classnames";
import { ReactNode, useState } from "react";

import { CloseIcon, HamburgerIcon } from "../icons";

export function GenericSidebar({ children }: { children: ReactNode }) {
  const [open, setOpen] = useState(false);

  return (
    <div className="dark">
      <div
        data-cy="sidebar"
        className={classNames(
          "sidebar bg-blueGray50 dark:bg-blueGray900 fixed inset-y-0 z-20 flex text-white lg:w-64",
          {
            "w-full": open,
            "w-10": !open,
          }
        )}
      >
        <div
          data-cy="sidebar-menu-icon"
          onClick={() => setOpen(!open)}
          className={classNames("absolute cursor-pointer select-none px-2 pt-4 lg:hidden", {
            "right-0": open,
          })}
        >
          {!open && <HamburgerIcon size={24} />}
          {open && <CloseIcon />}
        </div>
        <div
          className={classNames("flex flex-col justify-between pb-6 lg:w-full", {
            "w-full": open,
            "w-0 overflow-hidden": !open,
          })}
        >
          {children}
        </div>
      </div>
    </div>
  );
}
