import classNames from "classnames";
import { useIntl } from "react-intl";

import { defaultIconClassNames, IconProps } from "./Icon";

export default function DeleteOutlineIcon({ size = 16, className = "", onClick }: IconProps) {
  const intl = useIntl();
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 18 18"
      fill="none"
      className={classNames(defaultIconClassNames, className)}
      onClick={onClick}
      xmlns="http://www.w3.org/2000/svg"
      aria-label={intl.formatMessage({ defaultMessage: "Delete", id: "K3r6DQ" })}
    >
      <path d="M4.5 14.75C4.5 15.575 5.175 16.25 6 16.25H12C12.825 16.25 13.5 15.575 13.5 14.75V5.75H4.5V14.75ZM6 7.25H12V14.75H6V7.25ZM11.625 3.5L10.875 2.75H7.125L6.375 3.5H3.75V5H14.25V3.5H11.625Z" />
    </svg>
  );
}
