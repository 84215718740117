import { RefObject, useEffect } from "react";

export default function useClickOutside(
  ref: RefObject<HTMLElement>,
  callback: () => void,
  { capture }: EventListenerOptions = { capture: true }
): void {
  const handleClick = (e: DocumentEventMap["click"]) => {
    if (ref.current && e.target instanceof Node && !ref.current.contains(e.target)) {
      callback();
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClick, { capture });
    return () => {
      document.removeEventListener("click", handleClick);
    };
  });
}
