import classNames from "classnames";
import { useIntl } from "react-intl";

import { defaultIconClassNames, IconProps } from "./Icon";

export default function GlobeOutlineIcon({ size = 16, className = "" }: IconProps) {
  const intl = useIntl();
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 16 16"
      fill="none"
      className={classNames(defaultIconClassNames, className)}
      xmlns="http://www.w3.org/2000/svg"
      aria-label={intl.formatMessage({ defaultMessage: "Globe outline", id: "UqeBfU" })}
    >
      <path d="M14.9998 8C14.9998 7.08075 14.8188 6.17049 14.467 5.32122C14.1152 4.47194 13.5996 3.70026 12.9496 3.05025C12.2996 2.40024 11.5279 1.88463 10.6786 1.53284C9.82932 1.18106 8.91907 1 7.99982 1C6.1433 1 4.36282 1.7375 3.05007 3.05025C1.73731 4.36301 0.999817 6.14348 0.999817 8C0.999817 9.85651 1.73731 11.637 3.05007 12.9497C4.36282 14.2625 6.1433 15 7.99982 15C9.85633 15 11.6368 14.2625 12.9496 12.9497C14.2623 11.637 14.9998 9.85651 14.9998 8ZM13.5508 7.3H11.4998C11.3844 5.6537 10.8203 4.07045 9.86882 2.722C10.8463 3.06918 11.7082 3.68105 12.3584 4.48939C13.0085 5.29773 13.4213 6.27077 13.5508 7.3ZM5.95582 8.7H10.0998C9.92068 10.4294 9.18355 12.0535 7.99982 13.327C6.81687 12.0589 6.09665 10.4285 5.95582 8.7ZM5.95582 7.3C6.11884 5.57928 6.83613 3.958 7.99982 2.68C9.19312 3.94429 9.93191 5.56963 10.0998 7.3H5.95582ZM6.20782 2.701C5.2513 4.05692 4.67818 5.6457 4.54882 7.3H2.44882C2.58105 6.25805 3.00364 5.27429 3.66833 4.46106C4.33302 3.64784 5.21302 3.03793 6.20782 2.701ZM2.44882 8.7H4.54882C4.6641 10.3529 5.23333 11.9419 6.19382 13.292C5.2026 12.9534 4.32621 12.3436 3.6642 11.5319C3.0022 10.7202 2.58113 9.73906 2.44882 8.7ZM9.83382 13.285C10.7917 11.9345 11.3673 10.3504 11.4998 8.7H13.5648C13.4324 9.73617 13.0129 10.7147 12.3536 11.5249C11.6943 12.3351 10.8215 12.9448 9.83382 13.285Z" />
    </svg>
  );
}
