export const encodeBase64 = (str: string) => {
  return Buffer.from(str).toString("base64");
};

export const decodeBase64 = (str: string) => {
  return Buffer.from(str, "base64").toString();
};

export const getFileNameFromHeader = (contentDisposition: string) => {
  let fileName = "";

  if (contentDisposition) {
    const split = contentDisposition.split('filename="');
    if (split.length == 2) {
      fileName = split[1].substring(0, split[1].length);

      fileName = fileName.endsWith('"') ? fileName.substring(0, fileName.length - 1) : fileName;
    }
  }

  return fileName;
};

export const isUrlAbsolute = (url: string) =>
  url.indexOf("//") === 0
    ? true
    : url.indexOf("://") === -1
    ? false
    : url.indexOf(".") === -1
    ? false
    : url.indexOf("/") === -1
    ? false
    : url.indexOf(":") > url.indexOf("/")
    ? false
    : url.indexOf("://") < url.indexOf(".")
    ? true
    : false;
