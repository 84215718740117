import { DataAttributes, SpacingProps } from "@fonoa/ui-components/types";
import { convertDataAttributes, getSpacingClass } from "@fonoa/ui-components/utils";
import classNames from "classnames";
import { ReactNode } from "react";

interface SwitchProps extends SpacingProps {
  active?: boolean;
  title?: ReactNode;
  titleClassName?: string;
  onChange: (value: boolean) => void;
  variant?: SwitchVariant;
  disabled?: boolean;
  locked?: boolean;
  dataAttributes?: DataAttributes;
}

type SwitchVariant = "default" | "tangerine";

const VARIANT_MAPS: Record<SwitchVariant, { active: string; inactive: string; locked: string }> = {
  default: {
    active:
      "border-primaryBlue500 bg-primaryBlue500 active:border-primaryBlue500 active:bg-primaryBlue500 hover:border-primaryBlue600 hover:bg-primaryBlue600",
    inactive:
      "border-blueGray200 bg-blueGray200 active:border-gray60 active:bg-gray60 hover:border-blueGray100 hover:bg-blueGray100",
    locked:
      "border-primaryBlue500 bg-primaryBlue500 active:border-primaryBlue500 active:bg-primaryBlue500",
  },
  tangerine: {
    active:
      "border-tangerine600 bg-tangerine600 active:border-tangerine600 active:bg-tangerine600 hover:border-tangerine700 hover:bg-tangerine700",
    inactive:
      "border-blueGray200 bg-blueGray200 active:border-gray60 active:bg-gray60 hover:border-blueGray100 hover:bg-blueGray100",
    locked: "border-tangerine600 bg-tangerine600 active:border-tangerine600 active:bg-tangerine600",
  },
};

export default function Switch({
  active,
  onChange,
  title,
  spacing,
  titleClassName,
  variant = "default",
  disabled,
  locked = false,
  dataAttributes,
}: SwitchProps) {
  const variantClassNames = VARIANT_MAPS[variant];
  const cursorStyle = disabled ? "cursor-not-allowed" : "cursor-pointer";

  const dataAttr = convertDataAttributes(dataAttributes);

  return (
    <div
      {...dataAttr}
      className={classNames("flex items-center", cursorStyle)}
      onClick={() => !disabled && onChange(!active)}
    >
      <div
        className={classNames(
          "box-content inline-block h-4 w-7 rounded-full border align-middle transition",
          getSpacingClass(spacing),
          cursorStyle,
          {
            [variantClassNames.active]: active && !locked,
            [variantClassNames.inactive]: !active,
            [variantClassNames.locked]: active && locked,
          }
        )}
      >
        <div
          className={classNames("mb-1 flex h-4 w-4 rounded-full bg-white transition-all", {
            "ml-3": active,
          })}
        />
      </div>
      {title ? (
        <span className={classNames("ml-2 select-none align-middle", titleClassName)}>{title}</span>
      ) : null}
    </div>
  );
}
