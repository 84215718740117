import classNames from "classnames";
import { useIntl } from "react-intl";

import { defaultIconClassNames, IconProps } from "./Icon";

export default function ChevronDownIcon({ size = 10, className = "" }: IconProps) {
  const intl = useIntl();
  return (
    <svg
      width={size}
      height={size}
      fill="none"
      className={classNames(defaultIconClassNames, className)}
      xmlns="http://www.w3.org/2000/svg"
      aria-label={intl.formatMessage({ defaultMessage: "Chevron down", id: "yj23Ms" })}
    >
      <path d="M7.1998 2.8999H0.799805L3.9998 6.0999L7.1998 2.8999Z" />
    </svg>
  );
}
