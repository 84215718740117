import { noop } from "@fonoa/ui-components/utils";
import { createContext, ReactNode, useContext, useState } from "react";

import { useAuth } from "@/hooks/auth";
import { localStorageKeys } from "@/lib/utils";
import { trpc } from "@/utils/trpc";

const defaultValue = false;

type RefreshDemoModeFn = (clientDemoMode: boolean, userDemoMode: boolean) => void;

const Context = createContext<{
  isDemoDataMode: boolean;
  toggleDemoMode: () => void;
  refreshDemoMode: RefreshDemoModeFn;
  initialised: boolean;
}>({
  isDemoDataMode: defaultValue,
  toggleDemoMode: noop,
  refreshDemoMode: noop,
  initialised: defaultValue,
});

export const DemoDataModeProvider = ({
  children,
  defaultInitialized,
}: {
  children: ReactNode;
  defaultInitialized?: boolean;
}) => {
  const auth = useAuth();

  const [demoDataMode, setDemoDataMode] = useState(defaultValue);
  const [initialised, setInitialised] = useState(defaultInitialized || defaultValue);

  const { mutateAsync: updateDemoMode } = trpc.user.updateDemoMode.useMutation();

  const toggleDemoMode = async () => {
    const isDemoMode = !demoDataMode;
    const dashboardUser = await updateDemoMode({ demoMode: isDemoMode });
    const doesProfileDemoModeMatchToggleValue = dashboardUser.demoMode === isDemoMode;
    if (!doesProfileDemoModeMatchToggleValue) {
      return;
    }

    await auth.refreshUserData();

    updateDemoModeInLocalStorage(isDemoMode);
    setDemoDataMode(isDemoMode);
  };

  const refreshDemoMode = (clientDemoMode: boolean, userDemoMode: boolean) => {
    if (!auth.user || auth.isLoading) {
      localStorage.removeItem(localStorageKeys.demo_data_mode);
    }

    const isDemoMode = clientDemoMode || userDemoMode;
    updateDemoModeInLocalStorage(isDemoMode);
    setDemoDataMode(isDemoMode);
    setInitialised(true);
  };

  const updateDemoModeInLocalStorage = (isDemoDataMode: boolean) => {
    localStorage.setItem(localStorageKeys.demo_data_mode, JSON.stringify(isDemoDataMode));
  };

  return (
    <Context.Provider
      value={{
        isDemoDataMode: demoDataMode,
        toggleDemoMode,
        refreshDemoMode,
        initialised,
      }}
    >
      {children}
    </Context.Provider>
  );
};

export const useDemoDataMode = () => {
  const context = useContext(Context);

  if (context === undefined) {
    throw new Error("useDemoDataMode was used outside of its context provider");
  }

  return context;
};
