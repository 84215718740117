import classNames from "classnames";
import { useIntl } from "react-intl";

import { defaultIconClassNames, IconProps } from "./Icon";

export default function EmptyInvoiceIcon({ size = 108, className }: IconProps) {
  const intl = useIntl();
  return (
    <svg
      width={size}
      height={size / 1.091}
      className={classNames(defaultIconClassNames, className)}
      viewBox="0 0 108 99"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      aria-label={intl.formatMessage({ defaultMessage: "Empty invoice", id: "ZEDRcx" })}
    >
      <path
        d="M13.7121 26H89.5384C92.5414 26 94.9009 28.3653 94.9009 31.3756V79.4336C94.9009 82.444 92.5414 84.8093 89.5384 84.8093H13.7121C10.7091 84.8093 8.34961 82.444 8.34961 79.4336V31.3756C8.34961 28.3653 10.8164 26 13.7121 26Z"
        fill="#C8D3DB"
      />
      <g filter="url(#filter0_b_6488:22267)">
        <path
          d="M70.0518 0H23.0518C20.8426 0 19.0518 1.79086 19.0518 4V88C19.0518 90.2091 20.8426 92 23.0518 92H84.5518C86.7609 92 88.5518 90.2091 88.5518 88V22.038C88.5518 21.048 88.1846 20.0932 87.5213 19.3582L70.0518 0Z"
          fill="#D7DEE3"
          fillOpacity="0.5"
        />
        <path
          d="M76.6028 7.25932L72.2355 2.41977L71.8643 2.75475L69.8295 0.5H66.1351V0H58.3018V0.5H50.4684V0H42.6351V0.5H34.8018V0H26.9684V0.5H23.0518C22.5763 0.5 22.1241 0.594542 21.7121 0.765385L21.5206 0.303522C20.5428 0.708984 19.7607 1.49109 19.3553 2.46885L19.8171 2.66038C19.6463 3.07236 19.5518 3.52451 19.5518 4V8.2H19.0518V16.6H19.5518V25H19.0518V33.4H19.5518V41.8H19.0518V50.2H19.5518V58.6H19.0518V67H19.5518V75.4H19.0518V83.8H19.5518V88C19.5518 88.4755 19.6463 88.9276 19.8171 89.3396L19.3553 89.5312C19.7607 90.5089 20.5428 91.291 21.5206 91.6965L21.7121 91.2346C22.1241 91.4055 22.5763 91.5 23.0518 91.5H26.8955V92H34.583V91.5H42.2705V92H49.958V91.5H57.6455V92H65.333V91.5H73.0205V92H80.708V91.5H84.5518C85.0273 91.5 85.4794 91.4055 85.8914 91.2346L86.0829 91.6965C87.0607 91.291 87.8428 90.5089 88.2482 89.5312L87.7864 89.3396C87.9572 88.9276 88.0518 88.4755 88.0518 88V83.8774H88.5518V75.6321H88.0518V67.3869H88.5518V59.1416H88.0518V50.8964H88.5518V42.6511H88.0518V34.4059H88.5518V26.1607H88.0518V22.038C88.0518 21.8223 88.0318 21.6086 87.993 21.3992L88.4846 21.308C88.395 20.8255 88.2173 20.3634 87.9606 19.9452L87.5345 20.2068C87.4231 20.0253 87.2946 19.8533 87.1501 19.6932L84.9664 17.2734L85.3376 16.9384L80.9702 12.0989L80.599 12.4338L76.2317 7.5943L76.6028 7.25932Z"
          fill="transparent"
          stroke="#627C91"
          strokeDasharray="8 8"
        />
      </g>
      <mask id="path-4-inside-1_6488:22267" fill="white">
        <path d="M70.0518 0V16.3224C70.0518 18.6 72.0331 20.4979 74.2975 20.4979H88.7328" />
      </mask>
      <path
        d="M70.0518 0V16.3224C70.0518 18.6 72.0331 20.4979 74.2975 20.4979H88.7328"
        fill="#D7DEE3"
      />
      <path
        d="M70.0518 0L70.4213 -0.336796L69.5518 -1.29092V0H70.0518ZM88.7328 20.4979V20.9979H89.865L89.1024 20.1611L88.7328 20.4979ZM69.5518 0V4.08061H70.5518V0H69.5518ZM69.5518 12.2418V16.3224H70.5518V12.2418H69.5518ZM69.5518 16.3224C69.5518 16.9596 69.6893 17.5691 69.9334 18.1243L70.8488 17.7217C70.6577 17.2873 70.5518 16.8144 70.5518 16.3224H69.5518ZM72.4998 20.6329C73.0583 20.8671 73.6672 20.9979 74.2975 20.9979V19.9979C73.8063 19.9979 73.3284 19.896 72.8865 19.7107L72.4998 20.6329ZM74.2975 20.9979H77.9063V19.9979H74.2975V20.9979ZM85.124 20.9979H88.7328V19.9979H85.124V20.9979ZM89.1024 20.1611L86.7672 17.5989L86.0281 18.2725L88.3633 20.8347L89.1024 20.1611ZM82.097 12.4744L77.4267 7.34993L76.6876 8.02352L81.3579 13.148L82.097 12.4744ZM72.7564 2.22545L70.4213 -0.336796L69.6822 0.336796L72.0173 2.89904L72.7564 2.22545ZM70.0518 0L70.7909 -0.673592L69.0518 -2.58183V0H70.0518ZM88.7328 20.4979V21.4979H90.9972L89.4719 19.8243L88.7328 20.4979ZM69.0518 0V4.08061H71.0518V0H69.0518ZM69.0518 12.2418V16.3224H71.0518V12.2418H69.0518ZM69.0518 16.3224C69.0518 17.0321 69.205 17.7101 69.4757 18.3256L71.3065 17.5204C71.142 17.1463 71.0518 16.7418 71.0518 16.3224H69.0518ZM72.3065 21.0941C72.9232 21.3526 73.5977 21.4979 74.2975 21.4979V19.4979C73.8758 19.4979 73.4635 19.4105 73.0798 19.2496L72.3065 21.0941ZM74.2975 21.4979H77.9063V19.4979H74.2975V21.4979ZM85.124 21.4979H88.7328V19.4979H85.124V21.4979ZM89.4719 19.8243L87.1368 17.2621L85.6586 18.6093L87.9937 21.1715L89.4719 19.8243ZM82.4665 12.1376L77.7963 7.01313L76.3181 8.36031L80.9883 13.4848L82.4665 12.1376ZM73.126 1.88865L70.7909 -0.673592L69.3127 0.673592L71.6478 3.23583L73.126 1.88865Z"
        fill="#627C91"
        mask="url(#path-4-inside-1_6488:22267)"
      />
      <line
        x1="30.5"
        y1="12.5"
        x2="62.5"
        y2="12.5"
        stroke="#C8D3DB"
        strokeWidth="3"
        strokeLinecap="round"
      />
      <line
        x1="30.5"
        y1="20.5"
        x2="38.5"
        y2="20.5"
        stroke="#C8D3DB"
        strokeWidth="3"
        strokeLinecap="round"
      />
      <line
        x1="30"
        y1="37"
        x2="49"
        y2="37"
        stroke="#C8D3DB"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <line
        x1="30"
        y1="45"
        x2="78"
        y2="45"
        stroke="#C8D3DB"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <line
        x1="30"
        y1="53"
        x2="78"
        y2="53"
        stroke="#C8D3DB"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M96.4201 56.8346V80.4873C96.4201 83.4977 93.9534 86.0782 90.8431 86.0782H12.8719C9.86889 86.0782 7.29492 83.6052 7.29492 80.4873V54.792"
        stroke="#1676EE"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <g filter="url(#filter1_d_6488:22267)">
        <path
          d="M89.6959 43.585H65.1744C62.7881 43.585 60.4842 44.4083 58.6739 45.8899L52.2555 51.1577C50.4451 52.6393 48.1412 53.4622 45.7549 53.4622H17.8596C15.0618 53.4622 12.7578 55.7671 12.7578 58.5656C12.7578 58.8125 12.7579 59.0594 12.8402 59.3063L17.9419 86.4688C18.3534 89.0204 20.4928 90.9134 23.0437 90.9134H82.4547C85.0055 90.9134 87.145 89.1028 87.5564 86.5511L94.7155 49.4291C95.2092 46.6305 93.3166 44.0788 90.5188 43.585C90.2719 43.585 89.9428 43.585 89.6959 43.585Z"
          fill="url(#paint0_linear_6488:22267)"
        />
      </g>
      <path
        d="M100.818 36.2998H68.8568C65.7465 36.2998 62.7436 37.3752 60.384 39.3105L52.0184 46.1912C49.6589 48.1264 46.656 49.2013 43.5457 49.2013H7.18758C3.54105 49.2013 0.538086 52.2118 0.538086 55.8673C0.538086 56.1898 0.538191 56.5122 0.645441 56.8348L7.29493 92.3139C7.83119 95.6467 10.6197 98.1194 13.9444 98.1194H91.3794C94.7042 98.1194 97.4926 95.7544 98.0289 92.4215L107.36 43.9333C108.003 40.2779 105.537 36.9449 101.89 36.2998C101.568 36.2998 101.139 36.2998 100.818 36.2998Z"
        fill="url(#paint1_linear_6488:22267)"
      />
      <defs>
        <filter
          id="filter0_b_6488:22267"
          x="18.0518"
          y="-1"
          width="71.5"
          height="94"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feGaussianBlur in="BackgroundImage" stdDeviation="0.5" />
          <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_6488:22267" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_backgroundBlur_6488:22267"
            result="shape"
          />
        </filter>
        <filter
          id="filter1_d_6488:22267"
          x="2.75781"
          y="24.585"
          width="102.036"
          height="67.3281"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="-9" />
          <feGaussianBlur stdDeviation="5" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.397708 0 0 0 0 0.47749 0 0 0 0 0.575 0 0 0 0.13 0"
          />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_6488:22267" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_6488:22267"
            result="shape"
          />
        </filter>
        <linearGradient
          id="paint0_linear_6488:22267"
          x1="53.7758"
          y1="43.585"
          x2="53.7758"
          y2="90.9134"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.104167" stopColor="white" />
          <stop offset="1" stopColor="#EEF2F6" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_6488:22267"
          x1="54"
          y1="36.2998"
          x2="54"
          y2="98.1194"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.104167" stopColor="white" />
          <stop offset="1" stopColor="#EEF2F6" />
        </linearGradient>
      </defs>
    </svg>
  );
}
