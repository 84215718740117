import classNames from "classnames";
import { useIntl } from "react-intl";

import { defaultIconClassNames, IconProps } from "./Icon";

export default function SupportAgentIcon({ size = 19, className = "" }: IconProps) {
  const intl = useIntl();
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 19 19"
      className={classNames(defaultIconClassNames, className)}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      aria-label={intl.formatMessage({ defaultMessage: "Support Agent", id: "PhzG0g" })}
    >
      <g clipPath="url(#clip0_12943_2578)">
        <path
          d="M13.9999 8.14667C13.9999 4.48667 11.1599 2 7.99992 2C4.87325 2 1.99992 4.43333 1.99992 8.18667C1.59992 8.41333 1.33325 8.84 1.33325 9.33333V10.6667C1.33325 11.4 1.93325 12 2.66659 12H3.33325V7.93333C3.33325 5.35333 5.41992 3.26667 7.99992 3.26667C10.5799 3.26667 12.6666 5.35333 12.6666 7.93333V12.6667H7.33325V14H12.6666C13.3999 14 13.9999 13.4 13.9999 12.6667V11.8533C14.3933 11.6467 14.6666 11.24 14.6666 10.76V9.22667C14.6666 8.76 14.3933 8.35333 13.9999 8.14667Z"
          fill="#50697D"
        />
        <path
          d="M5.99992 9.33333C6.36811 9.33333 6.66658 9.03486 6.66658 8.66667C6.66658 8.29848 6.36811 8 5.99992 8C5.63173 8 5.33325 8.29848 5.33325 8.66667C5.33325 9.03486 5.63173 9.33333 5.99992 9.33333Z"
          fill="#323232"
        />
        <path
          d="M9.99992 9.33333C10.3681 9.33333 10.6666 9.03486 10.6666 8.66667C10.6666 8.29848 10.3681 8 9.99992 8C9.63173 8 9.33325 8.29848 9.33325 8.66667C9.33325 9.03486 9.63173 9.33333 9.99992 9.33333Z"
          fill="#323232"
        />
        <path
          d="M11.9998 7.35333C11.6798 5.45333 10.0265 4 8.03315 4C6.01315 4 3.83982 5.67333 4.01315 8.3C5.65982 7.62667 6.89982 6.16 7.25315 4.37333C8.12648 6.12667 9.91982 7.33333 11.9998 7.35333Z"
          fill="#323232"
        />
      </g>
      <defs>
        <clipPath id="clip0_12943_2578">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
