import { Typography } from "@fonoa/ui-components/typography";
import { FormattedMessage } from "react-intl";

import Switch from "@/components/Form/Switch";

interface SidebarDemoDataModeToggleProps {
  isLocked: boolean;
  isDemoDataMode: boolean;
  toggleDemoDataMode: (isDemoMode: boolean) => void;
}

export function SidebarDemoDataModeToggle({
  isLocked,
  isDemoDataMode,
  toggleDemoDataMode,
}: SidebarDemoDataModeToggleProps) {
  return (
    <div className="mt-8 pl-7" data-cy="demoDataMode">
      <Switch
        title={<FormattedMessage defaultMessage="Demo data mode" id="T9Phho" />}
        dataAttributes={{ cy: "demo-data-mode-switch" }}
        variant="tangerine"
        locked={isLocked}
        titleClassName="text-sm"
        active={isDemoDataMode}
        onChange={toggleDemoDataMode}
        disabled={isLocked}
      />

      <Typography fontSize="text-xs" spacing={{ pl: 10, "-ml": 0.5 }} color="text-blueGray500">
        {isDemoDataMode ? (
          <FormattedMessage defaultMessage="Currently using demo data" id="coaOgu" />
        ) : (
          <FormattedMessage
            defaultMessage="Currently running Fonoa with production data"
            id="S/uaXA"
          />
        )}
      </Typography>
    </div>
  );
}
