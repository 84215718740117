import classNames from "classnames";
import { useIntl } from "react-intl";

import { defaultIconClassNames, IconProps } from "./Icon";

export default function FileIcon({ size = 32, className = "" }: IconProps) {
  const intl = useIntl();
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 32 32"
      fill="none"
      className={classNames(defaultIconClassNames, className)}
      xmlns="http://www.w3.org/2000/svg"
      aria-label={intl.formatMessage({ defaultMessage: "File", id: "gyrIEl" })}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5 29V3H16H18V11.082C18 11.7724 18.5804 12.332 19.2963 12.332L27 12.2867V16V29H5ZM2 2.66667C2 1.19391 3.2536 0 4.8 0H16H21.1501C21.6636 0 22.1575 0.197554 22.5294 0.551735L29.3793 7.07557C29.7757 7.45306 30 7.97649 30 8.52384V16V29.3333C30 30.8061 28.7464 32 27.2 32H4.8C3.2536 32 2 30.8061 2 29.3333V2.66667Z"
      />
    </svg>
  );
}
