import { CountryData } from "@fonoa/data-access/countries";
import axios from "axios";
import { ReportingCompaniesFilterProps } from "src/pages/reporting/companies/[[...companyId]]";

import { SearchCompanyData } from "@/api/reporting/company";
import { useAuth } from "@/hooks/auth";
import { ApiResponse } from "@/server/types";
import { trpc } from "@/utils/trpc";

import { CurrencyData } from "./stub/currencies";

export interface CompanyLookup {
  name: string;
}

const apis = {
  async getCountries() {
    const res = await axios.get<ApiResponse<CountryData[]>>("/api/countries");

    return res.data;
  },

  async getCurrencies() {
    const res = await axios.get<ApiResponse<CurrencyData[]>>("/api/currencies");

    return res.data;
  },

  async getCompanies(
    data: CompanyLookup
  ): Promise<{ data: { name: string; id: string; country_code: string }[] }> {
    const { name } = data;
    const res = await axios.get("/api/companies/lookup", {
      params: { name: name },
    });
    return res.data;
  },

  async getReportingCompanies(filters: ReportingCompaniesFilterProps) {
    const res = await axios.get<ApiResponse<SearchCompanyData[]>>("/api/reporting/companies", {
      params: filters,
    });
    return res.data;
  },

  async getCompanyDetails(id: string) {
    const res = await axios.get<ApiResponse<SearchCompanyData>>("/api/reporting/company", {
      params: { id },
    });

    return res.data;
  },
};

export default apis;

interface UseRolesProps {
  onlyAssignable?: boolean; // only include roles that the current user is allowed to assign to others
}

export const useRoles = ({ onlyAssignable = false }: UseRolesProps) => {
  const auth = useAuth();

  return trpc.user.roles.useQuery(
    { onlyAssignable },
    {
      staleTime: 1000 * 60 * 60, // One hour stale time, fair to say roles are not going to change very often
      enabled: Boolean(auth.user), // don't call until we're logged in
    }
  );
};
