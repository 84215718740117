import classNames from "classnames";
import { useIntl } from "react-intl";

import { defaultIconClassNames, IconProps } from "./Icon";

export default function AlignCenterIcon({ size = 16, className = "" }: IconProps) {
  const intl = useIntl();
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 16 16"
      fill="none"
      className={classNames(defaultIconClassNames, className)}
      xmlns="http://www.w3.org/2000/svg"
      aria-label={intl.formatMessage({ defaultMessage: "Align center", id: "KPMC7r" })}
    >
      <path d="M13.2 12.9H8.3V15H6.9V12.9H2V8.7H6.9V7.3H4.1V3.1H6.9V1H8.3V3.1H11.1V7.3H8.3V8.7H13.2V12.9Z" />
    </svg>
  );
}
