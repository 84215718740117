import type { DashboardUser } from "@fonoa/data-access/auth0";
import { LDProvider, useFlags } from "launchdarkly-react-client-sdk";
import { FC, PropsWithChildren, ReactNode } from "react";

import { useAuth } from "@/hooks/auth";
import config from "@/server/config";

import { FeatureFlagsSet } from "./FeatureFlags.types";

type FeatureFlagsProviderProps = {
  children: ReactNode;
  user: DashboardUser | null;
};

export function FeatureFlagsProvider({ children, user }: FeatureFlagsProviderProps) {
  if (!user) {
    return <>{children}</>;
  }

  const featureFlagProviderClientId = config.LAUNCH_DARKLY_CLIENT_SIDE_ID();

  return (
    <LDProvider
      clientSideID={featureFlagProviderClientId}
      context={{
        kind: "user",
        key: user.id,
        tenant: user.tenantSlug,
      }}
      options={{
        disableSyncEventPost: true,
      }}
      deferInitialization
    >
      {children}
    </LDProvider>
  );
}

export const FeatureFlagsProviderConnected: FC<PropsWithChildren<unknown>> = ({ children }) => {
  const auth = useAuth();

  return <FeatureFlagsProvider user={auth?.user ?? null}>{children}</FeatureFlagsProvider>;
};

export function useAreFeatureFlagsInitialized(): boolean {
  return Object.keys(useFlags()).length > 0;
}

export function useFeatureFlags(): FeatureFlagsSet {
  return useFlags() as FeatureFlagsSet;
}
