import classNames from "classnames";
import { useIntl } from "react-intl";

import { defaultIconClassNames, IconProps } from "./Icon";

export default function LocalOfferIcon({ size = 16, className = "" }: IconProps) {
  const intl = useIntl();
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 19 18"
      fill="none"
      className={classNames(defaultIconClassNames, className)}
      xmlns="http://www.w3.org/2000/svg"
      aria-label={intl.formatMessage({ defaultMessage: "Offer", id: "dqQE9k" })}
    >
      <g clip-path="url(#clip0_14825_54012)">
        <path d="M9.815 16.0575L16.565 9.3075C16.835 9.0375 17 8.6625 17 8.25V3C17 2.175 16.325 1.5 15.5 1.5L10.25 1.5C9.8375 1.5 9.4625 1.665 9.185 1.9425L2.435 8.6925C2.165 8.9625 2 9.3375 2 9.75C2 10.1625 2.165 10.5375 2.4425 10.8075L7.6925 16.0575C7.9625 16.335 8.3375 16.5 8.75 16.5C9.1625 16.5 9.545 16.3275 9.815 16.0575ZM3.4925 9.75L10.25 3L15.5 3L15.5 8.25H15.5075L8.7575 15L3.4925 9.75Z" />
        <path d="M12.5 4.875C12.5 5.49632 13.0037 6 13.625 6C14.2463 6 14.75 5.49632 14.75 4.875C14.75 4.25368 14.2463 3.75 13.625 3.75C13.0037 3.75 12.5 4.25368 12.5 4.875Z" />
      </g>
      <defs>
        <clipPath id="clip0_14825_54012">
          <rect width="19" height="18" fill="white" transform="matrix(0 1 -1 0 18.5 0)" />
        </clipPath>
      </defs>
    </svg>
  );
}
