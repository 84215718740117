import classNames from "classnames";
import { useIntl } from "react-intl";

import { defaultIconClassNames, IconProps } from "./Icon";

export default function UnlockIcon({ size = 32, className = "" }: IconProps) {
  const intl = useIntl();
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 16 16"
      fill="none"
      className={classNames(defaultIconClassNames, className)}
      xmlns="http://www.w3.org/2000/svg"
      aria-label={intl.formatMessage({ defaultMessage: "Unlock", id: "qXCbgZ" })}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6 0C3.92893 0 2.25 1.70946 2.25 3.81818H3.75C3.75 2.55295 4.75736 1.52727 6 1.52727C7.24264 1.52727 8.25 2.55295 8.25 3.81818V5.85455H2C0.895431 5.85455 0 6.76626 0 7.89091V11.9636C0 13.0883 0.895431 14 2 14H10C11.1046 14 12 13.0883 12 11.9636V7.89091C12 6.76626 11.1046 5.85455 10 5.85455H9.75V3.81818C9.75 1.70946 8.07107 0 6 0ZM1.5 7.89091C1.5 7.60975 1.72386 7.38182 2 7.38182H10C10.2761 7.38182 10.5 7.60975 10.5 7.89091V11.9636C10.5 12.2448 10.2761 12.4727 10 12.4727H2C1.72386 12.4727 1.5 12.2448 1.5 11.9636V7.89091Z"
      />
    </svg>
  );
}
