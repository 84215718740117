import classNames from "classnames";
import { useIntl } from "react-intl";

import { defaultIconClassNames, IconProps } from "./Icon";

export default function CodingIcon({ size = 72, className = "" }: IconProps) {
  const intl = useIntl();
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 72 72"
      fill="none"
      className={classNames(defaultIconClassNames, className)}
      xmlns="http://www.w3.org/2000/svg"
      aria-label={intl.formatMessage({ defaultMessage: "Coding", id: "6RW4LU" })}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M32.4823 14.3797C32.4823 12.5681 31.0837 11.099 29.3565 11.099C27.63 11.099 26.2296 12.5698 26.2296 14.3797C26.2296 16.1901 27.63 17.6592 29.3565 17.6592C31.0837 17.6592 32.4823 16.1919 32.4823 14.3797ZM70 59.1796V21.5721H2V59.1796C2 61.8964 4.09937 64.0989 6.6898 64.0989H65.3102C67.9006 64.0989 70 61.8964 70 59.1796ZM19.1952 11.099C20.9223 11.099 22.3227 12.5681 22.3227 14.3797C22.3227 16.1919 20.9223 17.6592 19.1952 17.6592C17.4686 17.6592 16.0683 16.1901 16.0683 14.3797C16.0683 12.5698 17.4686 11.099 19.1952 11.099ZM12.1613 14.3797C12.1613 12.5681 10.7609 11.099 9.03434 11.099C7.30719 11.099 5.90859 12.5698 5.90859 14.3797C5.90859 16.1901 7.30719 17.6592 9.03434 17.6592C10.7609 17.6592 12.1613 16.1919 12.1613 14.3797Z"
        fill="#BFEEC9"
      />
      <path
        d="M70 11.9193V21.5721H2V11.9193C2 9.20247 4.09937 7 6.6898 7H65.3102C67.9006 7 70 9.20247 70 11.9193ZM32.4822 14.3798C32.4822 12.5682 31.0836 11.0991 29.3565 11.0991C27.6299 11.0991 26.2295 12.5682 26.2295 14.3798C26.2295 16.192 27.6299 17.6594 29.3565 17.6594C31.0836 17.6594 32.4822 16.1903 32.4822 14.3798ZM22.3226 14.3798C22.3226 12.5682 20.9223 11.0991 19.1951 11.0991C17.4686 11.0991 16.0682 12.5682 16.0682 14.3798C16.0682 16.192 17.4686 17.6594 19.1951 17.6594C20.9223 17.6594 22.3226 16.1903 22.3226 14.3798ZM12.1613 14.3798C12.1613 12.5682 10.761 11.0991 9.0344 11.0991C7.30726 11.0991 5.90865 12.5682 5.90865 14.3798C5.90865 16.192 7.30726 17.6594 9.0344 17.6594C10.761 17.6594 12.1613 16.1903 12.1613 14.3798Z"
        fill="#133550"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M38.6791 29.2957C38.1457 29.1527 37.5973 29.4693 37.4544 30.0028L31.3601 52.7469C31.2172 53.2804 31.5337 53.8287 32.0672 53.9717L33.2199 54.2805C33.7533 54.4235 34.3017 54.1069 34.4446 53.5734L40.5389 30.8293C40.6818 30.2958 40.3652 29.7475 39.8318 29.6045L38.6791 29.2957ZM43.6025 36.4955C42.9981 35.8534 43.0287 34.8429 43.6709 34.2386C44.313 33.6342 45.3235 33.6648 45.9279 34.307L52.3145 41.0927C52.8931 41.7075 52.8931 42.6665 52.3145 43.2813L45.9279 50.0671C45.3235 50.7092 44.313 50.7399 43.6709 50.1355C43.0287 49.5311 42.9981 48.5207 43.6025 47.8785L48.9592 42.187L43.6025 36.4955ZM28.2951 36.4956C28.8995 35.8534 28.8689 34.8429 28.2267 34.2386C27.5846 33.6342 26.5741 33.6648 25.9698 34.307L19.5831 41.0928C19.0045 41.7075 19.0045 42.6665 19.5831 43.2813L25.9698 50.0671C26.5741 50.7093 27.5846 50.7399 28.2267 50.1355C28.8689 49.5312 28.8995 48.5207 28.2951 47.8785L22.9384 42.187L28.2951 36.4956Z"
        fill="#47B974"
      />
    </svg>
  );
}
