/* Cross-browser navigator language function
 * https://stackoverflow.com/questions/673905/best-way-to-determine-users-locale-within-browser/52112155#52112155
 */

const getUserLanguage = (supportedLanguages: string[]) => {
  if (typeof window !== "undefined") {
    if (navigator.languages && navigator.languages.length) {
      return navigator.languages.find(
        (navLang: string) =>
          supportedLanguages.find(
            (supportedLang: string) => navLang.toLowerCase() === supportedLang.toLowerCase()
          ) || false
      );
    } else {
      return (
        window.navigator["language"] ||
        // Not used anymore, left for posterity
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        window.navigator["userLanguage"] ||
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        window.navigator["browserLanguage"]
      );
    }
  } else {
    supportedLanguages[0];
  }
};

export { getUserLanguage };
