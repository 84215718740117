import classNames from "classnames";
import { useIntl } from "react-intl";

import { defaultIconClassNames, IconProps } from "./Icon";

export default function ThumbsDownActiveIcon({ size = 18, className = "" }: IconProps) {
  const intl = useIntl();
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 18 18"
      fill="none"
      className={classNames(defaultIconClassNames, className)}
      xmlns="http://www.w3.org/2000/svg"
      aria-label={intl.formatMessage({ defaultMessage: "Thumbs up", id: "0HnZ1c" })}
    >
      <g clipPath="url(#clip0_13281_3345)">
        <path
          d="M16.5 3H15C14.5875 3 14.25 3.3375 14.25 3.75V10.5C14.25 10.9125 14.5875 11.25 15 11.25H16.5V3ZM1.6275 8.34C1.545 8.5275 1.5 8.73 1.5 8.94V9.75C1.5 10.575 2.175 11.25 3 11.25H7.125L6.435 14.7375C6.3975 14.9025 6.42 15.0825 6.495 15.2325C6.6675 15.57 6.885 15.8775 7.155 16.1475L7.5 16.5L12.3075 11.6925C12.5925 11.4075 12.75 11.025 12.75 10.6275V4.755C12.75 3.7875 11.9625 3 10.995 3H4.92C4.3875 3 3.9 3.2775 3.63 3.7275L1.6275 8.34Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_13281_3345">
          <rect width="18" height="18" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
