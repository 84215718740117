import { Button } from "@fonoa/ui-components/button";
import { ArrowDownIcon, ArrowUpIcon, UnlockIcon } from "@fonoa/ui-components/icons";
import { Typography } from "@fonoa/ui-components/typography";
import { MouseEvent } from "react";
import { FormattedMessage } from "react-intl";

import Avatar from "@/components/Avatar";
import literalize from "@/lib/literalize";

interface SidebarFooterProps {
  firstname: string;
  lastname: string;
  company: string;
  open: boolean;
  onToggle: () => void;
}

export default function SidebarFooter({
  firstname,
  lastname,
  company,
  open,
  onToggle,
}: SidebarFooterProps) {
  const logout = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    e.stopPropagation();

    document.dispatchEvent(new Event("auth.signout"));
  };

  return (
    <div className="mx-6 border-t border-blueGray100 pt-6 dark:border-blueGray700">
      <div className="flex">
        <div>
          <Avatar alt={literalize(`${firstname} ${lastname}`)} dark={false} activeStatus size="s" />
        </div>
        <div className="ml-2.5 flex-1 items-center">
          <div className="w-36">
            <Typography
              fontSize="text-xs"
              lineHeight="leading-4"
              color="text-primaryBlue900"
              fontWeight="font-medium"
              spacing={{ mt: 1 }}
              overflow="truncate"
              darkColor="dark:text-white"
            >
              {firstname} {lastname}
            </Typography>
          </div>
          <Typography
            fontSize="text-xs"
            color="text-blueGray500"
            fontWeight="font-medium"
            lineHeight="leading-4"
            transform="uppercase"
            spacing={{ mt: 1 }}
          >
            {company}
          </Typography>
        </div>
        <div data-cy="sidebar-footer-icon" className="text-primaryBlue900 dark:text-white">
          {!open && <ArrowUpIcon onClick={onToggle} className="cursor-pointer" size={16} />}
          {open && <ArrowDownIcon onClick={onToggle} className="cursor-pointer" size={16} />}
        </div>
      </div>
      {open && (
        <div className="mt-6">
          <Button
            dataAttributes={{ cy: "logout-button" }}
            leftIcon={UnlockIcon}
            variant="TEXT_LIGHT"
            onClick={logout}
          >
            <FormattedMessage defaultMessage="Logout" id="C81/uG" />
          </Button>
          <Typography
            fontSize="text-xs"
            lineHeight="leading-4"
            color="text-blueGray500"
            align="text-right"
            spacing={{ mt: 1 }}
          >
            {window.__NEXT_DATA__?.buildId || "dev"}
          </Typography>
        </div>
      )}
    </div>
  );
}
