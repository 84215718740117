import classNames from "classnames";
import { ButtonHTMLAttributes, ComponentType, MouseEvent, ReactNode } from "react";

import { IconProps } from "../icons";
import { Loader } from "../loaders";
import { DataAttributes, SpacingProps } from "../types";
import { convertDataAttributes, getSpacingClass } from "../utils";
import {
  getLoadingState,
  isFilledVariant,
  isTextVariant,
  LeftButtonIcon,
  RightButtonIcon,
} from "./Button.utils";

export type ButtonDisplayVariant =
  | "FILLED_SUCCESS"
  | "FILLED_WARNING"
  | "FILLED_ALERT"
  | "FILLED_LIGHT"
  | "OUTLINED_SUCCESS"
  | "OUTLINED_WARNING"
  | "OUTLINED_ALERT"
  | "TEXT_DARK"
  | "TEXT_LIGHT"
  | "TEXT_SUCCESS"
  | "TEXT_WARNING"
  | "TEXT_WHITE"
  | "TEXT_ALERT"
  | "DARK_GRAY";

export type ButtonLoaderVariant = "FILLED" | "OUTLINED" | "TEXT" | "LINK";
type ButtonTextVariant = "TEXT" | "TEXT_SUCCESS" | "TEXT_WARNING" | "TEXT_ALERT" | "LINK";

export type ButtonVariant = ButtonDisplayVariant | ButtonLoaderVariant;

type SizeVariant = "SMALL" | "REGULAR" | "LARGE";

export interface ButtonProps
  extends Omit<ButtonHTMLAttributes<HTMLButtonElement>, "className">,
    SpacingProps {
  variant?: ButtonDisplayVariant | ButtonLoaderVariant | ButtonTextVariant;
  loading?: boolean;
  size?: SizeVariant;
  children?: ReactNode;
  fluid?: boolean;
  leftIcon?: ComponentType<IconProps>;
  rightIcon?: ComponentType<IconProps>;
  iconSize?: number;
  disabled?: boolean;
  shadow?: boolean;
  onClick?: (e: MouseEvent<HTMLButtonElement>) => void;
  dataAttributes?: DataAttributes;
  overrideDisabledStyle?: boolean; // Used to override the default Button disabled styling
  leftIconProps?: Omit<IconProps, "size">;
  rightIconProps?: Omit<IconProps, "size">;
  bold?: boolean;
  UNSAFE_className?: string;
}

const VARIANT_MAPS: Record<ButtonVariant, string> = {
  FILLED:
    "text-white font-medium bg-primaryBlue500 border-2 border-primaryBlue500 hover:bg-primaryBlue600 hover:border-primaryBlue600 active:bg-primaryBlue700 active:border-primaryBlue600 focus:bg-primaryBlue500 focus:border-2 focus:border-blueGray300",
  FILLED_SUCCESS:
    "text-white font-medium bg-green700 border-2 border-green700 hover:bg-green800 hover:border-green800 active:bg-green800 active:border-greenActive focus:bg-green800 focus:border-2 focus:border-blueGray300",
  FILLED_WARNING:
    "text-white font-medium bg-yellow800 border-2 border-yellow800 hover:bg-yellow800 hover:border-yellow800 active:border-yellow800 active:bg-yellow800 focus:bg-yellow800 focus:border-2 focus:border-blueGray300",
  FILLED_ALERT:
    "text-white font-medium bg-red500 border-2 border-red500 hover:bg-red600 hover:border-red600 active:border-red700 active:bg-red700 focus:bg-red500 focus:border-2 focus:border-blueGray300",
  FILLED_LIGHT:
    "text-blueGray900 font-medium bg-white border-1 border-blueGray200 hover:border-primaryBlue900 focus:border-1 focus:border-blueGray300 active:bg-blueGray10 active:border-primaryBlue900 disabled:text-blueGray200 disabled:border-blueGray200 bg-white disabled:text-blueGray200",
  OUTLINED:
    "text-blueGray900 font-medium border border-blueGray200 hover:border-primaryBlue900 focus:border-2 focus:border-blueGray300 active:bg-blueGray10 active:border-primaryBlue900 disabled:text-blueGray200 disabled:border-blueGray200 bg-white disabled:text-blueGray200",
  OUTLINED_SUCCESS:
    "text-green700 font-medium border border-green700 hover:text-green800 hover:border-green800 active:bg-blueGray10 active:text-green800 active:border-green800 focus:border-2 focus:border-blueGray600",
  OUTLINED_WARNING:
    "text-yellow800 font-medium border border-yellow800 hover:text-yellow800 hover:border-yellow800 active:bg-blueGray10 active:text-yellow800 active:border-yellow800 focus:border-2 focus:border-blueGray300",
  OUTLINED_ALERT:
    "text-red500 font-medium border border-red500 hover:text-red600 hover:border-red600 active:bg-blueGray10 active:text-red700 active:border-red700 focus:border-2 focus:border-blueGray300",
  TEXT: "!h-auto text-blueGray600 font-normal hover:underline active:text-primaryBlue900 focus:text-primaryBlue900 disabled:text-blueGray200",
  TEXT_LIGHT:
    "!h-auto text-blueGray500 font-normal hover:underline active:text-primaryBlue800 focus:text-primaryBlue800 disabled:text-blueGray200",
  TEXT_WHITE:
    "!h-auto text-white font-medium hover:underline active:underline focus:underline disabled:text-blueGray200",
  TEXT_DARK:
    "!h-auto text-primaryBlue900 font-normal hover:underline active:text-primaryBlue900 focus:text-primaryBlue900 disabled:text-primaryBlue200",
  TEXT_SUCCESS: "!h-auto text-green700 font-normal hover:underline active:text-green800 p-0",
  TEXT_WARNING: "!h-auto text-yellow800 font-normal hover:underline active:text-yellow800 p-0",
  TEXT_ALERT: "!h-auto text-red500 font-normal hover:underline active:text-red700 p-0",
  LINK: "!h-auto text-primaryBlue600 font-normal hover:underline active:text-primaryBlue700 disabled:blueGray200 p-0",
  DARK_GRAY:
    "text-white font-medium bg-blueGray700 border-2 border-blueGray700 hover:bg-blueGray800 hover:border-blueGray800 active:bg-blueGray800 active:border-blueGray800 focus:border-2 focus:border-blueGray300 disabled:bg-blueGray200",
};

export const LOADER_VARIANT_MAPS: Record<ButtonLoaderVariant, string> = {
  FILLED: "text-textWhite",
  OUTLINED: "text-blueGray900",
  TEXT: "text-blueGray600",
  LINK: "text-primaryBlue500",
};

const SIZE_VARIANT_MAPS: Record<SizeVariant, string> = {
  SMALL: "leading-4 h-8 text-xs",
  REGULAR: "leading-7 h-10 text-sm",
  LARGE: "leading-8 h-12 text-sm",
};

export function Button({
  variant = "FILLED",
  size = "REGULAR",
  spacing,
  children,
  leftIcon: LeftIconComponent,
  rightIcon: RightIconComponent,
  fluid,
  iconSize = 16,
  shadow = false,
  disabled = false,
  loading = false,
  dataAttributes = {},
  onClick,
  overrideDisabledStyle,
  leftIconProps = {},
  rightIconProps = {},
  UNSAFE_className,
  bold,
  ...props
}: ButtonProps) {
  return (
    <button
      {...convertDataAttributes(dataAttributes)}
      disabled={disabled}
      aria-disabled={loading}
      onClick={!loading ? onClick : undefined}
      type="button"
      className={classNames(
        getLoadingState({ variant, loading }),
        VARIANT_MAPS[variant],
        SIZE_VARIANT_MAPS[size],
        getSpacingClass(spacing),
        "group flex justify-center items-center whitespace-nowrap rounded focus:outline-none disabled:cursor-not-allowed",
        {
          "w-full": fluid,
          shadow: shadow,
          "disabled:bg-blueGray200 disabled:border-blueGray200":
            isFilledVariant(variant) && !overrideDisabledStyle,
          "disabled:bg-opacity-50 disabled:border-transparent": overrideDisabledStyle,
          "py-1 px-4": !isTextVariant(variant),
          "font-bold": bold,
        },
        UNSAFE_className
      )}
      {...props}
    >
      <LeftButtonIcon
        icon={LeftIconComponent}
        {...leftIconProps}
        variant={variant}
        size={iconSize}
        loading={loading}
      >
        {children}
      </LeftButtonIcon>
      {loading && !LeftIconComponent && !RightIconComponent && (
        <Loader
          className={classNames(
            "mx-3.5 align-middle",
            LOADER_VARIANT_MAPS[variant as ButtonLoaderVariant]
          )}
          size={16}
        />
      )}
      {!loading && <span className="mt-px block">{children}</span>}
      {loading && (Boolean(LeftIconComponent) || Boolean(RightIconComponent)) ? children : null}
      <RightButtonIcon
        icon={RightIconComponent}
        {...rightIconProps}
        variant={variant}
        size={iconSize}
        loading={loading}
      >
        {children}
      </RightButtonIcon>
    </button>
  );
}
