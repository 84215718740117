import { useIntl } from "react-intl";

import { IconProps } from "./Icon";

const PaymentIcon = ({ size = 32, className = "", onClick }: IconProps) => {
  const intl = useIntl();
  return (
    <svg
      width={size}
      className={className}
      onClick={onClick}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      aria-label={intl.formatMessage({ defaultMessage: "Payment card", id: "nzyw7z" })}
    >
      <g clipPath="url(#clip0_667_54494)">
        <path
          d="M20 4H4C2.89 4 2.01 4.89 2.01 6L2 18C2 19.11 2.89 20 4 20H20C21.11 20 22 19.11 22 18V6C22 4.89 21.11 4 20 4ZM20 18H4V12H20V18ZM20 8H4V6H20V8Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_667_54494">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default PaymentIcon;
