import classNames from "classnames";
import { useIntl } from "react-intl";

import { defaultIconClassNames, IconProps } from "./Icon";

export default function UploadIcon({ size = 16, className = "" }: IconProps) {
  const intl = useIntl();
  return (
    <svg
      width={size}
      height={size}
      className={classNames(defaultIconClassNames, className)}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      aria-label={intl.formatMessage({ defaultMessage: "Upload", id: "p4N05H" })}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.2258 4.83636C15.6534 4.83636 16 4.48975 16 4.06217V1.78182C16 0.797747 15.1912 0 14.1935 0H1.80645C0.808776 0 0 0.797748 0 1.78182V4.06217C0 4.48975 0.346619 4.83637 0.774194 4.83636C1.20177 4.83636 1.54839 4.48975 1.54839 4.06217L1.54839 1.78182C1.54839 1.64124 1.66393 1.52727 1.80645 1.52727L14.1935 1.52727C14.3361 1.52727 14.4516 1.64124 14.4516 1.78182V4.06217C14.4516 4.48975 14.7982 4.83636 15.2258 4.83636ZM4.38888 10.0694L7.22581 7.551V16H8.77419V7.551L11.6111 10.0694L12.6469 8.93421L8.51791 5.26876C8.22245 5.00647 7.77755 5.00647 7.48209 5.26876L3.35306 8.93421L4.38888 10.0694Z"
      />
    </svg>
  );
}
