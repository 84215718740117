import classNames from "classnames";
import { useIntl } from "react-intl";

import { defaultIconClassNames, IconProps } from "./Icon";

export default function PieChart({ size = 16, className = "" }: IconProps) {
  const intl = useIntl();
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 18 18"
      fill="none"
      className={classNames(defaultIconClassNames, className)}
      xmlns="http://www.w3.org/2000/svg"
      aria-label={intl.formatMessage({ defaultMessage: "Pie chart", id: "CAZ8vW" })}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9 1.5C4.86 1.5 1.5 4.86 1.5 9C1.5 13.14 4.86 16.5 9 16.5C13.14 16.5 16.5 13.14 16.5 9C16.5 4.86 13.14 1.5 9 1.5ZM14.9475 8.25H9.75V3.0525C12.4575 3.39 14.61 5.5425 14.9475 8.25ZM3 9C3 5.9475 5.295 3.42 8.25 3.0525V14.9475C5.295 14.58 3 12.0525 3 9ZM9.75 14.9475V9.75H14.9475C14.61 12.4575 12.4575 14.61 9.75 14.9475Z"
      />
    </svg>
  );
}
