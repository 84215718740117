import {
  DefaultOptions,
  hashQueryKey,
  QueryKey,
  QueryKeyHashFunction,
} from "@tanstack/react-query";
import { createTRPCProxyClient, httpBatchLink, httpLink, TRPCClientError } from "@trpc/client";
import { createTRPCNext } from "@trpc/next";

import { isBrowser, localStorageKeys } from "@/lib/utils";

// eslint-disable-next-line no-restricted-imports
import type { AppRouter } from "../pages/api/trpc/[trpc]";

// Use default key hasher from `react-query` and add demoDataMode at the end
// use fallback `false` if `getItem` returns null
const queryKeyHashFn: QueryKeyHashFunction<QueryKey> = (queryKey) =>
  `${hashQueryKey(queryKey)}.${localStorage.getItem(localStorageKeys.demo_data_mode) ?? "false"}`;

export function isTRPCClientError(cause: unknown): cause is TRPCClientError<AppRouter> {
  return cause instanceof TRPCClientError;
}

const createLinks = () => {
  if ((isBrowser && window.Cypress) || process.env.Cypress || process.env.NODE_ENV === "test") {
    return [
      httpLink({
        url: "/api/trpc",
      }),
    ];
  }

  return [
    httpBatchLink({
      url: "/api/trpc",
    }),
  ];
};

const MAX_QUERY_RETRIES = 3;

export const retry: Exclude<
  NonNullable<DefaultOptions["queries"]>["retry"],
  boolean | number | undefined
> = (failureCount, error) => {
  if (!isTRPCClientError(error)) {
    // do not retry if it is not a tRPC error
    return false;
  }

  const code = error.data?.code;
  const path = error.data?.path;

  if (code === "UNAUTHORIZED") {
    if (path === "user.whoami") {
      // `process401Response` essentially calls `user.whoami` endpoint again
      // and if it fails "retry" is triggered again, it can end up
      // going deeper and deeper into the stack if we don't return false here
      // to avoid any further retries
      return false;
    }

    process401Response();

    return false;
  }

  return failureCount < MAX_QUERY_RETRIES;
};

export const trpc = createTRPCNext<AppRouter>({
  config() {
    return {
      links: createLinks(),
      /**
       * @link https://tanstack.com/query/v4/docs/reference/QueryClient
       **/
      queryClientConfig: {
        defaultOptions: {
          queries: {
            refetchOnWindowFocus: false,
            staleTime: 60 * 1000,
            queryKeyHashFn,
            retry,
          },
        },
      },
    };
  },
  /**
   * @link https://trpc.io/docs/ssr
   **/
  ssr: false,
});

export const trpcClient = createTRPCProxyClient<AppRouter>({
  links: createLinks(),
});

export const process401Response = () => {
  document.dispatchEvent(new Event("auth.refresh"));
};
