import classNames from "classnames";
import { useIntl } from "react-intl";

import { defaultIconClassNames, IconProps } from "./Icon";

export default function InvoiceIcon({ size = 32, className = "" }: IconProps) {
  const intl = useIntl();
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 16 16"
      fill="none"
      className={classNames(defaultIconClassNames, className)}
      xmlns="http://www.w3.org/2000/svg"
      aria-label={intl.formatMessage({ defaultMessage: "Invoice", id: "Ecfndm" })}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2 1C1.44772 1 1 1.44772 1 2V14C1 14.5523 1.44772 15 2 15H14C14.5523 15 15 14.5523 15 14V5.41421C15 5.149 14.8946 4.89464 14.7071 4.70711L11.2929 1.29289C11.1054 1.10536 10.851 1 10.5858 1H2ZM4.75 4.5C4.33579 4.5 4 4.83579 4 5.25C4 5.66421 4.33579 6 4.75 6H11.25C11.6642 6 12 5.66421 12 5.25C12 4.83579 11.6642 4.5 11.25 4.5H4.75ZM4.75 7.5C4.33579 7.5 4 7.83579 4 8.25C4 8.66421 4.33579 9 4.75 9H11.25C11.6642 9 12 8.66421 12 8.25C12 7.83579 11.6642 7.5 11.25 7.5H4.75ZM4 11.25C4 10.8358 4.33579 10.5 4.75 10.5H11.25C11.6642 10.5 12 10.8358 12 11.25C12 11.6642 11.6642 12 11.25 12H4.75C4.33579 12 4 11.6642 4 11.25Z"
      />
    </svg>
  );
}
