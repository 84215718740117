import classNames from "classnames";

interface TinyProgressBarProps {
  value: number;
  inactive?: boolean;
  barClassName?: string;
}

export function TinyProgressBar({
  value,
  inactive = false,
  barClassName = "",
}: TinyProgressBarProps) {
  return (
    <div className="bg-blueGray100 relative mt-2 h-1.5 w-full rounded">
      <div
        className={classNames(
          "h-1.5 rounded",
          inactive ? "bg-blueGray400" : "bg-greenFonoa",
          barClassName
        )}
        style={{ width: value * 100 + "%" }}
      />
    </div>
  );
}
