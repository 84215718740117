import classNames from "classnames";
import { useIntl } from "react-intl";

import { defaultIconClassNames, IconProps } from "./Icon";

export default function FileCopyOutlineIcon({ size = 16, className = "", onClick }: IconProps) {
  const intl = useIntl();
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 18 18"
      className={classNames(defaultIconClassNames, className)}
      onClick={onClick}
      xmlns="http://www.w3.org/2000/svg"
      aria-label={intl.formatMessage({ defaultMessage: "File copy", id: "QbRtC8" })}
    >
      <path d="M12 0.75L3 0.75C2.175 0.75 1.5 1.425 1.5 2.25L1.5 12.75H3L3 2.25L12 2.25V0.75ZM11.25 3.75L6 3.75C5.175 3.75 4.5075 4.425 4.5075 5.25L4.5 15.75C4.5 16.575 5.1675 17.25 5.9925 17.25L14.25 17.25C15.075 17.25 15.75 16.575 15.75 15.75V8.25L11.25 3.75ZM6 15.75L6 5.25L10.5 5.25L10.5 9H14.25V15.75L6 15.75Z" />
    </svg>
  );
}
