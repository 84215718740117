import { colors } from "@fonoa/design/tokens";
import classNames from "classnames";
import { MouseEventHandler, ReactNode } from "react";
import { Slide, toast, ToastContent, ToastOptions, ToastPosition } from "react-toastify";

import {
  CloseIcon,
  StatusErrorIcon,
  StatusInfoIcon,
  StatusSuccessIcon,
  StatusWarningIcon,
} from "../icons";
import { DataAttributes, ThemeColorText } from "../types";
import { Typography } from "../typography";

export type ToastType = "success" | "error" | "warning" | "info";

export interface ToastConfig {
  type?: ToastType;
  description?: ReactNode;
  dataAttributes?: DataAttributes;
  highContrast?: boolean;
  inline?: boolean;
  action?: ToastAction;
  hideCloseButton?: boolean;
  position?: ToastPosition;
  autoCloseDelay?: number;
}

export interface ToastAction {
  title: ReactNode;
  onClick: MouseEventHandler;
}

type ToastTrigger = {
  Icon: () => JSX.Element | null;
  open: (content: ToastContent, options?: ToastOptions | undefined) => string | number;
};

type ToastBodyProps = {
  title: ReactNode;
  description?: ReactNode;
  dataAttributes?: DataAttributes;
  inline?: boolean;
  textColor: ThemeColorText;
  actionColor?: ThemeColorText;
  action?: ToastAction;
  Icon: () => JSX.Element | null;
  hasCloseIcon: boolean;
};

const MultilineBody = ({
  title,
  description,
  textColor,
  Icon,
  action,
  actionColor,
  dataAttributes,
  hasCloseIcon,
}: ToastBodyProps) => (
  <div className="items-top flex" data-cy="toast">
    <div className="leading-7">
      <Icon />
    </div>
    <div className="ml-1 w-full pl-4">
      <Typography
        component="h3"
        color={textColor}
        dataAttributes={dataAttributes}
        fontWeight="font-medium"
      >
        {title}
      </Typography>
      <Typography component="p" color={textColor} fontSize="text-sm" data-cy="toast-description">
        {description}
      </Typography>
      {Boolean(action) && (
        <div className="w-full text-right">
          <button
            onClick={action?.onClick}
            className={classNames(actionColor, "mt-2 font-bold", { "-mr-4": hasCloseIcon })}
          >
            {action?.title}
          </button>
        </div>
      )}
    </div>
  </div>
);

const InlineBody = ({
  title,
  description,
  textColor,
  Icon,
  action,
  actionColor,
  dataAttributes,
}: ToastBodyProps) => (
  <div className="flex" data-cy="toast">
    <div className="-mt-0.5">
      <Icon />
    </div>
    <div className="mx-4 grow">
      <Typography
        component="span"
        color={textColor}
        dataAttributes={dataAttributes}
        fontWeight="font-bold"
      >
        {title}
      </Typography>
      <Typography
        component="span"
        spacing={{ ml: 1 }}
        color={textColor}
        data-cy="toast-description"
        fontWeight="font-normal"
      >
        {description}
      </Typography>
    </div>
    {Boolean(action) && (
      <button
        onClick={action?.onClick}
        className={classNames(actionColor, "-mt-0.25 mr-8 ml-2 h-7 text-sm font-bold")}
      >
        {action?.title}
      </button>
    )}
  </div>
);

const ToastBody = (props: ToastBodyProps) =>
  props.inline ? InlineBody(props) : MultilineBody(props);

const toastTriggers: Record<ToastType, ToastTrigger> = {
  success: {
    Icon: () => StatusSuccessIcon && <StatusSuccessIcon size={28} />,
    open: toast.success,
  },
  error: {
    Icon: () => StatusErrorIcon && <StatusErrorIcon size={28} />,
    open: toast.error,
  },
  info: {
    Icon: () => StatusInfoIcon && <StatusInfoIcon size={28} />,
    open: toast.info,
  },
  warning: {
    Icon: () =>
      StatusWarningIcon && <StatusWarningIcon size={28} secondColor={colors.tangerine200} />,
    open: toast.warning,
  },
};

const defaultTrigger = toastTriggers["info"];

export const Toast = (title: ReactNode, config?: ToastConfig, toastifyConfig?: ToastOptions) => {
  const textColor: ThemeColorText = config?.highContrast ? "text-primaryWhite" : "text-blueGray700";
  const actionColor: ThemeColorText | undefined = config?.highContrast
    ? "text-primaryWhite"
    : undefined;

  const toastTrigger = toastTriggers[config?.type || "info"] || defaultTrigger;

  const actionWithDismiss: ToastAction | undefined = config?.action
    ? {
        title: config?.action?.title,
        onClick: (e) => {
          e.stopPropagation();
          toast.dismiss(id);
          config?.action?.onClick(e);
        },
      }
    : undefined;

  const id = toastTrigger.open(
    <ToastBody
      title={title}
      description={config?.description}
      dataAttributes={config?.dataAttributes}
      inline={config?.inline}
      textColor={textColor}
      actionColor={actionColor}
      Icon={toastTrigger.Icon}
      action={actionWithDismiss}
      hasCloseIcon={Boolean(!config?.hideCloseButton)}
    />,
    {
      position: config?.position || "top-right",
      autoClose: config?.autoCloseDelay || 5000,
      transition: Slide,
      closeButton: config?.hideCloseButton
        ? false
        : () => (
            <div className="cursor-pointer">
              <CloseIcon
                className={actionColor}
                size={18}
                onClick={(e) => {
                  e.stopPropagation();
                  toast.dismiss(id);
                }}
              />
            </div>
          ),
      className: config?.highContrast ? "high-contrast" : "",
      ...toastifyConfig,
    }
  );
};
