import classNames from "classnames";
import { useIntl } from "react-intl";

import { defaultIconClassNames, IconProps } from "./Icon";

export default function DotsVerticalIcon({ size = 20, className = "" }: IconProps) {
  const intl = useIntl();
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 20 20"
      fill="none"
      className={classNames(defaultIconClassNames, className)}
      xmlns="http://www.w3.org/2000/svg"
      aria-label={intl.formatMessage({ defaultMessage: "Dots verticall", id: "U9m5EE" })}
    >
      <path d="M10.0002 6.66667C10.9168 6.66667 11.6668 5.91667 11.6668 5C11.6668 4.08333 10.9168 3.33333 10.0002 3.33333C9.0835 3.33333 8.3335 4.08333 8.3335 5C8.3335 5.91667 9.0835 6.66667 10.0002 6.66667ZM10.0002 8.33333C9.0835 8.33333 8.3335 9.08333 8.3335 10C8.3335 10.9167 9.0835 11.6667 10.0002 11.6667C10.9168 11.6667 11.6668 10.9167 11.6668 10C11.6668 9.08333 10.9168 8.33333 10.0002 8.33333ZM10.0002 13.3333C9.0835 13.3333 8.3335 14.0833 8.3335 15C8.3335 15.9167 9.0835 16.6667 10.0002 16.6667C10.9168 16.6667 11.6668 15.9167 11.6668 15C11.6668 14.0833 10.9168 13.3333 10.0002 13.3333Z" />
    </svg>
  );
}
