import classNames from "classnames";
import { useIntl } from "react-intl";

import { defaultIconClassNames, IconProps } from "./Icon";

interface Props extends IconProps {
  height?: number;
  width?: number;
}

export default function PowerpointFileIcon({ className = "", height = 30, width = 24 }: Props) {
  const intl = useIntl();
  return (
    <svg
      width={width}
      height={height}
      className={classNames(className, defaultIconClassNames)}
      viewBox="0 0 24 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      aria-label={intl.formatMessage({ defaultMessage: "Powerpoint file", id: "sWsgmA" })}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.4 1.5c-.44 0-.9.39-.9 1v25c0 .61.46 1 .9 1h19.2c.44 0 .9-.39.9-1V8h-5.625A.875.875 0 0 1 16 7.125V1.5H2.4Zm0-1.5C1.075 0 0 1.12 0 2.5v25C0 28.88 1.075 30 2.4 30h19.2c1.325 0 2.4-1.12 2.4-2.5V7.59a1 1 0 0 0-.279-.693L17.395.307A1 1 0 0 0 16.674 0H2.4Zm2.48 20a.185.185 0 0 1-.135-.056.185.185 0 0 1-.056-.136V14.6c0-.058.016-.106.048-.144a.195.195 0 0 1 .144-.056h2.144c.64 0 1.144.152 1.512.456.368.3.552.74.552 1.32 0 .582-.184 1.022-.552 1.32-.363.294-.867.44-1.512.44H5.833v1.872a.185.185 0 0 1-.056.136.17.17 0 0 1-.136.056h-.76Zm2.105-2.952c.31 0 .546-.072.712-.216.165-.149.248-.368.248-.656 0-.277-.08-.493-.24-.648-.16-.16-.4-.24-.72-.24H5.817v1.76h1.168ZM10.395 20a.185.185 0 0 1-.137-.056.185.185 0 0 1-.056-.136V14.6c0-.058.016-.106.048-.144a.195.195 0 0 1 .144-.056h2.144c.64 0 1.144.152 1.512.456.368.3.552.74.552 1.32 0 .582-.184 1.022-.552 1.32-.363.294-.867.44-1.512.44h-1.192v1.872a.185.185 0 0 1-.056.136.17.17 0 0 1-.136.056h-.76Zm2.103-2.952c.31 0 .547-.072.712-.216.165-.149.248-.368.248-.656 0-.277-.08-.493-.24-.648-.16-.16-.4-.24-.72-.24H11.33v1.76h1.168Zm4.506 2.896a.185.185 0 0 0 .136.056h.736a.195.195 0 0 0 .144-.056.185.185 0 0 0 .056-.136v-4.424h1.472a.195.195 0 0 0 .144-.056.185.185 0 0 0 .056-.136V14.6a.195.195 0 0 0-.056-.144.195.195 0 0 0-.144-.056h-4.08a.195.195 0 0 0-.144.056.213.213 0 0 0-.048.144v.592c0 .054.018.1.056.136a.185.185 0 0 0 .136.056h1.48v4.424c0 .054.018.1.056.136Z"
      />
    </svg>
  );
}
