import classNames from "classnames";
import { useIntl } from "react-intl";

import { defaultIconClassNames, IconProps } from "./Icon";

export default function UnarchiveIcon({ size = 16, className = "" }: IconProps) {
  const intl = useIntl();
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 16 16"
      fill="none"
      className={classNames(defaultIconClassNames, className)}
      xmlns="http://www.w3.org/2000/svg"
      aria-label={intl.formatMessage({ defaultMessage: "Unarchive", id: "ftDYZo" })}
    >
      <path d="M15.405 4.6725L14.3625 3.4125C14.16 3.1575 13.8525 3 13.5 3H4.5C4.1475 3 3.84 3.1575 3.63 3.4125L2.595 4.6725C2.3775 4.9275 2.25 5.265 2.25 5.625V15C2.25 15.825 2.925 16.5 3.75 16.5H14.25C15.075 16.5 15.75 15.825 15.75 15V5.625C15.75 5.265 15.6225 4.9275 15.405 4.6725ZM4.68 4.5H13.32L13.9425 5.25H4.065L4.68 4.5ZM3.75 15V6.75H14.25V15H3.75ZM6 11.25H7.9125V13.5H10.0875V11.25H12L9 8.25L6 11.25Z" />
    </svg>
  );
}
