import classNames from "classnames";
import { useIntl } from "react-intl";

import { defaultIconClassNames, IconProps } from "./Icon";

export default function TooltipIcon({ size = 8, className = "" }: IconProps) {
  const intl = useIntl();
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 8 9"
      fill="none"
      className={classNames(defaultIconClassNames, className)}
      xmlns="http://www.w3.org/2000/svg"
      aria-label={intl.formatMessage({ defaultMessage: "Tooltip", id: "g3GD3g" })}
    >
      <path d="M0 4.5L3.53553 0.964466L7.07107 4.5L3.53553 8.03553L0 4.5Z" />
    </svg>
  );
}
