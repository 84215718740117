import classNames from "classnames";
import { useIntl } from "react-intl";

import { defaultIconClassNames, IconProps } from "./Icon";

export default function CheckIcon({ size = 32, className = "", onClick }: IconProps) {
  const intl = useIntl();
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 16 16"
      fill="none"
      className={classNames(defaultIconClassNames, className)}
      xmlns="http://www.w3.org/2000/svg"
      aria-label={intl.formatMessage({ defaultMessage: "Check", id: "RDZVQL" })}
      onClick={onClick}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.56007 7.81989L0 9.16713L3.95305 13.6492C4.34476 14.0933 5.03352 14.1195 5.45854 13.7064L16 3.4618L14.5491 2L4.79107 11.4832L1.56007 7.81989Z"
      />
    </svg>
  );
}
