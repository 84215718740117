import classNames from "classnames";
import { useIntl } from "react-intl";

import { defaultIconClassNames, IconProps } from "./Icon";

interface Props extends IconProps {
  height?: number;
  width?: number;
}

export default function VideoFileIcon({ className = "", height = 30, width = 24 }: Props) {
  const intl = useIntl();
  return (
    <svg
      width={width}
      height={height}
      className={classNames(className, defaultIconClassNames)}
      viewBox="0 0 24 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      aria-label={intl.formatMessage({ defaultMessage: "Video file", id: "4Uygjj" })}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.4 1.5c-.44 0-.9.39-.9 1v25c0 .61.46 1 .9 1h19.2c.44 0 .9-.39.9-1V8h-5.625A.875.875 0 0 1 16 7.125V1.5H2.4Zm0-1.5C1.075 0 0 1.12 0 2.5v25C0 28.88 1.075 30 2.4 30h19.2c1.325 0 2.4-1.12 2.4-2.5V7.59a1 1 0 0 0-.279-.693L17.395.307A1 1 0 0 0 16.674 0H2.4Zm4.913 20c-.155 0-.256-.074-.304-.224L5.297 14.64l-.016-.072a.163.163 0 0 1 .168-.168h.704c.07 0 .122.02.16.056a.244.244 0 0 1 .088.12l1.352 4.12 1.352-4.12a.239.239 0 0 1 .08-.12.246.246 0 0 1 .168-.056h.704c.042 0 .08.016.112.048a.152.152 0 0 1 .056.12l-.016.072-1.712 5.136c-.048.15-.15.224-.304.224h-.88Zm4.19 0a.185.185 0 0 1-.136-.056.185.185 0 0 1-.056-.136v-5.216a.17.17 0 0 1 .056-.136.185.185 0 0 1 .136-.056h.752a.17.17 0 0 1 .136.056.17.17 0 0 1 .056.136v5.216a.185.185 0 0 1-.056.136.17.17 0 0 1-.136.056h-.752Zm2.495-.056a.185.185 0 0 0 .136.056h1.992c.795 0 1.381-.176 1.76-.528.379-.357.579-.909.6-1.656.01-.309.016-.514.016-.616a22.2 22.2 0 0 0-.016-.624c-.032-1.45-.832-2.176-2.4-2.176h-1.952a.195.195 0 0 0-.144.056.213.213 0 0 0-.048.144v5.208c0 .054.019.1.056.136Zm3.056-1.16c-.197.198-.52.296-.968.296h-1.032v-3.76h.992c.448 0 .776.104.984.312.213.208.325.531.336.968.01.31.016.507.016.592 0 .08-.005.28-.016.6-.01.46-.115.79-.312.992Z"
      />
    </svg>
  );
}
