import { Ability, AbilityClass, MongoQueryOperators } from "@casl/ability";

import { PermissionProductType } from "../types";
import { AdminAbility, AdminActions, AdminSubjects } from "./admin";
import { DashboardAbility, DashboardActions, DashboardSubjects } from "./dashboard";
import { LegacyAbility, LegacyActions, LegacySubjects } from "./legacy";
import { LookupAbility, LookupActions, LookupSubjects } from "./lookup";
import { ReturnsAbility, ReturnsActions, ReturnsSubjects } from "./returns";

export type Actions =
  | AdminActions
  | ReturnsActions
  | LookupActions
  | DashboardActions
  | LegacyActions;
export type Subjects =
  | AdminSubjects
  | ReturnsSubjects
  | LookupSubjects
  | DashboardSubjects
  | LegacySubjects;
export type Abilities =
  | AdminAbility
  | ReturnsAbility
  | LookupAbility
  | DashboardAbility
  | LegacyAbility;

export type AppAbility = Ability<
  Abilities,
  { product: PermissionProductType; country?: MongoQueryOperators<string> }
>;
export const AppAbility = Ability as AbilityClass<AppAbility>;
