import classNames from "classnames";
import { useIntl } from "react-intl";

import { defaultIconClassNames, IconProps } from "./Icon";

export default function HomeIcon({ size = 32, className = "" }: IconProps) {
  const intl = useIntl();
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 16 16"
      fill="none"
      className={classNames(defaultIconClassNames, className)}
      xmlns="http://www.w3.org/2000/svg"
      aria-label={intl.formatMessage({ defaultMessage: "Home", id: "ejEGdx" })}
    >
      <path d="M15.746 7.23135L8.34595 0.138092C8.15271 -0.0473328 7.84729 -0.0473328 7.65405 0.138092L0.246704 7.23868C0.0899658 7.39542 0 7.61136 0 7.83206C0 8.29141 0.374023 8.66544 0.833374 8.66544H2V14.9987C2 15.5507 2.448 15.9987 3 15.9987H5.83337C6.10938 15.9987 6.33337 15.7747 6.33337 15.4987V11.1654C6.33337 11.074 6.40796 10.9987 6.5 10.9987H9.5C9.59131 10.9987 9.66663 11.074 9.66663 11.1654V15.4987C9.66663 15.7747 9.89062 15.9987 10.1666 15.9987H13C13.552 15.9987 14 15.5507 14 14.9987V8.66544H15.1666C15.626 8.66544 16 8.29141 16 7.83206C16 7.61136 15.91 7.39542 15.746 7.23135Z" />
    </svg>
  );
}
