import classNames from "classnames";
import { useIntl } from "react-intl";

import { defaultIconClassNames, IconProps } from "./Icon";

export default function EmptyFolderIcon({ size = 118, className = "" }: IconProps) {
  const intl = useIntl();
  return (
    <svg
      width={size}
      height={size / 1.335}
      viewBox="0 0 118 90"
      fill="none"
      className={classNames(defaultIconClassNames, className)}
      xmlns="http://www.w3.org/2000/svg"
      aria-label={intl.formatMessage({ defaultMessage: "Empty folder", id: "8a1Ts6" })}
    >
      <path
        d="M13.17 2H89c3 0 5.36 2.37 5.36 5.38v48.05A5.32 5.32 0 0189 60.81H13.17c-3 0-5.36-2.37-5.36-5.38V7.38A5.38 5.38 0 0113.17 2z"
        fill="#DAE2EB"
      />
      <path
        d="M95.88 32.83V56.5c0 3-2.46 5.59-5.57 5.59H12.33c-3 0-5.57-2.47-5.57-5.6V30.8"
        stroke="#1676EE"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <g filter="url(#filter0_d_empty_folder)">
        <path
          d="M89.16 19.59H64.64c-2.4 0-4.7.82-6.5 2.3l-6.42 5.27a10.28 10.28 0 01-6.5 2.3h-27.9c-2.8 0-5.1 2.3-5.1 5.1 0 .25 0 .5.08.75l5.1 27.16a5.2 5.2 0 005.1 4.44h59.42a5.13 5.13 0 005.1-4.36l7.16-37.12c.5-2.8-1.4-5.35-4.2-5.84h-.82z"
          fill="url(#paint0_linear_empty_folder)"
        />
      </g>
      <path
        d="M100.28 12.3H68.32c-3.11 0-6.11 1.08-8.47 3.01l-8.37 6.88a13.38 13.38 0 01-8.47 3.01H6.65A6.7 6.7 0 000 31.87c0 .32 0 .64.1.96l6.66 35.48a6.8 6.8 0 006.65 5.8h77.43a6.7 6.7 0 006.65-5.69l9.33-48.49a6.6 6.6 0 00-5.47-7.63h-1.07z"
        fill="url(#paint1_linear_empty_folder)"
      />
      <path
        opacity=".6"
        d="M77.77 72.6a20.99 20.99 0 100-41.97 20.99 20.99 0 000 41.98z"
        fill="#fff"
      />
      <path d="M109.2 77.19l-4.48 4.37-12.78-12.68 4.36-4.37 12.9 12.68z" fill="#627C91" />
      <path
        d="M60.72 33.3a24.3 24.3 0 000 34.51c9.59 9.48 25.04 9.6 34.52 0a24.3 24.3 0 000-34.52c-9.59-9.48-25.04-9.58-34.52 0zM91.3 63.97a18.97 18.97 0 01-26.74 0 18.97 18.97 0 010-26.74 18.97 18.97 0 0126.74 0 18.78 18.78 0 010 26.74zM116.12 88.48a4.95 4.95 0 01-7.14 0l-13-12.89a1.94 1.94 0 010-2.88l4.27-4.26c.74-.74 2.02-.74 2.87 0l13 12.9a5.15 5.15 0 010 7.13z"
        fill="#96AAB9"
      />
      <path
        d="M78.2 63.55c-.97 0-1.71-.74-1.71-1.7s.85-1.81 1.8-1.81c.97 0 1.82.74 1.82 1.7-.1 1.07-.85 1.81-1.81 1.81h-.1zm.1-5.43c-.96 0-1.7-.75-1.81-1.7v-3.74c0-1.8 1.17-3.4 2.87-4.04a3.1 3.1 0 002.14-3.2 3.32 3.32 0 00-3.1-3.09h-.2a3.3 3.3 0 00-3.1 2.34c-.21.75-.96 1.18-1.7 1.18-.21 0-.43 0-.64-.11a1.82 1.82 0 01-1.17-2.13c.85-2.88 3.51-5 6.6-5h.43c1.81.1 3.51.84 4.69 2.23a6.91 6.91 0 01-2.67 10.97H80.43c-.21.1-.43.32-.32.64v3.41c0 .53-.1.96-.43 1.28-.32.32-.74.53-1.27.64l-.11.32z"
        fill="#96AAB9"
      />
      <defs>
        <linearGradient
          id="paint0_linear_empty_folder"
          x1="53.24"
          y1="19.59"
          x2="53.24"
          y2="66.91"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset=".1" stopColor="#fff" />
          <stop offset="1" stopColor="#EEF2F6" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_empty_folder"
          x1="53.46"
          y1="12.3"
          x2="53.46"
          y2="74.12"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset=".1" stopColor="#fff" />
          <stop offset="1" stopColor="#EEF2F6" />
        </linearGradient>
        <filter
          id="filter0_d_empty_folder"
          x="2.22"
          y=".58"
          width="102.04"
          height="67.33"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="-9" />
          <feGaussianBlur stdDeviation="5" />
          <feColorMatrix values="0 0 0 0 0.397708 0 0 0 0 0.47749 0 0 0 0 0.575 0 0 0 0.13 0" />
          <feBlend in2="BackgroundImageFix" result="effect1_dropShadow" />
          <feBlend in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
        </filter>
      </defs>
    </svg>
  );
}
