import classNames from "classnames";
import { useIntl } from "react-intl";

import { defaultIconClassNames, IconProps } from "./Icon";

export default function SwitchErrorIcon({ className = "" }: IconProps) {
  const intl = useIntl();
  return (
    <svg
      width="160"
      height="55"
      viewBox="0 0 160 55"
      className={classNames(defaultIconClassNames, className)}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      aria-label={intl.formatMessage({ defaultMessage: "Switch error", id: "snpC87" })}
    >
      <path
        d="M118.637 15.5035C119.599 15.861 120.651 15.8848 121.651 15.6552C125.843 14.693 140.257 12.1454 137.752 23.3147C137.207 25.5307 128.765 37.9411 140.787 42.4715C141.668 42.8076 142.6 42.9911 143.542 43.0141C147.244 43.0903 154.579 42.2164 157.649 39.0914C161.357 35.3164 157.306 37.1066 157.306 37.1066C157.306 37.1066 143.207 43.259 138.227 36.7317C137.673 36.0054 137.32 34.9506 137.219 34.0425C137.044 32.4681 137.743 30.3875 138.661 28.5764C139.904 26.1251 145.871 15.0983 132.916 11.886C129.931 11.1753 125.596 10.5368 120.007 12.8901C116.747 14.2626 117.469 15.0695 118.637 15.5035Z"
        fill="#BDD6F9"
      />
      <path
        d="M121.522 11.3869L117.769 12.7914C116.474 13.2762 115.817 14.7193 116.302 16.0147L116.488 16.5117C116.972 17.807 118.415 18.4641 119.711 17.9793L123.463 16.5749C124.759 16.0901 125.416 14.647 124.931 13.3516L124.745 12.8546C124.26 11.5592 122.817 10.9021 121.522 11.3869Z"
        fill="#0E84E5"
      />
      <path
        d="M98.4878 15.4508L86.1121 20.0826C85.4644 20.325 85.1358 21.0465 85.3782 21.6942L85.427 21.8245C85.6694 22.4722 86.391 22.8007 87.0386 22.5583L99.4143 17.9266C100.062 17.6842 100.391 16.9626 100.148 16.3149L100.099 16.1847C99.857 15.537 99.1354 15.2084 98.4878 15.4508Z"
        fill="#BDD6F9"
      />
      <path
        d="M101.942 24.6793L89.5659 29.3111C88.9182 29.5535 88.5897 30.2751 88.8321 30.9227L88.8809 31.053C89.1233 31.7007 89.8448 32.0292 90.4925 31.7868L102.868 27.155C103.516 26.9126 103.844 26.1911 103.602 25.5434L103.553 25.4131C103.311 24.7654 102.589 24.4369 101.942 24.6793Z"
        fill="#BDD6F9"
      />
      <path
        d="M113.368 9.1529L103.337 12.9068C102.042 13.3917 101.385 14.8348 101.87 16.1301L105.294 25.2801C105.779 26.5755 107.222 27.2326 108.517 26.7478L118.548 22.9938C119.843 22.509 120.5 21.0659 120.015 19.7705L116.591 10.6206C116.106 9.32519 114.663 8.66809 113.368 9.1529Z"
        fill="#5895F9"
      />
      <path
        d="M104.374 11.5533L94.3433 15.3072C93.0479 15.792 92.3908 17.2352 92.8756 18.5305L96.9612 29.4468C97.446 30.7422 98.8891 31.3993 100.184 30.9145L110.215 27.1605C111.51 26.6757 112.167 25.2326 111.682 23.9372L107.597 13.0209C107.112 11.7256 105.669 11.0685 104.374 11.5533Z"
        fill="#699CFF"
      />
      <path
        d="M105.944 9.33798L105.865 9.36742C105.001 9.69063 104.563 10.6527 104.887 11.5163L110.61 26.8088C110.933 27.6724 111.895 28.1105 112.759 27.7873L112.838 27.7578C113.701 27.4346 114.139 26.4725 113.816 25.6089L108.093 10.3164C107.769 9.45284 106.807 9.01478 105.944 9.33798Z"
        fill="#208AF2"
      />
      <path
        d="M3.66809 19.1155C3.66809 19.1155 23.8123 13.4545 20.8876 26.497C20.3798 28.5607 13.0688 39.1867 21.7773 44.2885C23.6472 45.384 25.8051 45.8692 27.9722 45.8579C31.8575 45.8377 38.017 45.0899 40.7841 42.2734C44.4926 38.4983 40.4412 40.2885 40.4412 40.2885C40.4412 40.2885 28.8721 45.0474 22.9202 41.2539C20.8879 39.9587 19.9667 37.4508 20.5186 35.1048C20.8007 33.9395 21.2302 32.8149 21.7968 31.7583C23.0912 29.3339 29.0071 18.2802 16.0511 15.0679C13.0666 14.3571 8.73176 13.7187 3.14242 16.072C-2.44691 18.4252 3.66809 19.1155 3.66809 19.1155Z"
        fill="#BDD6F9"
      />
      <path
        d="M43.4928 37.3698L39.7403 38.7743C38.4449 39.2591 37.7878 40.7022 38.2726 41.9976L38.4586 42.4946C38.9434 43.79 40.3865 44.4471 41.6819 43.9623L45.4345 42.5578C46.7298 42.073 47.3869 40.6299 46.9021 39.3345L46.7161 38.8375C46.2313 37.5421 44.7882 36.885 43.4928 37.3698Z"
        fill="#0E84E5"
      />
      <path
        d="M49.8328 31.196L44.2714 33.2774C42.976 33.7623 42.3189 35.2054 42.8037 36.5008L45.4854 43.6661C45.9702 44.9615 47.4134 45.6185 48.7087 45.1337L54.2702 43.0523C55.5656 42.5675 56.2226 41.1243 55.7378 39.829L53.0561 32.6637C52.5713 31.3683 51.1282 30.7112 49.8328 31.196Z"
        fill="#5895F9"
      />
      <path
        d="M59.0914 25.6793L50.7978 28.7833C49.5024 29.2681 48.8453 30.7112 49.3301 32.0066L53.4156 42.9229C53.9005 44.2183 55.3436 44.8754 56.6389 44.3906L64.9326 41.2865C66.228 40.8017 66.8851 39.3586 66.4003 38.0632L62.3148 27.1469C61.8299 25.8516 60.3868 25.1945 59.0914 25.6793Z"
        fill="#699CFF"
      />
      <path
        d="M60.8481 23.4185L60.7695 23.448C59.9059 23.7712 59.4678 24.7333 59.791 25.5969L65.5145 40.8894C65.8377 41.753 66.7997 42.191 67.6633 41.8678L67.742 41.8384C68.6056 41.5152 69.0436 40.5531 68.7204 39.6895L62.997 24.397C62.6738 23.5334 61.7117 23.0953 60.8481 23.4185Z"
        fill="#208AF2"
      />
      <path
        d="M82.0265 13.6759C81.7278 13.6428 81.4537 13.495 81.2617 13.2638C81.0698 13.0326 80.975 12.7359 80.9974 12.4363L81.6162 1.1332C81.6531 0.458017 82.2233 -0.054962 82.8703 0.00472559L83.7719 0.0882047C84.4189 0.147892 84.8821 0.756038 84.7916 1.42646L83.271 12.646C83.236 12.9449 83.0871 13.2188 82.8553 13.4106C82.6235 13.6024 82.3266 13.6975 82.0265 13.6759Z"
        fill="#418DF9"
      />
      <path
        d="M76.6832 14.6505C76.5903 14.6778 76.493 14.6866 76.3967 14.6762C76.3004 14.6659 76.2071 14.6367 76.1221 14.5903C76.0372 14.5439 75.9621 14.4812 75.9014 14.4058C75.8406 14.3305 75.7953 14.2438 75.768 14.1509L73.5582 7.11983C73.4939 6.91987 73.5116 6.70258 73.6073 6.51564C73.7031 6.3287 73.8691 6.18737 74.0689 6.12268L74.6411 5.95004C74.7392 5.92139 74.8423 5.9134 74.9437 5.92658C75.0451 5.93976 75.1427 5.97382 75.2303 6.02661C75.3178 6.07941 75.3935 6.14978 75.4525 6.2333C75.5116 6.31682 75.5526 6.41167 75.5731 6.51186L77.211 13.7157C77.2566 13.9093 77.2277 14.113 77.1299 14.2863C77.0321 14.4595 76.8726 14.5896 76.6832 14.6505Z"
        fill="#418DF9"
      />
      <path
        d="M73.7012 18.7051C73.3053 19.1957 72.5089 19.1898 71.896 18.6918L61.0128 9.84954C60.3627 9.32137 60.1709 8.4471 60.591 7.92703L61.1764 7.20176C61.5965 6.68144 62.4592 6.72409 63.0744 7.29559L73.3721 16.8632C73.952 17.402 74.0971 18.2145 73.7012 18.7051Z"
        fill="#418DF9"
      />
      <path
        d="M78.7318 41.5438C79.0121 41.6521 79.2395 41.8649 79.366 42.1375C79.4926 42.4101 79.5084 42.7211 79.4103 43.0051L75.9253 53.775C75.7171 54.4184 75.0349 54.7684 74.4245 54.5458L73.5737 54.235C72.9634 54.012 72.6705 53.3054 72.9295 52.6807L77.2652 42.2216C77.3752 41.9414 77.5892 41.7145 77.8624 41.5882C78.1357 41.462 78.4471 41.446 78.7318 41.5438Z"
        fill="#418DF9"
      />
      <path
        d="M84.1468 41.9661C84.2436 41.9634 84.34 41.9799 84.4304 42.0144C84.5209 42.049 84.6036 42.1011 84.674 42.1676C84.7443 42.2342 84.8008 42.3139 84.8403 42.4023C84.8798 42.4907 84.9015 42.5861 84.9042 42.6828L85.245 50.0452C85.2561 50.255 85.1835 50.4605 85.0432 50.6168C84.9029 50.7731 84.7063 50.8674 84.4966 50.8789L83.8992 50.8997C83.797 50.9023 83.6953 50.8837 83.6006 50.8451C83.506 50.8064 83.4203 50.7486 83.3491 50.6752C83.2779 50.6017 83.2227 50.5144 83.187 50.4185C83.1513 50.3227 83.1358 50.2205 83.1416 50.1184L83.3981 42.7353C83.4034 42.5364 83.4834 42.3469 83.6221 42.2044C83.7609 42.0618 83.9482 41.9768 84.1468 41.9661Z"
        fill="#418DF9"
      />
      <path
        d="M88.0654 38.8076C88.5736 38.4344 89.3419 38.6434 89.8074 39.2815L98.0715 50.6096C98.5651 51.2864 98.5274 52.1803 97.988 52.5761L97.2367 53.1278C96.6977 53.5236 95.8745 53.262 95.4257 52.5523L87.9125 40.6721C87.4901 40.0034 87.5572 39.1808 88.0654 38.8076Z"
        fill="#418DF9"
      />
    </svg>
  );
}
