import classNames from "classnames";
import { useIntl } from "react-intl";

import { defaultIconClassNames } from "./Icon";
import {
  DARK_FIRST_COLOR_MAPS,
  DARK_SECOND_COLOR_MAPS,
  DARK_THIRD_COLOR_MAPS,
  LIGHT_FIRST_COLOR_MAPS,
  LIGHT_SECOND_COLOR_MAPS,
  LIGHT_THIRD_COLOR_MAPS,
  ProductIconProps,
} from "./ProductIcon";

export default function ProductInvoicingIcon({
  className,
  size = 16,
  variant = "default",
  overrideTextColor,
}: ProductIconProps) {
  const intl = useIntl();
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 16 16"
      fill="none"
      className={classNames(defaultIconClassNames, className)}
      xmlns="http://www.w3.org/2000/svg"
      aria-label={intl.formatMessage({ defaultMessage: "Product invoicing", id: "cI3Wrl" })}
    >
      <path
        fill="currentColor"
        d="M0 1C0 0.447716 0.447715 0 1 0H8.58579C8.851 0 9.10536 0.105357 9.29289 0.292893L12.7071 3.70711C12.8946 3.89464 13 4.149 13 4.41421V13C13 13.5523 12.5523 14 12 14H1C0.447715 14 0 13.5523 0 13V1Z"
        className={classNames(LIGHT_SECOND_COLOR_MAPS[variant], DARK_SECOND_COLOR_MAPS[variant])}
      />
      <rect
        fill="currentColor"
        x="3"
        y="4"
        width="10"
        height="9"
        className={classNames(
          overrideTextColor ? "text-white" : LIGHT_THIRD_COLOR_MAPS[variant],
          DARK_THIRD_COLOR_MAPS[variant]
        )}
      />
      <path
        fill="currentColor"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2 1C1.44772 1 1 1.44772 1 2V14C1 14.5523 1.44772 15 2 15H14C14.5523 15 15 14.5523 15 14V5.41421C15 5.149 14.8946 4.89464 14.7071 4.70711L11.2929 1.29289C11.1054 1.10536 10.851 1 10.5858 1H2ZM4.25 11.5C4.25 11.9142 4.58579 12.25 5 12.25C5.41421 12.25 5.75 11.9142 5.75 11.5V9.75C5.75 9.33579 5.41421 9 5 9C4.58579 9 4.25 9.33579 4.25 9.75V11.5ZM7.25 11.5C7.25 11.9142 7.58579 12.25 8 12.25C8.41421 12.25 8.75 11.9142 8.75 11.5V7.75C8.75 7.33578 8.41421 7 8 7C7.58579 7 7.25 7.33578 7.25 7.75V11.5ZM11 12.25C10.5858 12.25 10.25 11.9142 10.25 11.5L10.25 5.75C10.25 5.33578 10.5858 5 11 5C11.4142 5 11.75 5.33578 11.75 5.75V11.5C11.75 11.9142 11.4142 12.25 11 12.25Z"
        className={classNames(LIGHT_FIRST_COLOR_MAPS[variant], DARK_FIRST_COLOR_MAPS[variant])}
      />
    </svg>
  );
}
