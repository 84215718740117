import classNames from "classnames";
import { useIntl } from "react-intl";

import { defaultIconClassNames, IconProps } from "./Icon";

export default function RateClass({ size = 16, className = "" }: IconProps) {
  const intl = useIntl();
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 18 18"
      fill="none"
      className={classNames(defaultIconClassNames, className)}
      xmlns="http://www.w3.org/2000/svg"
      aria-label={intl.formatMessage({ defaultMessage: "Rate Class", id: "4IIvm+" })}
    >
      <g clipPath="url(#clip0_776_10554)">
        <path
          d="M6.75 13.5H15.75V12H6.75V13.5ZM2.25 4.5V6H15.75V4.5H2.25ZM6.75 9.75H15.75V8.25H6.75V9.75Z"
          fill="#03243D"
        />
      </g>
    </svg>
  );
}
