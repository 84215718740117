import { detect } from "detect-browser";

import { NOT_SUPPORTED_BROWSERS } from "@/components/SuggestBrowser/fixtures";

export const useIsNotBrowserSupported = () => {
  const currentBrowser = detect();

  return NOT_SUPPORTED_BROWSERS.some((browser) => {
    if (browser.name === currentBrowser?.name) {
      return (
        browser.allVersions ||
        browser.versions?.some((version) => currentBrowser.version === version) ||
        parseInt(currentBrowser.version?.split(".")[0] || "0") < (browser.belowVersion || Infinity)
      );
    }
    return false;
  });
};
