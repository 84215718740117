import classNames from "classnames";
import { useIntl } from "react-intl";

import { defaultIconClassNames, IconProps } from "./Icon";

export default function RoundedInfoIcon({ size = 16, className = "" }: IconProps) {
  const intl = useIntl();
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 16 16"
      fill="none"
      className={classNames(defaultIconClassNames, className)}
      xmlns="http://www.w3.org/2000/svg"
      aria-label={intl.formatMessage({ defaultMessage: "Rounded info", id: "Pj1Ewp" })}
    >
      <path d="M8 0.36377C3.86 0.36377 0.5 3.72377 0.5 7.86377C0.5 12.0038 3.86 15.3638 8 15.3638C12.14 15.3638 15.5 12.0038 15.5 7.86377C15.5 3.72377 12.14 0.36377 8 0.36377ZM8.75 11.6138H7.25V7.11377H8.75V11.6138ZM8.75 5.61377H7.25V4.11377H8.75V5.61377Z" />
    </svg>
  );
}
