import { MouseEventHandler } from "react";

export interface IconProps {
  size?: number;
  className?: string;
  secondColor?: string;
  onClick?: MouseEventHandler<SVGSVGElement> | undefined;
  height?: number;
  width?: number;
}

export const defaultIconClassNames = "fill-current inline-block";
