import classNames from "classnames";
import { useIntl } from "react-intl";

import { defaultIconClassNames, IconProps } from "./Icon";

export default function ErrorFillIcon({ size = 32, className = "" }: IconProps) {
  const intl = useIntl();
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      className={classNames(defaultIconClassNames, className)}
      xmlns="http://www.w3.org/2000/svg"
      aria-label={intl.formatMessage({ defaultMessage: "Error fill", id: "c/KJ3F" })}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M24 12C24 18.6274 18.6274 24 12 24C5.37258 24 0 18.6274 0 12C0 5.37258 5.37258 0 12 0C18.6274 0 24 5.37258 24 12ZM12 4.5C11.1815 4.5 10.5272 5.18091 10.56 5.9988L10.83 12.7509C10.8552 13.3789 11.3715 13.875 12 13.875C12.6285 13.875 13.1448 13.3789 13.17 12.7509L13.44 5.9988C13.4728 5.18091 12.8185 4.5 12 4.5ZM10.2751 17.4375C10.2751 16.5055 11.0307 15.75 11.9626 15.75C12.8946 15.75 13.6501 16.5055 13.6501 17.4375C13.6501 18.3695 12.8946 19.125 11.9626 19.125C11.0307 19.125 10.2751 18.3695 10.2751 17.4375Z"
      />
    </svg>
  );
}
