import { useRouter } from "next/router";
import { useEffect, useRef } from "react";

import { useAuth } from "@/hooks/auth";

import Error from "./Error";

type ErrorProxyProps = {
  statusCode?: number;
  resetError?: () => void;
};

function ErrorProxy({ statusCode = 500, resetError }: ErrorProxyProps) {
  const router = useRouter();
  const errorLocation = useRef(router.pathname);
  const auth = useAuth();

  useEffect(() => {
    if (router.pathname !== errorLocation.current) {
      resetError?.();
    }
  }, [resetError, router.pathname]);

  return <Error errorCode={statusCode} dashboard={Boolean(auth.user)} />;
}

export default ErrorProxy;
