import classNames from "classnames";
import { useIntl } from "react-intl";

import { defaultIconClassNames, IconProps } from "./Icon";

export default function CirclePendingIcon({
  size = 14,
  className = "",
}: IconProps & { color?: string }) {
  const intl = useIntl();
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 14 14"
      fill="none"
      className={classNames(defaultIconClassNames, className)}
      xmlns="http://www.w3.org/2000/svg"
      aria-label={intl.formatMessage({ defaultMessage: "Pending", id: "eKEL/g" })}
    >
      <circle fill="none" cx="7" cy="7" r="6.2" stroke="#C8D3DB" stroke-width="1.6" />
      <path d="M5 9.5H6.5V4H5V9.5ZM8 4V9.5H9.5V4H8Z" fill="#C8D3DB" />
    </svg>
  );
}
