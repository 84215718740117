import classNames from "classnames";
import { useIntl } from "react-intl";

import { defaultIconClassNames, IconProps } from "./Icon";

export default function AlignLeftIcon({ size = 16, className = "" }: IconProps) {
  const intl = useIntl();
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 16 16"
      fill="none"
      className={classNames(defaultIconClassNames, className)}
      xmlns="http://www.w3.org/2000/svg"
      aria-label={intl.formatMessage({ defaultMessage: "Align left", id: "jWDuFc" })}
    >
      <path d="M15 8.7V12.9H3.8V8.7H15ZM3.8 3.1V7.3H10.8V3.1H3.8ZM1 1V15H2.4V1H1Z" />
    </svg>
  );
}
