import { useRouter } from "next/router";
import Joyride, { ACTIONS, CallBackProps, EVENTS } from "react-joyride";

import { useTourGuideContext } from "@/hooks/useGuidedTour";

// Remove all the joyride props that we need to control from this enhanced component
type JoyrideProps = Omit<
  React.ComponentProps<typeof Joyride>,
  "run" | "stepIndex" | "steps" | "continuous" | "disableScrolling"
>;

// This is a controlled joyride component that can pause and resume based on step properties, and works with route changes and scroll adjustments
export default function EnhancedJoyride(joyrideProps: JoyrideProps) {
  const router = useRouter();
  const {
    tourGuideState: { run, stepIndex, steps },
    setTourGuideState,
  } = useTourGuideContext();

  const handleStepAfter = (data: CallBackProps) => {
    const { index, action } = data;

    const currentStep = steps[index];

    if (action === ACTIONS.NEXT) {
      if (index === steps.length - 1) {
        // Last step
        setTourGuideState({ run: false, tourActive: false });
        return;
      }

      const nextStep = steps[index + 1];

      if (nextStep.route && currentStep.route !== nextStep.route) {
        router.push(nextStep.route, undefined, { shallow: true }).then(() => {
          setTourGuideState({ run: false, stepIndex: index + 1 });
        });
      } else {
        setTourGuideState({ run: false, stepIndex: index + 1 });
      }
    }

    if (action === ACTIONS.PREV) {
      if (index === 0) {
        // First step
        setTourGuideState({ run: false, tourActive: false });
        return;
      }

      const prevStep = steps[index - 1];

      if (prevStep.route && currentStep.route !== prevStep.route) {
        router.push(prevStep.route, undefined, { shallow: true }).then(() => {
          setTourGuideState({ run: false, stepIndex: index - 1 });
        });
      } else {
        setTourGuideState({ run: false, stepIndex: index - 1 });
      }
    }
  };

  const handleTooltipAdjustments = (data: CallBackProps) => {
    const { index } = data;

    // Scroll element fully into view if it is supposed to show in full
    const step = steps[index];

    if (step.shouldShowInFull) {
      const targetElement =
        typeof step.target === "string" ? document.querySelector(step.target) : step.target;

      if (!targetElement) {
        return;
      }

      const rect = targetElement.getBoundingClientRect();

      const isInView =
        rect.top >= 0 &&
        rect.left >= 0 &&
        rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
        rect.right <= (window.innerWidth || document.documentElement.clientWidth);

      if (!isInView) {
        targetElement.scrollIntoView();
      }
    }
  };

  const defaultJoyrideCallback = (data: CallBackProps) => {
    const { type, action } = data;

    if (action === ACTIONS.CLOSE) {
      setTourGuideState({ run: false, tourActive: false, stepIndex: 0 });
      return;
    }

    if (type === EVENTS.STEP_AFTER) {
      handleStepAfter(data);
    }

    if (type === EVENTS.TOOLTIP) {
      handleTooltipAdjustments(data);
    }
  };

  const defaultJoyrideStyles: typeof joyrideProps.styles = {
    options: {
      arrowColor: "transparent",
      textColor: "text-blueGray700",
      primaryColor: "#2D69DC",
      width: 300,
      zIndex: 1000,
    },
  };

  return (
    <Joyride
      // We can override these via props
      styles={defaultJoyrideStyles}
      callback={defaultJoyrideCallback}
      showProgress={joyrideProps.showProgress ? joyrideProps.showProgress : true}
      {...joyrideProps}
      // We cannot override these
      continuous
      disableScrolling // REMINDER: no, don't remove it, because it mess up the scroll. If you need to auto-scroll in the step, set it to false on the individual step instead.
      stepIndex={stepIndex}
      run={run}
      steps={steps}
    />
  );
}
