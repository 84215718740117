import { A, Typography } from "@fonoa/ui-components/typography";
import Image from "next/image";
import { FormattedMessage } from "react-intl";

import { NOT_SUPPORTED_BROWSERS } from "@/components/SuggestBrowser/fixtures";

export const SuggestBrowser = () => (
  <div className="absolute z-20 h-full w-full bg-white">
    <div className="mx-auto mt-40 flex max-w-2xl flex-col items-center">
      <Image src="/img/support-browsers.svg" alt="" width="240" height="154" />
      <Typography
        component="h3"
        color="text-blueGray600"
        spacing={{ mt: 8 }}
        fontSize="text-lg"
        fontWeight="font-medium"
      >
        <FormattedMessage defaultMessage="Please update your browser." id="akCJle" />
      </Typography>
      <Typography color="text-blueGray600" spacing={{ mt: 4 }}>
        <FormattedMessage
          defaultMessage="Your current browser does not support Fonoa app. Please make sure your browser is fully updated or try a suggested browser below."
          id="CZO6E0"
        />
      </Typography>
      <Typography color="text-blueGray600" spacing={{ mt: 6 }}>
        <FormattedMessage
          defaultMessage="For more information please visit our <link1>support page</link1> or <link2>contact us</link2> for support."
          values={{
            link1: (chunks) => (
              <A
                className="mx-1 inline text-primaryBlue500"
                href="https://www.fonoa.com/help-center"
              >
                {chunks}
              </A>
            ),
            link2: (chunks) => (
              <A
                className="mx-1 inline text-primaryBlue500"
                href="https://www.fonoa.com/contact-us"
              >
                {chunks}
              </A>
            ),
          }}
          id="tBoIeE"
        />
      </Typography>
      <div className="mt-10 flex justify-between gap-x-8">
        {NOT_SUPPORTED_BROWSERS.filter(({ allVersions }) => !allVersions).map(
          ({ name, fullName, belowVersion, url }) => (
            <A key={name} className="mt-5 flex flex-col items-center" href={url}>
              <Image
                src={`/img/browsers/${name}.svg`}
                alt={fullName}
                width="32"
                height="32"
                className="h-auto max-w-full"
              />
              <Typography color="text-primaryBlue500" spacing={{ mt: 3 }} fontWeight="font-medium">
                {fullName}
              </Typography>
              <p className="text-xs text-blueGray600">
                <FormattedMessage
                  defaultMessage="Version {version}+"
                  id="ey/5ct"
                  values={{ version: belowVersion }}
                />
              </p>
            </A>
          )
        )}
      </div>
    </div>
  </div>
);
