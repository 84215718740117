import { useQuery, UseQueryOptions } from "@tanstack/react-query";
import axios from "axios";
import FormData from "form-data";

import { ValidateCSVResponseV1 } from "@/api/lookup/post-batch-validations-v1";
import { ValidateCSVResponseV2 } from "@/api/lookup/post-batch-validations-v2";
import { ApiResponse } from "@/server/types";

import { UploadCSVQueryKeys } from "./CsvUploadValidation.queries";
import {
  GetBatchValidationsResponse,
  GetBatchValidationsVariables,
} from "./CsvUploadValidation.types";

interface ValidateCSVProps {
  data: FormData;
  apiVersion: 1 | 2;
}

export async function validateCSV({
  data,
  apiVersion,
}: ValidateCSVProps): Promise<ApiResponse<ValidateCSVResponseV1 | ValidateCSVResponseV2>> {
  const url =
    apiVersion === 2
      ? "/api/lookup/post-batch-validations-v2"
      : "/api/lookup/post-batch-validations-v1";
  const res = await axios.post(url, data);

  return res.data;
}

interface GetBatchValidationsProps {
  variables?: GetBatchValidationsVariables;
}

async function getBatchValidations({ variables }: GetBatchValidationsProps) {
  const res = await axios.get("/api/lookup/get-batch-validations", {
    params: { variables },
  });

  return res.data;
}

export interface UseBatchValidationsProps<T> {
  variables?: GetBatchValidationsVariables;
  options?: UseQueryOptions<T, unknown, GetBatchValidationsResponse>;
}

export const useBatchValidations = ({
  variables,
  options,
}: UseBatchValidationsProps<GetBatchValidationsResponse>) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return useQuery<any, unknown, GetBatchValidationsResponse>(
    [UploadCSVQueryKeys.batchValidations, variables, options],
    () => getBatchValidations({ variables }),
    {
      select: (d) => d.data,
      ...options,
    }
  );
};
