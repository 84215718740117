const colorTokens = require("./tokens/color/colors.json");

const colors = {
  white: "#fff",
  black: "#000",

  current: "currentColor",
  default: colorTokens.primaryBlue800,

  transparent: "rgba(0, 0, 0, 0)",

  ...colorTokens,
};

const sizes = {
  0: 0,
  0.5: 0,
  1: 1,
  1.5: 1,
  2: 2,
  2.5: 2,
  3: 3,
  3.5: 3,
  4: 4,
  4.5: 4,
  5: 5,
  5.5: 5.5,
  6: 6,
  6.5: 6.5,
  7: 7,
  7.5: 7,
  8: 8,
  9: 9,
  10: 10,
  11: 11,
  12: 12,
  12.5: 12.5,
  14: 14,
  15: 15,
  16: 16,
  18: 18,
  20: 20,
  22: 22,
  23: 23,
  24: 24,
  26: 26,
  28: 28,
  32: 32,
  36: 36,
  38: 38,
  40: 40,
  44: 44,
  48: 48,
  52: 52,
  56: 56,
  60: 60,
  63: 63,
  64: 64,
  72: 72,
  80: 80,
  96: 96,
  104: 104,
  150: 150,
  px: "px",
  auto: "auto",
  full: "full",
};

module.exports = {
  colors,
  sizes,
};
