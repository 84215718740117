import classNames from "classnames";
import { useIntl } from "react-intl";

import { defaultIconClassNames, IconProps } from "./Icon";

interface Props extends IconProps {
  height?: number;
  width?: number;
}

export default function CsvFileIcon({ className = "", height = 30, width = 24 }: Props) {
  const intl = useIntl();
  return (
    <svg
      height={height}
      width={width}
      className={classNames(className, defaultIconClassNames)}
      viewBox="0 0 24 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      aria-label={intl.formatMessage({ defaultMessage: "Csv file", id: "dly/2T" })}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.4 1.5C1.96007 1.5 1.5 1.88942 1.5 2.5V27.5C1.5 28.1106 1.96007 28.5 2.4 28.5H21.6C22.0399 28.5 22.5 28.1106 22.5 27.5V15V8H16.875C16.3918 8 16 7.60825 16 7.125V1.5H12H2.4ZM2.4 0C1.07452 0 0 1.11929 0 2.5V27.5C0 28.8807 1.07452 30 2.4 30H21.6C22.9255 30 24 28.8807 24 27.5V15V7.58984C24 7.33169 23.9002 7.08354 23.7214 6.89731L17.3953 0.307474C17.2067 0.111041 16.9462 0 16.6739 0H12H2.4Z"
      />
      <path d="M16.8413 19.9203C16.6866 19.9203 16.5853 19.8456 16.5373 19.6963L14.8253 14.5603L14.8093 14.4883C14.8093 14.4403 14.8253 14.4003 14.8573 14.3683C14.8893 14.3363 14.9293 14.3203 14.9773 14.3203H15.6813C15.7506 14.3203 15.8039 14.339 15.8413 14.3763C15.8839 14.4083 15.9133 14.4483 15.9293 14.4963L17.2813 18.6163L18.6333 14.4963C18.6439 14.4536 18.6706 14.4136 18.7133 14.3763C18.7559 14.339 18.8119 14.3203 18.8813 14.3203H19.5853C19.6279 14.3203 19.6653 14.3363 19.6973 14.3683C19.7346 14.4003 19.7533 14.4403 19.7533 14.4883L19.7373 14.5603L18.0253 19.6963C17.9773 19.8456 17.8759 19.9203 17.7213 19.9203H16.8413Z" />
      <path d="M6.94577 20.0803C6.20977 20.0803 5.6391 19.891 5.23377 19.5123C4.83377 19.1336 4.62044 18.6003 4.59377 17.9123C4.58844 17.7683 4.58577 17.5336 4.58577 17.2083C4.58577 16.8776 4.58844 16.6376 4.59377 16.4883C4.62044 15.8056 4.83644 15.275 5.24177 14.8963C5.65244 14.5123 6.22044 14.3203 6.94577 14.3203C7.42044 14.3203 7.83377 14.403 8.18577 14.5683C8.53777 14.7283 8.80977 14.9496 9.00177 15.2323C9.19377 15.5096 9.2951 15.8243 9.30577 16.1763V16.1923C9.30577 16.235 9.28711 16.2723 9.24977 16.3043C9.21777 16.331 9.18044 16.3443 9.13777 16.3443H8.37777C8.31377 16.3443 8.26577 16.331 8.23377 16.3043C8.20177 16.2776 8.17511 16.227 8.15377 16.1523C8.06844 15.8163 7.9271 15.5816 7.72977 15.4483C7.53244 15.3096 7.26844 15.2403 6.93777 15.2403C6.16977 15.2403 5.76977 15.6696 5.73777 16.5283C5.73244 16.6723 5.72977 16.8936 5.72977 17.1923C5.72977 17.491 5.73244 17.7176 5.73777 17.8723C5.76977 18.731 6.16977 19.1603 6.93777 19.1603C7.26844 19.1603 7.53244 19.091 7.72977 18.9523C7.93244 18.8136 8.07377 18.579 8.15377 18.2483C8.16977 18.1736 8.19377 18.123 8.22577 18.0963C8.26311 18.0696 8.31377 18.0563 8.37777 18.0563H9.13777C9.18577 18.0563 9.22577 18.0723 9.25777 18.1043C9.2951 18.1363 9.31111 18.1763 9.30577 18.2243C9.2951 18.5763 9.19377 18.8936 9.00177 19.1763C8.80977 19.4536 8.53777 19.675 8.18577 19.8403C7.83377 20.0003 7.42044 20.0803 6.94577 20.0803Z" />
      <path d="M11.056 19.8643C11.3973 20.0083 11.8053 20.0803 12.28 20.0803C12.728 20.0803 13.1227 20.0136 13.464 19.8803C13.8107 19.747 14.0773 19.555 14.264 19.3043C14.456 19.0483 14.552 18.7496 14.552 18.4083C14.552 18.0936 14.4853 17.835 14.352 17.6323C14.2187 17.4296 14.0107 17.2616 13.728 17.1283C13.4507 16.995 13.0773 16.875 12.608 16.7683C12.2613 16.6776 11.9947 16.595 11.808 16.5203C11.6267 16.4456 11.4933 16.3603 11.408 16.2643C11.3227 16.1683 11.28 16.0456 11.28 15.8963C11.28 15.683 11.3653 15.5203 11.536 15.4083C11.7067 15.2963 11.9413 15.2403 12.24 15.2403C12.5067 15.2403 12.7307 15.299 12.912 15.4163C13.0933 15.5283 13.2 15.6696 13.232 15.8403C13.2747 15.9416 13.352 15.9923 13.464 15.9923H14.208C14.256 15.9923 14.296 15.9763 14.328 15.9443C14.36 15.9123 14.376 15.8723 14.376 15.8243C14.3653 15.595 14.2747 15.3656 14.104 15.1363C13.9387 14.9016 13.696 14.707 13.376 14.5523C13.056 14.3976 12.6773 14.3203 12.24 14.3203C11.8187 14.3203 11.448 14.3896 11.128 14.5283C10.8133 14.667 10.5707 14.8563 10.4 15.0963C10.2347 15.3363 10.152 15.611 10.152 15.9203C10.152 16.379 10.296 16.7336 10.584 16.9843C10.8773 17.2296 11.3387 17.427 11.968 17.5763C12.352 17.6776 12.64 17.763 12.832 17.8323C13.0293 17.9016 13.176 17.9843 13.272 18.0803C13.3733 18.1763 13.424 18.299 13.424 18.4483C13.424 18.6723 13.328 18.8483 13.136 18.9763C12.944 19.099 12.6587 19.1603 12.28 19.1603C11.9493 19.1603 11.688 19.099 11.496 18.9763C11.304 18.8536 11.1813 18.7016 11.128 18.5203C11.096 18.467 11.0613 18.4296 11.024 18.4083C10.992 18.3816 10.944 18.3683 10.88 18.3683H10.168C10.12 18.3683 10.08 18.3843 10.048 18.4163C10.016 18.4483 10 18.4883 10 18.5363C10.0107 18.8083 10.104 19.0616 10.28 19.2963C10.456 19.531 10.7147 19.7203 11.056 19.8643Z" />
    </svg>
  );
}
