import classNames from "classnames";
import { ReactNode } from "react";
import { FormattedMessage } from "react-intl";

import { CloseButton, CloseButtonProps } from "../button";
import IconButton from "../forms/IconButton";
import { EditIcon, InvoiceErrorIcon, TrashIcon } from "../icons";
import { DataAttributes, ThemeColorBg } from "../types";
import { Typography } from "../typography";
import { convertDataAttributes, KEYS, useOnKeyPress } from "../utils";

export interface DrawerProps {
  title?: ReactNode;
  topRightBlock?: ReactNode;
  subTitle?: ReactNode;
  children?: ReactNode;
  open?: boolean;
  error?: boolean;
  errorMessage?: ReactNode;
  loading?: boolean;
  onClose: () => void;
  onCloseButtonClick?: () => void;
  onOverlayClick?: () => void;
  onEdit?: () => void;
  onDelete?: () => void;
  dataAttributes?: DataAttributes;
  subtitleButton?: ReactNode;
  closeButtonProps?: CloseButtonProps;
  floatingClose?: boolean;
  customDrawerWidthClassName?: string;
  customHeaderBackgroundColor?: ThemeColorBg;
}

export default function Drawer({
  title,
  subTitle,
  topRightBlock,
  children,
  open = false,
  error = false,
  errorMessage = <FormattedMessage defaultMessage="Sorry, there was an error" id="oZrBEC" />,
  loading = false,
  onClose,
  onEdit,
  onDelete,
  onOverlayClick,
  onCloseButtonClick,
  dataAttributes = {},
  subtitleButton,
  closeButtonProps,
  floatingClose = false,
  customDrawerWidthClassName,
  customHeaderBackgroundColor: customHeaderBackgroundClassName,
}: DrawerProps) {
  useOnKeyPress(KEYS.ESCAPE, onClose, open);

  function buildHeader() {
    const hasTitleLine = title || topRightBlock;
    const hasHeader = hasTitleLine || subTitle;
    if (!hasHeader) return undefined;
    return (
      <div className={classNames("py-5 px-7", customHeaderBackgroundClassName)}>
        {hasTitleLine && (
          <div className="flex items-start justify-between">
            {title && (
              <Typography component="h2" data-cy="drawer-title">
                {title}
              </Typography>
            )}
            <div className="space-x-2">
              {topRightBlock}
              <div className="relative top-3 flex space-x-2">
                {onDelete && (
                  <IconButton
                    variant="OUTLINED"
                    icon={TrashIcon}
                    iconSize={9}
                    onClick={onDelete}
                    size="SMALL"
                  />
                )}
                {onEdit && (
                  <IconButton
                    variant="OUTLINED"
                    icon={EditIcon}
                    iconSize={9}
                    onClick={onEdit}
                    size="SMALL"
                  />
                )}
              </div>
            </div>
          </div>
        )}
        {subTitle && (
          <div
            className={classNames("flex w-full justify-between", {
              "items-center": subtitleButton,
              "items-start": !subtitleButton,
            })}
          >
            {subTitle && (
              <Typography color="text-blueGray700" fontWeight="font-light" spacing={{ mt: 1 }}>
                {subTitle}
              </Typography>
            )}
            {subtitleButton && subtitleButton}
          </div>
        )}
      </div>
    );
  }

  return (
    <section
      {...convertDataAttributes(dataAttributes)}
      className={classNames("fixed inset-0 z-50 overflow-hidden", {
        "opacity-100 transition ease-linear": open,
        "invisible opacity-0 transition ease-linear": !open,
      })}
    >
      <div className="absolute inset-0 overflow-hidden">
        {/* scrim */}
        {open && (
          <div
            onClick={() => {
              onClose();
              onOverlayClick?.();
            }}
            className="absolute inset-0 bg-black/60"
          >
            <span className="sr-only">
              <FormattedMessage defaultMessage="Overlay" id="KLiK/x" />
            </span>
          </div>
        )}
        <div
          className={classNames(
            customDrawerWidthClassName ? customDrawerWidthClassName : "max-w-xl xl:max-w-[45%]",
            "absolute right-0 h-full w-screen pl-10 transition duration-500 ease-in-out md:pl-0",
            {
              "translate-x-0": open,
              "translate-x-full": !open,
            }
          )}
        >
          <div
            className={classNames(
              floatingClose ? "relative" : "",
              "flex h-full flex-col overflow-y-scroll bg-white shadow-xl"
            )}
            tabIndex={0}
          >
            <div
              className={classNames(
                floatingClose ? "absolute top-4 right-0 z-10" : "flex justify-end pt-6",
                customHeaderBackgroundClassName
              )}
            >
              <CloseButton
                size={24}
                spacing={{ px: 5 }}
                onClose={() => {
                  onClose();
                  onCloseButtonClick?.();
                }}
                {...closeButtonProps}
              />
            </div>
            {loading ? (
              <div className="p-7">
                <div className="bg-blueGray200 h-4 w-48 animate-pulse p-0">
                  <span className="sr-only">
                    <FormattedMessage defaultMessage="Loading" id="iFsDVR" />
                  </span>
                </div>
                <div className="bg-blueGray200 mt-3 h-3 w-24 animate-pulse p-0" />
              </div>
            ) : (
              !error && buildHeader()
            )}

            {error ? (
              <div className="flex flex-1 items-center justify-center text-center">
                <div>
                  {InvoiceErrorIcon && <InvoiceErrorIcon className="text-red700 fill-current" />}
                  <div className="py-5">
                    <Typography component="h3">{errorMessage}</Typography>
                    {children}
                  </div>
                </div>
              </div>
            ) : (
              children
            )}
          </div>
        </div>
      </div>
    </section>
  );
}
