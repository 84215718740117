import classNames from "classnames";
import { ButtonHTMLAttributes, ComponentType } from "react";

import { IconProps } from "../icons";
import { Loader } from "../loaders";
import { SpacingProps } from "../types";
import { getSpacingClass } from "../utils";

export type IconButtonVariantBase = "FILLED" | "OUTLINED";
type IconButtonVariantDeviation = "SUCCESS" | "WARNING" | "ALERT";
type IconButtonSpecialVariant = "COPY" | "COPY_LIGHT";
export type IconButtonVariant =
  | IconButtonVariantBase
  | IconButtonSpecialVariant
  | `${IconButtonVariantBase}_${IconButtonVariantDeviation}`
  | "FILLED_LIGHT";

type SizeVariant = "EXTRA_SMALL" | "SMALL" | "REGULAR" | "LARGE";
type RoundedVariant = "REGULAR" | "FULL";

const VARIANT_MAPS: Record<IconButtonVariant, string> = {
  FILLED:
    "bg-primaryBlue500 border-2 border-primaryBlue500 hover:bg-primaryBlue600 hover:border-primaryBlue600 active:bg-primaryBlue700 active:border-primaryBlue600 focus:bg-primaryBlue500 focus:border-2 focus:border-blueGray300",
  FILLED_SUCCESS:
    "bg-green700 border-2 border-green700 hover:bg-green800 hover:border-green800 active:bg-green800 active:border-greenActive focus:bg-green500 focus:border-2 focus:border-blueGray300",
  FILLED_WARNING:
    "bg-yellow700 border-2 border-yellow700 hover:bg-yellow800 hover:border-yellow800 active:border-yellow800 active:bg-yellow800 focus:bg-yellow600 focus:border-2 focus:border-blueGray300",
  FILLED_ALERT:
    "bg-red500 border-2 border-red500 hover:bg-red600 hover:border-red600 active:border-red700 active:bg-red700 focus:bg-red500 focus:border-2 focus:border-blueGray300",
  FILLED_LIGHT:
    "text-blueGray900 font-medium bg-white border-1 border-blueGray200 hover:border-primaryBlue900 focus:border-1 focus:border-blueGray300 active:bg-blueGray10 active:border-primaryBlue900 disabled:text-blueGray200 disabled:border-blueGray200 bg-white disabled:text-blueGray200",
  OUTLINED:
    "border border-blueGray200 hover:border-primaryBlue900 focus:border-2 focus:border-blueGray300 active:bg-blueGray10 active:border-primaryBlue900 bg-white",
  OUTLINED_SUCCESS:
    "border border-green700 hover:border-green800 active:bg-blueGray10 active:border-green800 focus:border-2 focus:border-blueGray300",
  OUTLINED_WARNING:
    "border border-yellow700 hover:border-yellow800 active:bg-blueGray10 active:border-yellow800 focus:border-2 focus:border-blueGray300",
  OUTLINED_ALERT:
    "border border-red500 hover:border-red600 active:bg-blueGray10 active:border-red700 focus:border-2 focus:border-blueGray300",
  COPY: "bg-primaryBlue50 border border-primaryBlue100 focus:border-2",
  COPY_LIGHT: "bg-blueGray-100 focus:border-1",
};

const DISABLED_VARIANT_MAPS: Record<IconButtonVariantBase, string> = {
  FILLED: "disabled:bg-blueGray300 disabled:border-blueGray300",
  OUTLINED: "disabled:border-blueGray200 disabled:opacity-50",
};

const LOADER_VARIANT_MAPS: Record<IconButtonVariantBase, string> = {
  FILLED: "text-white",
  OUTLINED: "text-primaryBlue900",
};

const ICON_VARIANT_MAPS: Record<IconButtonVariant, string> = {
  FILLED: "text-white",
  FILLED_SUCCESS: "text-white",
  FILLED_WARNING: "text-white",
  FILLED_ALERT: "text-white",
  FILLED_LIGHT: "text-blueGray900",
  OUTLINED: "text-blueGray900",
  OUTLINED_SUCCESS: "text-green700",
  OUTLINED_WARNING: "text-yellow700",
  OUTLINED_ALERT: "text-red500",
  COPY: "text-primaryBlue400",
  COPY_LIGHT: "text-blueGray600",
};

const SIZE_VARIANT_MAPS: Record<SizeVariant, string> = {
  EXTRA_SMALL: "h-7 w-7",
  SMALL: "h-8 w-8",
  REGULAR: "h-10 w-10",
  LARGE: "h-12 w-12",
};

const ROUNDED_VARIANT_MAPS: Record<RoundedVariant, `rounded${string}`> = {
  REGULAR: "rounded",
  FULL: "rounded-full",
};

interface SharedIconButtonProps
  extends Omit<ButtonHTMLAttributes<HTMLButtonElement>, "loading" | "disabled">,
    SpacingProps {
  icon: ComponentType<IconProps>;
  iconSize?: number;
  size?: SizeVariant;
  variant: IconButtonVariant;
  rounded?: RoundedVariant;
  loading?: boolean;
}

interface DeviationVariantProps extends SharedIconButtonProps {
  variant: `${IconButtonVariantBase}_${IconButtonVariantDeviation}` | "FILLED_LIGHT";
  loading?: never;
  disabled?: never;
}

interface SpecialVariantProps
  extends SharedIconButtonProps,
    Pick<ButtonHTMLAttributes<HTMLButtonElement>, "disabled"> {
  variant: IconButtonSpecialVariant;
  loading?: never;
}

interface BaseVariantProps
  extends SharedIconButtonProps,
    Pick<ButtonHTMLAttributes<HTMLButtonElement>, "disabled"> {
  variant: IconButtonVariantBase;
  loading?: boolean;
}

export type IconButtonProps = DeviationVariantProps | BaseVariantProps | SpecialVariantProps;

export default function IconButton({
  disabled = false,
  variant,
  size = "REGULAR",
  iconSize = 16,
  spacing,
  icon: Icon,
  rounded = "REGULAR",
  loading,
  ...props
}: IconButtonProps) {
  return (
    <button
      data-testid="icon-button"
      disabled={disabled || loading}
      className={classNames(
        "flex items-center justify-center focus:outline-none disabled:cursor-not-allowed",
        VARIANT_MAPS[variant],
        SIZE_VARIANT_MAPS[size],
        ROUNDED_VARIANT_MAPS[rounded],
        getSpacingClass(spacing),
        { [DISABLED_VARIANT_MAPS[variant as IconButtonVariantBase]]: disabled }
      )}
      {...props}
    >
      {!loading && <Icon size={iconSize} className={ICON_VARIANT_MAPS[variant]} />}
      {loading && <Loader className={classNames(LOADER_VARIANT_MAPS[variant])} size={iconSize} />}
    </button>
  );
}
