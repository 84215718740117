import classNames from "classnames";
import { useIntl } from "react-intl";

import { defaultIconClassNames, IconProps } from "./Icon";

export default function WarningIcon({ size = 16, className = "" }: IconProps) {
  const intl = useIntl();
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 16 16"
      fill="none"
      className={classNames(defaultIconClassNames, className)}
      xmlns="http://www.w3.org/2000/svg"
      aria-label={intl.formatMessage({ defaultMessage: "Warning", id: "3SVI5p" })}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.947 7.56004L13.707 6.14671L13.8803 4.27338C13.9137 3.94004 13.687 3.63338 13.367 3.56004L11.527 3.14004L10.567 1.52004C10.3937 1.23338 10.0403 1.11338 9.73367 1.24671L8.00034 2.00004L6.27367 1.26004C5.967 1.12671 5.60701 1.24671 5.44034 1.53338L4.47367 3.14671L2.64034 3.56004C2.31367 3.63338 2.08701 3.93338 2.12034 4.27338L2.29367 6.14004L1.05367 7.56004C0.833672 7.81338 0.833672 8.18671 1.05367 8.44004L2.29367 9.85338L2.12034 11.7334C2.08701 12.0667 2.31367 12.3734 2.63367 12.4467L4.47367 12.8667L5.43367 14.48C5.60701 14.7667 5.96034 14.8867 6.27367 14.7534L8.00034 14L9.72701 14.74C10.0337 14.8734 10.3937 14.7534 10.5603 14.4667L11.5203 12.8467L13.3603 12.4267C13.687 12.3534 13.907 12.0467 13.8737 11.7134L13.7003 9.84004L14.9403 8.42671C15.167 8.18671 15.167 7.81338 14.947 7.56004ZM8.66701 11.3334H7.33367V10H8.66701V11.3334ZM8.00034 8.66671C7.63367 8.66671 7.33367 8.36671 7.33367 8.00004V5.33338C7.33367 4.96671 7.63367 4.66671 8.00034 4.66671C8.36701 4.66671 8.66701 4.96671 8.66701 5.33338V8.00004C8.66701 8.36671 8.36701 8.66671 8.00034 8.66671Z"
      />
    </svg>
  );
}
