import { useIntl } from "react-intl";

import { IconProps } from "./Icon";

const ArrowForwardIcon = ({ size = 32, className = "", onClick }: IconProps) => {
  const intl = useIntl();
  return (
    <svg
      width={size}
      className={className}
      onClick={onClick}
      height={size}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      aria-label={intl.formatMessage({ defaultMessage: "Arrow forward", id: "ION5xQ" })}
    >
      <path
        d="M9 3L7.9425 4.0575L12.1275 8.25H3V9.75H12.1275L7.9425 13.9425L9 15L15 9L9 3Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default ArrowForwardIcon;
