import classNames from "classnames";
import { useIntl } from "react-intl";

import { defaultIconClassNames, IconProps } from "./Icon";

export default function QuestionMarkIcon({ size = 14, className = "" }: IconProps) {
  const intl = useIntl();
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 14 15"
      fill="none"
      className={classNames(defaultIconClassNames, className)}
      xmlns="http://www.w3.org/2000/svg"
      aria-label={intl.formatMessage({ defaultMessage: "Question mark", id: "hKrDuE" })}
    >
      <path d="M6.45744 8.46947C6.90661 7.65863 7.76994 7.1803 8.27161 6.4628C8.80244 5.7103 8.50494 4.30447 6.99994 4.30447C6.01411 4.30447 5.52994 5.05113 5.32578 5.66947L3.81494 5.03363C4.22911 3.79113 5.35494 2.72363 6.99411 2.72363C8.36494 2.72363 9.30411 3.3478 9.78244 4.12947C10.1908 4.8003 10.4299 6.05447 9.79994 6.9878C9.09994 8.0203 8.42911 8.3353 8.06744 9.0003C7.92161 9.26863 7.86328 9.44363 7.86328 10.307H6.17744C6.17161 9.85197 6.10161 9.11113 6.45744 8.46947ZM8.16661 12.6403C8.16661 13.282 7.64161 13.807 6.99994 13.807C6.35828 13.807 5.83328 13.282 5.83328 12.6403C5.83328 11.9986 6.35828 11.4736 6.99994 11.4736C7.64161 11.4736 8.16661 11.9986 8.16661 12.6403Z" />
    </svg>
  );
}
