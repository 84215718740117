import classNames from "classnames";
import { useIntl } from "react-intl";

import { defaultIconClassNames, IconProps } from "./Icon";

export default function BookmarkIcon({ size = 16, className = "", onClick }: IconProps) {
  const intl = useIntl();
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 18 18"
      fill="none"
      className={classNames(defaultIconClassNames, className)}
      onClick={onClick}
      xmlns="http://www.w3.org/2000/svg"
      aria-label={intl.formatMessage({ defaultMessage: "Bookmark", id: "Rs4kCE" })}
    >
      <path d="M13.5 1.5L4.5 1.5C3.675 1.5 3 2.175 3 3L3 15C3 15.825 3.675 16.5 4.5 16.5H13.5C14.325 16.5 15 15.825 15 15L15 3C15 2.175 14.325 1.5 13.5 1.5ZM6.75 3H8.25V6.75L7.5 6.1875L6.75 6.75L6.75 3ZM13.5 15L4.5 15L4.5 3L5.25 3L5.25 9.75L7.5 8.0625L9.75 9.75V3L13.5 3L13.5 15Z" />
    </svg>
  );
}
