import { noop } from "@fonoa/ui-components/utils";
import { useRouter } from "next/router";
import { createContext, ReactNode, useContext, useEffect, useState } from "react";
import { useIntl } from "react-intl";

import { useNotificationsDispatcher } from "@/components/Notifications/NotificationsManager";
import { DatesRange, mapTagToRange } from "@/components/Utils/Date";
import { useBatchValidations } from "@/features/Lookup/CsvUploadValidation/CsvUploadValidation.api";
import { GetBatchValidationsResponse } from "@/features/Lookup/CsvUploadValidation/CsvUploadValidation.types";
import { useAuth, useTenant } from "@/hooks/auth";
import { useDemoDataMode } from "@/hooks/useDemoDataMode";
import { Products } from "@/lib/product";

import { buildValidationNotification } from "./ValidationsNotifications";

export type ValidationsStatus = "idle" | "fetching";

export interface ValidationsState {
  status: ValidationsStatus;
  isLoading: boolean;
  validations: GetBatchValidationsResponse | undefined;
  error?: Error;
  fetchValidations: () => void;
}

const INITIAL_STATE: ValidationsState = {
  status: "idle",
  isLoading: true,
  validations: undefined,
  fetchValidations: noop,
};

const ValidationContext = createContext<ValidationsState>(INITIAL_STATE);

const requestIntervalInMs = 2500;
export interface ValidationContextProviderProps {
  validationRange?: DatesRange;
  children: ReactNode;
  enabled: boolean;
  startValidating?: boolean;
  retry?: number;
}

const inProgressStatus = new Set<string>(["running", "paused"]);

const defaultValidationRange = mapTagToRange("last_24_hours");
export const ValidationContextProvider = ({
  children,
  validationRange = defaultValidationRange,
  enabled,
  startValidating = true,
  retry = 3,
}: ValidationContextProviderProps) => {
  const intl = useIntl();
  const router = useRouter();
  const notificationsDispatcher = useNotificationsDispatcher();
  const { initialised: demoDataModeInitialised } = useDemoDataMode();
  const { from } = validationRange;
  const [validationRequest, setFetchValidations] = useState(startValidating);
  const [itWasInProgress, setItWasInProgress] = useState<Set<string>>(new Set());
  const [state, setState] = useState<ValidationsState>({
    status: "idle",
    isLoading: false,
    validations: undefined,
    fetchValidations: () => setFetchValidations(true),
  });
  const allValidations = state.validations?.batches || [];
  const inProgressValidations = allValidations.filter((batch) =>
    inProgressStatus.has(batch.status)
  );

  const { data, error, failureCount, dataUpdatedAt } = useBatchValidations({
    options: {
      enabled:
        enabled && (validationRequest || state.status === "fetching") && demoDataModeInitialised,
      refetchInterval: requestIntervalInMs,
      retry,
    },
    variables: { date_from: from, sort: "timestamp.desc" },
  });

  useEffect(() => {
    allValidations
      .filter(
        (validation) =>
          inProgressStatus.has(validation.status) || itWasInProgress.has(validation.batch_id)
      )
      .forEach((validation) => {
        notificationsDispatcher({
          type: "PUSH",
          payload: buildValidationNotification(validation, intl, router),
        });
      }); // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allValidations, itWasInProgress]);

  useEffect(() => {
    setState({
      ...state,
      status: failureCount >= retry ? "idle" : state.status,
      error: (error as Error) || undefined,
    }); // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error, failureCount]);

  useEffect(() => {
    if (!data) return;
    const hasRunningValidations = data.batches.some((batch) => batch.status === "running");

    setItWasInProgress(
      new Set([
        ...itWasInProgress,
        ...inProgressValidations.map((validation) => validation.batch_id),
      ])
    );

    setState({
      ...state,
      validations: data,
      status: hasRunningValidations ? "fetching" : "idle",
      isLoading: false,
    }); // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, dataUpdatedAt]); // `dataUpdatedAt` is required so the fn gets called even after a batch is updated from the UI with the exact same name

  useEffect(() => {
    if (validationRequest) {
      setState({ ...state, status: "fetching", isLoading: true });
      setFetchValidations(false);
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [validationRequest]);

  return <ValidationContext.Provider value={state}>{children}</ValidationContext.Provider>;
};

function useIsValidationContextEnabled() {
  const auth = useAuth();
  const { data: tenant } = useTenant();

  return (
    Boolean(auth.user) &&
    Boolean(tenant?.products?.find(({ name }) => name === Products.LOOKUP)?.name)
  );
}

export const ValidationContextProviderConnected = (
  props: Omit<ValidationContextProviderProps, "enabled">
) => {
  const enabled = useIsValidationContextEnabled();

  return <ValidationContextProvider {...props} enabled={enabled} />;
};

export const ValidationContextConsumer = ({
  children,
}: {
  children: (state: ValidationsState) => ReactNode;
}) => <ValidationContext.Consumer>{children}</ValidationContext.Consumer>;

export const useValidationContext = () => useContext<ValidationsState>(ValidationContext);
