import classNames from "classnames";
import { useIntl } from "react-intl";

import { defaultIconClassNames, IconProps } from "./Icon";

export default function StatusErrorIcon({ size = 32, className = "" }: IconProps) {
  const intl = useIntl();
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 28 28"
      fill="none"
      className={classNames(defaultIconClassNames, className)}
      xmlns="http://www.w3.org/2000/svg"
      aria-label={intl.formatMessage({ defaultMessage: "Status error", id: "pKKWOh" })}
    >
      <path
        d="M14 28C21.732 28 28 21.732 28 14C28 6.26801 21.732 0 14 0C6.26801 0 0 6.26801 0 14C0 21.732 6.26801 28 14 28Z"
        fill="#FEAD9A"
      />
      <path
        d="M14 23C18.9706 23 23 18.9706 23 14C23 9.02944 18.9706 5 14 5C9.02944 5 5 9.02944 5 14C5 18.9706 9.02944 23 14 23Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6 14C6 12.154 6.635 10.457 7.688 9.103L18.897 20.312C17.542 21.365 15.846 22 14 22C9.589 22 6 18.411 6 14ZM20.312 18.897L9.103 7.688C10.458 6.635 12.154 6 14 6C18.411 6 22 9.589 22 14C22 15.846 21.365 17.543 20.312 18.897ZM4 14C4 19.514 8.486 24 14 24C19.514 24 24 19.514 24 14C24 8.486 19.514 4 14 4C8.486 4 4 8.486 4 14Z"
        fill="#BF0711"
      />
    </svg>
  );
}
