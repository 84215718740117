import * as Sentry from "@sentry/nextjs";
import { ReactNode, useEffect } from "react";

import { useAuth } from "@/hooks/auth";

export const WithSentryCustomTags = ({ children }: { children: ReactNode }) => {
  const { user } = useAuth();

  useEffect(() => {
    setSentryTags({
      tenantSlug: user?.tenantSlug,
      demoMode: user?.demoMode,
    });
  }, [user]);

  return <>{children}</>;
};

interface CustomSentryTags {
  tenantSlug?: string;
  demoMode?: boolean;
}

export const setSentryTags = ({ tenantSlug, demoMode }: CustomSentryTags) => {
  Sentry.setTag("tenant_slug", tenantSlug || "undefined");
  Sentry.setTag("demo_mode", typeof demoMode === "boolean" ? demoMode : "undefined");
};
