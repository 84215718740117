import classNames from "classnames";
import { useIntl } from "react-intl";

import { defaultIconClassNames, IconProps } from "./Icon";

interface Props extends IconProps {
  height?: number;
  width?: number;
}

export default function ZipFileIcon({ className = "", height = 30, width = 24 }: Props) {
  const intl = useIntl();
  return (
    <svg
      width={width}
      height={height}
      className={classNames(className, defaultIconClassNames)}
      viewBox="0 0 24 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      aria-label={intl.formatMessage({ defaultMessage: "Zip file", id: "jSjEWQ" })}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.4 1.5c-.44 0-.9.39-.9 1v25c0 .61.46 1 .9 1h19.2c.44 0 .9-.39.9-1V8h-5.625A.875.875 0 0 1 16 7.125V1.5H2.4Zm0-1.5C1.075 0 0 1.12 0 2.5v25C0 28.88 1.075 30 2.4 30h19.2c1.325 0 2.4-1.12 2.4-2.5V7.59a1 1 0 0 0-.279-.693L17.395.307A1 1 0 0 0 16.674 0H2.4Zm3.532 20a.195.195 0 0 1-.144-.056.213.213 0 0 1-.048-.144v-.56c0-.09.019-.16.056-.208.037-.053.059-.082.064-.088l2.784-3.6H6.012a.185.185 0 0 1-.136-.056.185.185 0 0 1-.056-.136V14.6c0-.058.016-.106.048-.144a.195.195 0 0 1 .144-.056h3.824c.059 0 .107.02.144.056a.195.195 0 0 1 .056.144v.552c0 .096-.035.19-.104.28l-2.728 3.616h2.72c.059 0 .107.02.144.056a.195.195 0 0 1 .056.144v.56a.195.195 0 0 1-.056.144.213.213 0 0 1-.144.048H5.932Zm5.552 0a.185.185 0 0 1-.136-.056.185.185 0 0 1-.056-.136v-5.216a.17.17 0 0 1 .056-.136.185.185 0 0 1 .136-.056h.752a.17.17 0 0 1 .136.056.17.17 0 0 1 .056.136v5.216a.185.185 0 0 1-.056.136.17.17 0 0 1-.136.056h-.752Zm2.495-.056a.185.185 0 0 0 .136.056h.76a.17.17 0 0 0 .136-.056.185.185 0 0 0 .056-.136v-1.872h1.192c.645 0 1.149-.146 1.512-.44.368-.298.552-.738.552-1.32 0-.58-.184-1.02-.552-1.32-.368-.304-.872-.456-1.512-.456h-2.144a.195.195 0 0 0-.144.056.213.213 0 0 0-.048.144v5.208c0 .054.018.1.056.136Zm2.952-3.112c-.166.144-.403.216-.712.216H15.05v-1.76h1.168c.32 0 .56.08.72.24.16.155.24.371.24.648 0 .288-.083.507-.248.656Z"
      />
    </svg>
  );
}
