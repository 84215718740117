import { Toast, ToastAction } from "@fonoa/ui-components/toast";
import { noop } from "@fonoa/ui-components/utils";
import {
  createContext,
  Dispatch,
  FC,
  PropsWithChildren,
  ReactNode,
  useContext,
  useEffect,
  useReducer,
} from "react";

type NotificationToast = {
  title: ReactNode;
  description?: ReactNode;
};

type NotificationAction = {
  title: ReactNode;
  onClick: () => void;
};

export type NotificationConfig = {
  id: string;
  title: ReactNode;
  description?: ReactNode;
  toast?: NotificationToast;
  action?: NotificationAction;
  type?: "info" | "success" | "error" | "warning";
};

export type NotificationElement = NotificationConfig & {
  unread: boolean;
  pendingToast: boolean;
};

type NotificationsState = {
  isCenterOpen: boolean;
  hasUnreadNotifications: boolean;
  notifications: NotificationElement[];
};
const INITIAL_STATE: NotificationsState = {
  isCenterOpen: false,
  hasUnreadNotifications: false,
  notifications: [],
};

export type NotificationsDispatcherAction =
  | { type: "OPEN_DRAWER" }
  | { type: "CLOSE_DRAWER" }
  | { type: "PUSH"; payload: NotificationConfig }
  | { type: "READ_ALL" }
  | { type: "READ"; payload: { notificationId: string } }
  | { type: "ALL_TOASTS_SHOWN" };

export type NotificationsDispatcher = Dispatch<NotificationsDispatcherAction>;

const NotificationsStateContext = createContext<NotificationsState>(INITIAL_STATE);
const NotificationsDispatcherContext = createContext<NotificationsDispatcher>(noop);

const pushNotificationToManager = (
  manager: NotificationsState,
  newNotification: NotificationConfig
) => {
  const previousNotification = manager.notifications.find((n) => n.id === newNotification.id);
  const pendingToast = Boolean(
    newNotification.toast &&
      (!previousNotification || previousNotification.type !== newNotification.type)
  );

  return {
    ...manager,
    notifications: !previousNotification
      ? [...manager.notifications, { ...newNotification, unread: true, pendingToast: pendingToast }]
      : manager.notifications.map((currentNotification) =>
          currentNotification === previousNotification
            ? { ...previousNotification, ...newNotification, pendingToast: pendingToast }
            : currentNotification
        ),
    hasUnreadNotifications: manager.hasUnreadNotifications || !previousNotification,
  };
};

const fireToasts = (
  notificationElement: NotificationElement,
  dispatch: NotificationsDispatcher
) => {
  const action: ToastAction | undefined = notificationElement.action
    ? {
        title: notificationElement.action.title,
        onClick: () => {
          notificationElement?.action?.onClick();
          dispatch({ type: "READ", payload: { notificationId: notificationElement.id } });
        },
      }
    : undefined;

  Toast(
    notificationElement?.toast?.title,
    {
      type: notificationElement.type || "info",
      description: notificationElement?.toast?.description,
      highContrast: true,
      inline: true,
      action,
    },
    {
      onClick: () => dispatch({ type: "OPEN_DRAWER" }),
    }
  );
};

const readNotification = (state: NotificationsState, id: string): NotificationsState => {
  const hasUnreadNotifications =
    state.notifications.filter((n) => id !== n.id && n.unread).length > 0;

  return {
    ...state,
    hasUnreadNotifications,
    notifications: state.notifications.map((notification) =>
      notification.id === id ? { ...notification, unread: false } : notification
    ),
  };
};

const stateReducer = (
  state: NotificationsState,
  action: NotificationsDispatcherAction
): NotificationsState => {
  switch (action.type) {
    case "OPEN_DRAWER":
      return { ...state, isCenterOpen: true };
    case "CLOSE_DRAWER":
      return { ...state, isCenterOpen: false };
    case "PUSH":
      return pushNotificationToManager(state, action.payload);
    case "READ":
      return readNotification(state, action.payload.notificationId);
    case "READ_ALL":
      return {
        ...state,
        hasUnreadNotifications: false,
        notifications: state.notifications.map((notification) => ({
          ...notification,
          unread: false,
        })),
      };
    case "ALL_TOASTS_SHOWN":
      return {
        ...state,
        notifications: state.notifications.map((current) => ({ ...current, pendingToast: false })),
      };
  }
};
export const NotificationsManagerProvider: FC<PropsWithChildren<unknown>> = ({ children }) => {
  const [state, dispatch] = useReducer(stateReducer, INITIAL_STATE);

  useEffect(() => {
    const toastsToShow = state.notifications.filter((current) => current.pendingToast);

    if (toastsToShow.length) {
      toastsToShow.forEach((current) => fireToasts(current, dispatch));
      dispatch({ type: "ALL_TOASTS_SHOWN" });
    }
  }, [state.notifications]);

  return (
    <NotificationsStateContext.Provider value={state}>
      <NotificationsDispatcherContext.Provider value={dispatch}>
        {children}
      </NotificationsDispatcherContext.Provider>
    </NotificationsStateContext.Provider>
  );
};

export const useNotificationsState = () => {
  return useContext<NotificationsState>(NotificationsStateContext);
};

export const useNotificationsDispatcher = () => {
  return useContext<NotificationsDispatcher>(NotificationsDispatcherContext);
};

export const useIsNotificationsCenterOpen = () => {
  const notificationsState = useContext<NotificationsState>(NotificationsStateContext);
  return notificationsState.isCenterOpen;
};

export const useHasUnreadNotifications = () => {
  const notificationsState = useContext<NotificationsState>(NotificationsStateContext);
  return notificationsState.hasUnreadNotifications;
};
