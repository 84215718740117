import classNames from "classnames";
import { useIntl } from "react-intl";

import { defaultIconClassNames, IconProps } from "./Icon";

export default function SortIcon({ size = 10, className = "" }: IconProps) {
  const intl = useIntl();
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 10 10"
      fill="none"
      className={classNames(defaultIconClassNames, className)}
      xmlns="http://www.w3.org/2000/svg"
      aria-label={intl.formatMessage({ defaultMessage: "Sort", id: "25oM9Q" })}
    >
      <path d="M9.375 1.875C9.375 1.52982 9.09518 1.25 8.75 1.25H1.25C0.904821 1.25 0.625 1.52982 0.625 1.875C0.625 2.22018 0.904821 2.5 1.25 2.5H8.75C9.09518 2.5 9.375 2.22018 9.375 1.875ZM7.5 5C7.5 4.65482 7.22018 4.375 6.875 4.375H1.25C0.904821 4.375 0.625 4.65482 0.625 5C0.625 5.34518 0.904821 5.625 1.25 5.625H6.875C7.22018 5.625 7.5 5.34518 7.5 5ZM5 7.5C5.34518 7.5 5.625 7.77982 5.625 8.125C5.625 8.47018 5.34518 8.75 5 8.75H1.25C0.904821 8.75 0.625 8.47018 0.625 8.125C0.625 7.77982 0.904821 7.5 1.25 7.5H5Z" />
    </svg>
  );
}
