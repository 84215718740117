import classNames from "classnames";
import { useIntl } from "react-intl";

import { defaultIconClassNames, IconProps } from "./Icon";

export default function SupportIcon({ size = 72, className = "" }: IconProps) {
  const intl = useIntl();
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 72 72"
      fill="none"
      className={classNames(defaultIconClassNames, className)}
      xmlns="http://www.w3.org/2000/svg"
      aria-label={intl.formatMessage({ defaultMessage: "Support", id: "HqRNN8" })}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M30 21.0878C27.2386 21.0878 25 23.3263 25 26.0878V60.6492C25 63.4106 27.2386 65.6492 30 65.6492H51.8772V65.6493H71L64.4737 56.4491V26.0878C64.4737 23.3263 62.2351 21.0878 59.4737 21.0878H30Z"
        fill="#BFEEC9"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M33 31.4912H57V33.5965H33V31.4912ZM33 37.1053H56.9999V39.2106H33V37.1053ZM56.9999 42.7194H33V44.8246H56.9999V42.7194ZM33 48.3334H56.9999V50.4387H33V48.3334ZM56.9999 53.9474H33V56.0526H56.9999V53.9474Z"
        fill="#47B974"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.8246 42.8421C30.9983 42.8421 39.2457 34.5947 39.2457 24.4211C39.2457 14.2474 30.9983 6 20.8246 6C10.651 6 2.40357 14.2474 2.40357 24.4211C2.40357 28.6112 3.80255 32.4745 6.15891 35.5696L1 42.8421H20.1228V42.829C20.3557 42.8377 20.5896 42.8421 20.8246 42.8421Z"
        fill="#133550"
      />
      <path d="M22.021 29.4503H19.366V32.1052H22.021V29.4503Z" fill="white" />
      <path
        d="M20.6959 16.1755C17.7621 16.1755 15.386 18.5517 15.386 21.4854H18.041C18.041 20.0251 19.2357 18.8304 20.696 18.8304C22.1562 18.8304 23.3509 20.0252 23.3509 21.4855C23.3509 24.1404 19.3684 23.8085 19.3684 28.1229H22.0234C22.0234 25.136 26.0059 24.8042 26.0059 21.4855C26.0059 18.5517 23.6297 16.1755 20.6959 16.1755Z"
        fill="white"
      />
    </svg>
  );
}
