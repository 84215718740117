import { Typography } from "@fonoa/ui-components/typography";
import { ReactNode } from "react";
import { FormattedMessage, useIntl } from "react-intl";

import { RangeTag } from "@/components/Utils/Date";
import { getFormatCell } from "@/features/Lookup/ValidationHistory/ValidationHistory.utils";
import { useCountries } from "@/hooks/useCountries";
import { useCurrencies } from "@/hooks/useCurrencies";
import getCountryFlagPath from "@/lib/countryFlagsPath";
import { ErrorCategory } from "@/lib/types/transaction";

export const useUserStatusOptions = () => {
  const intl = useIntl();

  return [
    {
      label: intl.formatMessage({ defaultMessage: "Active", id: "3a5wL8" }),
      value: "active",
    },
    {
      label: intl.formatMessage({ defaultMessage: "Inactive", id: "6Tps09" }),
      value: "inactive",
    },
  ];
};

interface CountryOptionsProps {
  flagRatio?: "1x1" | "3x4";
  excludeFlag?: boolean;
  includeCountryCodeOnLabel?: boolean;
}

export const useCountryOptions = (props?: CountryOptionsProps) => {
  const { isLoading, countries } = useCountries();
  const { flagRatio, excludeFlag } = props || {};

  return {
    isLoadingCountryOptions: isLoading,
    countryOptions: Object.values(countries).map(({ id, name }) => ({
      value: id,
      label: props?.includeCountryCodeOnLabel ? `${name} (${id.toUpperCase()})` : name,
      ...(excludeFlag ? {} : { flag: getCountryFlagPath(id, flagRatio) }),
    })),
  };
};

export const useCurrencyOptions = () => {
  const { isLoading, currencies } = useCurrencies();

  return {
    isLoadingCurrencyOptions: isLoading,
    currencyOptions: Object.values(currencies).map(({ code, name }) => ({
      value: code,
      label: `${name} (${code})`,
    })),
  };
};

export type DateRange = RangeTag;

interface DateRangeFilterOptionsProps {
  exclude?: DateRange[];
}

export const useDateRangeFilterOptions = (options?: DateRangeFilterOptionsProps) => {
  const intl = useIntl();

  const dateRangeOptions: { value: DateRange; label: ReactNode }[] = [
    {
      value: "today",
      label: intl.formatMessage({ defaultMessage: "Today", id: "zWgbGg" }),
    },
    {
      value: "last_7_days",
      label: intl.formatMessage({ defaultMessage: "Last 7 days", id: "irFBKn" }),
    },
    {
      value: "last_14_days",
      label: intl.formatMessage({ defaultMessage: "Last 14 days", id: "BVVhyH" }),
    },
    {
      value: "last_30_days",
      label: intl.formatMessage({ defaultMessage: "Last 30 days", id: "Rfvi9/" }),
    },
    {
      value: "last_3_months",
      label: intl.formatMessage({ defaultMessage: "Last 3 months", id: "E0NcxQ" }),
    },
    {
      value: "last_6_months",
      label: intl.formatMessage({ defaultMessage: "Last 6 months", id: "wFqRlU" }),
    },
    {
      value: "last_12_months",
      label: intl.formatMessage({ defaultMessage: "Last 12 months", id: "r5sWuC" }),
    },
    {
      value: "custom",
      label: intl.formatMessage({ defaultMessage: "Specific dates", id: "To1iMR" }),
    },
  ];

  if (!options?.exclude) return dateRangeOptions;

  return dateRangeOptions.filter((option) => !options?.exclude?.includes(option.value));
};

export const useMaxTotalFilterOptions = () => {
  const intl = useIntl();
  return [
    {
      value: "50",
      label: `${intl.formatMessage({ defaultMessage: "Under", id: "uhROer" })} 50`,
    },
    {
      value: "100",
      label: `${intl.formatMessage({ defaultMessage: "Under", id: "uhROer" })} 100`,
    },
    {
      value: "500",
      label: `${intl.formatMessage({ defaultMessage: "Under", id: "uhROer" })} 500`,
    },
    {
      value: "1000",
      label: `${intl.formatMessage({ defaultMessage: "Under", id: "uhROer" })} 1k`,
    },
    {
      value: "5000",
      label: `${intl.formatMessage({ defaultMessage: "Under", id: "uhROer" })} 5k`,
    },
    {
      value: "10000",
      label: `${intl.formatMessage({ defaultMessage: "Under", id: "uhROer" })} 10k`,
    },
    {
      value: "15000",
      label: `${intl.formatMessage({ defaultMessage: "Under", id: "uhROer" })} 15k`,
    },
  ];
};

export const useTransactionDocumentTypes = () => {
  const intl = useIntl();

  return [
    {
      label: intl.formatMessage({ defaultMessage: "Debit", id: "MxD6vP" }),
      value: "debit",
    },
    {
      label: intl.formatMessage({ defaultMessage: "Credit", id: "mLvz+r" }),
      value: "credit",
    },
  ];
};

export const CUSTOM_FONT_OPTIONS = [
  {
    label: "Roboto",
    value: "Roboto",
    fontFaces: [
      {
        name: "Roboto",
        url: "url(https://stfonoatemp.blob.core.windows.net/invoice-resources/fonts/Roboto-Regular.ttf)",
        options: { weight: "400" },
      },
      {
        name: "Roboto",
        url: "url(https://stfonoatemp.blob.core.windows.net/invoice-resources/fonts/Roboto-Medium.ttf)",
        options: { weight: "600" }, // There is no Roboto Semibold, so we'll use the Medium weight for this
      },
      {
        name: "Roboto",
        url: "url(https://stfonoatemp.blob.core.windows.net/invoice-resources/fonts/Roboto-Bold.ttf)",
        options: { weight: "700" },
      },
    ],
  },
  {
    label: "Open Sans",
    value: "Open Sans",
    fontFaces: [
      {
        url: "url(https://stfonoatemp.blob.core.windows.net/invoice-resources/fonts/OpenSans-Regular.ttf)",
        options: { weight: "400" },
      },
      {
        url: "url(https://stfonoatemp.blob.core.windows.net/invoice-resources/fonts/OpenSans-SemiBold.ttf)",
        options: { weight: "600" },
      },
      {
        url: "url(https://stfonoatemp.blob.core.windows.net/invoice-resources/fonts/OpenSans-Bold.ttf)",
        options: { weight: "700" },
      },
    ],
  },
  {
    label: "Source Serif Pro",
    value: "Source Serif Pro",
    fontFaces: [
      {
        url: "url(https://stfonoatemp.blob.core.windows.net/invoice-resources/fonts/SourceSerifPro-Regular.ttf)",
        options: { weight: "400" },
      },
      {
        url: "url(https://stfonoatemp.blob.core.windows.net/invoice-resources/fonts/SourceSerifPro-SemiBold.ttf)",
        options: { weight: "600" },
      },
      {
        url: "url(https://stfonoatemp.blob.core.windows.net/invoice-resources/fonts/SourceSerifPro-Bold.ttf)",
        options: { weight: "700" },
      },
    ],
  },
  {
    label: "Source Sans Pro",
    value: "Source Sans Pro",
    fontFaces: [
      {
        url: "url(https://stfonoatemp.blob.core.windows.net/invoice-resources/fonts/SourceSansPro-Regular.ttf)",
        options: { weight: "400" },
      },
      {
        url: "url(https://stfonoatemp.blob.core.windows.net/invoice-resources/fonts/SourceSansPro-SemiBold.ttf)",
        options: { weight: "600" },
      },
      {
        url: "url(https://stfonoatemp.blob.core.windows.net/invoice-resources/fonts/SourceSansPro-Bold.ttf)",
        options: { weight: "700" },
      },
    ],
  },
  {
    label: "Oswald",
    value: "Oswald",
    fontFaces: [
      {
        url: "url(https://stfonoatemp.blob.core.windows.net/invoice-resources/fonts/Oswald-Regular.ttf)",
        options: { weight: "400" },
      },
      {
        url: "url(https://stfonoatemp.blob.core.windows.net/invoice-resources/fonts/Oswald-SemiBold.ttf)",
        options: { weight: "600" },
      },
      {
        url: "url(https://stfonoatemp.blob.core.windows.net/invoice-resources/fonts/Oswald-Bold.ttf)",
        options: { weight: "700" },
      },
    ],
  },
  {
    label: "Lora",
    value: "Lora",
    fontFaces: [
      {
        url: "url(https://stfonoatemp.blob.core.windows.net/invoice-resources/fonts/Lora-Regular.ttf)",
        options: { weight: "400" },
      },
      {
        url: "url(https://stfonoatemp.blob.core.windows.net/invoice-resources/fonts/Lora-SemiBold.ttf)",
        options: { weight: "600" },
      },
      {
        url: "url(https://stfonoatemp.blob.core.windows.net/invoice-resources/fonts/Lora-Bold.ttf)",
        options: { weight: "700" },
      },
    ],
  },
  {
    label: "Noto Sans",
    value: "Noto Sans",
    fontFaces: [
      {
        url: "url(https://stfonoatemp.blob.core.windows.net/invoice-resources/fonts/NotoSans-Regular.ttf)",
        options: { weight: "400" },
      },
      // There is no NotoSans Semibold
      {
        url: "url(https://stfonoatemp.blob.core.windows.net/invoice-resources/fonts/NotoSans-Bold.ttf)",
        options: { weight: "700" },
      },
    ],
  },
];

export const tinStatusesV1 = [
  {
    label: (
      <Typography color="text-primaryBlue500" fontSize="text-sm">
        <FormattedMessage defaultMessage="Valid" id="BN5yXZ" />
      </Typography>
    ),
    value: "valid" as const,
  },
  {
    label: (
      <Typography color="text-red500" fontSize="text-sm">
        <FormattedMessage defaultMessage="Invalid" id="89T/ze" />
      </Typography>
    ),
    value: "invalid" as const,
  },
  {
    label: (
      <Typography
        color="text-blueGray600"
        fontSize="text-sm"
        dataAttributes={{ cy: "tin_exists_online" }}
      >
        <FormattedMessage defaultMessage="Unknown" id="5jeq8P" />
      </Typography>
    ),
    value: "unknown" as const,
  },
];

export const tinStatusesV2 = [
  {
    label: (
      <Typography color="text-primaryBlue500" fontSize="text-sm">
        <FormattedMessage defaultMessage="Yes" id="a5msuh" />
      </Typography>
    ),
    value: "yes",
  },
  {
    label: (
      <Typography color="text-red500" fontSize="text-sm">
        <FormattedMessage defaultMessage="No" id="oUWADl" />
      </Typography>
    ),
    value: "no",
  },
  {
    label: (
      <Typography color="text-blueGray600" fontSize="text-sm">
        <FormattedMessage defaultMessage="Unknown" id="5jeq8P" />
      </Typography>
    ),
    value: "unknown",
  },
  {
    label: (
      <Typography color="text-blueGray600" fontSize="text-sm">
        <FormattedMessage defaultMessage="Not applicable" id="61zy45" />
      </Typography>
    ),
    value: "not_applicable",
  },
];

export const useFormatChecksumStatuses = () => {
  return [
    {
      label: getFormatCell({ format: "valid" }),
      value: "valid" as const,
    },
    {
      label: getFormatCell({ format: "invalid" }),
      value: "invalid" as const,
    },
  ];
};

export const ERROR_TRANSACTION_CATEGORIES = [
  {
    label: <FormattedMessage defaultMessage="Tax Authority Error" id="TiVtsn" />,
    value: ErrorCategory.TaxAuthority,
  },
  {
    label: <FormattedMessage defaultMessage="Validation Error" id="+rF4tW" />,
    value: ErrorCategory.Validation,
  },
  {
    label: <FormattedMessage defaultMessage="Other" id="/VnDMl" />,
    value: ErrorCategory.General,
  },
];

export const ERROR_TRANSACTION_STATUSES = [
  {
    label: <FormattedMessage defaultMessage="Resolved" id="W6nSYE" />,
    value: "resolved" as const,
  },
  {
    label: <FormattedMessage defaultMessage="Unresolved" id="0U6tmb" />,
    value: "unresolved" as const,
  },
];

export const RETURN_CSV_STATUSES = [
  {
    label: <FormattedMessage defaultMessage="Active" id="3a5wL8" />,
    value: "active",
  },
  {
    label: <FormattedMessage defaultMessage="Inactive" id="6Tps09" />,
    value: "inactive",
  },
  {
    label: <FormattedMessage defaultMessage="Processing" id="1Ia8qN" />,
    value: "processing",
  },
  {
    label: <FormattedMessage defaultMessage="Validation failed" id="cT0yxo" />,
    value: "validation-failed",
  },
  {
    label: <FormattedMessage defaultMessage="Processing failed" id="49igYb" />,
    value: "processing-failed",
  },
];

export const RETURN_TRANSACTION_STATUSES = [
  {
    label: <FormattedMessage defaultMessage="Active" id="3a5wL8" />,
    value: "active",
  },
  {
    label: <FormattedMessage defaultMessage="Deactivated" id="plMsEA" />,
    value: "deactivated",
  },
  {
    label: <FormattedMessage defaultMessage="Duplicated" id="hZKFvw" />,
    value: "deactivated_duplicate",
  },
  {
    label: <FormattedMessage defaultMessage="Used in Return" id="A0ghka" />,
    value: "used_in_return",
  },
];

export const RETURN_TRANSACTION_SEARCH = [
  {
    label: <FormattedMessage defaultMessage="Invoice Number" id="TC0H0T" />,
    value: "invoice-number",
  },
  {
    label: <FormattedMessage defaultMessage="Item ID" id="EzuXw/" />,
    value: "item-id",
  },
  {
    label: <FormattedMessage defaultMessage="CSV Name" id="JhBlcn" />,
    value: "csv-name",
  },
];

export const RETURN_TRANSACTION_WARNINGS = [
  {
    label: <FormattedMessage defaultMessage="Any" id="ToO3/h" />,
    value: "any",
  },
  {
    label: <FormattedMessage defaultMessage="None" id="450Fty" />,
    value: "none",
  },
  {
    label: <FormattedMessage defaultMessage="Country currency mismatch" id="n7cFYr" />,
    value: "country_currency_mismatch",
  },
  {
    label: <FormattedMessage defaultMessage="Incorrect tax value" id="mpuPEk" />,
    value: "incorrect_tax_value",
  },
  {
    label: <FormattedMessage defaultMessage="Tax number mismatch" id="9Wn1Aq" />,
    value: "tax_number_taxable_entity_mismatch",
  },
];

export const useBatchStatuses = () => {
  const intl = useIntl();

  return [
    {
      label: intl.formatMessage({ defaultMessage: "Imported", id: "Udsmo2" }),
      value: "imported",
    },
    {
      label: intl.formatMessage({ defaultMessage: "Completed", id: "95stPq" }),
      value: "completed",
    },
    {
      label: intl.formatMessage({ defaultMessage: "In-progress", id: "12rpNY" }),
      value: "in_progress",
    },
    {
      label: intl.formatMessage({ defaultMessage: "Failed", id: "vXCeIi" }),
      value: "failed",
    },
  ];
};
